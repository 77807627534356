package es.cinfo.tiivii.core.features.catalogue.binder

import es.cinfo.tiivii.core.features.catalogue.store.CatalogueStore.*
import es.cinfo.tiivii.core.features.catalogue.view.CatalogueView.*

internal val STATE_TO_MODEL: State.() -> Model = {
    Model(
        nodeTree = nodeTree?.toViewModel(),
        selectedNode = selectedNode?.toViewModel(),
        areNodesLoading = loadingNodes.toViewModel(),
        nodeContents = nodeContents?.toViewModel(),
        selectedContent = selectedContent?.toViewModel(),
        hasMoreContents = hasMoreContents,
        queryFilter = queryFilter,
        selectedSort = selectedSort?.id,
        availableSorts = availableSorts?.map { it.id },
        areContentsLoading = loadingContents.toViewModel()
    )
}

internal val EVENT_TO_INTENT: Event.() -> Intent = {
    when (this) {
        is Event.LoadNode -> Intent.LoadNode(coordinates)
        is Event.Search -> Intent.Search(query)
        Event.ClearSearch -> Intent.ClearSearch
        is Event.Sort -> Intent.Sort(sortId)
        Event.Reload -> Intent.Reload
        Event.LoadMoreContent -> Intent.LoadMoreContent
        is Event.SelectContent -> Intent.SelectContent(coordinates)
        Event.DeselectContent -> Intent.DeselectContent
        Event.LogCatalogueView -> Intent.LogCatalogueView
        is Event.AddToFavorites -> Intent.AddToFavorites(coordinates)
        is Event.RemoveFromFavorites -> Intent.RemoveFromFavorites(coordinates)
    }
}

internal val LABEL_TO_OUTPUT: Label.() -> Output = {
    when (this) {
        is Label.UnexpectedError -> Output.UnexpectedError(code)
        is Label.GameActionSent -> Output.GameActionSent(actionResult.toViewModel())
        Label.UserSessionExpired -> Output.UserSessionExpired
        Label.RequestTimedOut -> Output.RequestTimedOut
        is Label.IllegalOperationError -> Output.IllegalOperationError(reason)
    }
}

package es.cinfo.tiivii.di

import es.cinfo.tiivii.core.modules.geolocation.LocationService
import org.kodein.di.DI
import org.kodein.di.bind
import org.kodein.di.singleton
import org.kodein.di.subDI

/**
 * Dependency injection module for location related components
 */
internal fun jsLocationModule() = DI.Module("location-js") {
    bind<LocationService>() with singleton {
        LocationService()
    }
}


/**
 * Dependency injection container
 */
internal actual var diContainer = subDI(coreContainer()) {
    import(jsLocationModule())
}.di
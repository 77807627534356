package es.cinfo.tiivii.core.modules.chat.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class PostMessageApiRequest(
    @SerialName(CHANNEL_PARAM)
    val channel: String,
    @SerialName(EVENT_PARAM)
    val event: String,
    @SerialName(MESSAGE_PARAM)
    val message: String
) {
    companion object {
        const val CHANNEL_PARAM = "channel"
        const val EVENT_PARAM = "event"
        const val MESSAGE_PARAM = "message"
    }
}
package es.cinfo.tiivii.core.profile

import kotlinx.serialization.SerializationException

sealed class ProfileModel {
    sealed class ViewModel {
        enum class Profile {
            KID, ADULT
        }
    }
    internal sealed class Model {
        enum class Profile {
            KID, ADULT;

            companion object {
                fun parseOrNull(value: String): Profile? {
                    return when (value) {
                        "kid" -> KID
                        "adult" -> ADULT
                        else -> null
                    }
                }

                fun parse(value: String): Profile {
                    return when (value) {
                        "kid" -> KID
                        "adult" -> ADULT
                        else -> throw SerializationException("Unrecognized profile $value")
                    }
                }

                fun byAge(age: Int): Profile {
                    return if (age > 13) {
                        ADULT
                    } else {
                        KID
                    }
                }
            }

            fun toViewModel(): ViewModel.Profile {
                return when (this) {
                    KID -> ViewModel.Profile.KID
                    ADULT -> ViewModel.Profile.ADULT
                }
            }
        }
    }

}


//internal enum class Profile {
//    KID, ADULT;
//
//    companion object {
//        fun parseOrNull(value: String): Profile? {
//            return when (value) {
//                "kid" -> KID
//                "adult" -> ADULT
//                else -> null
//            }
//        }
//
//        fun parse(value: String): Profile {
//            return when (value) {
//                "kid" -> KID
//                "adult" -> ADULT
//                else -> throw SerializationException("Unrecognized profile $value")
//            }
//        }
//
//        fun byAge(age: Int): Profile {
//            return if (age > 13) {
//                ADULT
//            } else {
//                KID
//            }
//        }
//    }
//}
package es.cinfo.tiivii.core.util

import kotlin.js.JsName

/**
 * Interface to be implemented by any content that can be nested
 */
interface Nestable {
    /**
     * Indicates if the related content has children. This will be used to build the [Tree]
     */
    fun hasChildren(): Boolean

    /**
     * A string representation associated with the content that may be used to obtain the full path of
     * contents to any given [Node]
     */
    fun getName(): String
}

/**
 * Interface that has to be implemented if any [Nestable] class needs to be exposed to the UI's
 */
interface Mappable<VM: Nestable> {
    /**
     * Function to be implemented by the content that transforms it to a UI valid representation
     */
    fun toViewModel(): VM
}

/**
 * Full interface for any content that may be inserted in a [Node]
 */
interface NodeValue<VM: Nestable>: Nestable, Mappable<VM>

sealed class ViewModel {

    /**
     * Tree representation of any kind of content that can be nested. Each content is represented by a [Node]
     * @param rootNodes Represents the first level contents available in the form of [Node]'s
     */
    data class Tree<M: Nestable>(
        val rootNodes: List<Node<M>>
    ) {
        @JsName("getPath")
        fun getPath(coordinates: List<Int>): List<String>? {
            val path = mutableListOf<String>()
            var currentLevel: List<Node<M>>? = rootNodes
            for (levelPos in coordinates) {
                // If level exists and node exists in level
                if (currentLevel != null && levelPos < currentLevel.size) {
                    path.add(currentLevel[levelPos].value.getName())
                    currentLevel = currentLevel[levelPos].children
                } else {
                    return null
                }
            }
            return path
        }

        @JsName("arrayToList")
        fun arrayToList(array: Array<Int>): List<Int>? {
            return array.toList()
        }

        /**
         * Retrieves the children nodes of the node at the given coordinates or the root nodes of
         * a tree if the coordinates are null or empty as a flat list
         * @param coordinates of the node to retrieve the children for
         */
        @JsName("asList")
        fun asList(coordinates: List<Int>? = null): List<M> {
            return if (coordinates == null || coordinates.isEmpty()) {
                rootNodes.map { it.value }
            } else {
                val node = findNodeOrNull(coordinates)
                node?.children?.map { it.value } ?: emptyList()
            }
        }

        private fun findNodeOrNull(coordinates: List<Int>): Node<M>? {
            return try {
                findNode(coordinates)
            } catch (e: NoSuchElementException) {
                null
            }
        }

        private fun findNode(coordinates: List<Int>): Node<M> {
            var nextLevel: List<Node<M>>? = rootNodes
            var nodeToUpdate: Node<M>? = null
            for (levelPos in coordinates) {
                // If level exists and node exists in level
                if (nextLevel != null && levelPos < nextLevel.size) {
                    nodeToUpdate = nextLevel[levelPos]
                    nextLevel = nodeToUpdate.children
                } else {
                    nodeToUpdate = null
                }
            }
            if (nodeToUpdate == null) {
                throw NoSuchElementException()
            } else {
                return nodeToUpdate
            }
        }
    }

    /**
     * Representation of a node inside a tree. Each [Node] may be final or have more children as [Node]s.
     * @param coordinates of the node for fast search of a node inside the tree
     * @param children List of children sections of the current [Node]
     * @param value Any representation of content associated to a node (must implement [Nestable]) Any custom
     * properties are exposed through this field to the UI's
     */
    data class Node<M: Nestable>(
        val coordinates: List<Int>,
        var children: List<Node<M>>? = null,
        val value: M
    )
}

internal sealed class Model {
    open class Tree<VM : Nestable, M: NodeValue<VM>>(nodes: List<M>) {
        var rootNodes: List<Node<VM, M>> = nodes.mapIndexed { index, node ->
            var children: List<Node<VM, M>>? = null
            if (!node.hasChildren()) {
                children = emptyList()
            }
            Node(
                coordinates = listOf(index),
                hasChildren = node.hasChildren(),
                children = children,
                value = node
            )
        }

        fun toViewModel(): ViewModel.Tree<VM> {
            return ViewModel.Tree(
                rootNodes.map {
                    it.toViewModel()
                }
            )
        }

        fun addChildren(coordinates: List<Int>, children: List<M>): Boolean {
            val nodeToUpdate: Node<VM, M>? = findNodeOrNull(coordinates)
            nodeToUpdate?.let {
                val nodes = mutableListOf<Node<VM, M>>()
                children.forEachIndexed { index, contentNode ->
                    val nodeCoordinates = nodeToUpdate.coordinates + index
                    var grandchildren: List<Node<VM, M>>? = null
                    if (!contentNode.hasChildren()) {
                        grandchildren = emptyList()
                    }
                    nodes.add(
                        Node(
                            coordinates = nodeCoordinates,
                            hasChildren = contentNode.hasChildren(),
                            children = grandchildren,
                            value = contentNode,
                        )
                    )
                }
                nodeToUpdate.children = nodes
            }
            return nodeToUpdate != null
        }

        fun addRootNodes(nodes: List<M>) {
            val nextIndex = rootNodes.size
            rootNodes = rootNodes + nodes.mapIndexed { index, node ->
                Node(
                    coordinates = listOf(nextIndex + index),
                    hasChildren = node.hasChildren(),
                    children = null,
                    value = node
                )
            }
        }

        fun findNode(coordinates: List<Int>): Node<VM, M> {
            var nextLevel: List<Node<VM, M>>? = rootNodes
            var nodeToUpdate: Node<VM, M>? = null
            for (levelPos in coordinates) {
                // If level exists and node exists in level
                if (nextLevel != null && levelPos < nextLevel.size) {
                    nodeToUpdate = nextLevel[levelPos]
                    nextLevel = nodeToUpdate.children
                } else {
                    nodeToUpdate = null
                }
            }
            if (nodeToUpdate == null) {
                throw NoSuchElementException()
            } else {
                return nodeToUpdate
            }
        }

        fun findNodeOrNull(coordinates: List<Int>): Node<VM, M>? {
            return try {
                findNode(coordinates)
            } catch (e: NoSuchElementException) {
                null
            }
        }
    }

    open class Node<VM : Nestable, M: NodeValue<VM>>(
        val coordinates: List<Int>,
        val hasChildren: Boolean,
        var children: List<Node<VM, M>>? = null,
        val value: M
    ) {
        fun addChildren(children: List<M>) {
            val nodes = mutableListOf<Node<VM, M>>()
            children.forEachIndexed { index, contentNode ->
                val nodeCoordinates = coordinates + index
                var grandchildren: List<Node<VM, M>>? = null
                if (!contentNode.hasChildren()) {
                    grandchildren = emptyList()
                }
                nodes.add(
                    Node(
                        coordinates = nodeCoordinates,
                        hasChildren = contentNode.hasChildren(),
                        children = grandchildren,
                        value = contentNode,
                    )
                )
            }
            this.children = nodes
        }

        fun toViewModel(): ViewModel.Node<VM> {
            return ViewModel.Node(
                coordinates = coordinates,
                children = children?.map { it.toViewModel() },
                value = value.toViewModel()
            )
        }
    }
}
package es.cinfo.tiivii.core.error

import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

interface ErrorService {
    suspend fun <E: CodedError> handleError(
        error: E,
        sessionExpiredAction: suspend () -> Unit = {},
        requestTimeoutAction: suspend () -> Unit = {},
        handler: suspend () -> Unit
    )
    fun handleStoreStateError(
        message: String,
        handler: () -> Unit
    )
}

class DefaultErrorService: ErrorService {

    private val configModule: ConfigModule by diContainer.instance()

    override suspend fun <E: CodedError> handleError(
        error: E,
        sessionExpiredAction: suspend () -> Unit,
        requestTimeoutAction: suspend () -> Unit,
        handler: suspend () -> Unit
    ) {
        if (configModule.getEnvConfig().loggingEnabled) {
            var errorMessage = ""
            errorMessage += "ErrorService | Use case error encountered, code: ${error.getCode()}\n"
            errorMessage += "----------------------------------------------------------------------------------------------\n"
            errorMessage += "\t- Component: ${error.component.id}, ${error.component.name}\n"
            errorMessage += "\t- Use case id: ${error.useCase.id}, ${error.useCase.name}\n"
            errorMessage += "\t- Error id: ${error.errorId.id}, ${error.errorId.name}\n"
            errorMessage += "\t- Error detail: ${error}\n"
            if (error.networkError != null) {
                errorMessage += "\t- Internal error: ${error.networkError}\n"
                if (error.networkError is NetworkError.Execution) {
                    errorMessage += "\t\t- Stacktrace: ${error.networkError.throwable.stackTraceToString()}\n"
                }
                if (error.networkError is NetworkError.Http) {
                    errorMessage += "\t\t- Request: ${error.networkError.statusCode}, ${error.networkError.method}, ${error.networkError.url}\n"
                }
            }
            println(errorMessage)
        }
        if (error.isSessionExpired()) {
            sessionExpiredAction()
        } else if (error.hasTimedOut()) {
            requestTimeoutAction()
        } else {
            handler()
        }
    }

    override fun handleStoreStateError(
        message: String,
        handler: () -> Unit
    ) {
        if (configModule.getEnvConfig().loggingEnabled) {
            var errorMessage = ""
            errorMessage += "ErrorService | Illegal store operation detected, reason: $message"
            println(errorMessage)
        }
        handler()
    }
}
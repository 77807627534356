package es.cinfo.tiivii.core.modules.share.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal data class ShortLinkApiRequest(
    @SerialName(DYNAMIC_LINK_KEY)
    val dynamicLink: DynamicLink
) {
    companion object {
        const val DYNAMIC_LINK_KEY = "dynamicLinkInfo"
    }

    @Serializable
    data class DynamicLink(
        @SerialName(DOMAIN_URI_PREFIX_KEY)
        val domainUriPrefix: String,
        @SerialName(LINK_KEY)
        val link: String,
        @SerialName(ANDROID_INFO_KEY)
        val androidInfo: AndroidInfo,
        @SerialName(IOS_INFO_KEY)
        val iosInfo: IosInfo,
        @SerialName(SOCIAL_INFO_KEY)
        val socialInfo: SocialInfo? = null
    ) {
        companion object {
            const val DOMAIN_URI_PREFIX_KEY = "domainUriPrefix"
            const val LINK_KEY = "link"
            const val ANDROID_INFO_KEY = "androidInfo"
            const val IOS_INFO_KEY = "iosInfo"
            const val SOCIAL_INFO_KEY = "socialMetaTagInfo"
        }

        @Serializable
        data class AndroidInfo(
            @SerialName(ANDROID_PACKAGE_NAME_KEY)
            val androidPackageName: String,
            @SerialName(ANDROID_FALLBACK_LINK_KEY)
            val androidFallbackLink: String? = null,
        ) {
            companion object {
                const val ANDROID_PACKAGE_NAME_KEY = "androidPackageName"
                const val ANDROID_FALLBACK_LINK_KEY = "androidFallbackLink"
            }
        }

        @Serializable
        data class IosInfo(
            @SerialName(IOS_BUNDLE_ID_KEY)
            val iosBundleId: String,
            @SerialName(IOS_IPAD_BUNDLE_ID_KEY)
            val iosIpadBundleId: String,
            @SerialName(IOS_FALLBACK_LINK_KEY)
            val iosFallbackLink: String? = null,
            @SerialName(IOS_APP_STORE_ID_KEY)
            val iosAppStoreId: String,
        ) {
            companion object {
                const val IOS_BUNDLE_ID_KEY = "iosBundleId"
                const val IOS_IPAD_BUNDLE_ID_KEY = "iosIpadBundleId"
                const val IOS_FALLBACK_LINK_KEY = "iosFallbackLink"
                const val IOS_APP_STORE_ID_KEY = "iosAppStoreId"
            }
        }

        @Serializable
        data class SocialInfo(
            @SerialName(SOCIAL_TITLE_KEY)
            val title: String,
            @SerialName(SOCIAL_DESCRIPTION_KEY)
            val description: String,
            @SerialName(IMAGE_LINK_KEY)
            val imageLink: String?,
        ) {
            companion object {
                const val SOCIAL_TITLE_KEY = "socialTitle"
                const val SOCIAL_DESCRIPTION_KEY = "socialDescription"
                const val IMAGE_LINK_KEY = "socialImageLink"
            }
        }
    }
}
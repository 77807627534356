package es.cinfo.tiivii.home.store

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.SuspendExecutor
import es.cinfo.tiivii.core.ComponentId
import es.cinfo.tiivii.core.content.model.WidgetModel
import es.cinfo.tiivii.core.error.ErrorService
import es.cinfo.tiivii.core.layout.model.LayoutModel
import es.cinfo.tiivii.core.modules.analytics.LogEvent
import es.cinfo.tiivii.core.modules.analytics.model.AnalyticsModel.Action
import es.cinfo.tiivii.core.modules.game.model.GameActions
import es.cinfo.tiivii.core.usecase.AddContentToFavorites
import es.cinfo.tiivii.core.usecase.RemoveContentFromFavorites
import es.cinfo.tiivii.core.usecase.SendGameAction
import es.cinfo.tiivii.core.util.Failure
import es.cinfo.tiivii.core.util.Success
import es.cinfo.tiivii.di.diContainer
import es.cinfo.tiivii.home.store.HomeStore.*
import es.cinfo.tiivii.home.usecase.LoadDefaultScreenLayoutFlow
import es.cinfo.tiivii.home.usecase.LoadScreenLayoutFlow
import kotlinx.coroutines.*
import kotlinx.coroutines.flow.collect
import org.kodein.di.instance

internal class HomeStoreFactory(
    private val storeFactory: StoreFactory,
) {

    private val errorService: ErrorService by diContainer.instance()

    private sealed class Result {
        data class ScreenLoaded(val screenLoad: LayoutModel.Model.ScreenLoad) : Result()
        data class FavoriteStatusChanged(val contentId: Int, val isFav: Boolean) : Result()
        data class WidgetLoadJobStarted(val widgetLoadJob: Job): Result()
        object WidgetLoadJobFinished: Result()
    }

    fun create(): HomeStore =
        object :
            HomeStore,
            Store<Intent, State, Label> by
            storeFactory.create(
                name = "HomeStore",
                initialState = State(
                    loadedScreen = null,
                    screenWidgets = emptyList(),
                    widgetLoadJob = null
                ),
                reducer = DefaultReducer,
                executorFactory = ::DefaultExecutor
            ) {
        }

    private object DefaultReducer : Reducer<State, Result> {
        override fun State.reduce(result: Result): State =
            when (result) {
                is Result.FavoriteStatusChanged -> {
                    widgetLoop@ for (widgetLoad in screenWidgets) {
                        if (widgetLoad.widget is WidgetModel.Model.Widget.ContentWidget) {
                            for (content in widgetLoad.widget.content) {
                                if (content.id == result.contentId) {
                                    content.isFav = result.isFav
                                    break@widgetLoop
                                }
                            }
                        }
                    }
                    copy(
                        screenWidgets = screenWidgets
                    )
                }
                is Result.ScreenLoaded -> {
                    copy(
                        loadedScreen = result.screenLoad.id,
                        screenWidgets = result.screenLoad.unFreeze().widgets
                    )
                }
                is Result.WidgetLoadJobStarted -> copy(
                    widgetLoadJob = result.widgetLoadJob
                )
                Result.WidgetLoadJobFinished -> {
                    widgetLoadJob?.cancel()
                    copy(
                        widgetLoadJob = null
                    )
                }
            }
    }

    private inner class DefaultExecutor :
        SuspendExecutor<Intent, Action,
                State,
                Result, Label>() {
        override suspend fun executeIntent(intent: Intent, getState: () -> State) {
            when (intent) {
                is Intent.LoadWidgets -> {
                    loadScreenLayoutFlow(intent.screenId, getState().widgetLoadJob)
                }
                Intent.LogHomeView -> logHomeView()
                Intent.LoadDefaultScreen -> {
                    loadDefaultScreenFlow(getState().widgetLoadJob)
                }
                is Intent.LogSelectItem -> logSelectItem(intent.contentId)
                is Intent.AddToFavorites -> addContentToFavorites(intent.contentId)
                is Intent.RemoveFromFavorites -> removeContentFromFavorites(intent.contentId)
            }
        }

        private suspend fun logSelectItem(contentId: Int) {
            LogEvent(
                action = Action.SelectItem,
                contentId = contentId
            ).invoke()
        }

        override suspend fun executeAction(action: Action, getState: () -> State) { }

        private suspend fun logHomeView() {
            LogEvent(
                action = Action.PageView,
                keyValue = Action.PageView.PAGE_ID_KEY to Action.PageView.HOME_VALUE
            ).invoke()
        }

        private suspend fun loadDefaultScreenFlow(currentWidgetLoadJob: Job?) {
            if (currentWidgetLoadJob == null) {
                val widgetLoadJob = CoroutineScope(Dispatchers.Main).launch {
                    val layoutFlow = LoadDefaultScreenLayoutFlow().invoke()
                    layoutFlow
                        .collect { outcome ->
                            when (outcome) {
                                is Success -> {
                                    dispatch(Result.ScreenLoaded(outcome.value))
                                    if (outcome.value.hasCompleted()) {
                                        dispatch(Result.WidgetLoadJobFinished)
                                    }
                                }
                                is Failure -> {
                                    errorService.handleError(
                                        error = outcome.error,
                                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                                        handler = {
                                            when (outcome.error) {
                                                LoadDefaultScreenLayoutFlow.Error.NoDynamicListAvailable -> {
                                                    publish(Label.UnexpectedError(outcome.error.getCode()))
                                                    dispatch(Result.WidgetLoadJobFinished)
                                                }
                                                is LoadDefaultScreenLayoutFlow.Error.UserUnavailable -> {
                                                    publish(Label.UnexpectedError(outcome.error.getCode()))
                                                    dispatch(Result.WidgetLoadJobFinished)
                                                }
                                                is LoadDefaultScreenLayoutFlow.Error.WidgetListUnavailable -> {
                                                    publish(Label.UnexpectedError(outcome.error.getCode()))
                                                    dispatch(Result.WidgetLoadJobFinished)
                                                }
                                                is LoadDefaultScreenLayoutFlow.Error.WidgetUnavailable -> publish(Label.UnexpectedError(outcome.error.getCode()))
                                                is LoadDefaultScreenLayoutFlow.Error.RatingFilterUnavailable -> publish(Label.UnexpectedError(outcome.error.getCode()))
                                            }
                                        }
                                    )
                                }
                            }
                        }
                }
                dispatch(Result.WidgetLoadJobStarted(widgetLoadJob))
            }
        }

        private suspend fun loadScreenLayoutFlow(id: Int, currentWidgetLoadJob: Job?) {
            if (currentWidgetLoadJob == null) {
                val widgetLoadJob = CoroutineScope(Dispatchers.Main).launch {
                    val layoutFlow = LoadScreenLayoutFlow(id).invoke()
                    layoutFlow
                        .collect { outcome ->
                            when (outcome) {
                                is Success -> {
                                    dispatch(Result.ScreenLoaded(outcome.value))
                                    if (outcome.value.hasCompleted()) {
                                        dispatch(Result.WidgetLoadJobFinished)
                                    }
                                }
                                is Failure -> {
                                    errorService.handleError(
                                        error = outcome.error,
                                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                                        handler = {
                                            when (outcome.error) {
                                                is LoadScreenLayoutFlow.Error.WidgetListUnavailable -> {
                                                    publish(Label.UnexpectedError(outcome.error.getCode()))
                                                    dispatch(Result.WidgetLoadJobFinished)
                                                }
                                                is LoadScreenLayoutFlow.Error.UserUnavailable -> {
                                                    publish(Label.UnexpectedError(outcome.error.getCode()))
                                                    dispatch(Result.WidgetLoadJobFinished)
                                                }
                                                is LoadScreenLayoutFlow.Error.WidgetUnavailable -> publish(Label.UnexpectedError(outcome.error.getCode()))
                                                is LoadScreenLayoutFlow.Error.RatingFilterUnavailable -> publish(Label.UnexpectedError(outcome.error.getCode()))
                                            }
                                        }
                                    )
                                }
                            }
                        }
                }
                dispatch(Result.WidgetLoadJobStarted(widgetLoadJob))
            }
        }

        private suspend fun addContentToFavorites(contentId: Int) {
            when (val outcome = AddContentToFavorites(contentId).invokeWith(ComponentId.HOME)) {
                is Success -> {
                    dispatch(Result.FavoriteStatusChanged(contentId, true))
                    val gameActionResult = SendGameAction(GameActions.FAV, contentId).invoke()
                    publish(Label.GameActionSent(gameActionResult))
                }
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                is AddContentToFavorites.Error.UnavailableFavorites -> publish(Label.UnexpectedError(outcome.error.getCode()))
                                is AddContentToFavorites.Error.ContentAlreadyFavorited -> { }
                                is AddContentToFavorites.Error.UserSessionUnavailable -> publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }

        private suspend fun removeContentFromFavorites(contentId: Int) {
            when (val outcome = RemoveContentFromFavorites(contentId).invokeWith(ComponentId.HOME)) {
                is Success -> dispatch(Result.FavoriteStatusChanged(contentId, false))
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                is RemoveContentFromFavorites.Error.UnavailableFavorites -> publish(Label.UnexpectedError(outcome.error.getCode()))
                                is RemoveContentFromFavorites.Error.UserSessionUnavailable -> publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }
    }

}
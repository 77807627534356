package es.cinfo.tiivii.core.features.notifications.usecase

import es.cinfo.tiivii.core.ComponentId
import es.cinfo.tiivii.core.ErrorId
import es.cinfo.tiivii.core.UseCaseId
import es.cinfo.tiivii.core.error.CodedError
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.error.asErrorId
import es.cinfo.tiivii.core.modules.auth.AuthService
import es.cinfo.tiivii.core.notifications.NotificationsModel
import es.cinfo.tiivii.core.notifications.NotificationsService
import es.cinfo.tiivii.core.util.*
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

internal class LoadNotifications : OutcomeUseCase<List<NotificationsModel.Model.Notification>, LoadNotifications.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.NOTIFICATIONS, UseCaseId.LOAD_NOTIFICATIONS, errorId, networkError) {
        data class UnavailableNotifications(val error: NetworkError) : Error(
            asErrorId<UnavailableNotifications>(1),
            error
        )
        object UserSessionUnavailable: Error(
            asErrorId<UserSessionUnavailable>(2),
        )
    }

    private val authService: AuthService by diContainer.instance()
    private val notificationsService: NotificationsService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<List<NotificationsModel.Model.Notification>, Error>
        get() = {
            val auth = authService.getStoredAuth()
            val username = auth?.username?.lowercase()
            if (username == null) {
                failure(Error.UserSessionUnavailable)
            } else {
                val notifications = notificationsService.getNotifications(username)
                    .mapError {
                        Error.UnavailableNotifications(it)
                    }.getOrAbort()
                success(notifications)
            }
        }
}

internal class DeleteNotification(val id: Int) : OutcomeUseCase<Unit, DeleteNotification.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.NOTIFICATIONS, UseCaseId.DELETE_NOTIFICATIONS, errorId, networkError) {
        data class UnavailableNotifications(val error: NetworkError) : Error(
            asErrorId<UnavailableNotifications>(1),
            error
        )
        object EmptyNotifications : Error(
            asErrorId<EmptyNotifications>(2)
        )
    }

    private val notificationsService: NotificationsService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Unit, Error>
        get() = {
            notificationsService.deleteNotification(id)
                .on {
                    success(this)
                }
                .mapError { Error.UnavailableNotifications(it) }
        }
}

internal class ReadNotification(val id: Int) : OutcomeUseCase<NotificationsModel.Model.Notification, ReadNotification.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.NOTIFICATIONS, UseCaseId.READ_NOTIFICATIONS, errorId, networkError) {
        data class UnavailableNotifications(val error: NetworkError) : Error(
            asErrorId<UnavailableNotifications>(1),
            error
        )
    }

    private val notificationsService: NotificationsService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<NotificationsModel.Model.Notification, Error>
        get() = {
            val notification = notificationsService.readNotification(id).mapError {
                Error.UnavailableNotifications(it)
            }.getOrAbort()
            success(notification)
        }
}
package es.cinfo.tiivii.core.interest

import es.cinfo.tiivii.core.interest.model.Model.Interest
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.core.util.TimedCache
import es.cinfo.tiivii.core.util.map
import es.cinfo.tiivii.core.util.mapToSet
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

internal interface InterestService {
    suspend fun getAvailableInterests(): Outcome<Set<Interest>, NetworkError>
}

internal class DefaultInterestService : InterestService {
    private val interestApi: InterestApi by diContainer.instance()

    private val cache = TimedCache<String, Set<Interest>>()

    override suspend fun getAvailableInterests(): Outcome<Set<Interest>, NetworkError> {
        return cache.get("interests") {
            interestApi.getAvailableInterests().map { interests -> interests.mapToSet { it.toModel() } }
        }
    }

}
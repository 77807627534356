package es.cinfo.tiivii.core.interest.model

import es.cinfo.tiivii.core.image.ImageModule
import es.cinfo.tiivii.di.diContainer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.kodein.di.instance
import es.cinfo.tiivii.core.image.ApiResponse.Image as ImageApiResponse
import es.cinfo.tiivii.core.image.Model.Image as ImageModel
import es.cinfo.tiivii.core.image.ViewModel.Image as ImageViewModel
import es.cinfo.tiivii.core.image.Model.Image.Type as ImageType

sealed class ViewModel {
    /**
     * Represents an interest that categorizes content on the application
     * @param id of the interest
     * @param name string representation of the interest
     * @param image associated image of the interest
     */
    data class Interest(
        val id: Int,
        val name: String,
        val image: ImageViewModel) {

        internal fun toModel(): Model.Interest =
            Model.Interest(id, name, image.toModel())
    }
}

internal sealed class Model {
    data class Interest(
        val id: Int,
        val name: String,
        val image: ImageModel) {

        fun toViewModel(): ViewModel.Interest =
            ViewModel.Interest(id, name, image.toViewModel())
    }
}

internal sealed class ApiResponse {
    @Serializable
    internal class Interest(
        @SerialName(ID_PARAM)
        private val id: Int,
        @SerialName(NAME_PARAM)
        private val name: String,
        @SerialName(IMAGE_PARAM)
        private val image: ImageApiResponse,
    ) {
        companion object {
            const val ID_PARAM = "id"
            const val NAME_PARAM = "name"
            const val IMAGE_PARAM = "image"
        }

        fun toModel(): Model.Interest {
            val imageModule: ImageModule by diContainer.instance()
            val image = ImageModel(
                image.id,
                image.width,
                image.height,
                "${imageModule.baseEndpoint}${image.data.assetUrl}",
                ImageType.OTHER
            )
            return Model.Interest(id, name, image)
        }
    }
}
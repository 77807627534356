package es.cinfo.tiivii.nav.menu.view

import com.arkivanov.mvikotlin.core.view.MviView
import es.cinfo.tiivii.core.layout.model.section.ViewModel.Section
import es.cinfo.tiivii.core.util.LoadingModel
import es.cinfo.tiivii.nav.menu.view.MenuView.Event
import es.cinfo.tiivii.nav.menu.view.MenuView.Event.*
import es.cinfo.tiivii.nav.menu.view.MenuView.Model

/**
 * View implementation for the menu screen
 */
interface MenuView : MviView<Model, Event> {

    /**
     * Operations available to be requested for the catalogue screen
     * @see Reload
     * @see SelectSection
     */
    sealed class Event {
        /**
         * Request the menu related information to be reloaded (not needed the first time binding
         * to the store)
         */
        object Reload : Event()

        /**
         * Request a section to be selected
         */
        data class SelectSection(val section: Section) : Event()
    }

    /**
     * The model of the catalogue screen exposed to the UI
     * @param language identifies to be used for loading any translatable content
     * @param sections represents the available [Section]s in the menu
     * @param loadingSections indicates if the [Section]s are being loaded
     * @param selectedSection identifies the currently selected [Section]
     * @param studioUrl indicates the url to the app's admin site
     * @param supportEmail indicates the support email
     * @param tutorialUrl indicates the tutoriasl url if available
     * @param privacyStatementUrl indicates the url to the privacy policy pdf document
     * @param termsAndConditionsUrl indicates the url to terms and conditions pdf document
     * @param legalText legal conditions text if available
     * @param iosAppUrl indicates the url to ios app store
     * @param androidAppUrl indicates the url to the play store
     * @param faqUrl indicates the url of the related FAQ with frequently asked questions about the application (if any)
     */
    data class Model(
        val language: String,
        val sections: List<Section>?,
        val loadingSections: LoadingModel.ViewModel.LoadState,
        val selectedSection: Section?,
        val studioUrl: String?,
        val supportEmail: String?,
        val tutorialUrl: String?,
        val privacyStatementUrl: String?,
        val termsAndConditionsUrl: String?,
        val legalText: String?,
        val iosAppUrl: String?,
        val androidAppUrl: String?,
        val faqUrl: String?
    )

    /**
     * One-time notifications that can be received on the menu screen
     * @see UnexpectedError
     * @see RequestTimedOut
     * @see UserSessionExpired
     */
    sealed class Output {
        /**
         * Unexpected error happened
         * @param code string representation of a internal error code to be shown with the generic error to the user
         */
        data class UnexpectedError(val code: String) : Output()

        /**
         * A request has timed out. This may indicate a problem with the internet connection
         */
        object RequestTimedOut : Output()

        /**
         * User session has expired, user needs to log in again
         */
        object UserSessionExpired : Output()
    }

}

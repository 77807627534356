package es.cinfo.tiivii.user.splash.store

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.SuspendExecutor
import es.cinfo.tiivii.core.error.ErrorService
import es.cinfo.tiivii.core.modules.analytics.LogEvent
import es.cinfo.tiivii.core.modules.analytics.model.AnalyticsModel
import es.cinfo.tiivii.core.modules.game.model.GameActions
import es.cinfo.tiivii.core.modules.platform.PLATFORM_ID
import es.cinfo.tiivii.core.modules.platform.PlatformModel
import es.cinfo.tiivii.core.usecase.SendGameAction
import es.cinfo.tiivii.core.util.Failure
import es.cinfo.tiivii.core.util.LoadingModel
import es.cinfo.tiivii.core.util.Success
import es.cinfo.tiivii.di.diContainer
import es.cinfo.tiivii.user.screen.ScreenModel.Model.Screen
import es.cinfo.tiivii.user.splash.store.SplashStore.*
import es.cinfo.tiivii.user.splash.usecase.GetFirstScreen
import es.cinfo.tiivii.user.splash.usecase.StoreAuthDataAndGetNextScreen
import org.kodein.di.instance

/**
 * Factory for [SplashStore] component
 */
internal class SplashStoreFactory(
    private val storeFactory: StoreFactory,
) {

    private val errorService: ErrorService by diContainer.instance()

    private sealed class Result {
        object Initializing: Result()
        object ErrorLoadingNextScreen: Result()
        data class Initialized(val nextScreen: Screen) : Result()
        object LoadingNextScreen: Result()
        data class LoadedNextScreen(val nextScreen: Screen): Result()
    }

    fun create(): SplashStore =
        object : SplashStore, Store<Intent, State, Label> by
        storeFactory.create(
            name = "SplashStore",
            initialState = State(
                loadingNextScreen = LoadingModel.Model.LoadState.RESET,
                nextScreen = null,
                iosAppUrl = null,
                androidAppUrl = null,
                auth = null,
                styles = null,
                keycloakParams = null
            ),
            reducer = DefaultReducer,
            executorFactory = ::DefaultExecutor
        ) {
        }

    private object DefaultReducer : Reducer<State, Result> {
        override fun State.reduce(result: Result): State =
            when (result) {
                Result.Initializing -> copy(
                    loadingNextScreen = LoadingModel.Model.LoadState.LOADING
                )
                is Result.Initialized -> copy(
                    loadingNextScreen = LoadingModel.Model.LoadState.LOADED,
                    nextScreen = result.nextScreen,
                    androidAppUrl = result.nextScreen.appLinks?.android,
                    iosAppUrl = result.nextScreen.appLinks?.ios,
                    auth = result.nextScreen.auth,
                    styles = result.nextScreen.styles,
                    keycloakParams = result.nextScreen.keycloakParams
                )
                Result.LoadingNextScreen -> copy(
                    loadingNextScreen = LoadingModel.Model.LoadState.LOADING
                )
                is Result.LoadedNextScreen -> copy(
                    loadingNextScreen = LoadingModel.Model.LoadState.LOADED,
                    nextScreen = result.nextScreen,
                )
                Result.ErrorLoadingNextScreen -> copy(
                    loadingNextScreen = LoadingModel.Model.LoadState.RESET,
                    nextScreen = null,
                    androidAppUrl = null,
                    iosAppUrl = null,
                    auth = null,
                    styles = null,
                    keycloakParams = null
                )
            }
    }

    private inner class DefaultExecutor :
        SuspendExecutor<Intent, Action, State, Result, Label>() {
        override suspend fun executeIntent(intent: Intent, getState: () -> State) {
            when (intent) {
                is Intent.GetFirstScreen -> init()
                is Intent.HandleLoginFailure -> handleAuthError(intent.error)
                is Intent.StoreLogin -> storeAuthData(intent.accessToken, intent.refreshToken)
                Intent.LogLoginView -> logLoginView()
            }
        }

        private suspend fun logLoginView() {
            LogEvent(
                action = AnalyticsModel.Action.PageView,
                keyValue = AnalyticsModel.Action.PageView.PAGE_ID_KEY to AnalyticsModel.Action.PageView.LOGIN_VALUE).invoke()
            // TODO: To be removed, temporal fix to ensure login action is sent
            if (PLATFORM_ID == PlatformModel.Model.Platform.WEB) {
                val actionResult = SendGameAction(GameActions.LOGIN).invoke()
                publish(Label.GameActionSent(actionResult))
            }
        }

        override suspend fun executeAction(action: Action, getState: () -> State) { }

        private suspend fun init() {
            dispatch(Result.Initializing)
            when (val outcome = GetFirstScreen().invoke()) {
                is Success -> {
                    dispatch(Result.Initialized(outcome.value))
                }
                is Failure -> {
                    dispatch(Result.ErrorLoadingNextScreen)
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                is GetFirstScreen.Error.LegalCheckUnavailable,
                                is GetFirstScreen.Error.UnavailableLayoutConfig,
                                GetFirstScreen.Error.UnavailableAppLink,
                                GetFirstScreen.Error.UnknownLoginClient ->
                                    publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }

        private fun handleAuthError(error: String) {
            publish(Label.UnexpectedError(error))
        }

        private suspend fun storeAuthData(accessToken: String, refreshToken: String) {
            dispatch(Result.LoadingNextScreen)
            when (val outcome = StoreAuthDataAndGetNextScreen(accessToken, refreshToken).invoke()) {
                is Success -> {
                    dispatch(Result.LoadedNextScreen(outcome.value))
                    val actionResult = SendGameAction(GameActions.LOGIN).invoke()
                    publish(Label.GameActionSent(actionResult))
                }
                is Failure -> {
                    dispatch(Result.ErrorLoadingNextScreen)
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                StoreAuthDataAndGetNextScreen.Error.LegalCheckUnavailable,
                                StoreAuthDataAndGetNextScreen.Error.StoreAuthError,
                                StoreAuthDataAndGetNextScreen.Error.UnavailableAppLink,
                                is StoreAuthDataAndGetNextScreen.Error.UnavailableLayoutConfig ->
                                    publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }

//        private fun startSignupProcess() {
//            // Just route to Signup screen
//        }

//        private suspend fun startGuestLoginProcess() {
//            when (val outcome = GetNextScreenForGuestLogin().invoke()) {
//                is Success -> publish(Label.NextScreen(outcome.value))
//                is Failure -> {
//                    errorService.handleError(
//                        error = outcome.error,
//                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
//                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
//                        handler = {
//                            when (outcome.error) {
//                                GetNextScreenForGuestLogin.Error.LegalCheckUnavailable,
//                                is GetNextScreenForGuestLogin.Error.UnavailableLayoutConfig ->
//                                    publish(Label.UnexpectedError(outcome.error.getCode()))
//                            }
//                        }
//                    )
//                }
//            }
//        }

    }
}
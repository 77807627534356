package es.cinfo.tiivii.core.modules.checkpoint

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.modules.checkpoint.model.CheckpointModel
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.network.HttpModule
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

/**
 * Network datasource for content checkpoint related operations
 */
internal interface CheckpointApi {

    suspend fun getCheckpoints(contentId: Int): Outcome<CheckpointModel.ApiResponse.Checkpoints, NetworkError>
}

internal class DefaultCheckpointApi: CheckpointApi {
    private val http: HttpModule by diContainer.instance()

    private val baseEndpoint: String by lazy {
        val configModule: ConfigModule by diContainer.instance()
        "${configModule.getEnvConfig().backendUrl}/sgca/${configModule.getEnvConfig().apiName}/optionevents"
    }

    override suspend fun getCheckpoints(contentId: Int): Outcome<CheckpointModel.ApiResponse.Checkpoints, NetworkError> {
        val endpoint = "$baseEndpoint/$contentId"
        return http.getAsOutcome(endpoint = endpoint)
    }

}
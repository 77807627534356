package es.cinfo.tiivii.core.translation

import es.cinfo.tiivii.core.translation.TranslationModel.ApiResponse.Translation.Companion.EMPTY_TRANSLATION
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.SerializationException
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.CompositeDecoder
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.encoding.decodeStructure
import kotlinx.serialization.json.*
import kotlin.js.JsName

sealed class TranslationModel {
    sealed class ViewModel {
        /**
         * Represents a string that is localized and contains translations for different languages
         * @param fallbackValue represents the default value established by backend
         * @param translations contains all translations available for the string
         */
        data class LocalizedString(
            val fallbackValue: String,
            val translations: List<Translation>
        ) {
            @JsName("forLanguage")
            fun forLanguage(language: String): String {
                return translations.find { it.language == language }?.value ?: fallbackValue
            }
        }

        /**
         * Represents a translation of a string
         * @param language identifies the language of the string
         * @param value the value of the string in the given [language]
         */
        data class Translation(
            val language: String,
            val value: String
        )
    }
    internal sealed class Model {
        data class LocalizedString(
            val fallbackValue: String,
            val translations: List<Translation>
        ) {
            companion object {
                fun empty(fallbackValue: String) = LocalizedString(fallbackValue, emptyList())
            }

            fun forLanguage(language: String): String {
                return translations.find { it.language == language }?.value ?: fallbackValue
            }

            fun toViewModel(): ViewModel.LocalizedString {
                return ViewModel.LocalizedString(
                    fallbackValue = fallbackValue,
                    translations = translations.map { it.toViewModel() }
                )
            }
        }
        data class Translation(
            val language: String,
            val value: String
        ) {
            fun toViewModel(): ViewModel.Translation {
                return ViewModel.Translation(
                    language, value
                )
            }
        }
    }
    internal sealed class ApiResponse {

        @Serializable
        internal data class Translations(
            @Serializable(with = Translation.TranslationDeserializer::class)
            val data: List<Translation>
        ) {
            object TranslationsDeserializer : KSerializer<Translations?> {
                override val descriptor = listSerialDescriptor(PrimitiveSerialDescriptor("Translations", PrimitiveKind.STRING))

                override fun serialize(encoder: Encoder, value: Translations?) {
                    // TODO: Add encoding implementation if needed
                    val string = value.toString()
                    encoder.encodeString(string)
                }

                override fun deserialize(decoder: Decoder): Translations? {
                    return decoder.decodeStructure(descriptor) {
                        val data = mutableListOf<Translation>()
                        decoder.decodeStructure(descriptor) {
                            while (true) {
                                when (val index = decodeElementIndex(descriptor)) {
                                    CompositeDecoder.DECODE_DONE -> break
                                    else -> {
                                        try {
                                            data.add(
                                                decodeSerializableElement(
                                                    descriptor,
                                                    index,
                                                    Translation.TranslationDeserializer
                                                )
                                            )
                                        } catch (e: JsonSerializationException) {
                                            if (e.errorCode != EMPTY_TRANSLATION) {
                                                throw e
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        if (data.isEmpty()) {
                            null
                        } else {
                            Translations(data)
                        }
                    }
                }
            }

            fun find(key: String, language: String): String? {
                return data.find { it.language == language }?.values?.get(key)
            }

            fun getAll(key: String, fallbackValue: String): Model.LocalizedString {
                val translations = data.mapNotNull {
                    val value = it.values[key]
                    value?.let { nonNullValue ->
                        Model.Translation(
                            language = it.language,
                            value = nonNullValue
                        )
                    }
                }
                return Model.LocalizedString(
                    fallbackValue = fallbackValue,
                    translations = translations
                )
            }
        }

        @Serializable
        internal data class Translation(
            val language: String,
            val values: Map<String, String>
        ) {
            companion object {
                const val LANGUAGE_PARAM = "language"
                const val VALUES_PARAM = "values"
                const val EMPTY_TRANSLATION = 1
                const val MISSING_FIELD = 2
            }

            object TranslationDeserializer : KSerializer<Translation> {
                override val descriptor = buildClassSerialDescriptor("Translation") {
                    element(LANGUAGE_PARAM, serialDescriptor<String>())
                    element(VALUES_PARAM, mapSerialDescriptor<String, String>())
                }

                override fun serialize(encoder: Encoder, value: Translation) {
                    // TODO: Add encoding implementation if needed
                    val string = value.toString()
                    encoder.encodeString(string)
                }

                override fun deserialize(decoder: Decoder): Translation {
                    val jsonInput = decoder as? JsonDecoder
                    val json = jsonInput!!.decodeJsonElement().jsonObject
                    val language = json[LANGUAGE_PARAM]?.jsonPrimitive?.content
                    val jsonValues = json.toMutableMap()
                    jsonValues.remove(LANGUAGE_PARAM)
                    val values = jsonValues.filterNot { it.value.jsonPrimitive is JsonNull }.mapValues { it.value.jsonPrimitive.content }
                    return if (values.isEmpty()) {
                        throw JsonSerializationException(EMPTY_TRANSLATION, "Empty translation object")
                    } else {
                        if (language == null) {
                            throw JsonSerializationException(MISSING_FIELD, "Missing 'language' field in translation object")
                        } else {
                            Translation(language, values)
                        }
                    }
                }
            }
        }

        internal data class JsonSerializationException(val errorCode: Int, override val message: String): SerializationException(message)
    }
}
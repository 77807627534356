package es.cinfo.tiivii.core.features.catalogue.usecase

import es.cinfo.tiivii.core.features.catalogue.model.Model.*
import es.cinfo.tiivii.core.features.catalogue.model.ViewModel
import es.cinfo.tiivii.core.ComponentId
import es.cinfo.tiivii.core.ErrorId
import es.cinfo.tiivii.core.UseCaseId
import es.cinfo.tiivii.core.content.ContentService
import es.cinfo.tiivii.core.content.model.ContentTypeModel
import es.cinfo.tiivii.core.error.CodedError
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.error.asErrorId
import es.cinfo.tiivii.core.modules.analytics.LogEvent
import es.cinfo.tiivii.core.modules.analytics.model.AnalyticsModel.Action
import es.cinfo.tiivii.core.modules.auth.AuthService
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.rating.RatingModel.Model.Companion.RATING_FILTER
import es.cinfo.tiivii.core.node.NodeService
import es.cinfo.tiivii.core.sorting.SortModel
import es.cinfo.tiivii.core.usecase.GetRatingFilter
import es.cinfo.tiivii.core.user.UserService
import es.cinfo.tiivii.core.util.*
import es.cinfo.tiivii.core.util.Model.Node
import es.cinfo.tiivii.core.util.Model.Tree
import es.cinfo.tiivii.di.diContainer
import es.cinfo.tiivii.search.data.SearchService
import org.kodein.di.instance

// TODO: To be added to a configuration service
private const val MAX_DEPTH_SEARCH = 2

internal class LoadRootNodes(
    private val sort: SortModel.Model.Sort
) : OutcomeUseCase<CatalogueLoad, LoadRootNodes.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.CATALOGUE, UseCaseId.LOAD_ROOT_NODES, errorId, networkError) {
        data class UnavailableNodes(val error: NetworkError) : Error(
            asErrorId<UnavailableNodes>(1),
            error
        )
        data class UnavailableContents(val error: NetworkError) : Error(
            asErrorId<UnavailableContents>(2),
            error
        )
        object UnexpectedEmptyNode : Error(
            asErrorId<UnexpectedEmptyNode>(3),
        )
    }

    private val authService: AuthService by diContainer.instance()
    private val userService: UserService by diContainer.instance()
    private val nodeService: NodeService by diContainer.instance()
    private val configModule: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<CatalogueLoad, Error>
        get() = {
            val auth = authService.getStoredAuth()
            val username = auth?.username
            val user = username?.let { userService.getUser(it).getOrNull() }
            val isAuthed = username != null
            val language = user?.preferredLanguage ?: configModule.getCoreConfig().signup.defaultLanguage
            // Load root nodes
            val rootNodes = nodeService.getRootNodes(language)
                .mapError { Error.UnavailableNodes(it) }.getOrAbort()
            val nodeTree = Tree(rootNodes)
            var nextNode = nodeTree.rootNodes.firstOrNull()
            // Load the first children node until MAX_DEPTH_SEARCH
            while (nextNode != null && nextNode.hasChildren && nextNode.coordinates.size < MAX_DEPTH_SEARCH) {
                val childrenNodes = nodeService.getChildrenNodes(nextNode.value.id, language)
                    .mapError { Error.UnavailableNodes(it) }.getOrAbort()
                nodeTree.addChildren(nextNode.coordinates, childrenNodes)
                nextNode = nextNode.children!!.firstOrNull()
            }
            if (nextNode == null) {
                // Backend returned an empty list with
                failure(Error.UnexpectedEmptyNode)
            } else {
                // Load associated contents
                val ratingFilter = GetRatingFilter().invokeWith(ComponentId.SEARCH).mapError {
                    when (it) {
                        is GetRatingFilter.Error.UnavailableRatings -> Error.UnavailableContents(it.error)
                    }
                }.getOrAbort()
                val searchFilters = if (ratingFilter != null) {
                    mutableMapOf(RATING_FILTER to ratingFilter)
                } else {
                    null
                }
                val contents = nodeService.getContents(nextNode.value.id, filters = searchFilters, page = 1, sort = sort.param)
                    .mapError { Error.UnavailableContents(it) }.getOrAbort()
                contents.contents.forEach { content ->
                    if (isAuthed) {
                        content.canFav = true
                        if (content.type != ContentTypeModel.Model.ContentType.CONTAINER) {
                            content.canPlay = true
                        }
                        content.isFav = user?.favorites?.contains(content.id.toString()) ?: false
                    }
                }
                val contentTree = Tree(contents.contents)
                val contentLoad = ContentTreeLoad(
                    contentTree,
                    contents.count,
                    contents.page,
                    contents.limit,
                    contents.sort
                )
                success(CatalogueLoad(nodeTree, nextNode, contentLoad))
            }
        }
}

internal class LoadNode(
    private val coordinates: List<Int>,
    private val nodeTree: Tree<ViewModel.ContentNode, ContentNode>,
    private val sort: SortModel.Model.Sort
) : OutcomeUseCase<CatalogueLoad, LoadNode.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.CATALOGUE, UseCaseId.LOAD_NODE, errorId, networkError) {
        data class UnavailableNodes(val error: NetworkError) : Error(
            asErrorId<UnavailableNodes>(1),
            error
        )
        data class UnavailableContents(val error: NetworkError) : Error(
            asErrorId<UnavailableContents>(2),
            error
        )
        object InvalidCoordinates : Error(
            asErrorId<InvalidCoordinates>(3),
        )
        object UnexpectedEmptyNode: Error(
            asErrorId<UnexpectedEmptyNode>(4),
        )
    }

    private val authService: AuthService by diContainer.instance()
    private val userService: UserService by diContainer.instance()
    private val nodeService: NodeService by diContainer.instance()
    private val configModule: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<CatalogueLoad, Error>
        get() = {
            val auth = authService.getStoredAuth()
            val username = auth?.username
            val user = username?.let { userService.getUser(it).getOrNull() }
            val isAuthed = username != null
            val language = user?.preferredLanguage ?: configModule.getCoreConfig().signup.defaultLanguage
            // TODO: Add filter when loading node
            var nodeToLoad = nodeTree.findNodeOrNull(coordinates)
            if (nodeToLoad == null) {
                // Non-existent node with the given coordinates
                failure(Error.InvalidCoordinates)
            } else {
                // Search for first child if not at MAX_DEPTH_SEARCH
                while (nodeToLoad != null && nodeToLoad.hasChildren && nodeToLoad.coordinates.size < MAX_DEPTH_SEARCH) {
                    if (nodeToLoad.children == null) {
                        val childrenNodes = nodeService.getChildrenNodes(nodeToLoad.value.id, language)
                            .mapError { Error.UnavailableNodes(it) }.getOrAbort()
                        nodeTree.addChildren(nodeToLoad.coordinates, childrenNodes)
                    }
                    nodeToLoad = nodeToLoad.children!!.firstOrNull()
                }
                if (nodeToLoad == null) {
                    // Backend notifies node has children but no children node have been found
                    failure(Error.UnexpectedEmptyNode)
                } else {
                    // Load associated contents
                    val ratingFilter = GetRatingFilter().invokeWith(ComponentId.SEARCH).mapError {
                        when (it) {
                            is GetRatingFilter.Error.UnavailableRatings -> Error.UnavailableContents(it.error)
                        }
                    }.getOrAbort()
                    val searchFilters = if (ratingFilter != null) {
                        mutableMapOf(RATING_FILTER to ratingFilter)
                    } else {
                        null
                    }
                    val contents = nodeService.getContents(nodeToLoad.value.id, filters = searchFilters, page = 1, sort = sort.param)
                        .mapError { Error.UnavailableContents(it) }.getOrAbort()
                    contents.contents.forEach { content ->
                        if (isAuthed) {
                            content.canFav = true
                            if (content.type != ContentTypeModel.Model.ContentType.CONTAINER) {
                                content.canPlay = true
                            }
                            content.isFav = user?.favorites?.contains(content.id.toString()) ?: false
                        }
                    }
                    val contentTree = Tree(contents.contents)
                    val contentLoad = ContentTreeLoad(
                        contentTree,
                        contents.count,
                        contents.page,
                        contents.limit,
                        contents.sort
                    )
                    success(CatalogueLoad(nodeTree, nodeToLoad, contentLoad))
                }
            }
        }
}

internal class LoadContentPage(
    private val node: Node<ViewModel.ContentNode, ContentNode>,
    private val contentTree: Tree<ViewModel.Content, Content>,
    private val selectedContent: Node<ViewModel.Content, Content>? = null,
    private val queryFilter: String?,
    private val page: Int,
    private val sort: SortModel.Model.Sort
) : OutcomeUseCase<ContentTreeLoad, LoadContentPage.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.CATALOGUE, UseCaseId.LOAD_CONTENT_PAGE, errorId, networkError) {
        data class UnavailableContents(val error: NetworkError) : Error(
            asErrorId<UnavailableContents>(1),
            error
        )
    }

    private val authService: AuthService by diContainer.instance()
    private val userService: UserService by diContainer.instance()
    private val searchService: SearchService by diContainer.instance()
    private val nodeService: NodeService by diContainer.instance()
    private val contentService: ContentService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<ContentTreeLoad, Error>
        get() = {
            val auth = authService.getStoredAuth()
            val username = auth?.username
            val user = username?.let { userService.getUser(it).getOrNull() }
            val isAuthed = username != null
            val contentLoad: ContentTreeLoad
            val ratingFilter = GetRatingFilter().invokeWith(ComponentId.CATALOGUE).mapError {
                when (it) {
                    is GetRatingFilter.Error.UnavailableRatings -> Error.UnavailableContents(it.error)
                }
            }.getOrAbort()
            if (queryFilter != null) {
                var searchFilters = node.value.filters.toMutableMap()
                selectedContent?.let {
                    searchFilters = mutableMapOf("parent" to selectedContent.value.id.toString())
                }
                if (ratingFilter != null) {
                    searchFilters[RATING_FILTER] = ratingFilter
                }
                val searchResults =
                    searchService.search(queryFilter, filters = searchFilters, sort = sort.param, page = page)
                        .mapError { Error.UnavailableContents(it) }.getOrAbort()
                val parsedContents = searchResults.searchResultEntries.map {
                    Content(
                        id = it.id,
                        title = it.title,
                        subtitle = it.subtitle,
                        score = it.score,
                        type = it.type,
                        background = it.background,
                        banner = it.banner,
                        poster = it.poster,
                        _hasChildren = it.hasChildren,
                        parentId = it.parentId
                    )
                }
                parsedContents.forEach { content ->
                    if (isAuthed) {
                        content.canFav = true
                        if (content.type != ContentTypeModel.Model.ContentType.CONTAINER) {
                            content.canPlay = true
                        }
                        content.isFav = user?.favorites?.contains(content.id.toString()) ?: false
                    }
                }
                contentTree.addRootNodes(parsedContents)
                contentLoad = ContentTreeLoad(
                    contentTree,
                    searchResults.count,
                    searchResults.page,
                    searchResults.limit,
                    searchResults.sort
                )
            } else {
                val childrenContents : List<Content>
                val childrenCount: Int
                val childrenPage: Int
                val childrenLimit: Int
                val childrenSort: SortModel.Model.Sort
                val ratingFilters = if (ratingFilter != null) {
                    mapOf(RATING_FILTER to ratingFilter)
                } else {
                    null
                }
                if (selectedContent != null) {
                    val childrenContentLoad = contentService.getSerialContents(
                        id = selectedContent.value.id,
                        filters = ratingFilters,
                        page = page,
                        sort = sort.param
                    )
                        .mapError { Error.UnavailableContents(it) }.getOrAbort()
                    childrenContents = childrenContentLoad.contents.map {
                        Content(
                            it.id,
                            title = it.title,
                            subtitle = it.subtitle,
                            score = it.score,
                            type = it.type,
                            background = it.background,
                            banner = it.banner,
                            poster = it.poster,
                            _hasChildren = it.hasChildren,
                            parentId = it.parentId
                        )
                    }
                    childrenCount = childrenContentLoad.count
                    childrenPage = childrenContentLoad.page
                    childrenLimit = childrenContentLoad.limit
                    childrenSort = childrenContentLoad.sort
                    childrenContents.forEach { content ->
                        if (isAuthed) {
                            content.canFav = true
                            if (content.type != ContentTypeModel.Model.ContentType.CONTAINER) {
                                content.canPlay = true
                            }
                            content.isFav = user?.favorites?.contains(content.id.toString()) ?: false
                        }
                    }
                    contentTree.addChildren(selectedContent.coordinates, childrenContents)
                    selectedContent.addChildren(childrenContents)
                } else {
                    val childrenRatingFilter = GetRatingFilter().invokeWith(ComponentId.SEARCH).mapError {
                        when (it) {
                            is GetRatingFilter.Error.UnavailableRatings -> Error.UnavailableContents(it.error)
                        }
                    }.getOrAbort()
                    val searchFilters = if (childrenRatingFilter != null) {
                        mutableMapOf(RATING_FILTER to childrenRatingFilter)
                    } else {
                        null
                    }
                    val childrenContentLoad = nodeService.getContents(node.value.id, filters = searchFilters, page = page, sort = sort.param)
                        .mapError { Error.UnavailableContents(it) }.getOrAbort()
                    childrenContents = childrenContentLoad.contents.map {
                        Content(
                            id = it.id,
                            title = it.title,
                            subtitle = it.subtitle,
                            score = it.score,
                            type = it.type,
                            background = it.background,
                            banner = it.banner,
                            poster = it.poster,
                            _hasChildren = it.hasChildren(),
                            parentId = it.parentId
                        )
                    }
                    childrenContents.forEach { content ->
                        if (isAuthed) {
                            content.canFav = true
                            if (content.type != ContentTypeModel.Model.ContentType.CONTAINER) {
                                content.canPlay = true
                            }
                            content.isFav = user?.favorites?.contains(content.id.toString()) ?: false
                        }
                    }
                    childrenCount = childrenContentLoad.count
                    childrenPage = childrenContentLoad.page
                    childrenLimit = childrenContentLoad.limit
                    childrenSort = childrenContentLoad.sort
                    contentTree.addRootNodes(childrenContents)
                }
                contentLoad = ContentTreeLoad(
                    contentTree,
                    childrenCount,
                    childrenPage,
                    childrenLimit,
                    childrenSort,
                    selectedContent
                )
            }
            success(contentLoad)
        }
}

internal class FilterContents(
    private val node: Node<ViewModel.ContentNode, ContentNode>,
    private val selectedContent: Node<ViewModel.Content, Content>?,
    private val query: String,
    private val sort: SortModel.Model.Sort
) : OutcomeUseCase<ContentTreeLoad, FilterContents.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.CATALOGUE, UseCaseId.FILTER_CONTENTS, errorId, networkError) {
        data class SearchUnavailable(val error: NetworkError) : Error(
            asErrorId<SearchUnavailable>(1),
            error
        )
    }

    private val authService: AuthService by diContainer.instance()
    private val userService: UserService by diContainer.instance()
    private val searchService: SearchService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<ContentTreeLoad, Error>
        get() = {
            val auth = authService.getStoredAuth()
            val username = auth?.username
            val user = username?.let { userService.getUser(it).getOrNull() }
            val isAuthed = username != null
            var searchFilters = node.value.filters.toMutableMap()
            selectedContent?.let {
                searchFilters = mutableMapOf("[parent]" to selectedContent.value.id.toString())
            }
            val ratingFilter = GetRatingFilter().invokeWith(ComponentId.CATALOGUE).mapError {
                when (it) {
                    is GetRatingFilter.Error.UnavailableRatings -> Error.SearchUnavailable(it.error)
                }
            }.getOrAbort()
            if (ratingFilter != null) {
                searchFilters[RATING_FILTER] = ratingFilter
            }
            val searchResults = searchService.search(query, filters = searchFilters, sort = sort.param, page = 1)
                .mapError { Error.SearchUnavailable(it) }.getOrAbort()
            val contents = searchResults.searchResultEntries.map {
                Content(
                    id = it.id,
                    title = it.title,
                    subtitle = it.subtitle,
                    score = it.score,
                    type = it.type,
                    background = it.background,
                    banner = it.banner,
                    poster = it.poster,
                    _hasChildren = it.hasChildren,
                    parentId = it.parentId
                )
            }
            contents.forEach { content ->
                if (isAuthed) {
                    content.canFav = true
                    if (content.type != ContentTypeModel.Model.ContentType.CONTAINER) {
                        content.canPlay = true
                    }
                    content.isFav = user?.favorites?.contains(content.id.toString()) ?: false
                }
            }
            val contentTree = Tree(contents)
            selectedContent?.let {
                selectedContent.addChildren(contents)
            }
            success(
                ContentTreeLoad(
                    contentTree,
                    searchResults.count,
                    searchResults.page,
                    searchResults.limit,
                    searchResults.sort,
                    selectedContent
                )
            )
        }
}

internal class ReloadContents(
    private val contentTree: Tree<ViewModel.Content, Content>,
    private val node: Node<ViewModel.ContentNode, ContentNode>,
    private val selectedContent: Node<ViewModel.Content, Content>?,
    private val queryFilter: String?,
    private val sort: SortModel.Model.Sort
) : OutcomeUseCase<ContentTreeLoad, ReloadContents.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.CATALOGUE, UseCaseId.RELOAD_CONTENTS, errorId, networkError) {
        data class UnavailableContents(val error: NetworkError) : Error(
            asErrorId<UnavailableContents>(1),
            error
        )
    }

    private val authService: AuthService by diContainer.instance()
    private val userService: UserService by diContainer.instance()
    private val nodeService: NodeService by diContainer.instance()
    private val contentService: ContentService by diContainer.instance()
    private val searchService: SearchService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<ContentTreeLoad, Error>
        get() = {
            val contentLoad : ContentTreeLoad
            val auth = authService.getStoredAuth()
            val username = auth?.username
            val user = username?.let { userService.getUser(it).getOrNull() }
            val isAuthed = username != null
            val ratingFilter = GetRatingFilter().invokeWith(ComponentId.CATALOGUE).mapError {
                when (it) {
                    is GetRatingFilter.Error.UnavailableRatings -> Error.UnavailableContents(it.error)
                }
            }.getOrAbort()
            if (queryFilter != null) {
                var searchFilters = node.value.filters.toMutableMap()
                selectedContent?.let {
                    searchFilters = mutableMapOf("[parent]" to selectedContent.value.id.toString())
                }
                if (ratingFilter != null) {
                    searchFilters[RATING_FILTER] = ratingFilter
                }
                val searchResults =
                    searchService.search(queryFilter, filters = searchFilters, sort = sort.param, page = 1)
                        .mapError { Error.UnavailableContents(it) }.getOrAbort()
                val parsedContents = searchResults.searchResultEntries.map {
                    Content(
                        id = it.id,
                        title = it.title,
                        subtitle = it.subtitle,
                        score = it.score,
                        type = it.type,
                        background = it.background,
                        banner = it.banner,
                        poster = it.poster,
                        _hasChildren = it.hasChildren,
                        parentId = it.parentId
                    )
                }
                parsedContents.forEach { content ->
                    if (isAuthed) {
                        content.canFav = true
                        if (content.type != ContentTypeModel.Model.ContentType.CONTAINER) {
                            content.canPlay = true
                        }
                        content.isFav = user?.favorites?.contains(content.id.toString()) ?: false
                    }
                }
                if (selectedContent != null) {
                    selectedContent.addChildren(parsedContents)
                    contentLoad = ContentTreeLoad(
                        contentTree,
                        searchResults.count,
                        searchResults.page,
                        searchResults.limit,
                        searchResults.sort,
                        selectedContent
                    )
                } else {
                    val contentTree = Tree(parsedContents)
                    contentLoad = ContentTreeLoad(
                        contentTree,
                        searchResults.count,
                        searchResults.page,
                        searchResults.limit,
                        searchResults.sort
                    )
                }
            } else {
                if (selectedContent != null) {
                    val ratingFilters = if (ratingFilter != null) {
                        mapOf(RATING_FILTER to ratingFilter)
                    } else {
                        null
                    }
                    val serialContents = contentService.getSerialContents(
                        id = selectedContent.value.id,
                        filters = ratingFilters,
                        page = 1,
                        sort = sort.param
                    )
                        .mapError { Error.UnavailableContents(it) }.getOrAbort()
                    val parsedContents = serialContents.contents.map {
                        Content(
                            id = it.id,
                            title = it.title,
                            subtitle = it.subtitle,
                            score = it.score,
                            type = it.type,
                            background = it.background,
                            banner = it.banner,
                            poster = it.poster,
                            _hasChildren = it.hasChildren,
                            parentId = it.parentId
                        )
                    }
                    parsedContents.forEach { parsedContent ->
                        if (isAuthed) {
                            parsedContent.canFav = true
                            if (parsedContent.type != ContentTypeModel.Model.ContentType.CONTAINER) {
                                parsedContent.canPlay = true
                            }
                            parsedContent.isFav = user?.favorites?.contains(parsedContent.id.toString()) ?: false
                        }
                    }
                    selectedContent.addChildren(parsedContents)
                    contentLoad = ContentTreeLoad(
                        contentTree,
                        serialContents.count,
                        serialContents.page,
                        serialContents.limit,
                        serialContents.sort,
                        selectedContent
                    )
                } else {
                    val searchFilters = if (ratingFilter != null) {
                        mutableMapOf(RATING_FILTER to ratingFilter)
                    } else {
                        null
                    }
                    val contents = nodeService.getContents(node.value.id, filters = searchFilters, page = 1, sort = sort.param)
                        .mapError { Error.UnavailableContents(it) }.getOrAbort()
                    contents.contents.forEach { content ->
                        if (isAuthed) {
                            content.canFav = true
                            if (content.type != ContentTypeModel.Model.ContentType.CONTAINER) {
                                content.canPlay = true
                            }
                            content.isFav = user?.favorites?.contains(content.id.toString()) ?: false
                        }
                    }
                    val contentTree = Tree(contents.contents)
                    contentLoad = ContentTreeLoad(
                        contentTree,
                        contents.count,
                        contents.page,
                        contents.limit,
                        contents.sort
                    )
                }
            }
            success(contentLoad)
        }
}

internal class LoadNestedContent(
    private val coordinates: List<Int>,
    private val contentTree: Tree<ViewModel.Content, Content>,
    private val sort: SortModel.Model.Sort
) : OutcomeUseCase<ContentTreeLoad, LoadNestedContent.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.CATALOGUE, UseCaseId.LOAD_NESTED_CONTENT, errorId, networkError) {
        data class UnavailableContents(val error: NetworkError) : Error(
            asErrorId<UnavailableContents>(1),
            error
        )
        object InvalidCoordinates : Error(
            asErrorId<InvalidCoordinates>(2),
        )
        object ContentNotSerialized : Error(
            asErrorId<ContentNotSerialized>(3),
        )
    }

    private val authService: AuthService by diContainer.instance()
    private val userService: UserService by diContainer.instance()
    private val contentService: ContentService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<ContentTreeLoad, Error>
        get() = {
            val contentLoad : ContentTreeLoad
            val auth = authService.getStoredAuth()
            val username = auth?.username
            val user = username?.let { userService.getUser(it).getOrNull() }
            val isAuthed = username != null
            // TODO: Load content with query filter
            var content = contentTree.findNodeOrNull(coordinates)
            if (content == null) {
                // Non-existent node with the given coordinates
                failure(Error.InvalidCoordinates)
            } else {
                if (content.hasChildren) {
                    val ratingFilter = GetRatingFilter().invokeWith(ComponentId.CATALOGUE).mapError {
                        when (it) {
                            is GetRatingFilter.Error.UnavailableRatings -> Error.UnavailableContents(it.error)
                        }
                    }.getOrAbort()
                    val searchFilters = if (ratingFilter != null) {
                        mutableMapOf(RATING_FILTER to ratingFilter)
                    } else {
                        null
                    }
                    val serialContents = contentService.getSerialContents(
                        id = content.value.id,
                        filters = searchFilters,
                        page = 1,
                        sort = sort.param
                    )
                        .mapError { Error.UnavailableContents(it) }.getOrAbort()
                    val parsedContents = serialContents.contents.map {
                        Content(
                            id = it.id,
                            title = it.title,
                            subtitle = it.subtitle,
                            score = it.score,
                            type = it.type,
                            background = it.background,
                            banner = it.banner,
                            poster = it.poster,
                            _hasChildren = it.hasChildren,
                            parentId = it.parentId
                        )
                    }
                    parsedContents.forEach { parsedContent ->
                        if (isAuthed) {
                            parsedContent.canFav = true
                            if (parsedContent.type != ContentTypeModel.Model.ContentType.CONTAINER) {
                                parsedContent.canPlay = true
                            }
                            parsedContent.isFav = user?.favorites?.contains(parsedContent.id.toString()) ?: false
                        }
                    }
                    contentTree.addChildren(coordinates, parsedContents)
                    content = contentTree.findNode(coordinates)
                    content.addChildren(parsedContents)
                    contentLoad = ContentTreeLoad(
                        contentTree,
                        serialContents.count,
                        serialContents.page,
                        serialContents.limit,
                        serialContents.sort,
                        content
                    )
                    success(contentLoad)
                } else {
                    failure(Error.ContentNotSerialized)
                }
            }
        }
}

internal class LogContentSelection(
    private val coordinates: List<Int>,
    private val contentTree: Tree<ViewModel.Content, Content>
) : UseCase<Unit> {
    override suspend fun invoke() {
        val content = contentTree.findNode(coordinates)
        LogEvent(
            action = Action.SelectItem,
            contentId = content.value.id
        ).invoke()
    }
}

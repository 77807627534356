package es.cinfo.tiivii.core.modules.game.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal data class PostActionRequest(
    @SerialName(HASH_PARAM)
    val hash: String,
    @SerialName(TIMESTAMP_PARAM)
    val timestamp: Long,
    @SerialName(CONTENT_ID_PARAM)
    val contentId: Int?
) {
    companion object {
        const val HASH_PARAM = "hashSignature"
        const val TIMESTAMP_PARAM = "timestamp"
        const val CONTENT_ID_PARAM = "contentId"
    }
}
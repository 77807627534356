package es.cinfo.tiivii.search.view

import com.arkivanov.mvikotlin.core.view.MviView
import es.cinfo.tiivii.core.util.LoadingModel
import es.cinfo.tiivii.search.model.Search.ViewModel.SearchResult.SearchResultEntry
import es.cinfo.tiivii.search.view.SearchView.Event
import es.cinfo.tiivii.search.view.SearchView.Event.*
import es.cinfo.tiivii.search.view.SearchView.Model
import es.cinfo.tiivii.search.view.SearchView.Output.*

/**
 * View implementation for the search screen
 */
interface SearchView : MviView<Model, Event> {

    /**
     * Operations available to be requested for the search screen
     * @see PerformSearch
     * @see ClearSearch
     * @see LogSearchView
     * @see LogSelectItem
     */
    sealed class Event {

        /**
         * Requests suggestions to be retrieved based on an input search query. Can be used for autocompletion
         * @param searchQuery The input of the user for the request
         */
        data class GetSuggestions(
            val searchQuery: String
        ) : Event()

        /**
         * Requests the suggestions to be cleared
         */
        object ClearSuggestions : Event()

        /**
         * Requests a search to be made
         * @param searchQuery The input of the user for searching
         */
        data class PerformSearch(
            val searchQuery: String
        ) : Event()

        /**
         * Requests the last search results to be cleared
         */
        object ClearSearch : Event()

        /**
         * Request a search view log event to be made. It should be send
         * whenever the search screen is viewed. This event is exposed for more grained control
         * for the UI
         */
        object LogSearchView : Event()

        /**
         * Request a select item log event to be made. It should be send
         * whenever the user selects a search result. This event is exposed for more grained control
         * for the UI
         */
        data class LogSelectItem(val contentId: Int) : Event()
    }

    /**
     * The model of the search screen exposed to the UI
     * @param searchLoading indicates the status of the search
     * @param suggestions indicates if the status of the suggestions retrieval
     * @param suggestions represents a list of suggestions based on the inputs through the [GetSuggestions] event that
     * can be used to show autocompletion results
     * @param searchResults represents the matching [SearchResultEntry] of the [PerformSearch] event
     */
    data class Model(
        val suggestions: List<SearchResultEntry>,
        val suggestionsLoading: LoadingModel.ViewModel.LoadState,
        val searchResults: List<SearchResultEntry>,
        val searchLoading: LoadingModel.ViewModel.LoadState,
    )

    /**
     * One-time notifications that can be received on the search screen
     * @see UnexpectedError
     * @see UserSessionExpired
     * @see RequestTimedOut
     */
    sealed class Output {
        /**
         * Unexpected error happened
         */
        data class UnexpectedError(val code: String) : Output()

        /**
         * User session has expired. Re-log is necessary
         */
        object UserSessionExpired : Output()

        /**
         * A request has timed out. This may indicate a problem with the internet connection
         */
        object RequestTimedOut : Output()
    }

}
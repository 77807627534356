package es.cinfo.tiivii.core.modules.auth.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

sealed class AuthModel {

    sealed class ViewModel {

        /**
         * Represents an auth object with authorization & identification information
         * @param accessToken access token for authorized requests
         * @param refreshToken refresh token to update the access token when expire
         * @param clientId client id used for the authorization
         * @param realm keycloak realm used for the authorization
         * @param baseUri keycloak base uri used for the authorization
         */
        data class Auth(
            val accessToken: String,
            val refreshToken: String,
            val clientId: String,
            val realm: String,
            val baseUri: String
        )

    }

    internal sealed class Model {

        @Serializable
        data class Auth(
            val username: String,
            val params: Data
        ) {

            @Serializable
            data class Data(
                val accessToken: String,
                val refreshToken: String,
                val clientId: String,
                val realm: String,
                val baseUri: String,
                val tokenUri: String,
                val userInfoUri: String
            ) {
                fun modelToUi(): ViewModel.Auth {
                    return ViewModel.Auth(
                        accessToken = accessToken,
                        refreshToken = refreshToken,
                        clientId = clientId,
                        realm = realm,
                        baseUri = baseUri
                    )
                }
            }

        }

    }

    internal sealed class ApiResponse {

        @Serializable
        data class Auth(
            @SerialName(ACCESS_TOKEN_PARAM)
            val accessToken: String,
            @SerialName(TOKEN_TYPE_PARAM)
            val tokenType: String,
            @SerialName(REFRESH_TOKEN_PARAM)
            val refreshToken: String,
            @SerialName(EXPIRES_IN_PARAM)
            val expiresInSeconds: Int,
            @SerialName(REFRESH_EXPIRES_IN_PARAM)
            val refreshExpiresInSeconds: Int,
            @SerialName(SCOPE_PARAM)
            val scope: String,
        ) {
            companion object {
                const val ACCESS_TOKEN_PARAM = "access_token"
                const val TOKEN_TYPE_PARAM = "token_type"
                const val REFRESH_TOKEN_PARAM = "refresh_token"
                const val EXPIRES_IN_PARAM = "expires_in"
                const val REFRESH_EXPIRES_IN_PARAM = "refresh_expires_in"
                const val SCOPE_PARAM = "scope"

            }

            fun apiToModel(clientId: String, realm: String, baseUri: String, tokenUri: String, userInfoUri: String): Model.Auth.Data {
                return Model.Auth.Data(
                    accessToken = accessToken,
                    refreshToken = refreshToken,
                    clientId = clientId,
                    realm = realm,
                    baseUri = baseUri,
                    tokenUri = tokenUri,
                    userInfoUri = userInfoUri
                )
            }
        }

    }

}


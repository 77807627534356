package es.cinfo.tiivii.core.qr

import es.cinfo.tiivii.core.util.Base64

actual class QRService {

    private val qrCodeWriter = QRCodeWriter()

    @JsName("generateQR")
    actual fun qrAsBase64(content: String, height: Int, width: Int): String? {
        // TODO: Implementation not needed for web right now
        return null
    }

    private fun buildQrAsBase64(content: String, height: Int, width: Int): String {
        val matrix = qrCodeWriter.encode(
            content,
            BarcodeFormat.QR_CODE,
            width,
            height,
            null
        )
        val w = matrix.getWidth()
        val h = matrix.getHeight()
        val black = 0
        val white = 127
        val image = ByteArray(w.toInt() * h.toInt())
        for (y in 0 until height) {
            for (x in 0 until width) {
                if (matrix.get(x, y))
                    image[y * width + x] = black.toByte()
                else image[y * width + x] = white.toByte()
            }

        }
        return Base64.encode(image)
    }

}

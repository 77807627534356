package es.cinfo.tiivii.core.node

import es.cinfo.tiivii.core.features.catalogue.model.Model.ContentNode
import es.cinfo.tiivii.core.features.catalogue.model.Model.NodeContentsLoad
import es.cinfo.tiivii.core.node.NodeService.Companion.defaultContentSort
import es.cinfo.tiivii.core.sorting.SortModel.Model.Sort
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.core.util.map
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

internal interface NodeService {

    companion object {
        // TODO: Add to configuration service
        const val DEFAULT_CONTENT_LIMIT = 100
        val defaultContentSort = Sort.mostRecentFirst
    }

    suspend fun getRootNodes(language: String): Outcome<List<ContentNode>, NetworkError>

    suspend fun getChildrenNodes(parentNodeId: Int, language: String): Outcome<List<ContentNode>, NetworkError>

    suspend fun getContents(
        nodeId: Int,
        filters: Map<String, String>? = null,
        limit: Int = DEFAULT_CONTENT_LIMIT,
        page: Int,
        sort: String? = defaultContentSort.param
    ): Outcome<NodeContentsLoad, NetworkError>
}

internal class DefaultNodeService : NodeService {

    private val nodeApi: NodeApi by diContainer.instance()

    override suspend fun getRootNodes(language: String): Outcome<List<ContentNode>, NetworkError> {
        return nodeApi.getRootNodes().map { nodes ->
            nodes.map {
                it.toModel(language)
            }
        }
    }

    override suspend fun getChildrenNodes(parentNodeId: Int, language: String): Outcome<List<ContentNode>, NetworkError> {
        return nodeApi.getChildrenNodes(parentNodeId).map { nodes ->
            nodes.map {
                it.toModel(language)
            }
        }
    }

    override suspend fun getContents(
        nodeId: Int,
        filters: Map<String, String>?,
        limit: Int,
        page: Int,
        sort: String?
    ): Outcome<NodeContentsLoad, NetworkError> {
        return nodeApi.getContents(nodeId, filters, limit, page, sort ?: defaultContentSort.param).map {
            it.toModel()
        }
    }

}
package es.cinfo.tiivii.core

import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.config.CoreBuild
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

/**
 * Initialization point for the core library
 */
object Core {
    /**
     * Initializes the internal core library and setups required dependencies
     * @return config values established by compilation
     */
    fun getCoreBuild(): CoreBuild {
        val configModule: ConfigModule by diContainer.instance()
        return CoreBuild(
            coreConfig = configModule.getCoreConfig().toViewModel(),
            envConfig = configModule.getEnvConfig().toViewModel()
        )
    }
}

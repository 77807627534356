package es.cinfo.tiivii.core.modules.auth

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.features.user.login.LoginModel.ApiResponse.Login
import es.cinfo.tiivii.core.modules.auth.model.AuthModel.ApiResponse.Auth
import es.cinfo.tiivii.core.modules.auth.model.LoginApiRequest
import es.cinfo.tiivii.core.modules.auth.model.LogoutApiRequest
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.network.HttpModule
import es.cinfo.tiivii.core.user.UserApi
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.di.diContainer
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.http.*
import org.kodein.di.instance
import es.cinfo.tiivii.core.modules.auth.model.AuthModel.Model.Auth as AuthModel

/**
 * Network datasource for authentication data and operations
 */
internal interface AuthApi {
    /**
     * Requests and update of the login data to backend using the previous values and stores the new ones
     * @param currentAuth currently stored [AuthModel]
     */
    suspend fun refreshAuthData(
        currentAuth: AuthModel
    ): Outcome<Auth, NetworkError>

    /**
     * Retrieves the username from keycloak for the given access token
     * @param token access token received from authentication
     * @param userInfoEndpoint keycloak userinfo endpoint to retrieve the username
     * @return username string associated with the given token
     */
    suspend fun getUsernameFromToken(token: String, userInfoEndpoint: String)
            : Outcome<String, NetworkError>

    /**
     * Requests a logout for the given data, ending the session and expiring the tokens
     * @param refreshToken used on the session
     * @param clientId used to login
     */
    suspend fun logout(refreshToken: String, clientId: String): Outcome<Unit, NetworkError>

    /**
     * Request a login even to the backend with the given information
     * @param username for the login
     * @param password for the login
     * @param clientId of keycloak to use
     */
    suspend fun login(username: String, password: String, clientId: String): Outcome<Login, NetworkError>
}

/**
 * Default implementation of [AuthApi] using Ktor
 */
internal class DefaultAuthApi : AuthApi {

    private val http: HttpModule by diContainer.instance()
    private val configModule: ConfigModule by diContainer.instance()

    override suspend fun refreshAuthData(
        currentAuth: AuthModel
    ): Outcome<Auth, NetworkError> {
        return http.submitFormAsOutcome(
            endpoint = currentAuth.params.tokenUri,
            parameters = Parameters.build {
                append("client_id", currentAuth.params.clientId)
                append("grant_type", "refresh_token")
                append("refresh_token", currentAuth.params.refreshToken)
            }
        )
    }

    override suspend fun getUsernameFromToken(token: String, userInfoEndpoint: String)
            : Outcome<String, NetworkError> {
        return http.getAsOutcome(endpoint = userInfoEndpoint, responseField = "preferred_username") {
            header("Authorization", "Bearer $token")
        }
    }

    override suspend fun logout(refreshToken: String, clientId: String)
    : Outcome<Unit, NetworkError> {
        val endpoint = "${configModule.getEnvConfig().backendUrl}/iden/users/${configModule.getEnvConfig().apiName}/logout"
        return http.postOrError(endpoint = endpoint) {
            header("Content-Type", "application/json")
            body = LogoutApiRequest(clientId, refreshToken)
        }
    }

    override suspend fun login(username: String, password: String, clientId: String): Outcome<Login, NetworkError> {
        val lowerCaseUsername = username.lowercase()
        val endpoint = "${configModule.getEnvConfig().backendUrl}/iden/users/${configModule.getEnvConfig().apiName}/login"
        return http.postAsOutcome(endpoint = endpoint) {
            header("Content-Type", "application/json")
            body = LoginApiRequest(clientId, lowerCaseUsername, password)
        }
    }
}
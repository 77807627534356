package es.cinfo.tiivii.search.data

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.network.HttpModule
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.di.diContainer
import es.cinfo.tiivii.search.model.Search
import org.kodein.di.instance
import es.cinfo.tiivii.search.model.Search.ApiResponse.SearchResult as SearchApiResponse

internal interface SearchApi {

    suspend fun performSearch(
        query: String?,
        sort: String,
        filters: Map<String, String>?,
        page: Int,
        limit: Int
    ): Outcome<SearchApiResponse, NetworkError>
}

internal class DefaultSearchApi : SearchApi {
    private val http: HttpModule by diContainer.instance()

    private val baseEndpoint: String by lazy {
        val configModule: ConfigModule by diContainer.instance()
        "${configModule.getEnvConfig().backendUrl}/sgca/${configModule.getEnvConfig().apiName}"
    }

    override suspend fun performSearch(
        query: String?,
        sort: String,
        filters: Map<String, String>?,
        page: Int,
        limit: Int
    ): Outcome<Search.ApiResponse.SearchResult, NetworkError> {
        var endpoint = "$baseEndpoint/search?sort=$sort&page=$page&limit=$limit&filter[status]=published"
        if (query != null) {
            endpoint += "&filter[title][contains]=$query"
        }
        filters?.forEach {
            endpoint += "&filter${it.key}=${it.value}"
        }
        return http.getAsOutcome(endpoint = endpoint)
    }
}
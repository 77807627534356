package es.cinfo.tiivii.core.features.ranking.view

import com.arkivanov.mvikotlin.core.view.MviView
import es.cinfo.tiivii.core.features.ranking.view.RankingView.Event
import es.cinfo.tiivii.core.features.ranking.view.RankingView.Event.ReloadUserStats
import es.cinfo.tiivii.core.features.ranking.view.RankingView.Model
import es.cinfo.tiivii.core.features.ranking.view.RankingView.Output.UnexpectedError
import es.cinfo.tiivii.core.features.ranking.view.RankingView.Output.UserSessionExpired
import es.cinfo.tiivii.core.modules.avatar.model.AvatarModel.ViewModel.Avatar
import es.cinfo.tiivii.core.modules.game.model.GameModel.ViewModel.Achievement
import es.cinfo.tiivii.core.modules.game.model.GameModel.ViewModel.Reward
import es.cinfo.tiivii.core.userstats.UserStatsModel.ViewModel.Ranking
import es.cinfo.tiivii.core.util.LoadingModel.ViewModel.LoadState

/**
 * View implementation for the ranking & user stats screen
 */
interface RankingView : MviView<Model, Event> {

    /**
     * Operations available to be requested for the ranking & user stats screen
     * @see ReloadUserStats
     */
    sealed class Event {
        /**
         * Requests a reload of all the rankings and user stats data
         */
        object ReloadUserStats : Event()
    }

    /**
     * The model of the ranking & user stats screen exposed to the UI
     * @param userAvatar indicates the user [Avatar] in the app
     * @param userRanking identifies the user [Ranking] in the app
     * @param rankings contains the list of all user [Ranking]s loaded
     * @param achievements identifies the list of [Achievement]s of the user
     * @param rewards identifies the list of [Reward]s of the user
     * @param closestAchievement identifies the next closes [Achievement] that the user can obtain
     * @param loadingStats identifies the loading state of the user stats & rankings
     */
    data class Model(
        val userAvatar: Avatar?,
        val userRanking: Ranking?,
        val rankings: List<Ranking>,
        val achievements: List<Achievement>,
        val rewards: List<Reward>,
        val closestAchievement: Achievement?,
        val loadingStats: LoadState
    )

    /**
     * One-time notifications that can be received on the home screen
     * @see UnexpectedError
     * @see UserSessionExpired
     */
    sealed class Output {
        /**
         * Unexpected error happened
         * @param code string representation of a internal error code to be shown with the generic error to the user
         */
        data class UnexpectedError(val code: String) : Output()

        /**
         * User session has expired, user needs to log in again
         */
        object UserSessionExpired : Output()

        /**
         * A request has timed out. This may indicate a problem with the internet connection
         */
        object RequestTimedOut : Output()
    }

}

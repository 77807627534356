package es.cinfo.tiivii.core.content.model

import es.cinfo.tiivii.core.content.model.ViewModel.Content
import es.cinfo.tiivii.core.layout.LayoutApi
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import es.cinfo.tiivii.core.content.model.ContentTypeModel.Model.ContentType as ContentTypeModel
import es.cinfo.tiivii.core.content.model.ContentTypeModel.ViewModel.ContentType as ContentTypeViewModel
import es.cinfo.tiivii.core.image.ApiResponse.Image as ImageApiResponse
import es.cinfo.tiivii.core.image.Model.Image as ImageModel
import es.cinfo.tiivii.core.image.Model.Image.Type as ImageType
import es.cinfo.tiivii.core.image.ViewModel.Image as ImageViewModel

sealed class RelatedContentModel {

    sealed class ViewModel {
        /**
         * Representation of a related content. This is a minified version of the [Content] class
         */
        data class RelatedContent(
            val id: Int,
            val title: String,
            val subtitle: String?,
            val type: ContentTypeViewModel,
            val background: ImageViewModel?,
            val banner: ImageViewModel?,
            val poster: ImageViewModel?,
            val episode: Int?
        )
    }

    internal sealed class Model {
        data class CachedRelatedContent(
            val categoryId: Int,
            val tags: List<String>?,
            val filters: Map<String, String>?
        ) {
            companion object {
                fun from(categoryId: Int, tags: List<String>?, filters: Map<String, String>?) =
                    CachedRelatedContent(categoryId, tags, filters)
            }
        }
        data class RelatedContent(
            val id: Int,
            val title: String,
            val subtitle: String?,
            val background: ImageModel?,
            val banner: ImageModel?,
            val poster: ImageModel?,
            val type: ContentTypeModel,
            val episode: Int?
        ) {
            fun toViewModel(): ViewModel.RelatedContent =
                ViewModel.RelatedContent(
                    id = id,
                    title = title,
                    subtitle = subtitle,
                    type = type.toViewModel(),
                    background = background?.toViewModel(),
                    banner = banner?.toViewModel(),
                    poster = poster?.toViewModel(),
                    episode = episode
                )
        }

    }

    internal sealed class ApiResponse {

        @Serializable
        data class RelatedContent(
            @SerialName(ID_PARAM)
            val id: Int,
            @SerialName(TITLE_PARAM)
            val title: String,
            @SerialName(SUBTITLE_PARAM)
            val subtitle: String?,
            @SerialName(DESCRIPTION_PARAM)
            val description: String,
            @SerialName(POSTER_PARAM)
            val poster: ImageApiResponse?,
            @SerialName(BANNER_PARAM)
            val banner: ImageApiResponse?,
            @SerialName(BACKGROUND_PARAM)
            val background: ImageApiResponse?,
            @SerialName(TYPE_PARAM)
            val type: String,
            @SerialName(EPISODE_PARAM)
            val episode: Int?,
        ) {
            companion object {
                const val ID_PARAM = "id"
                const val TITLE_PARAM = "title"
                const val SUBTITLE_PARAM = "subtitle"
                const val DESCRIPTION_PARAM = "description"
                const val POSTER_PARAM = "poster"
                const val BANNER_PARAM = "banner"
                const val BACKGROUND_PARAM = "background"
                const val TYPE_PARAM = "type"
                const val EPISODE_PARAM = "episode"
            }

            fun toModel(): Model.RelatedContent {
                return Model.RelatedContent(
                    id = id,
                    title = title,
                    subtitle = subtitle,
                    background = background?.toModel(ImageType.BACKGROUND),
                    banner = banner?.toModel(ImageType.BANNER),
                    poster = poster?.toModel(ImageType.POSTER),
                    type = ContentTypeModel.parse(type),
                    episode = episode
                )
            }

        }

    }

}
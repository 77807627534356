package es.cinfo.tiivii.search.model

import es.cinfo.tiivii.core.content.model.ApiResponse.Content.Data.Companion.MAX_SCORE_VALUE
import es.cinfo.tiivii.core.content.model.ApiResponse.Content.Data.Companion.MIN_SCORE_VALUE
import es.cinfo.tiivii.core.modules.rating.RatingModel
import es.cinfo.tiivii.core.sorting.SortModel.Model.Sort
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.math.max
import kotlin.math.min
import es.cinfo.tiivii.core.content.model.ContentTypeModel.Model.ContentType as ContentTypeModel
import es.cinfo.tiivii.core.content.model.ContentTypeModel.ViewModel.ContentType as ContentTypeViewModel
import es.cinfo.tiivii.core.image.ApiResponse.Image as ImageApiResponse
import es.cinfo.tiivii.core.image.Model.Image as ImageModel
import es.cinfo.tiivii.core.image.Model.Image.Type as ImageType
import es.cinfo.tiivii.core.image.ViewModel.Image as ImageViewModel

sealed class Search {

    sealed class ViewModel {
        data class SearchResult(
            val searchResultEntry: List<SearchResultEntry>,
        ) {
            data class SearchResultEntry(
                val id: Int,
                val title: String,
                val subtitle: String?,
                val score: Float,
                val votes: Int,
                val rating: String,
                val type: ContentTypeViewModel,
                val author: String,
                val background: ImageViewModel?,
                val banner: ImageViewModel?,
                val poster: ImageViewModel?,
                val parentId: Int?
            )
        }
    }

    internal sealed class Model {
        data class SearchResult(
            val searchResultEntries: List<SearchResultEntry>,
            val limit: Int,
            val page: Int,
            val sort: Sort,
            val count: Int,
        ) {
            data class SearchResultEntry(
                val id: Int,
                val title: String,
                val subtitle: String?,
                val score: Float,
                val votes: Int,
                val rating: String,
                val type: ContentTypeModel,
                val author: String,
                val background: ImageModel?,
                val banner: ImageModel?,
                val poster: ImageModel?,
                val hasChildren: Boolean,
                val parentId: Int?
            ) {

                fun toViewModel(): ViewModel.SearchResult.SearchResultEntry {
                    return ViewModel.SearchResult.SearchResultEntry(
                        id = id,
                        title = title,
                        subtitle = subtitle,
                        score = score,
                        votes = votes,
                        rating = rating,
                        type = type.toViewModel(),
                        author = author,
                        background = background?.toViewModel(),
                        banner = banner?.toViewModel(),
                        poster = poster?.toViewModel(),
                        parentId = parentId
                    )
                }

            }
        }
    }

    internal sealed class ApiResponse {
        @Serializable
        data class SearchResult(
            @SerialName(DATA_PARAM)
            val data: List<Data>,
            @SerialName(LIMIT_PARAM)
            val limit: Int,
            @SerialName(PAGE_PARAM)
            val page: Int,
            @SerialName(SORT_PARAM)
            val sort: String,
            @SerialName(METADATA_PARAM)
            val metadata: Metadata,
        ) {
            companion object {
                const val DATA_PARAM = "data"
                const val LIMIT_PARAM = "limit"
                const val PAGE_PARAM = "page"
                const val SORT_PARAM = "sort"
                const val METADATA_PARAM = "meta"
            }

            fun toModel(): Model.SearchResult {
                return Model.SearchResult(
                    data.map { it.toModel() },
                    limit,
                    page,
                    Sort.fromParam(sort),
                    metadata.filterCount
                )
            }


            @Serializable
            data class Data(
                @SerialName(ID_PARAM)
                val id: Int,
                @SerialName(TITLE_PARAM)
                val title: String,
                @SerialName(SUBTITLE_PARAM)
                val subtitle: String? = null,
                @SerialName(SCORE_PARAM)
                val score: Float,
                @SerialName(VOTES_PARAM)
                val votes: Int,
                @SerialName(RATING_PARAM)
                val rating: RatingModel.ApiResponse.AgeRating,
                @SerialName(TYPE_PARAM)
                val type: String,
                @SerialName(AUTHOR_PARAM)
                val author: String,
                @SerialName(POSTER_PARAM)
                val poster: ImageApiResponse?,
                @SerialName(BANNER_PARAM)
                val banner: ImageApiResponse?,
                @SerialName(BACKGROUND_PARAM)
                val background: ImageApiResponse?,
                @SerialName(SERIAL_PARAM)
                val serial: Boolean,
                @SerialName(PARENT_PARAM)
                val parent: Parent? = null,
            ) {
                companion object {
                    const val ID_PARAM = "id"
                    const val TITLE_PARAM = "title"
                    const val SUBTITLE_PARAM = "subtitle"
                    const val SCORE_PARAM = "valoration"
                    const val VOTES_PARAM = "votes"
                    const val RATING_PARAM = "rating"
                    const val TYPE_PARAM = "type"
                    const val AUTHOR_PARAM = "author"
                    const val POSTER_PARAM = "poster"
                    const val BANNER_PARAM = "banner"
                    const val BACKGROUND_PARAM = "background"
                    const val SERIAL_PARAM = "serial"
                    const val PARENT_PARAM = "parent"
                }

                @Serializable
                data class Parent(
                    @SerialName(ID_PARAM)
                    val id: Int
                ) {
                    companion object {
                        const val ID_PARAM = "id"
                    }
                }

                fun toModel(): Model.SearchResult.SearchResultEntry {
                    val parsedType = ContentTypeModel.parse(type)
                    return Model.SearchResult.SearchResultEntry(
                        id = id,
                        title = title,
                        subtitle = subtitle,
                        score = max(min(score, MAX_SCORE_VALUE), MIN_SCORE_VALUE),
                        votes = votes,
                        rating = rating.code,
                        type = ContentTypeModel.parse(type),
                        background = background?.toModel(ImageType.BACKGROUND),
                        banner = banner?.toModel(ImageType.BANNER),
                        poster = poster?.toModel(ImageType.POSTER),
                        author = author,
                        hasChildren = serial && parsedType == ContentTypeModel.CONTAINER,
                        parentId = parent?.id
                    )
                }

            }

            @Serializable
            data class Metadata(
                @SerialName(FILTER_COUNT_PARAM)
                val filterCount: Int
            ) {
                companion object {
                    const val FILTER_COUNT_PARAM = "filter_count"
                }
            }
        }
    }
}
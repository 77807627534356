package es.cinfo.tiivii.core.modules.chat

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.modules.chat.model.PostMessageApiRequest
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.network.HttpModule
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.di.diContainer
import io.ktor.client.request.*
import org.kodein.di.instance

internal interface ChatApi {

    suspend fun sendMessage(message: String, username: String, channel: String, event: String): Outcome<Unit, NetworkError>
}

internal class DefaultChatApi: ChatApi {
    private val http: HttpModule by diContainer.instance()

    private val baseEndpoint: String by lazy {
        val configModule: ConfigModule by diContainer.instance()
        "${configModule.getEnvConfig().backendUrl}/comm/chat/${configModule.getEnvConfig().apiName}"
    }

    override suspend fun sendMessage(message: String, username: String, channel: String, event: String): Outcome<Unit, NetworkError> {
        val endpoint = "$baseEndpoint/${username}"
        return http.postOrError(endpoint = endpoint) {
            header("Content-Type", "application/json")
            body = PostMessageApiRequest(channel, event, message)
        }
    }
}

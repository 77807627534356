package es.cinfo.tiivii.footer.store

import com.arkivanov.mvikotlin.core.store.Store
import es.cinfo.tiivii.core.util.LoadingModel
import es.cinfo.tiivii.footer.model.Footer
import es.cinfo.tiivii.footer.store.FooterStore.*

internal interface FooterStore : Store<Intent, State, Label> {

    sealed class Intent {
        object FetchSponsors : Intent()
    }

    data class State(
        val sponsorsLogos: List<Footer.Model.Sponsor>,
        val sponsorsLoading: LoadingModel.Model.LoadState
    )

    sealed class Label {
        object UserSessionExpired : FooterStore.Label()
        object RequestTimedOut : FooterStore.Label()
        data class UnexpectedError(val code: String) : FooterStore.Label()
    }
}

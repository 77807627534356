package es.cinfo.tiivii.core

/**
 * Declares the identifiers for all the components in the app
 */
internal enum class ComponentId(val id: Int) {
    HOME(1),
    DETAIL(2),
    CONTENT(3),
    CATALOGUE(4),
    RANKING(5),
    NOTIFICATIONS(6),
    PLAYBACK(7),
    SPLASH(8),
    LOGIN(9),
    VR(10),
    FOOTER(11),
    MENU(12),
    SEARCH(13),
    LEGAL(14),
    PROFILE(15),
    SIGNUP(16)
}


/**
 * Declares the identifiers for all the use cases in the app
 */
internal enum class UseCaseId(val id: Int) {
    LOAD_SCREEN_LAYOUT(1),
    LOAD_DEFAULT_SCREEN_LAYOUT(2),
    ADD_CONTENT_TO_FAVORITES(3),
    REMOVE_CONTENT_FROM_FAVORITES(4),
    LOAD_CONTENT_BY_WIDGET(5),
    LOAD_CONTENT_BY_PARTICIPANT(6),
    LOAD_CONTENT_BY_CATEGORY(7),
    LOAD_MORE_CONTENT_BY_WIDGET(8),
    LOAD_MORE_CONTENT_BY_PARTICIPANT(9),
    LOAD_MORE_CONTENT_BY_CATEGORY(10),
    FILTER_CONTENT_FOR_WIDGET(11),
    FILTER_CONTENT_FOR_PARTICIPANT(12),
    FILTER_CONTENT_FOR_CATEGORY(13),
    SORT_CONTENT_FOR_WIDGET(14),
    SORT_CONTENT_FOR_PARTICIPANT(15),
    SORT_CONTENT_FOR_CATEGORY(16),
    LOAD_CONTENT_BY_ID(17),
    LOAD_MORE_CONTENT_BY_ID(18),
    FILTER_CONTENT_FOR_ID(19),
    SORT_CONTENT_FOR_ID(20),
    LOAD_USER_STATS(21),
    LOAD_ROOT_NODES(22),
    RELOAD_CONTENTS(23),
    LOAD_NODE(24),
    LOAD_CONTENT_PAGE(25),
    FILTER_CONTENTS(26),
    LOAD_NESTED_CONTENT(27),
    LOAD_CONTENT(28),
    LOAD_CONTENT_COMMENTS_PAGE(29),
    RATE_CONTENT(30),
    DELETE_CONTENT_RATING(31),
    ADD_COMMENT(32),
    REMOVE_COMMENT(33),
    UPDATE_COMMENT(34),
    REFRESH_COMMENTS(35),
    REPORT_CONTENT(36),
    GET_SPONSORS(37),
    GET_UI_LANGUAGE(38),
    GET_ORDERED_SECTIONS(39),
    PERFORM_SEARCH(40),
    GET_LATEST_LEGAL_CONDITIONS(41),
    ACCEPT_LATEST_LEGAL_CONDITIONS(42),
    UPDATE_INTERESTS(43),
    UPDATE_LANGUAGE(44),
    UPDATE_AVATAR(45),
    UPDATE_CREDENTIALS(46),
    UPDATE_FIRST_NAME(47),
    UPDATE_LAST_NAME(48),
    DELETE_USER_ACCOUNT(49),
    GET_CURRENT_USER(50),
    RELOAD_USER_STATS(51),
    GET_AVAILABLE_INTERESTS(52),
    GET_AVAILABLE_AVATARS(53),
    IS_USERNAME_VALID_SIGNUP(54),
    IS_EMAIL_VALID(55),
    GET_ROLE(56),
    GET_RANDOM_USERNAME(57),
    CREATE_USER(58),
    GET_FIRST_SCREEN(59),
    LAST_LEGAL_CONDITIONS_SIGNED(60),
    REFRESH_AUTH(61),
    STORE_AUTH(62),
    STORE_AUTH_AND_NEXT_SCREEN(63),
    NEXT_SCREEN_GUEST_LOGIN(64),
    GET_LOGIN_CLIENT(65),
    LOAD_NOTIFICATIONS(66),
    DELETE_NOTIFICATIONS(67),
    READ_NOTIFICATIONS(68),
    GET_FIRST_SCREEN_NATIVE(69),
    GET_VIDEO_URL(70),
    IS_USERNAME_VALID_LOGIN(71),
    LOGIN(72),
    ENTER_AS_GUEST(73),
    RESET_PASSWORD(74),
    GET_DEFAULT_STYLES(75),
    GET_PAYLOAD(76),
    CHECK_CHECKPOINT_OPTION_SELECTED(77),
    SEND_GAME_ACTION(78),
    GET_SUGGESTIONS(79),
    PUBLISH_CONTENT(80),
    LOAD_PRODUCTS(81),
    GET_PRODUCT_CHECKOUT(82),
    LOAD_MORE_USER_PRODUCTS(83),
    GET_RATING_FILTER(84),
    GET_DECLARATIONS(85),
    GET_PRODUCT_DASHBOARD_URL(86),
    GET_LEGAL_CONDITIONS_VERSION(87),
    LOAD_SORT_METHODS(88),
    GET_SUPPORT_EMAIL(89),
    GET_NEXT_CONTENT(90),
    GET_CONFIG_VALUES(91),
    USER_SESSION_REQUIRED(92),
    GET_APP_LINK(93)
}

internal data class ErrorId(val id: Int, val name: String)
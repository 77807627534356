package es.cinfo.tiivii.core.modules.chat.model

class ChatModel {
    sealed class ViewModel {
        data class Message(
            val timestamp: Long,
            val username: String,
            val message: String
        )
        enum class ChatStatus {
            DISCONNECTED, CONNECTING, CONNECTED
        }
    }
    internal sealed class Model {
        data class Message(
            val timestamp: Long,
            val username: String,
            val message: String
        ) {
            fun toViewModel(): ViewModel.Message =
                ViewModel.Message(timestamp, username, message)
        }
        enum class ChatStatus {
            DISCONNECTED, CONNECTING, CONNECTED;

            fun toViewModel(): ViewModel.ChatStatus =
                when (this) {
                    DISCONNECTED -> ViewModel.ChatStatus.DISCONNECTED
                    CONNECTING -> ViewModel.ChatStatus.CONNECTING
                    CONNECTED -> ViewModel.ChatStatus.CONNECTED
                }
        }
    }
}
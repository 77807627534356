package es.cinfo.tiivii.core.modules.avatar

import es.cinfo.tiivii.core.modules.avatar.model.AvatarModel.Model.Avatar
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.core.util.map
import es.cinfo.tiivii.core.util.mapToSet
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

internal interface AvatarService {
    suspend fun getAvailableAvatars(): Outcome<Set<Avatar>, NetworkError>
}

internal class DefaultAvatarService : AvatarService {
    private val avatarApi: AvatarApi by diContainer.instance()

    override suspend fun getAvailableAvatars(): Outcome<Set<Avatar>, NetworkError> {
        return avatarApi.getAvailableAvatars().map {
            it.mapToSet { avatar -> avatar.toModel() }
        }
    }

}
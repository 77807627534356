package es.cinfo.tiivii.search.binder

import es.cinfo.tiivii.search.store.SearchStore
import es.cinfo.tiivii.search.view.SearchView.*

/**
 * Transforms the internal [SearchStore.State] to the [Model] for the UI
 */
internal val STATE_TO_MODEL: SearchStore.State.() -> Model = {
    Model(
        suggestionsLoading = suggestionsLoading.toViewModel(),
        suggestions = suggestions.map {
            it.toViewModel()
        },
        searchLoading = searchLoading.toViewModel(),
        searchResults = searchResults.map {
            it.toViewModel()
        }
    )
}

/**
 * Transforms the UI [Event] to the internal [SearchStore.Intent]
 */
internal val EVENT_TO_INTENT: Event.() -> SearchStore.Intent = {
    when (this) {
        is Event.PerformSearch -> SearchStore.Intent.PerformSearch(searchQuery)
        Event.ClearSearch -> SearchStore.Intent.ClearSearch
        Event.LogSearchView -> SearchStore.Intent.LogSearchView
        is Event.LogSelectItem -> SearchStore.Intent.LogSelectItem(contentId)
        Event.ClearSuggestions -> SearchStore.Intent.ClearSuggestions
        is Event.GetSuggestions -> SearchStore.Intent.GetSuggestions(searchQuery)
    }
}

/**
 * Transforms the internal [SearchStore.Label] to the [Output] for the UI
 */
internal val LABEL_TO_OUTPUT: SearchStore.Label.() -> Output = {
    when (this) {
        is SearchStore.Label.UnexpectedError -> Output.UnexpectedError(code)
        SearchStore.Label.UserSessionExpired -> Output.UserSessionExpired
        SearchStore.Label.RequestTimeOut -> Output.RequestTimedOut
    }
}
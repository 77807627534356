package es.cinfo.tiivii.nav.menu.binder

import es.cinfo.tiivii.nav.menu.store.MenuStore.*
import es.cinfo.tiivii.nav.menu.view.MenuView.*

internal val STATE_TO_MODEL: State.() -> Model = {
    Model(
        language = language,
        sections = sections?.map { it.toViewModel() },
        loadingSections = loadingSections.toViewModel(),
        selectedSection = selectedSection?.toViewModel(),
        studioUrl = studioUrl,
        supportEmail = supportEmail,
        tutorialUrl = tutorialUrl,
        privacyStatementUrl = privacyStatementUrl,
        termsAndConditionsUrl = termsAndConditionsUrl,
        legalText = legalText,
        iosAppUrl = iosAppUrl,
        androidAppUrl = androidAppUrl,
        faqUrl = faqUrl
    )
}

internal val EVENT_TO_INTENT: Event.() -> Intent = {
    when (this) {
        Event.Reload -> Intent.Reload
        is Event.SelectSection -> Intent.SelectSection(section.id)
    }
}

internal val LABEL_TO_OUTPUT: Label.() -> Output = {
    when (this) {
        Label.RequestTimedOut -> Output.RequestTimedOut
        is Label.UnexpectedError -> Output.UnexpectedError(code)
        Label.UserSessionExpired -> Output.UserSessionExpired
    }
}

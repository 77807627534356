package es.cinfo.tiivii.core.content.model

import es.cinfo.tiivii.core.content.model.ApiResponse.Content.Data.Companion.MAX_SCORE_VALUE
import es.cinfo.tiivii.core.content.model.ApiResponse.Content.Data.Companion.MIN_SCORE_VALUE
import es.cinfo.tiivii.core.content.model.ViewModel.Content
import es.cinfo.tiivii.core.modules.rating.RatingModel
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.math.max
import kotlin.math.min
import es.cinfo.tiivii.core.content.model.ContentTypeModel.Model.ContentType as ContentTypeModel
import es.cinfo.tiivii.core.content.model.ContentTypeModel.ViewModel.ContentType as ContentTypeViewModel
import es.cinfo.tiivii.core.image.ApiResponse.Image as ImageApiResponse
import es.cinfo.tiivii.core.image.Model.Image as ImageModel
import es.cinfo.tiivii.core.image.Model.Image.Type as ImageType
import es.cinfo.tiivii.core.image.ViewModel.Image as ImageViewModel

sealed class SerialContentModel {

    sealed class ViewModel {

        /**
         * Representation of a serial content. This is a minified version of the [Content] class
         */
        data class SerialContent(
            val id: Int,
            val title: String,
            val subtitle: String?,
            val type: ContentTypeViewModel,
            val background: ImageViewModel?,
            val banner: ImageViewModel?,
            val poster: ImageViewModel?,
            val episode: Int?,
            val score: Float,
            val votes: Int,
            val rating: String,
            val parentId: Int?
        )
    }

    internal sealed class Model {
        data class SerialContent(
            val id: Int,
            val title: String,
            val subtitle: String?,
            val background: ImageModel?,
            val banner: ImageModel?,
            val poster: ImageModel?,
            val type: ContentTypeModel,
            val hasChildren: Boolean,
            val episode: Int?,
            val score: Float,
            val votes: Int,
            val rating: RatingModel.Model.AgeRating,
            val smil: String?,
            val lockingAchievements: List<Int>,
            val parentId: Int?
        ) {
            fun toViewModel(): ViewModel.SerialContent =
                ViewModel.SerialContent(
                    id = id,
                    title = title,
                    subtitle = subtitle,
                    type = type.toViewModel(),
                    background = background?.toViewModel(),
                    banner = banner?.toViewModel(),
                    poster = poster?.toViewModel(),
                    episode = episode,
                    score = score,
                    votes = votes,
                    rating = rating.code,
                    parentId = parentId
                )
        }

        data class SerialContentLoad(
            val contents: List<SerialContent>,
            val count: Int,
            val page: Int,
            val limit: Int,
            val sort: es.cinfo.tiivii.core.sorting.SortModel.Model.Sort,
        )
    }

    internal sealed class ApiResponse {
        @Serializable
        data class SerialContentLoad(
            @SerialName(CONTENTS_PARAM)
            val contents: List<SerialContent>,
            @SerialName(PAGE_PARAM)
            val page: Int,
            @SerialName(LIMIT_PARAM)
            val limit: Int,
            @SerialName(SORT_PARAM)
            val sort: String,
            @SerialName(META_PARAM)
            val meta: Meta
        ) {
            companion object {
                const val CONTENTS_PARAM = "data"
                const val PAGE_PARAM = "page"
                const val LIMIT_PARAM = "limit"
                const val SORT_PARAM = "sort"
                const val META_PARAM = "meta"
            }

            @Serializable
            data class Meta(
                @SerialName(FILTER_COUNT_PARAM)
                val filterCount: Int
            ) {
                companion object {
                    const val FILTER_COUNT_PARAM = "filter_count"
                }
            }

            fun toModel(parentId: Int): Model.SerialContentLoad {
                return Model.SerialContentLoad(
                    contents.map { it.toModel(parentId) },
                    meta.filterCount,
                    page,
                    limit,
                    es.cinfo.tiivii.core.sorting.SortModel.Model.Sort.fromParam(sort)
                )
            }
        }

        @Serializable
        data class SerialContent(
            @SerialName(ID_PARAM)
            val id: Int,
            @SerialName(TITLE_PARAM)
            val title: String,
            @SerialName(SUBTITLE_PARAM)
            val subtitle: String?,
            @SerialName(POSTER_PARAM)
            val poster: ImageApiResponse?,
            @SerialName(BANNER_PARAM)
            val banner: ImageApiResponse?,
            @SerialName(BACKGROUND_PARAM)
            val background: ImageApiResponse?,
            @SerialName(SERIAL_PARAM)
            val serial: Boolean,
            @SerialName(TYPE_PARAM)
            val type: String,
            @SerialName(EPISODE_PARAM)
            val episode: Int?,
            @SerialName(SCORE_PARAM)
            val score: Float,
            @SerialName(VOTES_PARAM)
            val votes: Int,
            @SerialName(RATING_PARAM)
            val rating: RatingModel.ApiResponse.AgeRating,
            @SerialName(CONFIGURATION_PARAM)
            val configuration: Configuration? = null,
            @SerialName(RESOURCES_PARAM)
            val resources: List<ResourceWrapper>? = null
        ) {
            companion object {
                const val ID_PARAM = "id"
                const val TITLE_PARAM = "title"
                const val SUBTITLE_PARAM = "subtitle"
                const val POSTER_PARAM = "poster"
                const val BANNER_PARAM = "banner"
                const val BACKGROUND_PARAM = "background"
                const val TYPE_PARAM = "type"
                const val SERIAL_PARAM = "serial"
                const val EPISODE_PARAM = "episode"
                const val SCORE_PARAM = "valoration"
                const val VOTES_PARAM = "votes"
                const val RATING_PARAM = "rating"
                const val RESOURCES_PARAM = "resources"
                const val CONFIGURATION_PARAM = "configuration"
            }

            @Serializable
            data class Parent(
                @SerialName(ID_PARAM)
                val id: Int
            ) {
                companion object {
                    const val ID_PARAM = "id"
                }
            }

            @Serializable
            data class ResourceWrapper(
                @SerialName(DIRECTUS_ID_PARAM)
                val file: Resource,
            ) {
                companion object {
                    const val DIRECTUS_ID_PARAM = "directus_files_id"
                }

                @Serializable
                data class Resource(
                    @SerialName(ID_PARAM)
                    val id: Int,
                    @SerialName(TYPE_PARAM)
                    val type: String,
                    @SerialName(METADATA_PARAM)
                    val metadata: Metadata? = null,
                ) {
                    companion object {
                        const val ID_PARAM = "id"
                        const val TYPE_PARAM = "type"
                        const val METADATA_PARAM = "metadata"
                    }

                    @Serializable
                    data class Metadata(
                        @SerialName(SMIL_PARAM)
                        val smil: String? = null,
                    ) {
                        companion object {
                            const val SMIL_PARAM = "smil"
                        }
                    }

                }
            }

            @Serializable
            data class Configuration(
                @SerialName(GEOLOCATION_PARAM)
                val geolocation: Boolean = false,
                @SerialName(GAMIFICATION_PARAM)
                val gamification: Gamification? = null
            ) {
                companion object {
                    const val GEOLOCATION_PARAM = "geolocation"
                    const val GAMIFICATION_PARAM = "gamification"
                }

                @Serializable
                data class Gamification(
                    @SerialName(REQUIREMENTS_PARAM)
                    val requirements: List<Int> = emptyList(),
                ) {
                    companion object {
                        const val REQUIREMENTS_PARAM = "requirements"
                    }
                }
            }

            fun toModel(parentId: Int): Model.SerialContent {
                val smil = resources?.find { it.file.type == "video/mp4" }?.file?.metadata?.smil
                val parsedType = ContentTypeModel.parse(type)
                return Model.SerialContent(
                    id = id,
                    title = title,
                    subtitle = subtitle,
                    background = background?.toModel(ImageType.BACKGROUND),
                    banner = banner?.toModel(ImageType.BANNER),
                    poster = poster?.toModel(ImageType.POSTER),
                    type = ContentTypeModel.parse(type),
                    hasChildren = serial && parsedType == ContentTypeModel.CONTAINER,
                    episode = episode,
                    score = max(min(score, MAX_SCORE_VALUE), MIN_SCORE_VALUE),
                    votes = votes,
                    rating = rating.toModel(),
                    smil = smil,
                    lockingAchievements = configuration?.gamification?.requirements ?: emptyList(),
                    parentId = parentId
                )
            }

        }
    }

}
package es.cinfo.tiivii.core.modules.game

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.game.model.GameModel.ApiResponse.Action
import es.cinfo.tiivii.core.modules.game.model.PostActionRequest
import es.cinfo.tiivii.core.modules.network.HttpModule
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.di.diContainer
import io.ktor.client.request.*
import org.kodein.di.instance

/**
 * Network datasource for game related operations
 */
internal interface GameApi {

    suspend fun getHash(action: String, timestamp: Long, username: String): Outcome<String, NetworkError>

    suspend fun postAction(action: String, timestamp: Long, username: String, hash: String, contentId: Int?): Outcome<Action, NetworkError>

}

/**
 * Default implementation of [GameApi] using Ktor
 */
internal class DefaultGameApi : GameApi {
    private val http: HttpModule by diContainer.instance()

    private val baseEndpoint: String by lazy {
        val configModule: ConfigModule by diContainer.instance()
        "${configModule.getEnvConfig().backendUrl}/game/${configModule.getEnvConfig().apiName}"
    }

    override suspend fun getHash(action: String, timestamp: Long, username: String): Outcome<String, NetworkError> {
        val endpoint = "$baseEndpoint/${username}/hash?action=${action}&timestamp=${timestamp}"
        return http.getAsOutcome(endpoint = endpoint, responseField = "hash")
    }

    override suspend fun postAction(action: String, timestamp: Long, username: String, hash: String, contentId: Int?): Outcome<Action, NetworkError> {
        val endpoint = "$baseEndpoint/${username}/action/${action}"
        return http.postAsOutcome(endpoint = endpoint) {
            header("Content-Type", "application/json")
            body = PostActionRequest(hash, timestamp, contentId)
        }
    }

}
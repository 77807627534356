package es.cinfo.tiivii.core.modules.analytics

import es.cinfo.tiivii.core.modules.analytics.model.AnalyticsModel
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.core.util.map
import es.cinfo.tiivii.core.util.success
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

internal interface AnalyticsService {
    /**
     * Sends the log of an event to backend
     * @param username The username associated with the event or null if anonymous
     * @param contentId The id of the content related to the action if any
     * @param action [AnalyticsModel.Action] that is to be logged
     * @param keyValue Pair of "key" and "value" params ot register with the query
     */
    suspend fun logEvent(
        username: String? = null,
        contentId: Int? = null,
        action: AnalyticsModel.Action,
        keyValue: Pair<String, String>? = null,
    ): Outcome<Unit, NetworkError>

    /**
     * Sends the log of an api call that has been done
     * @param username The username associated with the event or null if anonymous
     * @param url That has been called
     */
    suspend fun logApiCall(
        username: String?,
        url: String
    )
}

internal class DefaultAnalyticsService : AnalyticsService {
    private val analyticsApi: AnalyticsApi by diContainer.instance()

    override suspend fun logEvent(
        username: String?,
        contentId: Int?,
        action: AnalyticsModel.Action,
        keyValue: Pair<String, String>?,
    ): Outcome<Unit, NetworkError> {
        return analyticsApi.logEvent(username, contentId, action, keyValue)
            .map {
                success()
            }
    }

    override suspend fun logApiCall(username: String?, url: String) {
        analyticsApi.logApiCall(username, url)
    }
}
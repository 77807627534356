package es.cinfo.tiivii.core.layout.model

import kotlinx.serialization.Serializable

/**
 * Models a legal sign of the legal conditions by a user
 */
@Serializable
data class LegalSign(
    val version: Int,
    val isDataCollectionAllowed: Boolean = false
)
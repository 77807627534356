package es.cinfo.tiivii.core.modules.network

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.util.HttpService
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.core.util.failure
import es.cinfo.tiivii.core.util.map
import es.cinfo.tiivii.di.diContainer
import io.ktor.client.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.async
import kotlinx.coroutines.currentCoroutineContext
import kotlinx.serialization.json.Json
import org.kodein.di.instance
import kotlin.jvm.Synchronized

/**
 * Internal HTTP module to make HTTP request and parse responses
 */
internal class HttpModule {

    companion object {
        enum class HttpClients {
            CORE, FIREBASE
        }
    }

    // HTTP GET Request cache used to avoid redundant GET calls
    class RequestCache {

        data class RequestJob(
            val response: Deferred<HttpResponse>,
            val clients: Int = 1
        )

        private val map: MutableMap<String, RequestJob> = mutableMapOf()

        private fun put(key: String, value: RequestJob) {
            map[key] = value
        }

        @Synchronized
        private fun addClient(key: String) {
            val job = map[key]
            if (job != null) {
                put(key, job.copy(clients = job.clients + 1))
            }
        }

        @Synchronized
        fun removeClient(key: String): Int? {
            val job = map[key]
            val remainingClients = if (job != null) {
                val clients = job.clients - 1
                put(key, job.copy(clients = clients))
                clients
            } else {
                null
            }
            return remainingClients
        }

        suspend fun execute(requestId: String, block: suspend () -> HttpResponse): HttpResponse {
            var requestJob = map[requestId]
            if (requestJob == null || !requestJob.response.isActive) {
                val response = CoroutineScope(currentCoroutineContext()).async {
                    block()
                }
                val newRequestJob = RequestJob(response)
                put(requestId, newRequestJob)
                requestJob = newRequestJob
            } else {
                addClient(requestId)
            }
            return requestJob.response.await()
        }

    }

    private val json: Json by diContainer.instance()
    private val coreClient: HttpClient by diContainer.instance()
    private val httpService: HttpService by diContainer.instance()
    private val requestCache = RequestCache()

    /**
     * Executes a GET request and tries to return the result as an [Outcome] of the given type T
     * or [NetworkError] in case of any error
     */
    suspend inline fun <reified T : Any> getAsOutcome(
        client: HttpClients = HttpClients.CORE,
        endpoint: String,
        responseField: String? = null,
        crossinline block: HttpRequestBuilder.() -> Unit = {}
    ): Outcome<T, NetworkError> {
        return try {
            with(getClient(client)) {
                val response = requestCache.execute(endpoint) {
                    get(endpoint, block)
                }
                getResponseAsOutcome(response, responseField)
            }
        } catch (error: Throwable) {
            failure(NetworkError.Execution(error))
        }
    }

    /**
     * Executes a GET request and tries to return the result as an [Outcome] of unit representing a
     * successful and completed request or [NetworkError] in case of any error
     */
    suspend inline fun getOrError(
        client: HttpClients = HttpClients.CORE,
        endpoint: String,
        crossinline block: HttpRequestBuilder.() -> Unit = {}
    ): Outcome<Unit, NetworkError>  {
        return try {
            with(getClient(client)) {
                val response = requestCache.execute(endpoint) {
                    get(endpoint, block)
                }
                getResultAsOutcome<Unit>(response)
            }
        } catch (error: Throwable) {
            failure(NetworkError.Execution(error))
        }
    }

    /**
     * Executes a PATCH request and tries to return the result as an [Outcome] of the given type T
     * or [NetworkError] in case of any error
     */
    suspend inline fun <reified T> patchAsOutcome(
        client: HttpClients = HttpClients.CORE,
        endpoint: String,
        responseField: String? = null,
        block: HttpRequestBuilder.() -> Unit = {}
    ): Outcome<T, NetworkError>  {
        return try {
            with(getClient(client)) {
                getResponseAsOutcome(
                    patch(endpoint, block), responseField)
            }
        } catch (error: Throwable) {
            failure(NetworkError.Execution(error))
        }
    }

    /**
     * Executes a PATCH request and tries to return the result as an [Outcome] of unit representing a
     * successful and completed request or [NetworkError] in case of any error
     */
    suspend inline fun patchOrError(
        client: HttpClients = HttpClients.CORE,
        endpoint: String,
        block: HttpRequestBuilder.() -> Unit = {}
    ): Outcome<Unit, NetworkError>  {
        return try {
            with(getClient(client)) {
                getResultAsOutcome<Unit>(
                    patch(endpoint, block))
            }
        } catch (error: Throwable) {
            failure(NetworkError.Execution(error))
        }
    }

    /**
     * Executes a POST request and tries to return the result as an [Outcome] of the given type T
     * or [NetworkError] in case of any error
     */
    suspend inline fun <reified T> postAsOutcome(
        client: HttpClients = HttpClients.CORE,
        endpoint: String,
        responseField: String? = null,
        block: HttpRequestBuilder.() -> Unit = {}
    ): Outcome<T, NetworkError>  {
        return try {
            with(getClient(client)) {
                val response: HttpResponse = post(endpoint, block)
                getResponseAsOutcome(response, responseField)
            }
        } catch (error: Throwable) {
            failure(NetworkError.Execution(error))
        }
    }

    /**
     * Executes a PATCH request and tries to return the result as an [Outcome] of [HttpResponse]
     * or [NetworkError] in case of any error
     */
    suspend inline fun postAsHttpOutcome(
        client: HttpClients = HttpClients.CORE,
        endpoint: String,
        block: HttpRequestBuilder.() -> Unit = {}
    ): Outcome<HttpResponse, NetworkError>  {
        return try {
            with(getClient(client)) {
                val httpResponse = post<HttpResponse>(endpoint, block)
                getResultAsOutcome<Unit>(httpResponse)
                    .map { httpResponse }
            }
        } catch (error: Throwable) {
            failure(NetworkError.Execution(error))
        }
    }

    /**
     * Executes a POST request and tries to return the result as an [Outcome] of unit representing a
     * successful and completed request or [NetworkError] in case of any error
     */
    suspend inline fun postOrError(
        client: HttpClients = HttpClients.CORE,
        endpoint: String,
        block: HttpRequestBuilder.() -> Unit = {}
    ): Outcome<Unit, NetworkError>  {
        return try {
            with(getClient(client)) {
                getResultAsOutcome<Unit>(
                    post(endpoint, block))
            }
        } catch (error: Throwable) {
            failure(NetworkError.Execution(error))
        }
    }

    /**
     * Executes a DELETE request and tries to return the result as an [Outcome] of the given type T
     * or [NetworkError] in case of any error
     */
    suspend inline fun <reified T> deleteAsOutcome(
        client: HttpClients = HttpClients.CORE,
        endpoint: String,
        responseField: String? = null,
        block: HttpRequestBuilder.() -> Unit = {}
    ): Outcome<T, NetworkError>  {
        return try {
            with(getClient(client)) {
                getResponseAsOutcome(
                    delete(endpoint, block), responseField)
            }
        } catch (error: Throwable) {
            failure(NetworkError.Execution(error))
        }
    }

    /**
     * Executes a DELETE request and tries to return the result as an [Outcome] of unit representing a
     * successful and completed request or [NetworkError] in case of any error
     */
    suspend inline fun deleteOrError(
        client: HttpClients = HttpClients.CORE,
        endpoint: String,
        block: HttpRequestBuilder.() -> Unit = {}
    ): Outcome<Unit, NetworkError>  {
        return try {
            with(getClient(client)) {
                getResultAsOutcome<Unit>(
                    delete(endpoint, block))
            }
        } catch (error: Throwable) {
            failure(NetworkError.Execution(error))
        }
    }

    /**
     * Executes a PUT request and tries to return the result as an [Outcome] of the given type T
     * or [NetworkError] in case of any error
     */
    suspend inline fun <reified T> putAsOutcome(
        client: HttpClients = HttpClients.CORE,
        endpoint: String,
        responseField: String? = null,
        block: HttpRequestBuilder.() -> Unit = {}
    ): Outcome<T, NetworkError>  {
        return try {
            with(getClient(client)) {
                getResponseAsOutcome(
                    put(endpoint, block), responseField)
            }
        } catch (error: Throwable) {
            failure(NetworkError.Execution(error))
        }
    }

    /**
     * Executes a POST request of multiplatform/form-data and tries to return the result as an [Outcome] of the given type T
     * or [NetworkError] in case of any error
     */
    suspend inline fun <reified  T> submitFormAsOutcome(
        client: HttpClients = HttpClients.CORE,
        endpoint: String,
        responseField: String? = null,
        parameters: Parameters
    ): Outcome<T, NetworkError> {
        return try {
            with(getClient(client)) {
                getResponseAsOutcome(
                    submitForm(endpoint, parameters), responseField)
            }
        } catch (error: Throwable) {
            failure(NetworkError.Execution(error))
        }
    }

    private fun getClient(client: HttpClients): HttpClient {
//        return when (client) {
//            HttpClients.CORE -> coreClient
//            HttpClients.FIREBASE -> firebaseClient
//        }
        return coreClient
    }

    private suspend inline fun <reified T> getResultAsOutcome(response: HttpResponse): Outcome<Unit, NetworkError> {
        val parsedResponse = httpService.checkSuccess<Unit>(response)
        val remainingClients = requestCache.removeClient(response.request.url.toString())
        if (remainingClients != null && remainingClients <= 0) {
            httpService.remove(response)
        }
        return parsedResponse
//        return if (!response.status.isSuccess()) {
//            failure(
//                NetworkError.Http(
//                    response.status.value,
//                    response.request.method.toString(),
//                    response.request.url.toString(),
//                    false
//                ))
//        } else {
//            // Check Krakend internal error
//            val parsedResponse = response.body<JsonElement>()
//            val errorCode = getErrorCode(parsedResponse)
//            if (errorCode != null) {
//                val jsonError = getErrorBody(parsedResponse)?.let { body ->
//                    json.decodeFromString<JsonElement>(body)
//                }
//                failure(
//                    NetworkError.Http(
//                        errorCode,
//                        response.request.method.toString(),
//                        response.request.url.toString(),
//                        true,
//                        jsonError
//                    ))
//            } else {
//                success()
//            }
//        }
    }

    private suspend inline fun <reified T> getResponseAsOutcome(response: HttpResponse, jsonKey: String?): Outcome<T, NetworkError> {
        val parsedResponse = httpService.read<T>(response, jsonKey)
        val remainingClients = requestCache.removeClient(response.request.url.toString())
        if (remainingClients != null && remainingClients <= 0) {
            httpService.remove(response)
        }
        return parsedResponse
//        return if (!response.status.isSuccess()) {
//            failure(
//                NetworkError.Http(
//                    response.status.value,
//                    response.request.method.toString(),
//                    response.request.url.toString(),
//                    false
//                ))
//        } else {
//            val parsedResponse = response.body<JsonElement>()
//            // Check Krakend internal error
//            val errorCode = getErrorCode(parsedResponse)
//            if (errorCode != null) {
//                val jsonError = getErrorBody(parsedResponse)?.let { body ->
//                    json.decodeFromString<JsonElement>(body)
//                }
//                failure(
//                    NetworkError.Http(
//                        errorCode,
//                        response.request.method.toString(),
//                        response.request.url.toString(),
//                        true,
//                        jsonError
//                    ))
//            } else {
//                try {
//                    if (jsonKey == null) {
//                        success(json.decodeFromJsonElement(parsedResponse))
//                    } else {
//                        success(json.decodeFromJsonElement(parsedResponse.jsonObject[jsonKey]!!))
//                    }
//                } catch (exception: SerializationException) {
//                    failure(NetworkError.Execution(exception))
//                }
//            }
//        }
    }

}

package es.cinfo.tiivii.core.util

import es.cinfo.tiivii.core.content.model.WidgetModel
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.*
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.polymorphic
import kotlinx.serialization.modules.subclass
import kotlin.js.JsName

internal fun buildJson(): Json =
    Json {
        isLenient = true
        ignoreUnknownKeys = true
        // TODO: To be removed
        // This allows to omit null default values on data classes but also omits any other default value
        // Ensure that all data classes receive through the constructor the value if it needs to be
        // serialized. To be removed when the Kotlin serialization library allows fine grained selection
        // for omitting null values
        encodeDefaults = false
        serializersModule = SerializersModule {
            polymorphic(WidgetModel.ApiResponse.Widget.Data::class) {
                subclass(WidgetModel.ApiResponse.Widget.Data.Widget::class)
                default { WidgetModel.ApiResponse.Widget.Data.Content.serializer() }
            }
        }
    }

internal object IntListSerializer
    : JsonTransformingSerializer<List<Int>>(
    ListSerializer(Int.serializer())
) {
    // If response is not an array, then it is a single object that should be wrapped into the array
    override fun transformDeserialize(element: JsonElement): JsonElement =
        if (element !is JsonArray) JsonArray(listOf(element)) else element
}

internal val JsonElement.jsonObjectOrNull
    get() = this as? JsonObject
package es.cinfo.tiivii.core.image

import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.di.diContainer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.SerializationException
import org.kodein.di.instance
import kotlin.js.JsName

sealed class ViewModel {

    /**
     * Representation of an image of the content
     */
    data class Image(
        val id: Int,
        val width: Int,
        val height: Int,
        val url: String,
        val type: Type
    ) {
        /**
         * Retrieves the url for loading the image
         *
         * @param pixelWidth width of the image in pixels
         * @param pixelHeight height of the image in pixels
         * @param quality value between 1 and 100 for image quality (100 best quality)
         * @return The url to retrieve the image from
         */
        @JsName("urlForSize")
        fun urlForSize(pixelWidth: Int, pixelHeight: Int, quality: Int? = null): String {
            val configModule: ConfigModule by diContainer.instance()
            val q = quality ?: configModule.getCoreConfig().content.defaultImageQuality
            val imageUrl = if(url.contains("?w")) { //TODO: Remove fix (this fix avoid the repeated part on url)
                url.substring(0, url.indexOf("?w"))
            } else {
                url
            }
            return "$imageUrl?w=$pixelWidth&h=$pixelHeight&q=$q&f=crop"
        }

        /**
         * Retrieves the url for loading the image adjusted for the given width
         *
         * @param pixelWidth width of the image in pixels]
         * @param quality value between 1 and 100 for image quality (100 best quality)
         * @return The url to retrieve the image from
         */
        @JsName("urlForWidth")
        fun urlForWidth(pixelWidth: Int, quality: Int? = null): String {
            val configModule: ConfigModule by diContainer.instance()
            val q = quality ?: configModule.getCoreConfig().content.defaultImageQuality
            val pixelHeight = pixelWidth * height / width
            return "$url?w=$pixelWidth&h=$pixelHeight&q=$q&f=crop"
        }

        /**
         * Retrieves the url for loading the image adjusted for the given height
         *
         * @param pixelHeight width of the image in pixels
         * @param quality value between 1 and 100 for image quality (100 best quality)
         * @return The url to retrieve the image from
         */
        @JsName("urlForHeight")
        fun urlForHeight(pixelHeight: Int, quality: Int? = null): String {
            val configModule: ConfigModule by diContainer.instance()
            val q = quality ?: configModule.getCoreConfig().content.defaultImageQuality
            val pixelWidth = pixelHeight * width / height
            return "$url?w=$pixelWidth&h=$pixelHeight&q=$q&f=crop"
        }

        enum class Type {
            POSTER, BANNER, BACKGROUND, OTHER;

            internal fun toViewModel(): Model.Image.Type {
                return when (this) {
                    POSTER -> Model.Image.Type.POSTER
                    BANNER -> Model.Image.Type.BANNER
                    BACKGROUND -> Model.Image.Type.BACKGROUND
                    OTHER -> Model.Image.Type.OTHER
                }
            }
        }

        internal fun toModel(): Model.Image {
            return Model.Image(
                id = id,
                width = width,
                height = height,
                url = url,
                type = type.toViewModel()
            )
        }
    }

}

internal sealed class Model {

//    data class Images(
//        val images: List<Image>
//    ) {
//
//        fun findBestMatch(preference: Image.Type): Image? {
//           return when (preference) {
//               Image.Type.POSTER -> findWithPosterPreference()
//               Image.Type.BANNER -> findWithBannerPreference()
//               Image.Type.BACKGROUND -> findWithBackgroundPreference()
//               Image.Type.OTHER -> findWithDefaultPreference()
//           }
//        }
//
//        fun findWithType(type: Image.Type): Image? {
//            return images.find { it.type == type }
//        }
//
//        private fun findWithPosterPreference(): Image? {
//            return findWithType(Image.Type.POSTER) ?: findWithType(Image.Type.BANNER) ?: findWithType(Image.Type.BACKGROUND)
//        }
//
//        private fun findWithBannerPreference(): Image? {
//            return findWithType(Image.Type.BANNER) ?: findWithType(Image.Type.BACKGROUND) ?: findWithType(Image.Type.POSTER)
//        }
//
//        private fun findWithBackgroundPreference(): Image? {
//            return findWithType(Image.Type.BACKGROUND) ?: findWithType(Image.Type.BANNER) ?: findWithType(Image.Type.POSTER)
//        }
//
//        private fun findWithDefaultPreference(): Image? {
//            return findWithBannerPreference()
//        }
//    }

    data class Image(
        val id: Int,
        val width: Int,
        val height: Int,
        val url: String,
        val type: Type
    ) {

        enum class Type {
            POSTER, BANNER, BACKGROUND, OTHER;

            companion object {
                fun parse(value: String): Type {
                    return when (value) {
                        "poster" -> POSTER
                        "banner" -> BANNER
                        "background" -> BACKGROUND
                        else -> throw SerializationException("Unrecognized image type $value")
                    }
                }
            }

            fun toViewModel(): ViewModel.Image.Type {
                return when (this) {
                    POSTER -> ViewModel.Image.Type.POSTER
                    BANNER -> ViewModel.Image.Type.BANNER
                    BACKGROUND -> ViewModel.Image.Type.BACKGROUND
                    OTHER -> ViewModel.Image.Type.OTHER
                }
            }
        }

        fun toViewModel(): ViewModel.Image {

            return ViewModel.Image(
                id, width, height, url, type.toViewModel()
            )
        }

    }

}

internal sealed class ApiResponse {

    @Serializable
    data class Image(
        @SerialName(ID_PARAM)
        val id: Int,
        @SerialName(STORAGE_PARAM)
        val storage: String,
        @SerialName(PRIVATE_HASH_PARAM)
        val privateHash: String,
        @SerialName(FILENAME_DISK_PARAM)
        val filenameDisk: String,
        @SerialName(FILENAME_DOWNLOAD_PARAM)
        val filenameDownload: String,
        @SerialName(TITLE_PARAM)
        val title: String,
        @SerialName(TYPE_PARAM)
        val type: String,
        @SerialName(UPLOADED_BY_PARAM)
        val uploadedBy: Int,
        @SerialName(UPLOADED_ON_PARAM)
        val uploadedOn: String,
        @SerialName(CHARSET_PARAM)
        val charset: String,
        @SerialName(FILE_SIZE_PARAM)
        val fileSize: Int,
        @SerialName(WIDTH_PARAM)
        val width: Int,
        @SerialName(HEIGHT_PARAM)
        val height: Int,
        @SerialName(DURATION_PARAM)
        val duration: Int,
        @SerialName(DESCRIPTION_PARAM)
        val description: String,
        @SerialName(LOCATION_PARAM)
        val location: String,
        @SerialName(CHECKSUM_PARAM)
        val checksum: String,
        @SerialName(DATA_PARAM)
        val data: Data,
    ) {
        companion object {
            const val ID_PARAM = "id"
            const val STORAGE_PARAM = "storage"
            const val PRIVATE_HASH_PARAM = "private_hash"
            const val FILENAME_DISK_PARAM = "filename_disk"
            const val FILENAME_DOWNLOAD_PARAM = "filename_download"
            const val TITLE_PARAM = "title"
            const val TYPE_PARAM = "type"
            const val UPLOADED_BY_PARAM = "uploaded_by"
            const val UPLOADED_ON_PARAM = "uploaded_on"
            const val CHARSET_PARAM = "charset"
            const val FILE_SIZE_PARAM = "filesize"
            const val WIDTH_PARAM = "width"
            const val HEIGHT_PARAM = "height"
            const val DURATION_PARAM = "duration"
            const val DESCRIPTION_PARAM = "description"
            const val LOCATION_PARAM = "location"
            const val CHECKSUM_PARAM = "checksum"
            const val DATA_PARAM = "data"
        }

        @Serializable
        data class Data(
            @SerialName(FULL_URL_PARAM)
            val fullUrl: String,
            @SerialName(URL_PARAM)
            val url: String,
            @SerialName(ASSET_URL_PARAM)
            val assetUrl: String,
            @SerialName(THUMBNAILS_PARAM)
            val thumbnails: List<Thumbnail>
        ) {
            companion object {
                const val FULL_URL_PARAM = "full_url"
                const val URL_PARAM = "url"
                const val ASSET_URL_PARAM = "asset_url"
                const val THUMBNAILS_PARAM = "thumbnails"
            }

            @Serializable
            data class Thumbnail(
                @SerialName(KEY_PARAM)
                val key: String,
                @SerialName(URL_PARAM)
                val url: String,
                @SerialName(RELATIVE_URL_PARAM)
                val relativeUrl: String,
                @SerialName(DIMENSION_PARAM)
                val dimension: String,
                @SerialName(WIDTH_PARAM)
                val width: Int,
                @SerialName(HEIGHT_PARAM)
                val height: Int,
            ) {
                companion object {
                    const val KEY_PARAM = "key"
                    const val URL_PARAM = "url"
                    const val RELATIVE_URL_PARAM = "relative_url"
                    const val DIMENSION_PARAM = "dimension"
                    const val WIDTH_PARAM = "width"
                    const val HEIGHT_PARAM = "height"
                }
            }
        }

        fun toModel(type: Model.Image.Type): Model.Image {
            val imageModule: ImageModule by diContainer.instance()
            return Model.Image(
                id = id,
                width = width,
                height = height,
                url = "${imageModule.baseEndpoint}${data.assetUrl}",
                type = type
            )
        }
    }

}
package es.cinfo.tiivii.core.modules.video

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.network.HttpModule
import es.cinfo.tiivii.core.modules.video.model.VideoModel
import es.cinfo.tiivii.core.modules.video.model.VideoModel.ApiResponse.VideoUrls
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

/**
 * Network datasource for video operations
 */
internal interface VideoApi {

    /**
     * Returns the available [VideoUrls] for the video with the given smil identifier
     * @param smil backend identifier of a video
     */
    suspend fun getVodUrls(smil: String): Outcome<VideoUrls, NetworkError>

    /**
     * Returns the available stream [VideoUrls] for the given streamer
     * @param streamer represents the user that we want to retrieve the stream for
     */
    suspend fun getStreamUrls(streamer: String, type: VideoModel.Model.Type? = null): Outcome<VideoUrls, NetworkError>
}

/**
 * Default implementation of [VideoApi] using Ktor
 */
internal class DefaultVideoApi : VideoApi {
    private val http: HttpModule by diContainer.instance()

    private val baseEndpoint: String by lazy {
        val configModule: ConfigModule by diContainer.instance()
        "${configModule.getEnvConfig().backendUrl}/s2/${configModule.getEnvConfig().apiName}"
    }

    override suspend fun getVodUrls(smil: String): Outcome<VideoUrls, NetworkError> {
        val endpoint = "$baseEndpoint/vod?smil=$smil"
        return http.getAsOutcome(endpoint = endpoint)
    }

    override suspend fun getStreamUrls(
        streamer: String,
        type: VideoModel.Model.Type?
    ): Outcome<VideoUrls, NetworkError> {
        val endpoint = "$baseEndpoint/basiclive/$streamer"
        return http.getAsOutcome(endpoint = endpoint)
    }

}
package es.cinfo.tiivii.core.modules.cas

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.modules.cas.model.CasModel
import es.cinfo.tiivii.core.modules.cas.model.CasModel.Model.ContentHash
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.game.model.GameModel
import es.cinfo.tiivii.core.modules.product.model.ProductModel
import es.cinfo.tiivii.core.modules.video.model.VideoModel
import es.cinfo.tiivii.core.util.*
import es.cinfo.tiivii.di.diContainer
import kotlinx.serialization.SerializationException
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.decodeFromJsonElement
import org.kodein.di.instance

internal interface CasService {
    sealed class Error {
        data class MissingAchievements(val achievements: List<GameModel.Model.Achievement>): Error()
        data class ProductNotPurchased(val products: List<ProductModel.Model.Product>): Error()
        object RatingLocked: Error()
        object Unknown: Error()
        object UserNotAllowed: Error()
        data class CasNotAvailable(val error: NetworkError): Error()
    }

    suspend fun getPlayableVideo(
        contentId: Int,
        username: String?,
        video: VideoModel.Model.Video
    ): Outcome<VideoModel.Model.ProcessedVideo, Error>

    suspend fun getContentHash(
        contentId: Int
    ): Outcome<ContentHash, Error>

    suspend fun getContentVast(
        contentId: Int,
        hash: String
    ): Outcome<String?, NetworkError>

    suspend fun isSessionRequired(): Outcome<Boolean, NetworkError>
}

internal class DefaultCasService: CasService {
    private val casApi: CasApi by diContainer.instance()
    private val json: Json by diContainer.instance()
    private val configModule: ConfigModule by diContainer.instance()

    override suspend fun getPlayableVideo(
        contentId: Int,
        username: String?,
        video: VideoModel.Model.Video
    ): Outcome<VideoModel.Model.ProcessedVideo, CasService.Error> {
        return when (val contentHash = casApi.getContentHash(contentId)) {
            is Success -> {
                if (!contentHash.value.allowed) {
                    failure(CasService.Error.UserNotAllowed)
                } else {
                    val vastUrl = contentHash.value.hash?.let {
                        casApi.getContentVast(contentId, contentHash.value.hash).getOrNull()
                    }
                    val finalUsername = username ?: "anonymous"
                    var videoUrl = video.url + "?appname=${configModule.getEnvConfig().apiName}&contentid=$contentId&username=${finalUsername}"
                    if (contentHash.value.required) {
                        videoUrl += "&hash=${contentHash.value.hash!!}"
                    }
                    val playableVideo: VideoModel.Model.ProcessedVideo =
                        VideoModel.Model.ProcessedVideo(
                            url = videoUrl,
                            vastUrl = vastUrl,
                            type = video.type
                        )
                    success(playableVideo)
                }
            }
            is Failure -> {
                if (contentHash.error is NetworkError.Http) {
                    if (contentHash.error.json != null) {
                        try {
                            val krakenError: CasModel.ApiResponse.KrakenError = json.decodeFromJsonElement(contentHash.error.json)
                            when (krakenError.error) {
                                is CasModel.ApiResponse.CasError.MissingAchievements ->
                                    failure(CasService.Error.MissingAchievements(krakenError.error.achievements.map { it.toModel() }))
                                is CasModel.ApiResponse.CasError.ProductNotPurchased ->
                                    failure(CasService.Error.ProductNotPurchased(krakenError.error.wrapper.map { it.product.toModel() }))
                                CasModel.ApiResponse.CasError.RatingLocked ->
                                    failure(CasService.Error.RatingLocked)
                                CasModel.ApiResponse.CasError.Unknown ->
                                    failure(CasService.Error.Unknown)
                            }
                        } catch (exception: SerializationException) {
                            failure(CasService.Error.CasNotAvailable(contentHash.error))
                        }
                    } else {
                        failure(CasService.Error.CasNotAvailable(contentHash.error))
                    }
                } else {
                    failure(CasService.Error.CasNotAvailable(contentHash.error))
                }
            }
        }
    }

    override suspend fun getContentHash(contentId: Int): Outcome<ContentHash, CasService.Error> {
        return when (val contentHash = casApi.getContentHash(contentId)) {
            is Success -> {
                if (!contentHash.value.allowed) {
                    failure(CasService.Error.UserNotAllowed)
                } else {
                    success(ContentHash(
                        contentHash.value.hash,
                        contentHash.value.isFree,
                        contentHash.value.products.map { it.product.toModel() }
                    ))
                }
            }
            is Failure -> {
                if (contentHash.error is NetworkError.Http) {
                    if (contentHash.error.json != null) {
                        val krakenError: CasModel.ApiResponse.KrakenError = json.decodeFromJsonElement(contentHash.error.json)
                        when (krakenError.error) {
                            is CasModel.ApiResponse.CasError.MissingAchievements ->
                                failure(CasService.Error.MissingAchievements(krakenError.error.achievements.map { it.toModel() }))
                            is CasModel.ApiResponse.CasError.ProductNotPurchased ->
                                failure(CasService.Error.ProductNotPurchased(krakenError.error.wrapper.map { it.product.toModel() }))
                            CasModel.ApiResponse.CasError.RatingLocked ->
                                failure(CasService.Error.RatingLocked)
                            CasModel.ApiResponse.CasError.Unknown ->
                                failure(CasService.Error.Unknown)
                        }
                    } else {
                        failure(CasService.Error.CasNotAvailable(contentHash.error))
                    }
                } else {
                    failure(CasService.Error.CasNotAvailable(contentHash.error))
                }
            }
        }
    }

    override suspend fun getContentVast(contentId: Int, hash: String): Outcome<String?, NetworkError> {
        return casApi.getContentVast(contentId, hash)
    }

    override suspend fun isSessionRequired(): Outcome<Boolean, NetworkError> {
        return casApi.getCasRules().map {
            !it.areAnonsAllowed
        }
    }
}

package es.cinfo.tiivii.core.util

import es.cinfo.tiivii.core.date.DateService
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance
import kotlin.jvm.Synchronized

data class CacheEntry<T>(val data: T, val timestamp: Long)

interface Cache<K, V> {
    fun get(key: K): V?
    fun exist(key: K): Boolean
    fun put(key: K, value: V): V?
    fun remove(key: K): V?
    fun clear()
    fun getSize(): Int
}

class TimedCache<K, V>(private val expirationTimeSeconds: Long = DEFAULT_EXPIRATION_TIME_SECONDS) : Cache<K, V> {

    companion object {
        private const val DEFAULT_EXPIRATION_TIME_SECONDS = 30 * 60L
    }

    private val dateService: DateService by diContainer.instance()
    private var map: MutableMap<K, CacheEntry<V>> = mutableMapOf()

    init {
        if (expirationTimeSeconds <= 0) {
            throw IllegalArgumentException("expirationTimeSeconds <= 0")
        }
    }

    @Synchronized
    override fun get(key: K): V? =
        getIfNotExpired(key)

    suspend fun <E> get(key: K, override: Boolean = false, renewPredicate: (oldContent: V) -> Boolean = { false }, fallback: suspend () -> Outcome<V, E>): Outcome<V, E> {
        return if (override) {
            executeFallback(key, fallback)
        } else {
            val entry = getIfNotExpired(key)
            if (entry == null) {
                executeFallback(key, fallback)
            } else {
                if (renewPredicate(entry)) {
                    executeFallback(key, fallback)
                } else {
                    success(entry)
                }
            }
        }
    }

    private suspend fun <E> executeFallback(key: K, block: suspend () -> Outcome<V, E>): Outcome<V, E> {
        val outcome = block()
        if (outcome is Success) {
            val value = outcome.value
            put(key, value)
        }
        return outcome
    }

    fun getIfNotExpired(key: K): V? {
        val entry = map[key]
        return if (entry == null) {
            null
        } else {
            val age = dateService.currentEpochMillis() - entry.timestamp
            if (age > expirationTimeSeconds * 1000) {
                null
            } else {
                entry.data
            }
        }
    }

    @Synchronized
    override fun put(key: K, value: V): V? = 
        map.put(key, CacheEntry(value, dateService.currentEpochMillis()))?.data

    @Synchronized
    override fun remove(key: K): V? = 
        map.remove(key)?.data
    

    override fun exist(key: K): Boolean = 
        map.containsKey(key)

    override fun clear() = 
        map.clear()

    override fun getSize(): Int = 
        map.size

}
package es.cinfo.tiivii.core.util

import kotlinx.coroutines.flow.Flow
import kotlinx.datetime.Clock
import kotlin.random.Random
import kotlinx.coroutines.flow.mapNotNull as mapNotNullFlow

/*
 * Original mapNotNull accepts suspend function and we can't pass a non-suspend function by reference as of now
 */
internal inline fun <T, R : Any> Flow<T>.mapNotNull(crossinline mapper: (T) -> R?): Flow<R> =
    mapNotNullFlow { mapper(it) }


/**
 * Maps an [Iterable] to a [HashSet]
 */
internal inline fun <T, R> Iterable<T>.mapToSet(transform: (T) -> R): Set<R> {
    return mapTo(HashSet(), transform)
}

/**
 * Maps an [Iterable] to a [LinkedHashSet] that keeps the insertion order
 */
internal inline fun <T, R> Iterable<T>.mapToLinkedSet(transform: (T) -> R): Set<R> {
    return mapTo(LinkedHashSet(), transform)
}

/**
 * Custom let function for if statements. The closure is only called when all elements are not null
 */
inline fun <T : Any> ifLet(vararg elements: T?, closure: (List<T>) -> Unit) {
    if (elements.all { it != null }) {
        closure(elements.filterNotNull())
    }
}

/**
 * Calculates the md5 hash value for the given input
 *
 * MD5 hashing extracted from Krypto library
 * @see [Krypto](https://github.com/korlibs/krypto)
 */
internal fun String.md5(): String {
    return this.encodeToByteArray().md5().hex
}

/**
 * Transforms a [Set] of [String] into a single [String] using the given separator between values
 */
internal fun Set<Int>.encodeToString(separator: String): String {
    val builder = StringBuilder()
    this.forEach {
        builder.append(it).append(separator)
    }
    return builder.toString()
}

/**
 * Transforms a boolean to a [LoadingModel.Model.LoadState] (omits [LoadingModel.Model.LoadState.RESET] value)
 */
internal fun Boolean.asLoadState(): LoadingModel.Model.LoadState {
    return if (this) {
        LoadingModel.Model.LoadState.LOADING
    } else {
        LoadingModel.Model.LoadState.LOADED
    }
}

internal fun Random.nextString(size: Int): String {
    val charset = "ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz0123456789"
    return (1..size)
        .map { charset.random() }
        .joinToString("")
}
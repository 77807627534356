package es.cinfo.tiivii.user.signup

import es.cinfo.tiivii.core.interest.model.Model.Interest
import es.cinfo.tiivii.core.modules.avatar.model.AvatarModel.Model.Avatar
import es.cinfo.tiivii.core.user.UserApi
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

internal interface SignupService {
    suspend fun signupUser(
        language: String,
        legalVersion: Int,
        username: String,
        firstName: String,
        lastName: String,
        credentials: String,
        email: String,
        hasTutor: Boolean,
        birthday: String,
        interests: Set<Interest>,
        avatar: Avatar,
    ): Outcome<Boolean, NetworkError>
}

internal class DefaultSignupService : SignupService {
    private val userApi: UserApi by diContainer.instance()

    override suspend fun signupUser(
        language: String,
        legalVersion: Int,
        username: String,
        firstName: String,
        lastName: String,
        credentials: String,
        email: String,
        hasTutor: Boolean,
        birthday: String,
        interests: Set<Interest>,
        avatar: Avatar,
    ): Outcome<Boolean, NetworkError> {
        return userApi.createUser(
            language, legalVersion, username, firstName, lastName, credentials, email, hasTutor, birthday, interests,
            avatar
        )
    }

}
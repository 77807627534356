package es.cinfo.tiivii.core.notifications

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.network.HttpModule
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.di.diContainer
import io.ktor.client.request.*
import org.kodein.di.instance

internal interface NotificationsApi {

    suspend fun getNotifications(username: String): Outcome<NotificationsModel.ApiResponse.Notifications, NetworkError>
    suspend fun deleteNotification(id: Int): Outcome<Unit, NetworkError>
    suspend fun readNotification(id: Int): Outcome<NotificationsModel.ApiResponse.Notifications.Notification, NetworkError>

}

internal class DefaultNotificationsApi: NotificationsApi {
    private val http: HttpModule by diContainer.instance()

    private val baseEndpoint: String by lazy {
        val configModule: ConfigModule by diContainer.instance()
        "${configModule.getEnvConfig().backendUrl}/comm/notifications/${configModule.getEnvConfig().apiName}"
    }

    override suspend fun getNotifications(username: String): Outcome<NotificationsModel.ApiResponse.Notifications, NetworkError> {
        val endpoint = "$baseEndpoint/list/$username"
        return http.getAsOutcome(endpoint = endpoint) {
            header("Cache-Control", "no-cache")
        }
    }

    override suspend fun deleteNotification(id: Int): Outcome<Unit, NetworkError> {
        val endpoint = "$baseEndpoint/delete/$id"
        return http.deleteOrError(endpoint = endpoint)
    }

    override suspend fun readNotification(id: Int): Outcome<NotificationsModel.ApiResponse.Notifications.Notification, NetworkError> {
        val endpoint = "$baseEndpoint/read/$id"
        return http.patchAsOutcome(endpoint = endpoint) {
            header("Cache-Control", "no-cache")
        }
    }
}
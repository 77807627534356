package es.cinfo.tiivii.core.features.catalogue.view

import com.arkivanov.mvikotlin.core.view.MviView
import es.cinfo.tiivii.core.features.catalogue.model.ViewModel.Content
import es.cinfo.tiivii.core.features.catalogue.model.ViewModel.ContentNode
import es.cinfo.tiivii.core.features.catalogue.view.CatalogueView.Event
import es.cinfo.tiivii.core.features.catalogue.view.CatalogueView.Event.*
import es.cinfo.tiivii.core.features.catalogue.view.CatalogueView.Model
import es.cinfo.tiivii.core.features.catalogue.view.CatalogueView.Output.UnexpectedError
import es.cinfo.tiivii.core.modules.game.model.GameModel
import es.cinfo.tiivii.core.util.LoadingModel.ViewModel.LoadState
import es.cinfo.tiivii.core.util.ViewModel.Node
import es.cinfo.tiivii.core.util.ViewModel.Tree

/**
 * View implementation for the catalogue screen
 */
interface CatalogueView : MviView<Model, Event> {

    /**
     * Operations available to be requested for the catalogue screen
     * @see Reload
     * @see LoadNode
     * @see SelectContent
     * @see DeselectContent
     * @see LoadMoreContent
     * @see Search
     * @see ClearSearch
     * @see Sort
     * @see LogCatalogueView
     */
    sealed class Event {
        /**
         * Requests a re-load of the entire catalogue screen. This will trigger a clear of the model and
         * reload of the node tree and selected node
         */
        object Reload : Event()

        /**
         * Request the load of the content associated with the node at the given coordinates. Should be sent
         * when the user requests access to any given section to update the content display and the node tree if needed
         */
        data class LoadNode(val coordinates: List<Int>) : Event()

        /**
         * Request the load of the children of the content associated at the given coordinates. Should be sent
         * when the user requests access to the children of a content to display them. If there are no children
         * contents nothing will happen
         */
        data class SelectContent(val coordinates: List<Int>) : Event()

        /**
         * Instructs the core that the previously selected content has been deselected to correctly keep track
         * of the select content status
         */
        object DeselectContent: Event()

        /**
         * Request the load of more content. Contextually this will load more content for the node
         * or the selected content
         */
        object LoadMoreContent : Event()

        /**
         * Requests a search filter. Contextually this will filter the content associated to a node
         * or the selected content currently loaded
         */
        data class Search(val query: String) : Event()

        /**
         * Requests a search to be cleared and all the content of the related node or selected content
         * to be loaded again
         */
        object ClearSearch : Event()

        /**
         * Requests a new sort for the content of the currently selected node or currently selected content
         */
        data class Sort(val sortId: String) : Event()

        /**
         * Request a catalogue screen view to be made. It should be sent
         * whenever the catalogue screen is viewed. This event is exposed for more grained control
         * for the UI
         */
        object LogCatalogueView: Event()

        /**
         * Requests content at the given coordinates to be added as a favorite
         * @param coordinates of the content to be added as a favorite
         */
        data class AddToFavorites(
            val coordinates: List<Int>
        ): Event()

        /**
         * Requests the content at the given coordinates to be removed from favorites
         * @param coordinates of the content to be removed from favorites
         */
        data class RemoveFromFavorites(
            val coordinates: List<Int>
        ): Event()
    }

    /**
     * The model of the catalogue screen exposed to the UI
     * @param nodeTree represents the [Tree] of [Node]s with the catalogue sections
     * @param selectedNode represents the currently selected [Node]
     * @param areNodesLoading indicates the [LoadState] of the nodes being loaded
     * @param nodeContents represents the [Content]s associated with the currently selected [Node]
     * @param selectedContent represents the currently selected [Content] if any
     * @param hasMoreContents indicates if more [Content] is available (used for pagination)
     * @param queryFilter applied to the current loaded [Content]s
     * @param selectedSort id of the applied sort to the current loaded [Content]s
     * @param availableSorts represents all the available sort methods with an id
     * @param areContentsLoading indicates the [LoadState] of the [nodeContents] being loaded
     */
    data class Model(
        // Nodes
        val nodeTree: Tree<ContentNode>?,
        val selectedNode: Node<ContentNode>?,
        val areNodesLoading: LoadState,

        // Content
        val nodeContents: Tree<Content>?,
        val selectedContent: Node<Content>?,
        val hasMoreContents: Boolean,
        val queryFilter: String?,
        val selectedSort: String?,
        val availableSorts: List<String>?,
        val areContentsLoading: LoadState,
    )

    /**
     * One-time notifications that can be received on the catalogue screen
     * @see UnexpectedError
     */
    sealed class Output {
        /**
         * Unexpected error happened
         */
        data class UnexpectedError(val code: String) : Output()

        /**
         * An operation has been request through an [Event] but the operation cannot be done due to some requirements
         * not met. This may happen if an [Event] is requested when is not due. This usually means that the usage of the
         * internal of the component is wrong and should be checked
         * @param reason explains why the operation cannot be executed
         */
        data class IllegalOperationError(val reason: String) : Output()

        /**
         * User session has expired. Re-log is necessary
         */
        object UserSessionExpired : Output()

        /**
         * A request has timed out. This may indicate a problem with the internet connection
         */
        object RequestTimedOut : Output()

        /**
         * A game action has been sent. The user points, ranking or achievements may have changed
         * @param actionResult indicates if the game action has been successful or has failed with an
         * [GameModel.ViewModel.ActionResult]
         */
        data class GameActionSent(
            val actionResult: GameModel.ViewModel.ActionResult
        ): Output()
    }

}

package es.cinfo.tiivii.user.legal.binder

import es.cinfo.tiivii.user.legal.store.LegalStore.*
import es.cinfo.tiivii.user.legal.view.LegalView.*

internal val STATE_TO_MODEL: State.() -> Model = {
    Model(
        legalText = legalText,
        legalTextLanguage = legalTextLanguage,
        loadingLegalText = loadingLegalText.toViewModel()
    )
}

internal val EVENT_TO_INTENT: Event.() -> Intent = {
    when (this) {
        is Event.AcceptConditions -> Intent.AcceptConditions(isDataCollectionAllowed)
        is Event.RejectConditions -> Intent.RejectConditions
        is Event.ReloadLegalConditions -> Intent.ReloadLegalConditions(language)
    }
}

internal val LABEL_TO_OUTPUT: Label.() -> Output = {
    when (this) {
        is Label.UnexpectedError -> Output.UnexpectedError(code)
        Label.UserSessionExpired -> Output.UserSessionExpired
        Label.RequestTimedOut -> Output.RequestTimedOut
        Label.AcceptedLegalConditions -> Output.AcceptedLegalConditions
        is Label.IllegalOperationError -> Output.IllegalOperationError(reason)
    }
}

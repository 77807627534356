package es.cinfo.tiivii.user.profile.usecase

import es.cinfo.tiivii.core.modules.analytics.LogEvent
import es.cinfo.tiivii.core.modules.analytics.model.AnalyticsModel.Action
import es.cinfo.tiivii.core.modules.auth.AuthService
import es.cinfo.tiivii.core.user.UserService
import es.cinfo.tiivii.core.util.UseCase
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

internal actual class Logout : UseCase<Unit> {

    private val authService: AuthService by diContainer.instance()
    private val userService: UserService by diContainer.instance()

    override suspend fun invoke() {
        authService.clearAuth()
        userService.clearUserData()
        LogEvent(action = Action.Logout, logIfAnonymous = false).invoke()
    }
}
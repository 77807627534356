package es.cinfo.tiivii.core.features.notifications.view

import com.arkivanov.mvikotlin.core.view.MviView
import es.cinfo.tiivii.core.features.notifications.view.NotificationsView.Event.DeleteNotification
import es.cinfo.tiivii.core.features.notifications.view.NotificationsView.Event.ReloadNotifications
import es.cinfo.tiivii.core.features.notifications.view.NotificationsView.Output.UnexpectedError
import es.cinfo.tiivii.core.features.notifications.view.NotificationsView.Output.UserSessionExpired
import es.cinfo.tiivii.core.notifications.NotificationsModel
import es.cinfo.tiivii.core.util.LoadingModel.ViewModel.LoadState

/**
 * View implementation for the notifications screen
 */
interface NotificationsView : MviView<NotificationsView.Model, NotificationsView.Event> {
    /**
     * Operations available to be requested for the home screen
     * @see ReloadNotifications
     * @see DeleteNotification
     */
    sealed class Event {
        /**
         * Requests a reload of all the notifications data
         */
        object ReloadNotifications : NotificationsView.Event()

        /**
         * Requests to remove a notification
         */
        data class DeleteNotification(val id: Int) : NotificationsView.Event()

        /**
         * Requests to remove a notification
         */
        data class ReadNotification(val id: Int) : NotificationsView.Event()
    }

    /**
     * The model of the login screen exposed to the UI
     * @param newNotificationsCount to see the number of notifications unread
     * @param notifications to see the list of notifications
     * @param loadingStats indicates the loading status of the [notifications] object
     */
    data class Model(
        val newNotificationsCount: Int,
        val notifications: List<NotificationsModel.ViewModel.Notification>,
        val loadingStats: LoadState
    )

    /**
     * One-time notifications that can be received on the login screen
     * @see UserSessionExpired
     * @see UnexpectedError
     */
    sealed class Output {
        /**
         * User session has expired, user needs to log in again
         */
        object UserSessionExpired : NotificationsView.Output()

        /**
         * A request has timed out. This may indicate a problem with the internet connection
         */
        object RequestTimedOut : NotificationsView.Output()

        /**
         * Unexpected error happened
         * @param code string representation of a internal error code to be shown with the generic error to the user
         */
        data class UnexpectedError(val code: String) : NotificationsView.Output()

        /**
         * An operation has been request through an [Event] but the operation cannot be done due to some requirements
         * not met. This may happen if an [Event] is requested when is not due. This usually means that the usage of the
         * internal of the component is wrong and should be checked
         * @param reason explains why the operation cannot be executed
         */
        data class IllegalOperationError(val reason: String) : NotificationsView.Output()
    }
}
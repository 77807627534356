package es.cinfo.tiivii.user.splash.binder

import es.cinfo.tiivii.user.splash.store.SplashStore
import es.cinfo.tiivii.user.splash.view.SplashView

/**
 * Mapper between the [SplashView.Event] to the [SplashStore.Intent]
 */
internal val EVENT_TO_INTENT: SplashView.Event.() -> SplashStore.Intent = {
    when (this) {
        is SplashView.Event.StoreAuth -> SplashStore.Intent.StoreLogin(accessToken, refreshToken)
        SplashView.Event.LogLoginView -> SplashStore.Intent.LogLoginView
        SplashView.Event.GetFirstScreen -> SplashStore.Intent.GetFirstScreen
    }
}

/**
 * Mapper between the [SplashStore.Label] to the [SplashView.Output]
 */
internal val LABEL_TO_OUTPUT: SplashStore.Label.() -> SplashView.Output = {
    when (this) {
        is SplashStore.Label.UnexpectedError -> SplashView.Output.UnexpectedError(code)
        is SplashStore.Label.GameActionSent -> SplashView.Output.GameActionSent(actionResult.toViewModel())
        SplashStore.Label.RequestTimedOut -> SplashView.Output.RequestTimedOut
        SplashStore.Label.UserSessionExpired -> SplashView.Output.UserSessionExpired
    }
}

internal val STATE_TO_MODEL: SplashStore.State.() -> SplashView.Model = {
    SplashView.Model(
        loadState = loadingNextScreen.toViewModel(),
        nextScreen = nextScreen?.toViewModel(),
        iosAppUrl = iosAppUrl,
        androidAppUrl = androidAppUrl,
        accessToken = auth?.accessToken,
        refreshToken = auth?.refreshToken,
        styles = styles?.toViewModel(),
        keycloakParams = keycloakParams?.toViewModel()
    )
}
package es.cinfo.tiivii.core.content.model

import es.cinfo.tiivii.core.content.model.ViewModel.Content
import es.cinfo.tiivii.core.layout.LayoutApi
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import es.cinfo.tiivii.core.content.model.ContentTypeModel.Model.ContentType as ContentTypeModel
import es.cinfo.tiivii.core.content.model.ContentTypeModel.ViewModel.ContentType as ContentTypeViewModel
import es.cinfo.tiivii.core.image.ApiResponse.Image as ImageApiResponse
import es.cinfo.tiivii.core.image.Model.Image as ImageModel
import es.cinfo.tiivii.core.image.Model.Image.Type as ImageType
import es.cinfo.tiivii.core.image.ViewModel.Image as ImageViewModel

sealed class NextContentModel {

    sealed class ViewModel {
        /**
         * Representation of the next content. This is a minified version of the [Content] class
         */
        data class NextContent(
            val id: Int,
            val title: String,
            val subtitle: String?,
            val description: String?,
            val type: ContentTypeViewModel,
            val background: ImageViewModel?,
            val banner: ImageViewModel?,
            val poster: ImageViewModel?,
            val episode: Int?,
            val parentId: Int?
        )
    }

    internal sealed class Model {
        data class CachedNextContent(
            val id: Int,
            val filters: Map<String, String>?,
            val ignoreList: Set<Int>?
        )
        data class NextContent(
            val id: Int,
            val title: String,
            val subtitle: String?,
            val description: String?,
            val background: ImageModel?,
            val banner: ImageModel?,
            val poster: ImageModel?,
            val type: ContentTypeModel,
            val episode: Int?,
            val parentId: Int?

        ) {
            fun toViewModel(): ViewModel.NextContent =
                ViewModel.NextContent(
                    id = id,
                    title = title,
                    subtitle = subtitle,
                    description = description,
                    type = type.toViewModel(),
                    background = background?.toViewModel(),
                    banner = banner?.toViewModel(),
                    poster = poster?.toViewModel(),
                    episode = episode,
                    parentId = parentId
                )
        }
    }

    internal sealed class ApiResponse {

        @Serializable
        data class NextContent(
            @SerialName(ID_PARAM)
            val id: Int,
            @SerialName(TITLE_PARAM)
            val title: String,
            @SerialName(SUBTITLE_PARAM)
            val subtitle: String? = null,
            @SerialName(DESCRIPTION_PARAM)
            val description: String? = null,
            @SerialName(POSTER_PARAM)
            val poster: ImageApiResponse? = null,
            @SerialName(BANNER_PARAM)
            val banner: ImageApiResponse? = null,
            @SerialName(BACKGROUND_PARAM)
            val background: ImageApiResponse? = null,
            @SerialName(TYPE_PARAM)
            val type: String,
            @SerialName(EPISODE_PARAM)
            val episode: Int? = null,
            @SerialName(PARENT_PARAM)
            val parent: Parent? = null,
        ) {
            companion object {
                const val ID_PARAM = "id"
                const val TITLE_PARAM = "title"
                const val SUBTITLE_PARAM = "subtitle"
                const val DESCRIPTION_PARAM = "description"
                const val POSTER_PARAM = "poster"
                const val BANNER_PARAM = "banner"
                const val BACKGROUND_PARAM = "background"
                const val TYPE_PARAM = "type"
                const val EPISODE_PARAM = "episode"
                const val PARENT_PARAM = "parent"
            }

            @Serializable
            data class Parent(
                @SerialName(ID_PARAM)
                val id: Int
            ) {
                companion object {
                    const val ID_PARAM = "id"
                }
            }

            fun toModel(): Model.NextContent {
                return Model.NextContent(
                    id = id,
                    title = title,
                    subtitle = subtitle,
                    description = description,
                    background = background?.toModel(ImageType.BACKGROUND),
                    banner = banner?.toModel(ImageType.BANNER),
                    poster = poster?.toModel(ImageType.POSTER),
                    type = ContentTypeModel.parse(type),
                    episode = episode,
                    parentId = parent?.id
                )
            }

        }

    }

}
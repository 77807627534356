package es.cinfo.tiivii.core.notifications

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.core.util.map
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

internal interface NotificationsService {

    suspend fun getNotifications(username: String): Outcome<List<NotificationsModel.Model.Notification>, NetworkError>
    suspend fun deleteNotification(id: Int): Outcome<Unit, NetworkError>
    suspend fun readNotification(id: Int): Outcome<NotificationsModel.Model.Notification, NetworkError>

}

internal class DefaultNotificationsService : NotificationsService {
    private val notificationsApi: NotificationsApi by diContainer.instance()

    override suspend fun getNotifications(username: String): Outcome<List<NotificationsModel.Model.Notification>, NetworkError> {
        return notificationsApi.getNotifications(username)
            .map { response ->
                response.toModel()
            }
    }

    override suspend fun deleteNotification(id: Int): Outcome<Unit, NetworkError> {
        return notificationsApi.deleteNotification(id)
    }

    override suspend fun readNotification(id: Int): Outcome<NotificationsModel.Model.Notification, NetworkError> {
        return notificationsApi.readNotification(id).map { response ->
            response.toModel()
        }
    }

}
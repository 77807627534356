package es.cinfo.tiivii.footer.usecase

import es.cinfo.tiivii.core.ComponentId
import es.cinfo.tiivii.core.ErrorId
import es.cinfo.tiivii.core.UseCaseId
import es.cinfo.tiivii.core.error.CodedError
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.error.asErrorId
import es.cinfo.tiivii.core.modules.auth.AuthService
import es.cinfo.tiivii.core.layout.LayoutService
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.profile.ProfileModel.Model.Profile
import es.cinfo.tiivii.core.user.UserService
import es.cinfo.tiivii.core.util.*
import es.cinfo.tiivii.di.diContainer
import es.cinfo.tiivii.footer.model.Footer
import org.kodein.di.instance

internal class GetSponsors() : OutcomeUseCase<List<Footer.Model.Sponsor>, GetSponsors.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.FOOTER, UseCaseId.GET_SPONSORS, errorId, networkError) {
        data class SponsorsUnavailable(val error: NetworkError) : Error(
            asErrorId<SponsorsUnavailable>(1),
            error
        )
    }

    private val layoutService: LayoutService by diContainer.instance()
    private val authService: AuthService by diContainer.instance()
    private val userService: UserService by diContainer.instance()
    private val configService: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<List<Footer.Model.Sponsor>, Error>
        get() = {
            var userProfile = configService.getCoreConfig().signup.defaultProfile
            var userLanguage = configService.getCoreConfig().signup.defaultLanguage
            val auth = authService.getStoredAuth()
            if (auth != null) {
                val user = userService.getUser(auth.username)
                    .mapError {
                        Error.SponsorsUnavailable(it)
                    }.getOrAbort()
                userProfile = user.profile
                userLanguage = user.preferredLanguage
            }
            layoutService.getLayoutConfig(userProfile, userLanguage)
                .mapError {
                    Error.SponsorsUnavailable(it)
                }
                .map {
                    it.sponsors
                }
        }
}
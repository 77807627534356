package es.cinfo.tiivii.core.content.model

sealed class ContentTypeModel {

    sealed class ViewModel {
        enum class ContentType {
            VOD, LIVE, LIVE_TIIVII, VOD_360, VOD_FORKED, CONTAINER, AR, VR, IFRAME, NEWS, UNKNOWN
        }
    }

    internal sealed class Model {
        enum class ContentType(val value: String) {
            VOD("basicvideo"),
            LIVE("basiclive"),
            LIVE_TIIVII("tiiviilive"),
            VOD_360("360video"),
            VOD_FORKED("forkedvideo"),
            CONTAINER("container"),
            AR("ar"),
            VR("nlvrscene"),
            IFRAME("iframe"),
            NEWS("news"),
            UNKNOWN("");

            fun toViewModel(): ViewModel.ContentType {
                return when (this) {
                    VOD -> ViewModel.ContentType.VOD
                    LIVE -> ViewModel.ContentType.LIVE
                    LIVE_TIIVII -> ViewModel.ContentType.LIVE_TIIVII
                    VOD_360 -> ViewModel.ContentType.VOD_360
                    VOD_FORKED -> ViewModel.ContentType.VOD_FORKED
                    CONTAINER -> ViewModel.ContentType.CONTAINER
                    AR -> ViewModel.ContentType.AR
                    VR -> ViewModel.ContentType.VR
                    IFRAME -> ViewModel.ContentType.IFRAME
                    NEWS -> ViewModel.ContentType.NEWS
                    UNKNOWN -> ViewModel.ContentType.UNKNOWN
                }
            }

            companion object {
                fun parse(value: String): ContentType {
                    return when (value) {
                        "basicvideo" -> VOD
                        "360video" -> VOD_360
                        "basiclive" -> LIVE
                        "tiiviilive" -> LIVE_TIIVII
                        "forkedvideo" -> VOD_FORKED
                        "container" -> CONTAINER
                        "ar" -> AR
                        "nlvrscene" -> VR
                        "iframe" -> IFRAME
                        "news" -> NEWS
                        else -> UNKNOWN
                    }
                }
            }

        }
    }

}


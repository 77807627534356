package es.cinfo.tiivii.core.modules.cas

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.modules.cas.model.CasModel
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.network.HttpModule
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.core.util.map
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

internal interface CasApi {

    suspend fun getContentHash(contentId: Int): Outcome<CasModel.ApiResponse.Hash, NetworkError>

    suspend fun getContentVast(contentId: Int, hash: String): Outcome<String?, NetworkError>

    suspend fun getCasRules(): Outcome<CasModel.Rules, NetworkError>
}

internal class DefaultCasApi: CasApi {
    private val http: HttpModule by diContainer.instance()

    private val baseEndpoint: String by lazy {
        val configModule: ConfigModule by diContainer.instance()
        "${configModule.getEnvConfig().backendUrl}/cas/${configModule.getEnvConfig().apiName}"
    }

    override suspend fun getContentHash(contentId: Int): Outcome<CasModel.ApiResponse.Hash, NetworkError> {
        val endpoint = "$baseEndpoint/content/$contentId"
        return http.getAsOutcome(endpoint = endpoint)
    }

    override suspend fun getContentVast(contentId: Int, hash: String): Outcome<String?, NetworkError> {
        val endpoint = "$baseEndpoint/vast/$contentId?hash=$hash"
        return http.getAsOutcome<CasModel.Vast>(endpoint = endpoint).map {
            it.url
        }
    }

    override suspend fun getCasRules(): Outcome<CasModel.Rules, NetworkError> {
        val endpoint = "$baseEndpoint/rules"
        return http.getAsOutcome(endpoint = endpoint)
    }

}
package es.cinfo.tiivii.core.usecase

import es.cinfo.tiivii.core.ComponentId
import es.cinfo.tiivii.core.ErrorId
import es.cinfo.tiivii.core.UseCaseId
import es.cinfo.tiivii.core.content.ContentService
import es.cinfo.tiivii.core.content.model.Model.Content
import es.cinfo.tiivii.core.date.DateService
import es.cinfo.tiivii.core.error.CodedError
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.error.asErrorId
import es.cinfo.tiivii.core.features.ranking.usecase.LoadUserStats
import es.cinfo.tiivii.core.interest.InterestService
import es.cinfo.tiivii.core.layout.LayoutService
import es.cinfo.tiivii.core.layout.model.section.Model
import es.cinfo.tiivii.core.modules.analytics.LogEvent
import es.cinfo.tiivii.core.modules.analytics.model.AnalyticsModel
import es.cinfo.tiivii.core.modules.auth.AuthService
import es.cinfo.tiivii.core.modules.avatar.AvatarService
import es.cinfo.tiivii.core.modules.avatar.model.AvatarModel
import es.cinfo.tiivii.core.modules.bookmark.BookmarksService
import es.cinfo.tiivii.core.modules.cas.CasService
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.game.GameService
import es.cinfo.tiivii.core.modules.game.model.GameModel
import es.cinfo.tiivii.core.modules.platform.PLATFORM_ID
import es.cinfo.tiivii.core.modules.rating.RatingModel
import es.cinfo.tiivii.core.modules.rating.RatingService
import es.cinfo.tiivii.core.sorting.SortModel.Model.Sort
import es.cinfo.tiivii.core.user.UserService
import es.cinfo.tiivii.core.user.model.Model.UserLoad
import es.cinfo.tiivii.core.userstats.UserStatsModel
import es.cinfo.tiivii.core.userstats.UserStatsService
import es.cinfo.tiivii.core.util.*
import es.cinfo.tiivii.di.diContainer
import es.cinfo.tiivii.user.role.Role
import org.kodein.di.instance

internal class GetCurrentUser : OutcomeUseCase<UserLoad, GetCurrentUser.Error>() {
    sealed class Error(component: ComponentId, errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(component, UseCaseId.GET_CURRENT_USER, errorId, networkError) {
        data class AuthDataUnavailable(val useCaseComponent: ComponentId) : Error(
            useCaseComponent,
            asErrorId<AuthDataUnavailable>(1)
        )
        data class UserUnavailable(val useCaseComponent: ComponentId, val error: NetworkError) : Error(
            useCaseComponent,
            asErrorId<UserUnavailable>(2),
            error
        )
    }

    private val userService: UserService by diContainer.instance()
    private val authService: AuthService by diContainer.instance()
    private val interestService: InterestService by diContainer.instance()
    private val userStatsService: UserStatsService by diContainer.instance()
    private val configModule: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<UserLoad, Error>
        get() = {
            val authData = authService.getStoredAuth()
            if (authData != null) {
                var user = userService.getUser(authData.username)
                    .mapError { Error.UserUnavailable(component, it) }.getOrAbort()
                // No interest = all interest
                if (user.interests.isEmpty()) {
                    val allInterest = interestService.getAvailableInterests().mapError { Error.UserUnavailable(component, it) }.getOrAbort()
                    user = user.copy(interests = allInterest)
                }
                val stats = if (configModule.getCoreConfig().gamification.enabled) {
                    userStatsService.getUserStats(authData.username, true)
                        .mapError { Error.UserUnavailable(component, it) }.getOrAbort()
                } else {
                    null
                }
                success(UserLoad(user, stats))
            } else {
                failure(Error.AuthDataUnavailable(component))
            }
        }
}

internal class ReloadUserStats : OutcomeUseCase<UserStatsModel.Model.UserStats, ReloadUserStats.Error>() {
    sealed class Error(component: ComponentId, errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(component, UseCaseId.RELOAD_USER_STATS, errorId, networkError) {
        data class AuthDataUnavailable(val useCaseComponent: ComponentId) : Error(
            useCaseComponent,
            asErrorId<AuthDataUnavailable>(1)
        )
        data class UserUnavailable(val useCaseComponent: ComponentId, val error: NetworkError) : Error(
            useCaseComponent,
            asErrorId<UserUnavailable>(2),
            error
        )
        data class GamificationModuleDisabled(val useCaseComponent: ComponentId) : Error(
            useCaseComponent,
            asErrorId<GamificationModuleDisabled>(3)
        )
    }

    private val authService: AuthService by diContainer.instance()
    private val userStatsService: UserStatsService by diContainer.instance()
    private val configModule: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<UserStatsModel.Model.UserStats, Error>
        get() = {
            if (!configModule.getCoreConfig().gamification.enabled) {
                failure(Error.GamificationModuleDisabled(component))
            } else {
                val authData = authService.getStoredAuth()
                if (authData != null) {
                    val stats = userStatsService.getUserStats(authData.username, false)
                        .mapError { Error.UserUnavailable(component, it) }.getOrAbort()
                    success(stats)
                } else {
                    failure(Error.AuthDataUnavailable(component))
                }
            }
        }
}

/**
 * Retrieve the set of ordered [Model.Section] available for navigation
 */
internal class GetOrderedSections : OutcomeUseCase<List<Model.Section>, GetOrderedSections.Error>() {
    sealed class Error(component: ComponentId, errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(component, UseCaseId.GET_ORDERED_SECTIONS, errorId, networkError) {
        data class UserUnavailable(val useCaseComponent: ComponentId, val error: NetworkError) : Error(
            useCaseComponent,
            asErrorId<UserUnavailable>(1),
            error
        )
        data class LayoutConfigUnavailable(val useCaseComponent: ComponentId, val error: NetworkError) : Error(
            useCaseComponent,
            asErrorId<LayoutConfigUnavailable>(2),
            error
        )
    }

    private val layoutService: LayoutService by diContainer.instance()
    private val authService: AuthService by diContainer.instance()
    private val userService: UserService by diContainer.instance()
    private val configModule: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<List<Model.Section>, Error>
        get() = {
            var userProfile = configModule.getCoreConfig().signup.defaultProfile
            var userLanguage: String = configModule.getCoreConfig().signup.defaultLanguage
            val auth = authService.getStoredAuth()
            if (auth != null) {
                val user = userService.getUser(auth.username)
                    .mapError { Error.UserUnavailable(component, it) }.getOrAbort()
                userProfile = user.profile
                userLanguage = user.preferredLanguage
            }
            val sections = layoutService.getLayoutConfig(userProfile, userLanguage)
                .mapError { Error.LayoutConfigUnavailable(component, it) }.getOrAbort().sections
                .filter {
                    it.platforms.contains(PLATFORM_ID)
                }
            success(sections)
        }
}

internal class AddContentToFavorites(
    private val contentId: Int
) :
    OutcomeUseCase<Unit, AddContentToFavorites.Error>() {
    sealed class Error(component: ComponentId, errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(component, UseCaseId.ADD_CONTENT_TO_FAVORITES, errorId, networkError) {
        data class UnavailableFavorites(val useCaseComponent: ComponentId, val error: NetworkError) : Error(
            useCaseComponent,
            asErrorId<UnavailableFavorites>(1),
            error
        )
        data class ContentAlreadyFavorited(val useCaseComponent: ComponentId, val error: NetworkError) : Error(
            useCaseComponent,
            asErrorId<ContentAlreadyFavorited>(2),
            error
        )
        data class UserSessionUnavailable(val useCaseComponent: ComponentId) : Error(
            useCaseComponent,
            asErrorId<UserSessionUnavailable>(3)
        )
    }

    private val authService: AuthService by diContainer.instance()
    private val userService: UserService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Unit, Error>
        get() = {
            val username = authService.getStoredAuth()?.username
            if (username != null) {
                val favorites = userService.addContentToFavorites(contentId, username)
                    .mapError {
                        if (it is NetworkError.Http && it.statusCode == 409) {
                            Error.ContentAlreadyFavorited(component, it)
                        } else {
                            Error.UnavailableFavorites(component, it)
                        }
                    }.getOrAbort()
                userService.updateCachedUserFavorites(favorites)
                LogEvent(AnalyticsModel.Action.SetFavorite, contentId).invoke()
                success()
            } else {
                failure(Error.UserSessionUnavailable(component))
            }
        }
}

internal class SendGameAction(
    private val action: String,
    private val contentId: Int? = null
) :
    UseCase<GameModel.Model.ActionResult> {

    private var actionContentId: Int? = null
    private var gameBunchName: String? = null

    constructor(action: String, content: Content) : this(action, content.id) {
        actionContentId = getActionContentId(content)
        gameBunchName = getGameBunchName(content)
    }

    private val authService: AuthService by diContainer.instance()
    private val gameService: GameService by diContainer.instance()
    private val contentService: ContentService by diContainer.instance()
    private val configModule: ConfigModule by diContainer.instance()

    override suspend fun invoke(): GameModel.Model.ActionResult {
        if (!configModule.getCoreConfig().gamification.enabled) {
            return GameModel.Model.ActionResult.Failure.GamificationDisabled
        } else {
            var actionResult: GameModel.Model.ActionResult? = null
            val username = authService.getStoredAuth()?.username
            if (username == null) {
                actionResult = GameModel.Model.ActionResult.Failure.NoUserSession
            } else {
                if (contentId != null && (actionContentId == null || gameBunchName == null)) {
                    contentService.getContent(contentId, language = configModule.getCoreConfig().signup.defaultLanguage, username = username)
                        .onError {
                            actionResult = GameModel.Model.ActionResult.Failure.ContentActionsUnavailable
                        }
                        .on {
                            actionContentId = getActionContentId(this.value)
                            gameBunchName = getGameBunchName(this.value)
                        }
                }
                gameService.sendAction(
                    action = action,
                    username = username,
                    contentId = contentId,
                    inGameActionId = actionContentId,
                    gameBunch = gameBunchName
                )
                    .onError {
                        actionResult = when (val actionFailed = this.error) {
                            is GameService.GameServiceError.ActionError ->
                                GameModel.Model.ActionResult.Failure.GamificationServiceUnavailable(
                                    actionFailed.defaultAction, actionFailed.inGameAction, actionFailed.gameBunchAction
                                )
                            is GameService.GameServiceError.UnavailableService ->
                                GameModel.Model.ActionResult.Failure.GamificationServiceUnavailable(
                                    actionFailed.error, null, null
                                )
                        }
                    }
                    .on {
                        actionResult = GameModel.Model.ActionResult.Success(
                            this.value.achievements, this.value.rewards
                        )
                    }
            }
            return actionResult!!
        }
    }

    private fun getActionContentId(content: Content): Int? =
        if (content.inGameActions.contains(action)) {
            contentId
        } else {
            null
        }

    private fun getGameBunchName(content: Content): String? =
        if (content.gameBunch?.actions?.contains(action) == true) {
            content.gameBunch.name
        } else {
            null
        }
}

/**
 * Removes the given content from the favorites list
 * @param contentId of the content to be removed from the favorite list
 */
internal class RemoveContentFromFavorites(private val contentId: Int) :
    OutcomeUseCase<Unit, RemoveContentFromFavorites.Error>() {
    sealed class Error(component: ComponentId, errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(component, UseCaseId.REMOVE_CONTENT_FROM_FAVORITES, errorId, networkError) {
        data class UnavailableFavorites(val useCaseComponent: ComponentId, val error: NetworkError) : Error(
            useCaseComponent,
            asErrorId<UnavailableFavorites>(1)
        )

        data class UserSessionUnavailable(val useCaseComponent: ComponentId) : Error(
            useCaseComponent,
            asErrorId<UserSessionUnavailable>(2)
        )
    }

    private val authService: AuthService by diContainer.instance()
    private val userService: UserService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Unit, Error>
        get() = {
            val username = authService.getStoredAuth()?.username
            if (username != null) {
                val favorites = userService.removeContentFromFavorites(contentId, username)
                    .mapError {
                        Error.UnavailableFavorites(component, it)
                    }.getOrAbort()
                userService.updateCachedUserFavorites(favorites)
                // Log un-favorite
                LogEvent(AnalyticsModel.Action.UnsetFavorite, contentId).invoke()
                success()
            } else {
                failure(Error.UserSessionUnavailable(component))
            }
        }
}

/**
 * Retrieves the list of available [Role] for the user
 */
internal class GetAvailableRoles : UseCase<Set<Role>> {
    override suspend fun invoke(): Set<Role> {
        return setOf(
            Role.Kid(9),
            Role.Teen(10, 13),
            Role.Adult(14)
        )
    }
}

/**
 * Retrieves the list of available [AvatarModel] for the user to choose from
 */
internal class GetAvailableAvatars : OutcomeUseCase<Set<AvatarModel.Model.Avatar>, GetAvailableAvatars.Error>() {
    sealed class Error(component: ComponentId, errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(component, UseCaseId.GET_AVAILABLE_AVATARS, errorId, networkError) {
        data class UnavailableAvatars(val useCaseComponent: ComponentId, val error: NetworkError) : Error(
            useCaseComponent,
            asErrorId<UnavailableAvatars>(1),
            error
        )
    }

    private val avatarService: AvatarService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Set<AvatarModel.Model.Avatar>, Error>
        get() = {
            avatarService.getAvailableAvatars()
                .mapError { Error.UnavailableAvatars(component, it) }
        }

}

/**
 * Retrieves the list of available [Interest] for the user to choose from
 */
internal class GetAvailableInterests : OutcomeUseCase<Set<es.cinfo.tiivii.core.interest.model.Model.Interest>, GetAvailableInterests.Error>() {
    sealed class Error(component: ComponentId, errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(component, UseCaseId.GET_AVAILABLE_INTERESTS, errorId, networkError) {
        data class UnavailableInterests(val useCaseComponent: ComponentId, val error: NetworkError) : Error(
            useCaseComponent,
            asErrorId<UnavailableInterests>(1),
            error
        )
    }

    private val interestService: InterestService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Set<es.cinfo.tiivii.core.interest.model.Model.Interest>, Error>
        get() = {
            interestService.getAvailableInterests()
                .mapError { Error.UnavailableInterests(component, it) }
        }
}

/**
 * Retrieves the rating filter to apply to content loads
 */
internal class GetRatingFilter: OutcomeUseCase<String?, GetRatingFilter.Error>(){
    sealed class Error(component: ComponentId, errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(component, UseCaseId.GET_RATING_FILTER, errorId, networkError) {
        data class UnavailableRatings(val useCaseComponent: ComponentId, val error: NetworkError) : Error(
            useCaseComponent,
            asErrorId<UnavailableRatings>(1),
            error
        )
    }

    private val authService: AuthService by diContainer.instance()
    private val userService: UserService by diContainer.instance()
    private val configModule: ConfigModule by diContainer.instance()
    private val ratingService: RatingService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<String?, Error>
        get() = {
            val auth = authService.getStoredAuth()
            val username = auth?.username
            val user = username?.let { userService.getUser(it).getOrNull() }
            val allowedRatings: List<RatingModel.Model.AgeRating>? = if (user == null) {
                val maxAgeGuestRating = configModule.getCoreConfig().content.maxGuestAgeRating
                if (maxAgeGuestRating != null) {
                    ratingService.getRatingsForAge(maxAgeGuestRating)
                        .mapError { Error.UnavailableRatings(component, it) }.getOrAbort()
                } else {
                    null
                }
            } else {
                user.allowedAgeRatings
            }
            var ratingFilter: String? = null
            if (allowedRatings != null) {
                ratingFilter = ""
                allowedRatings.forEach { ratingFilter += "${it.id}," }
                ratingFilter = ratingFilter.removeSuffix(",")
            }
            success(ratingFilter)
        }
}

internal class UpdateLatestPositionBookmark(
    private val contentId: Int,
    private val posMs: Long,
    private val durationMs: Long,
    private val lastPosSec: Int? = null,
    private val overrideProgressCheck: Boolean = false
) : OutcomeUseCase<Int, UpdateLatestPositionBookmark.Error>() {
    sealed class Error {
        object NoUserSession : Error()
        data class UnavailableBookmarkService(val networkError: NetworkError): Error()
    }
    private val bookmarkService: BookmarksService by diContainer.instance()
    private val authService: AuthService by diContainer.instance()
    private val userService: UserService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Int, Error>
        get() = {
            val username = authService.getStoredAuth()?.username
            if (username != null) {
                val currentPercent = if (posMs == 0L || durationMs == 0L) {
                    0
                } else {
                    posMs * 100 / durationMs
                }
                val lastPercent = if (lastPosSec == null) {
                    null
                } else {
                    if (lastPosSec == 0 || durationMs == 0L) {
                        0
                    } else {
                        lastPosSec * 1000 * 100 / durationMs
                    }
                }
                val shouldUpdate = if (lastPercent == null) {
                    true
                } else {
                    if (currentPercent - lastPercent < 0) {
                        false
                    } else {
                        // If max percentage between bookmarks or finished, update
                        overrideProgressCheck ||
                        (currentPercent - lastPercent >= BookmarksService.ADD_BOOKMARK_EVERY_PERCENT) ||
                        currentPercent >= BookmarksService.FINISHED_PLAYBACK_PERCENT && lastPercent < BookmarksService.FINISHED_PLAYBACK_PERCENT
                    }
                }
                val latestPosSec = (posMs / 1000).toInt()
                val durationSec = (durationMs / 1000).toInt()
                if (shouldUpdate) {
                    if (currentPercent >= BookmarksService.FINISHED_PLAYBACK_PERCENT) {
                        bookmarkService.sendVideoFinished(contentId, username)
                            .map {
                                userService.updateCachedUserBookmarks(it)
                                latestPosSec
                            }
                            .mapError {
                                Error.UnavailableBookmarkService(it)
                            }
                    } else {
                        bookmarkService.sendLatestPosSec(contentId, latestPosSec, durationSec, username)
                            .map {
                                userService.updateCachedUserBookmarks(it)
                                latestPosSec
                            }
                            .mapError {
                                Error.UnavailableBookmarkService(it)
                            }
                    }
                } else {
                    success(lastPosSec!!)
                }
            } else {
                failure(Error.NoUserSession)
            }
        }
}

internal class UpdateLatestAnalyticsPositionVod(
    private val contentId: Int,
    private val posMs: Long,
    private val durationMs: Long?,
    private val lastAnalyticsPercent: Int? = null
) : UseCase<Int?> {

    override suspend fun invoke(): Int? {
        var analyticPosPercent: Int? = null
        val shouldUpdateAnalyticPos = if (durationMs == null) {
            false
        } else {
            val currentPercent = if (posMs == 0L || durationMs == 0L) {
                0
            } else {
                posMs * 100 / durationMs
            }
            val percentSteps: Int = (currentPercent / 5).toInt()
            analyticPosPercent = percentSteps * 5
            if (analyticPosPercent > 0) {
                if (lastAnalyticsPercent == null) {
                    true
                } else {
                    analyticPosPercent > lastAnalyticsPercent
                }
            } else {
                false
            }
        }
        if (shouldUpdateAnalyticPos) {
            LogEvent(AnalyticsModel.Action.Play(analyticPosPercent!!), contentId = contentId).invoke()
        }
        return analyticPosPercent
    }
}

internal class UpdateLatestAnalyticsPositionLive(
    private val contentId: Int,
    private val lastAnalyticsTimestamp: Long? = null
) : UseCase<Long?> {

    private val dateService: DateService by diContainer.instance()

    override suspend fun invoke(): Long? {
        val newAnalyticsTimeStamp: Long = dateService.currentEpochMillis()
        val shouldUpdateAnalyticPos =
            if (lastAnalyticsTimestamp == null) {
                true
            } else {
                newAnalyticsTimeStamp >= (lastAnalyticsTimestamp + 5000)
            }
        return if (shouldUpdateAnalyticPos) {
            LogEvent(AnalyticsModel.Action.PlayLive, contentId = contentId).invoke()
            newAnalyticsTimeStamp
        } else {
            lastAnalyticsTimestamp
        }
    }
}

internal class LoadDefaultOrders : OutcomeUseCase<List<Sort>, LoadDefaultOrders.Error>() {
    sealed class Error(component: ComponentId, errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(component, UseCaseId.LOAD_SORT_METHODS, errorId, networkError) {
        data class EmptySortList(val useCaseComponent: ComponentId) : Error(
            useCaseComponent,
            asErrorId<EmptySortList>(1),
        )
    }
    private val configModule: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<List<Sort>, Error>
        get() = {
            val orders = configModule.getCoreConfig().content.orders
            if (orders.isEmpty()) {
                failure(Error.EmptySortList(component))
            } else {
                success(orders)
            }
        }
}

internal class CheckUserSession : OutcomeUseCase<Unit, CheckUserSession.Error>() {
    sealed class Error(component: ComponentId, errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(component, UseCaseId.USER_SESSION_REQUIRED, errorId, networkError) {
        data class UserSessionRequired(val useCaseComponent: ComponentId) : Error(
            useCaseComponent,
            asErrorId<UserSessionRequired>(1),
        )
        data class CasUnavailable(val useCaseComponent: ComponentId, val error: NetworkError) : Error(
            useCaseComponent,
            asErrorId<CasUnavailable>(2),
            error
        )
    }
    private val casService: CasService by diContainer.instance()
    private val authService: AuthService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Unit, Error>
        get() = {
            val username = authService.getStoredAuth()?.username
            when (val sessionRequiredOutcome = casService.isSessionRequired()) {
                is Failure -> {
                    failure(Error.CasUnavailable(component, sessionRequiredOutcome.error))
                }
                is Success -> {
                    val isSessionRequired = sessionRequiredOutcome.value
                    if (isSessionRequired && username == null) {
                        failure(Error.UserSessionRequired(component))
                    } else {
                        success()
                    }
                }
            }
        }
}
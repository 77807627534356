package es.cinfo.tiivii.core.modules.geolocation

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal data class SendLocationApiRequest(
    @SerialName(LATITUDE_PARAM)
    val latitude: Double,
    @SerialName(LONGITUDE_PARAM)
    val longitude: Double,
    @SerialName(HASH_PARAM)
    val hash: String,
) {
    companion object {
        const val LATITUDE_PARAM = "latitude"
        const val LONGITUDE_PARAM = "longitude"
        const val HASH_PARAM = "hashSignature"
    }
}

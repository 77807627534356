package es.cinfo.tiivii.core.modules.timeline

import es.cinfo.tiivii.core.date.DateService
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.pusher.PusherService
import es.cinfo.tiivii.core.modules.timeline.model.TimelineModel
import es.cinfo.tiivii.core.modules.timeline.model.TimelineModel.ApiResponse.Timespot.Companion.BUTTON_COLOR_PARAM
import es.cinfo.tiivii.core.modules.timeline.model.TimelineModel.ApiResponse.Timespot.Companion.BUTTON_MESSAGE_PARAM
import es.cinfo.tiivii.core.modules.timeline.model.TimelineModel.ApiResponse.Timespot.Companion.BUTTON_URL_PARAM
import es.cinfo.tiivii.core.modules.timeline.model.TimelineModel.ApiResponse.Timespot.Companion.CONTENT_PARAM
import es.cinfo.tiivii.core.modules.timeline.model.TimelineModel.ApiResponse.Timespot.Companion.HTML_PARAM
import es.cinfo.tiivii.core.modules.timeline.model.TimelineModel.ApiResponse.Timespot.Companion.IMG_URL_PARAM
import es.cinfo.tiivii.core.modules.timeline.model.TimelineModel.ApiResponse.Timespot.Companion.TIMESTAMP_PARAM
import es.cinfo.tiivii.core.modules.timeline.model.TimelineModel.ApiResponse.Timespot.Companion.TITLE_PARAM
import es.cinfo.tiivii.core.modules.timeline.model.TimelineModel.ApiResponse.Timespot.Companion.TYPE_PARAM
import es.cinfo.tiivii.core.util.getOrNull
import es.cinfo.tiivii.di.diContainer
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.map
import kotlinx.serialization.json.jsonObject
import kotlinx.serialization.json.jsonPrimitive
import kotlinx.serialization.json.long
import org.kodein.di.instance

internal class TimelineService {
    private val pusherService: PusherService by diContainer.instance()
    private val dateService: DateService by diContainer.instance()
    private val timelineApi: TimelineApi by diContainer.instance()
    private var timelineMap: MutableMap<Int, List<TimelineModel.Model.Timespot>> = mutableMapOf()
    private val configModule: ConfigModule by diContainer.instance()

    suspend fun connect(contentId: Int): Flow<TimelineModel.Model.Timespot?> {
        return pusherService.subscribe(configModule.getEnvConfig().apiName, "timeline-$contentId")
            .filter {
                it.json.jsonObject.containsKey(TYPE_PARAM) &&
                it.json.jsonObject.containsKey(TITLE_PARAM)
            }
            .map {
                val type = it.json.jsonObject[TYPE_PARAM]?.jsonPrimitive?.content!!
                val timestamp = it.json.jsonObject[TIMESTAMP_PARAM]?.jsonPrimitive?.long ?: dateService.currentEpochMillis()
                val title = it.json.jsonObject[TITLE_PARAM]?.jsonPrimitive?.content!!
                when (type) {
                    "message" -> {
                        val content = it.json.jsonObject[CONTENT_PARAM]?.jsonPrimitive?.content
                        TimelineModel.Model.Message(
                            timestamp,
                            title,
                            TimelineModel.Model.Timespot.StreamType.LIVE,
                            content
                        )
                    }
                    "click" -> {
                        val content = it.json.jsonObject[CONTENT_PARAM]?.jsonPrimitive?.content
                        val buttonUrl = it.json.jsonObject[BUTTON_URL_PARAM]?.jsonPrimitive?.content
                        val buttonColor = it.json.jsonObject[BUTTON_COLOR_PARAM]?.jsonPrimitive?.content
                        val buttonMessage = it.json.jsonObject[BUTTON_MESSAGE_PARAM]?.jsonPrimitive?.content
                        TimelineModel.Model.Click(
                            timestamp,
                            title,
                            TimelineModel.Model.Timespot.StreamType.LIVE,
                            content,
                            buttonUrl,
                            buttonColor,
                            buttonMessage
                        )
                    }
                    "image" -> {
                        val imgUrl = it.json.jsonObject[IMG_URL_PARAM]?.jsonPrimitive?.content
                        TimelineModel.Model.Image(
                            timestamp,
                            title,
                            TimelineModel.Model.Timespot.StreamType.LIVE,
                            imgUrl
                        )
                    }
                    "embed" -> {
                        val html = it.json.jsonObject[HTML_PARAM]?.jsonPrimitive?.content
                        TimelineModel.Model.Embed(
                            timestamp,
                            title,
                            TimelineModel.Model.Timespot.StreamType.LIVE,
                            html
                        )
                    }
                    else -> {
                        null
                    }
                }
            }
            .filterNotNull()
    }

    suspend fun disconnect(contentId: Int) {
        pusherService.unsubscribe(configModule.getEnvConfig().apiName, "timeline-$contentId")
    }

    internal suspend fun getVodTimespots(contentId: Int, posSec: Int): List<TimelineModel.Model.Timespot> {
        if (!timelineMap.containsKey(contentId)) {
            val timespots = timelineApi.getTimespots(contentId).getOrNull()
            val contentTimeline = timespots?.sortedBy { it.timestamp }?.mapNotNull { it.toModel() }
            if (contentTimeline != null) {
                timelineMap[contentId] = contentTimeline
            } else {
                timelineMap[contentId] = emptyList()
            }
        }
        val timeline = timelineMap[contentId]
        return timeline?.filter { it.timestamp <= posSec } ?: emptyList()
    }
}
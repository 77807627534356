package es.cinfo.tiivii.core.modules.avatar

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.modules.avatar.model.AvatarModel.ApiResponse.Avatar
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.network.HttpModule
import es.cinfo.tiivii.core.util.*
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

/**
 * Network datasource for avatar operations
 */
internal interface AvatarApi {

    /**
     * Retrieves from backend a list of all the available avatars for the user
     * @return A list of [Avatar]
     */
    suspend fun getAvailableAvatars(): Outcome<List<Avatar>, NetworkError>

    /**
     * Retrieves the [Avatar] model for the given avatar id
     */
    suspend fun getAvatar(avatarImageId: Int): Outcome<Avatar, NetworkError>
}

/**
 * Default implementation of [AvatarApi] using Ktor
 */
internal class DefaultAvatarApi : AvatarApi {
    private val http: HttpModule by diContainer.instance()

    private val baseEndpoint: String by lazy {
        val configModule: ConfigModule by diContainer.instance()
        "${configModule.getEnvConfig().backendUrl}/sergio/${configModule.getEnvConfig().apiName}/avatars"
    }

    override suspend fun getAvailableAvatars(): Outcome<List<Avatar>, NetworkError> {
        val endpoint = "$baseEndpoint?fields=*.*"
        return http.getAsOutcome(endpoint = endpoint, responseField = "data")
    }

    override suspend fun getAvatar(avatarImageId: Int): Outcome<Avatar, NetworkError> {
        val endpoint = "$baseEndpoint?fields=*.*&filter[image.id]=$avatarImageId"
        return when (val outcome = http.getAsOutcome<List<Avatar>>(endpoint = endpoint, responseField = "data")) {
            is Success -> {
                if (outcome.value.isEmpty()) {
                    failure(NetworkError.Http(
                        statusCode = 404,
                        method = "GET",
                        url = endpoint,
                        encapsulated = false))
                } else {
                    success(outcome.value.first())
                }
            }
            is Failure -> outcome
        }
    }
}
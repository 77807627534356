package es.cinfo.tiivii.core.modules.cas.model

import es.cinfo.tiivii.core.modules.game.model.GameModel.ApiResponse.Achievement
import es.cinfo.tiivii.core.modules.product.model.ProductModel.Model.Product as ProductModel
import es.cinfo.tiivii.core.modules.product.model.ProductModel.ApiResponse.ProductWrapper as ProductWrapper
import kotlinx.serialization.DeserializationStrategy
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.SerializationException
import kotlinx.serialization.json.*

class CasModel {
    internal sealed class Model {
        data class ContentHash(
            val hash: String?,
            val isFree: Boolean,
            val products: List<ProductModel>
        )
    }
    internal sealed class ApiResponse {
        object CasErrorSerializer : JsonContentPolymorphicSerializer<CasError>(CasError::class) {
            override fun selectDeserializer(element: JsonElement): DeserializationStrategy<out CasError> {
                return when (element.jsonObject["key"]?.jsonPrimitive?.content) {
                    "missing-achievement" -> CasError.MissingAchievements.serializer()
                    "missing-product" -> CasError.ProductNotPurchased.serializer()
                    "age-restriction" -> CasError.RatingLocked.serializer()
                    else -> CasError.Unknown.serializer()
                }
            }
        }

        @Serializable
        data class Hash(
            @SerialName(ALLOWED_PARAM)
            val allowed: Boolean,
            @SerialName(REQUIRED_PARAM)
            val required: Boolean,
            @SerialName(HASH_PARAM)
            val hash: String? = null,
            @SerialName(IS_FREE_PARAM)
            val isFree: Boolean = false,
            @SerialName(PRODUCTS_PARAM)
            val products: List<ProductWrapper> = emptyList(),
        ) {
            companion object {
                const val ALLOWED_PARAM = "allowed"
                const val REQUIRED_PARAM = "hashRequired"
                const val HASH_PARAM = "hash"
                const val IS_FREE_PARAM = "isFree"
                const val PRODUCTS_PARAM = "products"
            }
        }
        @Serializable
        data class KrakenError(
            @SerialName("error")
            val error: CasError
        )
        @Serializable(with = CasErrorSerializer::class)
        sealed class CasError {
            @Serializable
            data class MissingAchievements(
                val achievements: List<Achievement>
            ): CasError()
            @Serializable
            data class ProductNotPurchased(
                @SerialName("products")
                val wrapper: List<ProductWrapper>
            ): CasError()
            @Serializable
            object RatingLocked: CasError()
            @Serializable
            object Unknown: CasError()
        }
    }

    @Serializable
    data class Vast(
        @SerialName("vastUrl")
        val url: String? = null
    )

    @Serializable
    data class Rules(
        @SerialName(ANONS_ALLOWED_PARAM)
        val areAnonsAllowed: Boolean,
        @SerialName(BLOCKING_ACHIEVEMENTS_PARAM)
        val hasBlockingAchievements: Boolean,
        @SerialName(MONETIZATION_PARAM)
        val hasMonetization: Boolean,
        @SerialName(UNDERAGE_USERS_PARAM)
        val hasUnderageUsers: Boolean,
        @SerialName(PRODUCT_BUYOUT_PARAM)
        val productBuyoutEnabled: Boolean,
    ) {
        companion object {
            const val ANONS_ALLOWED_PARAM = "areAnonAllowed"
            const val BLOCKING_ACHIEVEMENTS_PARAM = "hasBlockingAchievements"
            const val MONETIZATION_PARAM = "hasMonetization"
            const val UNDERAGE_USERS_PARAM = "hasUnderagedUsers"
            const val PRODUCT_BUYOUT_PARAM = "productBuyoutEnabled"
        }
    }

}

package es.cinfo.tiivii.nav.menu.store

import com.arkivanov.mvikotlin.core.store.Store
import es.cinfo.tiivii.core.layout.model.section.Model.Section
import es.cinfo.tiivii.core.util.LoadingModel
import es.cinfo.tiivii.nav.menu.store.MenuStore.*

internal interface MenuStore :
    Store<Intent, State, Label> {

    sealed class Action {
        object GetOrderedSections : Action()
        object GetUserLanguage : Action()
        object GetDeclarations : Action()
        object GetConfigValues : Action()
        object GetAppLink : Action()
    }

    sealed class Intent {
        object Reload : Intent()
        data class SelectSection(val sectionId: String) : Intent()
    }

    data class State(
        val language: String,
        val sections: List<Section>?,
        val loadingSections: LoadingModel.Model.LoadState,
        val selectedSection: Section?,
        val studioUrl: String?,
        val supportEmail: String?,
        val tutorialUrl: String?,
        val privacyStatementUrl: String?,
        val termsAndConditionsUrl: String?,
        val legalText: String?,
        val iosAppUrl: String?,
        val androidAppUrl: String?,
        val faqUrl: String?
    )

    sealed class Label {
        object UserSessionExpired : Label()
        object RequestTimedOut : Label()
        data class UnexpectedError(val code: String) : Label()
    }

}
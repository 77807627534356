package es.cinfo.tiivii.user.legal.store

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.SuspendExecutor
import es.cinfo.tiivii.core.error.ErrorService
import es.cinfo.tiivii.core.util.Failure
import es.cinfo.tiivii.core.util.LoadingModel
import es.cinfo.tiivii.core.util.Success
import es.cinfo.tiivii.di.diContainer
import es.cinfo.tiivii.user.legal.store.LegalStore.*
import es.cinfo.tiivii.user.legal.usecase.AcceptLatestLegalConditions
import es.cinfo.tiivii.user.legal.usecase.GetLatestLegalConditions
import es.cinfo.tiivii.user.legal.usecase.LegalConditions
import org.kodein.di.instance
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper

internal class LegalStoreFactory(
    private val storeFactory: StoreFactory,
) {

    private val errorService: ErrorService by diContainer.instance()

    private sealed class Result {
        object LoadingLegalText: Result()
        object ErrorLoadingLegalText: Result()
        data class LegalText(val legalConditions: LegalConditions) : Result()
    }

    fun create(): LegalStore =
        object :
            LegalStore,
            Store<Intent, State, Label> by
            storeFactory.create(
                name = "LegalStore",
                initialState = State(
                    legalText = null,
                    legalTextLanguage = null,
                    loadingLegalText = LoadingModel.Model.LoadState.RESET
                ),
                bootstrapper = SimpleBootstrapper(Action.GetLegalText),
                reducer = DefaultReducer,
                executorFactory = ::DefaultExecutor
            ) {
        }

    private object DefaultReducer : Reducer<State, Result> {
        override fun State.reduce(result: Result): State =
            when (result) {
                is Result.LegalText -> copy(
                    legalText = result.legalConditions.text,
                    legalTextLanguage = result.legalConditions.language,
                    loadingLegalText = LoadingModel.Model.LoadState.LOADED
                )
                Result.ErrorLoadingLegalText -> copy(
                    loadingLegalText = LoadingModel.Model.LoadState.RESET
                )
                Result.LoadingLegalText -> copy(
                    loadingLegalText = LoadingModel.Model.LoadState.LOADING
                )
            }
    }

    private inner class DefaultExecutor :
        SuspendExecutor<Intent, Action,
                State,
                Result, Label>() {
        override suspend fun executeIntent(intent: Intent, getState: () -> State) {
            when (intent) {
                is Intent.AcceptConditions -> acceptLegalConditions(intent.isDataRecollectionAllowed)
                is Intent.RejectConditions -> rejectLegalConditions()
                is Intent.ReloadLegalConditions -> getLatestLegalConditions(intent.language)
            }
        }

        override suspend fun executeAction(action: Action, getState: () -> State) {
            when (action) {
                Action.GetLegalText -> getLatestLegalConditionsImplicit()
            }
        }

        private suspend fun getLatestLegalConditionsImplicit(
            language: String? = null
        ) {
            dispatch(Result.LoadingLegalText)
            when (val outcome = GetLatestLegalConditions(language).invoke()) {
                is Success -> dispatch(Result.LegalText(outcome.value))
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = {
                            dispatch(Result.ErrorLoadingLegalText)
                            publish(Label.UserSessionExpired)
                        },
                        requestTimeoutAction = {
                            dispatch(Result.ErrorLoadingLegalText)
                            publish(Label.RequestTimedOut)
                        },
                        handler = {
                            dispatch(Result.ErrorLoadingLegalText)
                            when (outcome.error) {
                                is GetLatestLegalConditions.Error.UnavailableLegalConditions -> {
                                    publish(Label.UnexpectedError(outcome.error.getCode()))
                                }
                                GetLatestLegalConditions.Error.LegalModuleDisabled -> {
                                    // Implicit call, no need to notify the UI
                                }
                            }
                        }
                    )
                }
            }
        }

        private suspend fun getLatestLegalConditions(language: String? = null) {
            dispatch(Result.LoadingLegalText)
            when (val outcome = GetLatestLegalConditions(language).invoke()) {
                is Success -> dispatch(Result.LegalText(outcome.value))
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = {
                            dispatch(Result.ErrorLoadingLegalText)
                            publish(Label.UserSessionExpired)
                        },
                        requestTimeoutAction = {
                            dispatch(Result.ErrorLoadingLegalText)
                            publish(Label.RequestTimedOut)
                        },
                        handler = {
                            dispatch(Result.ErrorLoadingLegalText)
                            when (outcome.error) {
                                is GetLatestLegalConditions.Error.UnavailableLegalConditions -> {
                                    publish(Label.UnexpectedError(outcome.error.getCode()))
                                }
                                GetLatestLegalConditions.Error.LegalModuleDisabled ->
                                    publish(Label.IllegalOperationError(
                                        "Legal module is disabled for current compilation. No legal related operations can be done"
                                    ))
                            }
                        }
                    )
                }
            }
        }

        private suspend fun acceptLegalConditions(isDataRecollectionAllowed: Boolean) {
            when (val outcome = AcceptLatestLegalConditions(isDataRecollectionAllowed).invoke()) {
                is Success -> {
                    publish(Label.AcceptedLegalConditions)
                }
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = {
                            publish(Label.UserSessionExpired)
                        },
                        requestTimeoutAction = {
                            publish(Label.RequestTimedOut)
                        },
                        handler = {
                            when (outcome.error) {
                                is AcceptLatestLegalConditions.Error.UnavailableLegalConditionSign,
                                is AcceptLatestLegalConditions.Error.UnavailableLegalConditions ->
                                    publish(Label.UnexpectedError(outcome.error.getCode()))
                                AcceptLatestLegalConditions.Error.LegalModuleDisabled ->
                                    publish(Label.IllegalOperationError(
                                        "Legal module is disabled for current compilation. No legal related operations can be done"
                                    ))
                            }
                        }
                    )
                }
            }
        }

        private fun rejectLegalConditions() {
            // Nothing to do
        }

    }

}
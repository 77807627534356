package es.cinfo.tiivii.core.features.playback.store

import com.arkivanov.mvikotlin.core.store.Store
import es.cinfo.tiivii.core.content.model.ContentTypeModel
import es.cinfo.tiivii.core.features.playback.store.PlaybackStore.*
import es.cinfo.tiivii.core.features.playback.native.model.PlaybackModel.Model.PlaybackLockReason
import es.cinfo.tiivii.core.util.LoadingModel.Model.LoadState

internal interface PlaybackStore :
    Store<Intent, State, Label> {

    sealed class Action {}

    sealed class Intent {
        data class LoadVideoUrl(val contentId: Int, val ignoreList: String? = null) : Intent()
        object LogPlaybackView : Intent()
    }

    data class State(
        val videoUrl: String?,
        val shareUrl: String?,
        val contentType: ContentTypeModel.Model.ContentType?,
        val loading: LoadState
    )

    sealed class Label {
        data class UnexpectedError(val code: String) : Label()
        object UserSessionExpired : Label()
        object RequestTimedOut : Label()
        data class PlaybackLocked(val reason: PlaybackLockReason) : Label()
    }

}
package es.cinfo.tiivii.core.features.user.login

import es.cinfo.tiivii.core.features.user.login.LoginModel.ViewModel.LoginConfig.LoginType
import es.cinfo.tiivii.core.features.user.login.LoginModel.ViewModel.LoginConfig.LoginType.GUEST
import es.cinfo.tiivii.core.features.user.login.LoginModel.ViewModel.LoginConfig.LoginType.USER
import es.cinfo.tiivii.core.features.user.login.LoginModel.ViewModel.LoginStatus.*
import es.cinfo.tiivii.core.layout.model.layoutconfig.ViewModel.StyleConfig
import es.cinfo.tiivii.core.modules.game.model.GameModel
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import es.cinfo.tiivii.core.layout.model.layoutconfig.Model.LayoutConfig.StyleConfig as StyleConfigModel
import es.cinfo.tiivii.core.image.Model.Image as ImageModel

class LoginModel {

    class ViewModel {
        /**
         * Represents the status of a login process
         * @see LOGGED_OUT
         * @see LOGGED_IN
         * @see LOGGING_IN
         */
        enum class LoginStatus {
            /**
             * The login process has not started
             */
            LOGGED_OUT,

            /**
             * The login process finished successfully and some user has logged in
             */
            LOGGED_IN,

            /**
             * The login process is currently being executed
             */
            LOGGING_IN,
        }

        /**
         * Represents a screen that must be routed
         */
        enum class Screen {
            /**
             * The legal conditions screens to accept them
             */
            LEGAL,

            /**
             * The main home screen
             */
            HOME
        }

        /**
         * Represents parameters obtained when the login has been successful
         * @param styles [StyleConfig] with appearance attributes
         * @param nextScreen with the [Screen] that must be routed next
         * @param loginType indicates the type of the [LoginType]
         * @param birthday indicates the birthday of the user if not a guest
         */
        data class LoginConfig(
            val styles: StyleConfig,
            val nextScreen: Screen,
            val loginType: LoginType,
            val birthday: String?
        ) {
            /**
             * Represents the type of the login
             * @see USER
             * @see GUEST
             */
            enum class LoginType {
                /**
                 * Standard user login
                 */
                USER,

                /**
                 * Guest login
                 */
                GUEST
            }
        }
    }

    internal sealed class Model {
        data class DefaultStyles(
            val logo: ImageModel,
            val background: ImageModel? = null
        )

        data class LoginConfig(
            val styles: StyleConfigModel,
            val nextScreen: Screen,
            val loginType: LoginType,
            val birthday: String?
        ) {
            fun toViewModel() : ViewModel.LoginConfig {
                return ViewModel.LoginConfig(
                    styles.toViewModel(),
                    nextScreen.toViewModel(),
                    loginType.toViewModel(),
                    birthday
                )
            }
            enum class LoginType {
                USER, GUEST;

                fun toViewModel(): ViewModel.LoginConfig.LoginType {
                    return when (this) {
                        USER -> ViewModel.LoginConfig.LoginType.USER
                        GUEST -> ViewModel.LoginConfig.LoginType.GUEST
                    }
                }
            }
        }

        enum class LoginStatus {
            LOGGED_OUT, LOGGED_IN, LOGGING_IN;

            fun toViewModel(): ViewModel.LoginStatus {
                return when (this) {
                    LOGGED_OUT -> ViewModel.LoginStatus.LOGGED_OUT
                    LOGGED_IN -> ViewModel.LoginStatus.LOGGED_IN
                    LOGGING_IN -> ViewModel.LoginStatus.LOGGING_IN
                }
            }
        }

        enum class Screen {
            LEGAL, HOME;

            fun toViewModel(): ViewModel.Screen {
                return when (this) {
                    LEGAL -> ViewModel.Screen.LEGAL
                    HOME -> ViewModel.Screen.HOME
                }
            }
        }

        data class Login(
            val accessToken: String,
            val refreshToken: String
        )
    }

    internal sealed class ApiResponse {
        @Serializable
        data class Login(
            @SerialName(ACCESS_TOKEN_PARAM)
            val accessToken: String,
            @SerialName(REFRESH_TOKEN_PARAM)
            val refreshToken: String
        ) {
            companion object {
                const val ACCESS_TOKEN_PARAM = "access_token"
                const val REFRESH_TOKEN_PARAM = "refresh_token"
            }

            fun toModel(): Model.Login {
                return Model.Login(accessToken, refreshToken)
            }
        }
    }

}
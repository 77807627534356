package es.cinfo.tiivii.footer.binder

import es.cinfo.tiivii.footer.store.FooterStore
import es.cinfo.tiivii.footer.view.FooterView

/**
 * Transforms the internal [FooterStore.State] to the [FooterView.Model] for the UI
 */
internal val STATE_TO_MODEL: FooterStore.State.() -> FooterView.Model = {
    FooterView.Model(
        sponsors = sponsorsLogos.map { it.toViewModel() },
        sponsorsLoading = sponsorsLoading.toViewModel()
    )
}

/**
 * Transforms the UI [FooterView.Event] to the internal [FooterStore.Intent]
 */
internal val EVENT_TO_INTENT: FooterView.Event.() -> FooterStore.Intent = {
    when (this) {
        is FooterView.Event.GetSponsors -> FooterStore.Intent.FetchSponsors
    }
}

/**
 * Transforms the internal [FooterStore.Label] to the [FooterView.Output] for the UI
 */
internal val LABEL_TO_OUTPUT: FooterStore.Label.() -> FooterView.Output = {
    when (this) {
        is FooterStore.Label.UnexpectedError -> FooterView.Output.UnexpectedError(code)
        FooterStore.Label.UserSessionExpired -> FooterView.Output.UserSessionExpired
        FooterStore.Label.RequestTimedOut -> FooterView.Output.RequestTimedOut
    }
}
package es.cinfo.tiivii.core.interest

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.interest.model.ApiResponse.Interest
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.network.HttpModule
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.core.util.encodeToString
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

internal interface InterestApi {

    /**
     * Retrieves the list of available interests for the user
     */
    suspend fun getAvailableInterests(): Outcome<Set<Interest>, NetworkError>

    /**
     * Retrieves the list of [Interest] that match the given interest names
     * @param ids Set of interest ids to retrieve the data for
     */
    suspend fun getInterests(ids: Set<Int>): Outcome<Set<Interest>, NetworkError>
}

internal class DefaultInterestApi : InterestApi {
    private val http: HttpModule by diContainer.instance()

    private val baseEndpoint: String by lazy {
        val configModule: ConfigModule by diContainer.instance()
        "${configModule.getEnvConfig().backendUrl}/sergio/${configModule.getEnvConfig().apiName}/interests"
    }

    override suspend fun getAvailableInterests(): Outcome<Set<Interest>, NetworkError> {
        val endpoint = "$baseEndpoint?fields=*.*"
        return http.getAsOutcome(endpoint = endpoint, responseField = "data")
    }

    override suspend fun getInterests(ids: Set<Int>): Outcome<Set<Interest>, NetworkError> {
        var endpoint = "$baseEndpoint?fields=*.*"
        val encodedInterests = ids.encodeToString(",")
        if (encodedInterests.isNotBlank()) {
            endpoint += "&filter[id][in]=$encodedInterests"
        }
        return http.getAsOutcome(
            endpoint = endpoint,
            responseField = "data")
    }

}
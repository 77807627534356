package es.cinfo.tiivii.core.features.content.usecase

import es.cinfo.tiivii.core.features.content.model.Model.Content
import es.cinfo.tiivii.core.features.content.model.Model.ContentTreeLoad
import es.cinfo.tiivii.core.features.content.model.ViewModel
import es.cinfo.tiivii.core.ComponentId
import es.cinfo.tiivii.core.ErrorId
import es.cinfo.tiivii.core.UseCaseId
import es.cinfo.tiivii.core.content.ContentService
import es.cinfo.tiivii.core.error.CodedError
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.error.asErrorId
import es.cinfo.tiivii.core.modules.rating.RatingModel.Model.Companion.RATING_FILTER
import es.cinfo.tiivii.core.sorting.SortModel.Model.Sort
import es.cinfo.tiivii.core.usecase.GetRatingFilter
import es.cinfo.tiivii.core.util.*
import es.cinfo.tiivii.core.util.Model.Node
import es.cinfo.tiivii.core.util.Model.Tree
import es.cinfo.tiivii.di.diContainer
import es.cinfo.tiivii.search.data.SearchService
import org.kodein.di.instance

const val PARTICIPANT_FILTER = "[participants.participants_id.id]"

internal class LoadContentsByParticipant(
    private val id: Int,
    private val sort: Sort
) : OutcomeUseCase<ContentTreeLoad, LoadContentsByParticipant.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.CONTENT, UseCaseId.LOAD_CONTENT_BY_PARTICIPANT, errorId, networkError) {
        data class UnavailableContents(val error: NetworkError) : Error(
            asErrorId<UnavailableContents>(1),
            error
        )
    }

    private val searchService: SearchService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<ContentTreeLoad, Error>
        get() = {
            val contentLoad: ContentTreeLoad
            val searchFilters = mutableMapOf(PARTICIPANT_FILTER to id.toString())
            val ratingFilter = GetRatingFilter().invokeWith(ComponentId.CONTENT).mapError {
                when (it) {
                    is GetRatingFilter.Error.UnavailableRatings -> Error.UnavailableContents(it.error)
                }
            }.getOrAbort()
            if (ratingFilter != null) {
                searchFilters[RATING_FILTER] = ratingFilter
            }
            val participantContentLoad = searchService.search(filters = searchFilters, sort = sort.param, page = 1)
                .mapError {
                    Error.UnavailableContents(it)
                }.getOrAbort()
            val participantContents = participantContentLoad.searchResultEntries.map {
                Content(
                    id = it.id,
                    title = it.title,
                    type = it.type,
                    background = it.background,
                    banner = it.banner,
                    poster = it.poster,
                    votes = it.votes,
                    score = it.score,
                    rating = it.rating,
                    // TODO: Revisit if nested support needed
                    _hasChildren = false
                )
            }
            val contentTree = Tree(
                participantContents
            )
            contentLoad = ContentTreeLoad(
                contentTree,
                participantContentLoad.count,
                participantContentLoad.page,
                participantContentLoad.limit,
                participantContentLoad.sort,
                // TODO: Revisit when support for participants is completed
            "Participants"
            )
            success(contentLoad)
        }
}

internal class LoadContentPageByParticipant(
    private val id: Int,
    private val contentTree: Tree<ViewModel.Content, Content>,
    private val selectedContent: Node<ViewModel.Content, Content>? = null,
    private val queryFilter: String?,
    private val page: Int,
    private val sort: Sort
) : OutcomeUseCase<ContentTreeLoad, LoadContentPageByParticipant.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.CONTENT, UseCaseId.LOAD_MORE_CONTENT_BY_PARTICIPANT, errorId, networkError) {
        data class UnavailableContents(val error: NetworkError) : Error(
            asErrorId<UnavailableContents>(1),
            error
        )
    }

    private val contentService: ContentService by diContainer.instance()
    private val searchService: SearchService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<ContentTreeLoad, Error>
        get() = {
            val contentLoad: ContentTreeLoad
            val participantContents : List<Content>
            val contentsCount: Int
            val contentsPage: Int
            val contentsLimit: Int
            val contentsSort: Sort
            val contentsTitle: String
            if (selectedContent != null) {
                val ratingFilter = GetRatingFilter().invokeWith(ComponentId.CONTENT).mapError {
                    when (it) {
                        is GetRatingFilter.Error.UnavailableRatings -> Error.UnavailableContents(it.error)
                    }
                }.getOrAbort()
                val searchFilters = if (ratingFilter != null) {
                    mutableMapOf(RATING_FILTER to ratingFilter)
                } else {
                    null
                }
                // TODO: Revisit if nested support needed (missing query filter on serial contents endpoint)
                val participantContentLoad = contentService.getSerialContents(
                    id = selectedContent.value.id,
                    filters = searchFilters,
                    page = page,
                    sort = sort.param
                )
                    .mapError { Error.UnavailableContents(it) }.getOrAbort()
                participantContents = participantContentLoad.contents.map {
                    Content(
                        id = it.id,
                        title = it.title,
                        type = it.type,
                        background = it.background,
                        banner = it.banner,
                        poster = it.poster,
                        votes = it.votes,
                        score = it.score,
                        rating = it.rating.code,
                        _hasChildren = it.hasChildren
                    )
                }
                contentsCount = participantContentLoad.count
                contentsPage = participantContentLoad.page
                contentsLimit = participantContentLoad.limit
                contentsSort = participantContentLoad.sort
                // TODO: Revisit when support for participants is completed
                contentsTitle = "Participants"
                contentTree.addChildren(selectedContent.coordinates, participantContents)
            } else {
                val searchFilters = mutableMapOf(PARTICIPANT_FILTER to id.toString())
                val ratingFilter = GetRatingFilter().invokeWith(ComponentId.CONTENT).mapError {
                    when (it) {
                        is GetRatingFilter.Error.UnavailableRatings -> Error.UnavailableContents(it.error)
                    }
                }.getOrAbort()
                if (ratingFilter != null) {
                    searchFilters[RATING_FILTER] = ratingFilter
                }
                val widgetContentLoad = searchService.search(queryFilter, filters = searchFilters, sort = sort.param, page = page)
                    .mapError {
                        Error.UnavailableContents(it)
                    }.getOrAbort()
                participantContents = widgetContentLoad.searchResultEntries.map {
                    Content(
                        id = it.id,
                        title = it.title,
                        type = it.type,
                        background = it.background,
                        banner = it.banner,
                        poster = it.poster,
                        votes = it.votes,
                        score = it.score,
                        rating = it.rating,
                        // TODO: Revisit if nested support needed
                        _hasChildren = false
                    )
                }
                contentsCount = widgetContentLoad.count
                contentsPage = widgetContentLoad.page
                contentsLimit = widgetContentLoad.limit
                contentsSort = widgetContentLoad.sort
                // TODO: Revisit when support for participants is completed
                contentsTitle = "Participants"
                contentTree.addRootNodes(participantContents)
            }
            contentLoad = ContentTreeLoad(
                contentTree,
                contentsCount,
                contentsPage,
                contentsLimit,
                contentsSort,
                contentsTitle
            )
            success(contentLoad)
        }
}

internal class SortContentForParticipant(
    private val id: Int,
    private val query: String?,
    private val sort: Sort
) : OutcomeUseCase<ContentTreeLoad, SortContentForParticipant.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.CONTENT, UseCaseId.SORT_CONTENT_FOR_PARTICIPANT, errorId, networkError) {
        data class SortUnavailable(val error: NetworkError) : Error(
            asErrorId<SortUnavailable>(1),
            error
        )
    }

    private val searchService: SearchService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<ContentTreeLoad, Error>
        get() = {
            val searchFilters = mutableMapOf(PARTICIPANT_FILTER to id.toString())
            val ratingFilter = GetRatingFilter().invokeWith(ComponentId.CONTENT).mapError {
                when (it) {
                    is GetRatingFilter.Error.UnavailableRatings -> Error.SortUnavailable(it.error)
                }
            }.getOrAbort()
            if (ratingFilter != null) {
                searchFilters[RATING_FILTER] = ratingFilter
            }
            val searchResults = searchService.search(query, filters = searchFilters, sort = sort.param, page = 1)
                .mapError {
                    Error.SortUnavailable(it)
                }.getOrAbort()
            val contents = searchResults.searchResultEntries.map {
                Content(
                    id = it.id,
                    title = it.title,
                    type = it.type,
                    background = it.background,
                    banner = it.banner,
                    poster = it.poster,
                    votes = it.votes,
                    score = it.score,
                    rating = it.rating,
                    _hasChildren = it.hasChildren
                )
            }
            val contentTree = Tree(contents)
            success(
                ContentTreeLoad(
                    contentTree,
                    searchResults.count,
                    searchResults.page,
                    searchResults.limit,
                    searchResults.sort,
                    // TODO: Revisit when support for participants is completed
                    "Participants"
                )
            )
        }
}

internal class FilterContentsForParticipant(
    private val id: Int,
    private val query: String?,
    private val sort: Sort
) : OutcomeUseCase<ContentTreeLoad, FilterContentsForParticipant.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.CONTENT, UseCaseId.FILTER_CONTENT_FOR_PARTICIPANT, errorId, networkError) {
        data class FilterUnavailable(val error: NetworkError) : Error(
            asErrorId<FilterUnavailable>(1),
            error
        )
    }

    private val searchService: SearchService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<ContentTreeLoad, Error>
        get() = {
            val searchFilters = mutableMapOf(PARTICIPANT_FILTER to id.toString())
            val ratingFilter = GetRatingFilter().invokeWith(ComponentId.CONTENT).mapError {
                when (it) {
                    is GetRatingFilter.Error.UnavailableRatings -> Error.FilterUnavailable(it.error)
                }
            }.getOrAbort()
            if (ratingFilter != null) {
                searchFilters[RATING_FILTER] = ratingFilter
            }
            val searchResults = searchService.search(query, filters = searchFilters, sort = sort.param, page = 1)
                .mapError {
                    Error.FilterUnavailable(it)
                }.getOrAbort()
            val contents = searchResults.searchResultEntries.map {
                Content(
                    id = it.id,
                    title = it.title,
                    type = it.type,
                    background = it.background,
                    banner = it.banner,
                    poster = it.poster,
                    votes = it.votes,
                    score = it.score,
                    rating = it.rating,
                    _hasChildren = it.hasChildren
                )
            }
            val contentTree = Tree(contents)
            success(
                ContentTreeLoad(
                    contentTree,
                    searchResults.count,
                    searchResults.page,
                    searchResults.limit,
                    searchResults.sort,
                    // TODO: Revisit when support for participants is completed
                    "Participants"
                )
            )
        }
}
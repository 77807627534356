package es.cinfo.tiivii.core.features.notifications.binder

import es.cinfo.tiivii.core.features.notifications.store.NotificationsStore
import es.cinfo.tiivii.core.features.notifications.view.NotificationsView


internal val STATE_TO_MODEL: NotificationsStore.State.() -> NotificationsView.Model = {
    NotificationsView.Model(
        newNotificationsCount = numNotifications,
        notifications = notifications.map { it.toViewModel() },
        loadingStats = loadingStats.toViewModel()
    )
}

internal val EVENT_TO_INTENT: NotificationsView.Event.() -> NotificationsStore.Intent = {
    when (this) {
        NotificationsView.Event.ReloadNotifications -> NotificationsStore.Intent.ReloadNotifications
        is NotificationsView.Event.DeleteNotification -> NotificationsStore.Intent.DeleteNotification(id)
        is NotificationsView.Event.ReadNotification -> NotificationsStore.Intent.ReadNotification(id)
    }
}

internal val LABEL_TO_OUTPUT: NotificationsStore.Label.() -> NotificationsView.Output = {
    when (this) {
        NotificationsStore.Label.UserSessionExpired -> NotificationsView.Output.UserSessionExpired
        is NotificationsStore.Label.UnexpectedError -> NotificationsView.Output.UnexpectedError(code)
        NotificationsStore.Label.RequestTimedOut -> NotificationsView.Output.RequestTimedOut
        is NotificationsStore.Label.IllegalOperationError -> NotificationsView.Output.IllegalOperationError(reason)
    }
}

package es.cinfo.tiivii.user.legal.usecase

import es.cinfo.tiivii.core.ComponentId
import es.cinfo.tiivii.core.ErrorId
import es.cinfo.tiivii.core.UseCaseId
import es.cinfo.tiivii.core.error.CodedError
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.error.asErrorId
import es.cinfo.tiivii.core.layout.LayoutService
import es.cinfo.tiivii.core.modules.auth.AuthService
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.user.UserService
import es.cinfo.tiivii.core.util.*
import es.cinfo.tiivii.di.diContainer
import es.cinfo.tiivii.user.profile.usecase.LoadProducts
import es.cinfo.tiivii.user.splash.usecase.GetStoredAuth
import org.kodein.di.instance

data class LegalConditions(
    val text: String,
    val language: String
)

internal data class GetLatestLegalConditions(val language: String?) : OutcomeUseCase<LegalConditions, GetLatestLegalConditions.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.LEGAL, UseCaseId.GET_LATEST_LEGAL_CONDITIONS, errorId, networkError) {
        data class UnavailableLegalConditions(val error: NetworkError) : Error(
            asErrorId<UnavailableLegalConditions>(1),
            error
        )
        object LegalModuleDisabled : Error(
            asErrorId<LegalModuleDisabled>(2)
        )
    }

    private val layoutService: LayoutService by diContainer.instance()
    private val authService: AuthService by diContainer.instance()
    private val userService: UserService by diContainer.instance()
    private val configModule: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<LegalConditions, Error>
        get() = {
            var userProfile = configModule.getCoreConfig().signup.defaultProfile
            var userLanguage : String = language ?: configModule.getCoreConfig().signup.defaultLanguage
            val auth = authService.getStoredAuth()
            if (auth != null) {
                val user = userService.getUser(auth.username)
                    .mapError {
                        Error.UnavailableLegalConditions(it)
                    }.getOrAbort()
                userProfile = user.profile
                userLanguage = user.preferredLanguage
            }
            layoutService.getLayoutConfig(userProfile, userLanguage)
                .map {
                    LegalConditions(
                        it.legal.text.forLanguage(userLanguage),
                        userLanguage
                    )
                }
                .mapError {
                    Error.UnavailableLegalConditions(it)
                }
        }
}

internal data class GetLegalConditionsVersion(val version: Int, val language: String?) : OutcomeUseCase<LegalConditions, GetLegalConditionsVersion.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.LEGAL, UseCaseId.GET_LEGAL_CONDITIONS_VERSION, errorId, networkError) {
        data class UnavailableLegalConditions(val error: NetworkError) : Error(
            asErrorId<UnavailableLegalConditions>(1),
            error
        )
        object MissingLegalConditions: Error(
            asErrorId<MissingLegalConditions>(2)
        )
        object LegalModuleDisabled : Error(
            asErrorId<LegalModuleDisabled>(3)
        )
    }

    private val layoutService: LayoutService by diContainer.instance()
    private val authService: AuthService by diContainer.instance()
    private val userService: UserService by diContainer.instance()
    private val configModule: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<LegalConditions, Error>
        get() = {
            var userLanguage : String = language ?: configModule.getCoreConfig().signup.defaultLanguage
            val auth = authService.getStoredAuth()
            if (auth != null) {
                val user = userService.getUser(auth.username)
                    .mapError {
                        Error.UnavailableLegalConditions(it)
                    }.getOrAbort()
                userLanguage = user.preferredLanguage
            }
            layoutService.getLegal(version)
                .map {
                    LegalConditions(
                        it.text.forLanguage(userLanguage),
                        userLanguage
                    )
                }
                .mapError {
                    when (it) {
                        LayoutService.GetLegalError.MissingLegalText -> Error.MissingLegalConditions
                        is LayoutService.GetLegalError.UnavailableLegalText -> Error.UnavailableLegalConditions(it.networkError)
                    }
                }
        }
}

internal class AcceptLatestLegalConditions(private val isDataCollectionAllowed: Boolean) :
    OutcomeUseCase<Unit, AcceptLatestLegalConditions.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.LEGAL, UseCaseId.ACCEPT_LATEST_LEGAL_CONDITIONS, errorId, networkError) {
        data class UnavailableLegalConditions(val error: NetworkError) : Error(
            asErrorId<UnavailableLegalConditions>(1),
            error
        )
        data class UnavailableLegalConditionSign(val error: NetworkError) : Error(
            asErrorId<UnavailableLegalConditionSign>(2),
            error
        )
        object LegalModuleDisabled : Error(
            asErrorId<LegalModuleDisabled>(3)
        )
    }

    private val layoutService: LayoutService by diContainer.instance()
    private val userService: UserService by diContainer.instance()
    private val configModule: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Unit, Error>
        get() = {
            val legalEnabled = configModule.getCoreConfig().legal.enabled
            if (!legalEnabled) {
                failure(Error.LegalModuleDisabled)
            } else {
                var userProfile = configModule.getCoreConfig().signup.defaultProfile
                var userLanguage : String = configModule.getCoreConfig().signup.defaultLanguage
                val auth = GetStoredAuth().invoke()
                if (auth != null) {
                    val user = userService.getUser(auth.username)
                        .mapError {
                            Error.UnavailableLegalConditions(it)
                        }.getOrAbort()
                    userProfile = user.profile
                    userLanguage = user.preferredLanguage
                }
                val legal = layoutService.getLayoutConfig(userProfile, userLanguage)
                    .mapError {
                        Error.UnavailableLegalConditions(it)
                    }.getOrAbort()
                    .legal
                // If default user sign legal in backend, if guest sign locally
                if (auth != null) {
                    userService.signLegal(auth.username, legal.version, isDataCollectionAllowed)
                        .mapError {
                            Error.UnavailableLegalConditionSign(it)
                        }
                } else {
                    layoutService.signLegalAsGuest(legal.version, isDataCollectionAllowed)
                    success()
                }
            }
        }
}
package es.cinfo.tiivii.core.modules.product

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.modules.product.model.ProductModel.Model.*
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.core.util.map
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

internal interface ProductService {

    suspend fun getProducts(): Outcome<List<Product>, NetworkError>

    suspend fun getUserProducts(username: String): Outcome<ProductsLoad, NetworkError>

    suspend fun getProductCheckout(id: Int, username: String, successUrl: String, cancelUrl: String, language: String): Outcome<ProductCheckout, NetworkError>

    suspend fun getProductsDashboard(username: String, returnUrl: String, language: String): Outcome<ProductsDashboard, NetworkError>
}

internal class DefaultProductService : ProductService {
    private val productApi: ProductApi by diContainer.instance()

    override suspend fun getProducts(): Outcome<List<Product>, NetworkError> {
        return productApi.getProducts().map { it.toModel() }
    }

    override suspend fun getUserProducts(username: String): Outcome<ProductsLoad, NetworkError> {
        return productApi.getUserProducts(username).map { it.toModel() }
    }

    override suspend fun getProductCheckout(id: Int, username: String, successUrl: String, cancelUrl: String, language: String): Outcome<ProductCheckout, NetworkError> {
        return productApi.getProductCheckout(id, username, successUrl, cancelUrl, language).map { it.toModel() }
    }

    override suspend fun getProductsDashboard(
        username: String,
        returnUrl: String,
        language: String
    ): Outcome<ProductsDashboard, NetworkError> {
        return productApi.getProductsDashboard(username, returnUrl, language).map { it.toModel() }
    }

}

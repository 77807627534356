package es.cinfo.tiivii.core.modules.auth

import es.cinfo.tiivii.core.features.user.login.LoginModel.Model.Login
import es.cinfo.tiivii.core.modules.auth.model.AuthModel.Model.Auth
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.core.util.map
import es.cinfo.tiivii.core.util.onError
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

/**
 * Service exposing authentication data and related operations
 */
internal interface AuthService {

    /**
     * Obtains the stored auth data available on disk and caches it on memory (if available)
     */
    suspend fun getStoredAuth(): Auth?

    fun getStoredAuthSync(): Auth?

    /**
     * Stores the new auth data on disk and updates the cache
     */
    suspend fun storeAuth(auth: Auth)

    /**
     * Requests and update of the auth data to backend using the previous values and stores the new ones
     */
    suspend fun refreshAuth(
        currentAuth: Auth
    ): Outcome<Auth, NetworkError>

    /**
     * Retrieves the username from keycloak for the given access token
     * @param token access token received from authentication
     * @param userInfoEndpoint keycloak userinfo endpoint to retrieve the username
     * @return username string associated with the given token
     */
    suspend fun getUsernameFromToken(token: String, userInfoEndpoint: String): Outcome<String, NetworkError>

    /**
     * Clears the local and memory stored auth info
     */
    suspend fun clearAuth()

    /**
     * Requests the backend the logout of the current session if any
     */
    suspend fun requestLogout()

    /**
     * Request a login even to the backend with the given information
     * @param username for the login
     * @param password for the login
     * @param clientId of keycloak to use
     */
    suspend fun login(username: String, password: String, clientId: String): Outcome<Login, NetworkError>

}

/**
 * Default implementation of [AuthService]
 *
 * This implementation caches the disk values for faster future access
 */
internal class DefaultAuthService : AuthService {
    private val authStorage: AuthStorage by diContainer.instance()
    private val authApi: AuthApi by diContainer.instance()

    private var auth: Auth? = null

    override suspend fun getStoredAuth(): Auth? {
        val value = authStorage.getAuth()
        if (value != null) {
            auth = value
        }
        return auth
    }

    override fun getStoredAuthSync(): Auth? {
        val value = authStorage.getAuthSync()
        if (value != null) {
            auth = value
        }
        return auth
    }

    override suspend fun storeAuth(auth: Auth) {
        authStorage.storeAuth(auth)
        this.auth = auth
    }

    override suspend fun refreshAuth(
        currentAuth: Auth
    ): Outcome<Auth, NetworkError> {
        return authApi
            .refreshAuthData(currentAuth)
            .map {
                val authResponse = it.apiToModel(
                    currentAuth.params.clientId,
                    currentAuth.params.realm,
                    currentAuth.params.baseUri,
                    currentAuth.params.tokenUri,
                    currentAuth.params.userInfoUri
                )
                val updatedAuth = currentAuth.copy(params = authResponse)
                storeAuth(updatedAuth)
                updatedAuth
            }
            .onError {
                clearAuth()
            }
    }

    override suspend fun getUsernameFromToken(token: String, userInfoEndpoint: String): Outcome<String, NetworkError> {
        return authApi.getUsernameFromToken(token, userInfoEndpoint)
    }

    override suspend fun clearAuth() {
        if (auth != null) {
            authStorage.clearAuth()
            auth = null
        }
    }

    override suspend fun requestLogout() {
        if (auth != null) {
            authApi.logout(auth!!.params.refreshToken, auth!!.params.clientId)
            authStorage.clearAuth()
            auth = null
        }
    }

    override suspend fun login(username: String, password: String, clientId: String): Outcome<Login, NetworkError> {
        return authApi.login(username, password, clientId).map { it.toModel() }
    }

}
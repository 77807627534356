package es.cinfo.tiivii.core.features.detail.model

import es.cinfo.tiivii.core.modules.product.model.ProductModel
import es.cinfo.tiivii.core.modules.video.model.VideoModel

sealed class DetailModel {
    sealed class ViewModel {
        /**
         * Represents a content purchase information
         * @param hasFreeVersion true if the content can be played for free
         * @param purchasableProducts list of products that can be purchase to enhance the free version of the product
         */
        data class ContentPurchaseInfo(
            val hasFreeVersion: Boolean,
            val purchasableProducts: List<ProductModel.ViewModel.Product>
        )
    }
    internal sealed class Model {
        data class ContentPurchaseInfo(
            val hasFreeVersion: Boolean,
            val purchasableProducts: List<ProductModel.Model.Product>
        ) {
            fun toViewModel(): ViewModel.ContentPurchaseInfo =
                ViewModel.ContentPurchaseInfo(
                    hasFreeVersion,
                    purchasableProducts.map { it.toViewModel() }
                )
        }
        data class VodCheck(
            val smil: String?,
            val adminTiiviiUrl: String?,
            val nimbleUrls: List<VideoModel.Model.Video>
        ) {
            fun isVideoUrlAvailable(): Boolean = smil != null || adminTiiviiUrl != null || nimbleUrls.isNotEmpty()
        }
    }
}

package es.cinfo.tiivii.user.splash.usecase

import es.cinfo.tiivii.core.ComponentId
import es.cinfo.tiivii.core.ErrorId
import es.cinfo.tiivii.core.UseCaseId
import es.cinfo.tiivii.core.error.CodedError
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.error.asErrorId
import es.cinfo.tiivii.core.modules.analytics.LogEvent
import es.cinfo.tiivii.core.modules.analytics.model.AnalyticsModel.Action
import es.cinfo.tiivii.core.modules.auth.AuthService
import es.cinfo.tiivii.core.modules.auth.model.AuthModel.Model.Auth
import es.cinfo.tiivii.core.layout.LayoutService
import es.cinfo.tiivii.core.layout.model.LegalSign
import es.cinfo.tiivii.core.layout.model.loginclient.Model.LoginClient
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.user.UserService
import es.cinfo.tiivii.core.util.*
import es.cinfo.tiivii.di.diContainer
import es.cinfo.tiivii.nav.menu.usecase.GetAppLink
import es.cinfo.tiivii.user.screen.ScreenModel.Model.Screen
import org.kodein.di.instance

/**
 * Retrieves the next screen based on the current app data available
 *
 * This will retrieve the base app configuration from the backend, check login and legal conditions
 * and return the [Screen] that should be routed upon completion
 *
 * @return [Screen] to be routed by the interface upon completion
 */
internal class GetFirstScreen : OutcomeUseCase<Screen, GetFirstScreen.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.SPLASH, UseCaseId.GET_FIRST_SCREEN, errorId, networkError) {
        data class LegalCheckUnavailable(val reason: String) : Error(
            asErrorId<LegalCheckUnavailable>(1)
        )
        object UnknownLoginClient : Error(
            asErrorId<UnknownLoginClient>(2)
        )
        data class UnavailableLayoutConfig(val error: NetworkError) : Error(
            asErrorId<UnavailableLayoutConfig>(3),
            error
        )
        object UnavailableAppLink : Error(
            asErrorId<UnavailableAppLink>(4)
        )
    }

    private val layoutService: LayoutService by diContainer.instance()
    private val configModule: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Screen, Error>
        get() = {
            val storedAuth = GetStoredAuth().invoke()
            val defaultProfile = configModule.getCoreConfig().signup.defaultProfile
            val defaultLanguage = configModule.getCoreConfig().signup.defaultLanguage
            val appLink = GetAppLink().invoke()
                .mapError { Error.UnavailableAppLink }.getOrAbort()
            val appLinks = if (appLink != null) {
                Screen.AppLinks(appLink, appLink)
            } else {
                null
            }
            val styleConfig = layoutService.getStyleConfig(defaultProfile, defaultLanguage)
                .mapError { Error.UnavailableLayoutConfig(it) }.getOrAbort()
            val loginClient = GetLogin().invoke()
                .mapError { Error.UnknownLoginClient }.getOrAbort()
            if (storedAuth == null) {
                success(Screen.Login(
                    Screen.KeycloakParams(
                        confUri = loginClient.confUri,
                        baseUri = loginClient.baseUri,
                        realm = loginClient.realm,
                        clientId = loginClient.clientId
                    ),
                    appLinks = appLinks,
                    styles = styleConfig
                ))
            } else {
                when (val refreshedAuth = RefreshAuth(storedAuth).invoke()) {
                    is Success -> {
                        val legalEnabled = configModule.getCoreConfig().legal.enabled
                        if (legalEnabled) {
                            val isLastLegalVersionSigned = IsLastLegalVersionSigned(refreshedAuth.value.username).invoke()
                                .mapError { Error.LegalCheckUnavailable(it.toString()) }
                                .getOrAbort()
                            if (!isLastLegalVersionSigned) {
                                success(
                                    Screen.Legal(
                                        Screen.KeycloakParams(
                                            confUri = loginClient.confUri,
                                            baseUri = loginClient.baseUri,
                                            realm = loginClient.realm,
                                            clientId = loginClient.clientId
                                        ),
                                        styles = styleConfig,
                                        appLinks = appLinks,
                                        auth = Screen.Auth(
                                            storedAuth.params.accessToken,
                                            storedAuth.params.refreshToken
                                        )
                                    )
                                )
                            } else {
                                success(
                                    Screen.Home(
                                        Screen.KeycloakParams(
                                            confUri = loginClient.confUri,
                                            baseUri = loginClient.baseUri,
                                            realm = loginClient.realm,
                                            clientId = loginClient.clientId
                                        ),
                                        styles = styleConfig,
                                        appLinks = appLinks,
                                        auth = Screen.Auth(
                                            storedAuth.params.accessToken,
                                            storedAuth.params.refreshToken
                                        )
                                    )
                                )
                            }
                        } else {
                            success(
                                Screen.Home(
                                    Screen.KeycloakParams(
                                        confUri = loginClient.confUri,
                                        baseUri = loginClient.baseUri,
                                        realm = loginClient.realm,
                                        clientId = loginClient.clientId
                                    ),
                                    styles = styleConfig,
                                    appLinks = appLinks,
                                    auth = Screen.Auth(
                                        storedAuth.params.accessToken,
                                        storedAuth.params.refreshToken
                                    )
                                )
                            )
                        }
                    }
                    is Failure -> {
                        success(Screen.Login(
                            Screen.KeycloakParams(
                                confUri = loginClient.confUri,
                                baseUri = loginClient.baseUri,
                                realm = loginClient.realm,
                                clientId = loginClient.clientId
                            ),
                            appLinks = appLinks,
                            styles = styleConfig
                        ))
                    }
                }
            }
        }
}

internal class IsLastLegalVersionSigned(private val username: String? = null) :
    OutcomeUseCase<Boolean, IsLastLegalVersionSigned.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.SPLASH, UseCaseId.LAST_LEGAL_CONDITIONS_SIGNED, errorId, networkError) {
        data class UserUnavailable(val error: NetworkError) : Error(
            asErrorId<UserUnavailable>(1),
            error
        )
        data class UnavailableLegalConditions(val error: NetworkError) : Error(
            asErrorId<UnavailableLegalConditions>(2),
            error
        )
    }

    private val layoutService: LayoutService by diContainer.instance()
    private val userService: UserService by diContainer.instance()
    private val authService: AuthService by diContainer.instance()
    private val configModule: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Boolean, Error>
        get() = {
            var userProfile = configModule.getCoreConfig().signup.defaultProfile
            var userLanguage = configModule.getCoreConfig().signup.defaultLanguage
            val auth = authService.getStoredAuth()
            if (auth != null) {
                val user = userService.getUser(auth.username)
                    .mapError { Error.UserUnavailable(it) }.getOrAbort()
                userProfile = user.profile
                userLanguage = user.preferredLanguage
            }
            val lastAvailableLegalVersion = layoutService.getLayoutConfig(userProfile, userLanguage)
                .mapError { Error.UnavailableLegalConditions(it) }.getOrAbort()
                .legal.version
            // With no username we assume a guest login
            val lastSignedLegalVersion: LegalSign? = if (username == null) {
                layoutService.getSignedLegal()
            } else {
                val user = userService.getUser(username).mapError { Error.UserUnavailable(it) }.getOrAbort()
                LegalSign(user.signedLegalVersion)
            }
            if (lastSignedLegalVersion == null) {
                success(false)
            } else {
                success(lastAvailableLegalVersion <= lastSignedLegalVersion.version)
            }
        }
}

internal class GetStoredAuth : UseCase<Auth?> {
    private val authService: AuthService by diContainer.instance()

    override suspend fun invoke(): Auth? {
        return authService.getStoredAuth()
    }
}

internal class RefreshAuth(private val auth: Auth) : OutcomeUseCase<Auth, RefreshAuth.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.SPLASH, UseCaseId.REFRESH_AUTH, errorId, networkError) {
        data class RefreshFailed(val error: NetworkError) : Error(
            asErrorId<RefreshFailed>(1),
            error
        )
    }

    private val authService: AuthService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Auth, Error>
        get() = {
            authService.refreshAuth(auth)
                .mapError {
                    Error.RefreshFailed(it)
                }
        }
}

/**
 * Retrieves the current [Auth.Data]
 */
internal class GetToken : UseCase<Auth.Data> {
    private val authService: AuthService by diContainer.instance()

    override suspend fun invoke(): Auth.Data {
        val data = authService.getStoredAuth()
        return data?.params ?: Auth.Data("", "", "", "", "", "",  "")
    }
}

/**
 * Stores the given [Auth] on persistent memory
 */
internal class StoreAuth(private val authData: Auth.Data) : OutcomeUseCase<Auth, StoreAuth.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.SPLASH, UseCaseId.STORE_AUTH, errorId, networkError) {
        object Login : Error(
            asErrorId<Login>(1)
        )
        object Username : Error(
            asErrorId<Username>(2)
        )
    }

    private val authService: AuthService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Auth, Error>
        get() = {
            val username = authService.getUsernameFromToken(authData.accessToken, authData.userInfoUri)
                .mapError {
                    Error.Username
                }.getOrAbort()
            val auth = Auth(username, authData)
            authService.storeAuth(auth)
            success(auth)
        }
}

/**
 * Stores the given auth data and retrieves the next screen to be shown
 *
 * @return [Screen] to be routed by the interface upon completion
 */
internal class StoreAuthDataAndGetNextScreen(
    private val accessToken: String,
    private val refreshToken: String) :
    OutcomeUseCase<Screen, StoreAuthDataAndGetNextScreen.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.SPLASH, UseCaseId.STORE_AUTH_AND_NEXT_SCREEN, errorId, networkError) {
        object LegalCheckUnavailable : Error(
            asErrorId<LegalCheckUnavailable>(1)
        )
        data class UnavailableLayoutConfig(val error: NetworkError) : Error(
            asErrorId<UnavailableLayoutConfig>(2),
            error
        )
        object StoreAuthError : Error(
            asErrorId<StoreAuthError>(3),
        )
        object UnavailableAppLink : Error(
            asErrorId<UnavailableAppLink>(4),
        )
    }

    private val layoutService: LayoutService by diContainer.instance()
    private val userService: UserService by diContainer.instance()
    private val configModule: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Screen, Error>
        get() = {
            val loginClient = GetLogin().invoke()
                .mapError {
                    Error.StoreAuthError
                }.getOrAbort()
            val authData = Auth.Data(
                accessToken,
                refreshToken,
                loginClient.clientId,
                loginClient.realm,
                loginClient.baseUri,
                loginClient.tokenUri,
                loginClient.userInfoUri
            )
            val auth = StoreAuth(authData).invoke()
                .mapError {
                    Error.StoreAuthError
                }.getOrAbort()
            val user = userService.getUser(auth.username)
                .mapError { Error.UnavailableLayoutConfig(it) }.getOrAbort()
            val userProfile = user.profile
            val userLanguage = user.preferredLanguage
            val styleConfig = layoutService.getStyleConfig(userProfile, userLanguage)
                .mapError { Error.UnavailableLayoutConfig(it) }.getOrAbort()
            LogEvent(Action.Login).invoke()
            val legalEnabled = configModule.getCoreConfig().legal.enabled
            val appLink = GetAppLink().invoke()
                .mapError { Error.UnavailableAppLink }.getOrAbort()
            val appLinks = if (appLink != null) {
                Screen.AppLinks(appLink, appLink)
            } else {
                null
            }
            val nextScreen: Screen = if (legalEnabled) {
                val isLastLegalVersionSigned = IsLastLegalVersionSigned(auth.username).invoke()
                    .mapError { Error.LegalCheckUnavailable }.getOrAbort()
                if (!isLastLegalVersionSigned) {
                    Screen.Legal(
                        Screen.KeycloakParams(
                            confUri = loginClient.confUri,
                            baseUri = loginClient.baseUri,
                            realm = loginClient.realm,
                            clientId = loginClient.clientId
                        ),
                        styles = styleConfig,
                        appLinks = appLinks,
                        auth = Screen.Auth(
                            accessToken,
                            refreshToken
                        )
                    )
                } else {
                    Screen.Home(
                        Screen.KeycloakParams(
                            confUri = loginClient.confUri,
                            baseUri = loginClient.baseUri,
                            realm = loginClient.realm,
                            clientId = loginClient.clientId
                        ),
                        styles = styleConfig,
                        appLinks = appLinks,
                        auth = Screen.Auth(
                            accessToken,
                            refreshToken
                        )
                    )
                }
            } else {
                Screen.Home(
                    Screen.KeycloakParams(
                        confUri = loginClient.confUri,
                        baseUri = loginClient.baseUri,
                        realm = loginClient.realm,
                        clientId = loginClient.clientId
                    ),
                    styles = styleConfig,
                    appLinks = appLinks,
                    auth = Screen.Auth(
                        accessToken,
                        refreshToken
                    )
                )
            }
            success(nextScreen)
        }
}

///**
// * Retrieves the next [Screen] for the guest login
// *
// * @return [Screen] to be routed by the interface upon completion
// */
//internal class GetNextScreenForGuestLogin : OutcomeUseCase<Screen, GetNextScreenForGuestLogin.Error>() {
//    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
//        : CodedError(ComponentId.SPLASH, UseCaseId.NEXT_SCREEN_GUEST_LOGIN, errorId, networkError) {
//        object LegalCheckUnavailable : Error(
//            asErrorId<LegalCheckUnavailable>(1)
//        )
//        data class UnavailableLayoutConfig(val error: NetworkError) : Error(
//            asErrorId<UnavailableLayoutConfig>(1),
//            error
//        )
//    }
//
//    private val layoutService: LayoutService by diContainer.instance()
//    private val configModule: ConfigModule by diContainer.instance()
//
//    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Screen, Error>
//        get() = {
//            val defaultProfile = configModule.getCoreConfig().signup.defaultProfile
//            val defaultLanguage = configModule.getCoreConfig().signup.defaultLanguage
//            val styleConfig = layoutService.getStyleConfig(defaultProfile, defaultLanguage)
//                .mapError { Error.UnavailableLayoutConfig(it) }.getOrAbort()
//            val legalEnabled = configModule.getCoreConfig().legal.enabled
//            if (legalEnabled) {
//                val isLastLegalVersionSigned = IsLastLegalVersionSigned().invoke()
//                    .mapError { Error.LegalCheckUnavailable }.getOrAbort()
//                if (!isLastLegalVersionSigned) {
//                    success(Screen.Legal(styles = styleConfig))
//                } else {
//                    success(Screen.Home(styles = styleConfig))
//                }
//            } else {
//                success(Screen.Home(styles = styleConfig))
//            }
//        }
//}

internal expect val PLATFORM_CLIENT_ID: String
internal expect val PLATFORM_CLIENT_ID_SUFFIX: String

/**
 * Retrieves the first available [LoginClient] for the app to use
 *
 * This is a platform specific implementation since each implementation needs to
 * retrieve different [LoginClient]
 */
internal class GetLogin : OutcomeUseCase<LoginClient, GetLogin.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.SPLASH, UseCaseId.GET_LOGIN_CLIENT, errorId, networkError) {
        object UnknownLoginClient : Error(
            asErrorId<UnknownLoginClient>(1)
        )
    }

    private val configModule: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<LoginClient, Error>
        get() = {
            try {
                success(
                    LoginClient(
                        confUri = configModule.getEnvConfig().keycloakConfUri,
                        tokenUri = configModule.getEnvConfig().keycloakTokenUri,
                        userInfoUri = configModule.getEnvConfig().keycloakUserInfoUri,
                        logoutUri = configModule.getEnvConfig().keycloakLogoutUri,
                        baseUri = configModule.getEnvConfig().keycloakBaseUri,
                        realm = configModule.getEnvConfig().realm,
                        clientId = PLATFORM_CLIENT_ID
                    )
                )
            } catch (e: NoSuchElementException) {
                failure(Error.UnknownLoginClient)
            }
        }

}
package es.cinfo.tiivii.nav.menu.usecase

import es.cinfo.tiivii.core.ComponentId
import es.cinfo.tiivii.core.ErrorId
import es.cinfo.tiivii.core.UseCaseId
import es.cinfo.tiivii.core.error.CodedError
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.error.asErrorId
import es.cinfo.tiivii.core.layout.LayoutService
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.usecase.GetCurrentUser
import es.cinfo.tiivii.core.util.*
import es.cinfo.tiivii.di.diContainer
import es.cinfo.tiivii.nav.menu.model.MenuModel
import org.kodein.di.instance
import es.cinfo.tiivii.core.modules.auth.AuthService
import es.cinfo.tiivii.core.modules.platform.PLATFORM_ID
import es.cinfo.tiivii.core.modules.platform.PlatformModel
import es.cinfo.tiivii.core.modules.share.ShareService
import es.cinfo.tiivii.core.user.UserService
import es.cinfo.tiivii.user.legal.usecase.GetLegalConditionsVersion

/**
 * Retrieves the default language for the app. If the user has logged in the preferred
 * user language will be used
 */
internal class GetUiLanguage : OutcomeUseCase<String, GetUiLanguage.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.MENU, UseCaseId.GET_UI_LANGUAGE, errorId, networkError)

    val configModule: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<String, Error>
        get() = {
            when (val outcome = GetCurrentUser().invokeWith(ComponentId.MENU)) {
                is Success -> success(outcome.value.user.preferredLanguage)
                is Failure -> {
                    success(configModule.getCoreConfig().signup.defaultLanguage)
                }
            }
        }
}

internal class GetDeclarations : OutcomeUseCase<MenuModel.Model.Declarations, GetDeclarations.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.MENU, UseCaseId.GET_DECLARATIONS, errorId, networkError) {
            object UnavailableLayoutConfig : Error(
                asErrorId<UnavailableLayoutConfig>(1)
            )
        }

    val configModule: ConfigModule by diContainer.instance()
    val layoutService: LayoutService by diContainer.instance()
    val authService: AuthService by diContainer.instance()
    val userService: UserService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<MenuModel.Model.Declarations, Error>
        get() = {
            val authData = authService.getStoredAuth()
            var profile = configModule.getCoreConfig().signup.defaultProfile
            var language = configModule.getCoreConfig().signup.defaultLanguage
            var signedLegalText: String? = null
            if (authData != null) {
                val user = userService.getUser(authData.username).getOrNull()
                if (user != null) {
                    signedLegalText = GetLegalConditionsVersion(user.signedLegalVersion, language).invoke()
                        .getOrNull()?.text
                    profile = user.profile
                    language = user.preferredLanguage
                }
            }
            val layoutConfig = layoutService.getLayoutConfig(profile, language)
                .mapError {
                    Error.UnavailableLayoutConfig
                }.getOrAbort()
            success(
                MenuModel.Model.Declarations(
                    privacyStatementUrl = layoutConfig.privacyStatementUrl?.forLanguage(language),
                    termsAndConditionsUrl = layoutConfig.termsAndConditionsUrl?.forLanguage(language),
                    legalText = signedLegalText
                )
            )
        }
}

internal class GetConfigValues : OutcomeUseCase<MenuModel.Model.Config, GetConfigValues.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.MENU, UseCaseId.GET_CONFIG_VALUES, errorId, networkError)

    val configModule: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<MenuModel.Model.Config, Error>
        get() = {
            val config = MenuModel.Model.Config(
                faqUrl = configModule.getCoreConfig().support.faqUrl,
                supportEmail = configModule.getCoreConfig().support.supportEmail,
                tutorialUrl = configModule.getCoreConfig().support.tutorialUrl
            )
            success(config)
        }
}

internal class GetAppLink : OutcomeUseCase<String?, GetAppLink.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.MENU, UseCaseId.GET_APP_LINK, errorId, networkError) {
            data class UnavailableLinkError(val error: NetworkError) : Error(
                asErrorId<UnavailableLinkError>(1),
                error
            )
        }

    private val shareService: ShareService by diContainer.instance()
    private val configModule: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<String?, Error>
        get() = {
            if (PLATFORM_ID == PlatformModel.Model.Platform.WEB && configModule.getCoreConfig().support.showAppsUrls) {
                shareService.getAppLink()
                    .mapError {
                        Error.UnavailableLinkError(it)
                    }
            } else {
                success(null)
            }
        }
}
package es.cinfo.tiivii.core.util

object Interops {
    @JsName("toKotlinList")
    fun <TValue> toKotlinList(array: Array<TValue>) = array.toList()

    @JsName("toKotlinSet")
    fun <TValue> toKotlinSet(array: Array<TValue>) = array.toSet()

    @JsName("getKotlinName")
    fun <TValue> getKotlinName(value: TValue): String?  {
        return if (value == null) {
            null
        } else {
            value!!::class.simpleName
        }
    }

    @JsName("toJsArray")
    fun <TValue> toJsArray(list: List<TValue>): Array<TValue> = list.toTypedArray()
}
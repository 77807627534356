package es.cinfo.tiivii.core.util

import com.arkivanov.mvikotlin.core.lifecycle.Lifecycle
import com.arkivanov.mvikotlin.core.view.BaseMviView
import com.arkivanov.mvikotlin.core.view.ViewRenderer

private interface OutputView<Output : Any> {
    fun handleOutput(output: Output)
}

/**
 * View component that will receive updates and notifications from a store
 *
 * This component is to be used by some UI implementation to access updates from a related store. This updates
 * can come in the form of model updates through [modelUpdater] function
 * (or a renderer object can be used with the [modelRenderer] property) or one-time notification labels through
 * [outputHandler] function. Both functions may be omitted meaning that the UI component will not expect
 * either model updates or one-time notification labels
 */
class View<Event : Any, Model : Any, Output : Any>(
    private val outputHandler: (Output) -> Unit
) :
    BaseMviView<Model, Event>(), OutputView<Output> {
    private var modelUpdater: ((Model) -> Unit)? = null
    private var modelRenderer: ViewRenderer<Model>? = null
    var currentModel: Model? = null

    constructor(modelUpdater: ((Model) -> Unit), outputHandler: (Output) -> Unit) :
            this(outputHandler) {
        this.modelUpdater = modelUpdater
    }

    constructor(modelRenderer: ViewRenderer<Model>, outputHandler: (Output) -> Unit) :
            this(outputHandler) {
        this.modelRenderer = modelRenderer
    }

    override val renderer: ViewRenderer<Model>?
        get() = modelRenderer

    override fun render(model: Model) {
        currentModel = model
        super.render(model)
        modelUpdater?.invoke(model)
    }

    override fun handleOutput(output: Output) {
        outputHandler.invoke(output)
    }

}

/**
 * Binder between a view and its store
 *
 * Any implementation of this component is in charge of the binding between the view and its related
 * store and must implement operations such as store creation, destruction, binding and unbinding
 */
interface ViewBinder<Event : Any, Model : Any, Output : Any> {
    /**
     * Binds the given [View] with its store with the given [Lifecycle]
     * @param view to bind with its store
     * @param viewLifecycle lifecycle of the view to which the bind will occur
     */
    fun onBindReady(view: View<Event, Model, Output>, viewLifecycle: Lifecycle)
}
package es.cinfo.tiivii.core.user

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.interest.model.Model
import es.cinfo.tiivii.core.modules.avatar.model.AvatarModel
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.network.HttpModule
import es.cinfo.tiivii.core.user.model.ApiResponse.User
import es.cinfo.tiivii.core.user.model.CreateTutoredUserApiRequest
import es.cinfo.tiivii.core.user.model.CreateUserApiRequest
import es.cinfo.tiivii.core.user.model.UpdateUserApiRequest
import es.cinfo.tiivii.core.user.model.UpdateUserCredentialsApiRequest
import es.cinfo.tiivii.core.util.*
import es.cinfo.tiivii.di.diContainer
import io.ktor.client.request.*
import io.ktor.http.*
import org.kodein.di.instance

/**
 * Network datasource for user data and operations
 */
internal interface UserApi {

    /**
     * Requests to backend the user data for the given username
     * @param username login username to retrieve the data for
     * @return A [User] with all the data available
     */
    suspend fun getUser(username: String): Outcome<User, NetworkError>

    /**
     * Requests to backend the creation of a new user with the given values
     */
    suspend fun createUser(
        language: String,
        legalVersion: Int,
        username: String,
        firstName: String,
        lastName: String,
        credentials: String,
        email: String,
        hasTutor: Boolean,
        birthday: String,
        interests: Set<Model.Interest>,
        avatar: AvatarModel.Model.Avatar,
    ): Outcome<Boolean, NetworkError>

    /**
     * Retrieves a random username
     */
    suspend fun getRandomUsername(): Outcome<String, NetworkError>

    /**
     * Updates the information of a previously registered user
     */
    suspend fun updateUser(
        username: String,
        interests: Set<Int>? = null,
        language: String? = null,
        avatar: AvatarModel.Model.Avatar? = null,
        firstName: String? = null,
        lastName: String? = null,
        legalVersion: Int? = null,
    ): Outcome<User, NetworkError>

    suspend fun updateUserCredentials(
        username: String,
        oldCredentials: String,
        credentials: String,
    ): Outcome<Unit, NetworkError>

    /**
     * Updates the latest legal conditions signed for the given user
     */
    suspend fun updateLegal(
        username: String,
        legalVersion: Int,
        isDataCollectionAllowed: Boolean,
    ): Outcome<Unit, NetworkError>

    /**
     * Checks against backend if a user with the given email exists already
     * @return true if it exists, false otherwise
     */
    suspend fun existsUserWithEmail(email: String): Outcome<Boolean, NetworkError>

    /**
     * Checks against backend if a user with the given username exists already
     * @return true if it exists, false otherwise
     */
    suspend fun existsUserWithUsername(username: String): Outcome<Boolean, NetworkError>

    /**
     * Requests the content to be added to the user's favorite lists
     * @param id of the content
     * @param username to add the content to favorites
     * @return List of the updated favorites of the user
     */
    suspend fun addContentToFavorites(id: Int, username: String): Outcome<List<String>, NetworkError>

    /**
     * Requests the content to be removed from the user's favorite lists
     * @param id of the content
     * @param username to add the content to favorites
     * @return List of the updated favorites of the user
     */
    suspend fun removeContentFromFavorites(id: Int, username: String): Outcome<List<String>, NetworkError>

    suspend fun resetUserPassword(user: String): Outcome<Unit, NetworkError>

    suspend fun deleteAccount(username: String): Outcome<Unit, NetworkError>

    suspend fun publishContent(payload: String): Outcome<Unit, NetworkError>
}

/**
 * Default implementation of [UserApi] using Ktor
 */
internal class DefaultUserApi : UserApi {
    private val http: HttpModule by diContainer.instance()
    private val configModule: ConfigModule by diContainer.instance()

    private val baseEndpoint: String by lazy {
        "${configModule.getEnvConfig().backendUrl}/iden/users/${configModule.getEnvConfig().apiName}"
    }

    override suspend fun getUser(username: String): Outcome<User, NetworkError> {
        val lowerCaseUsername = username.lowercase()
        val endpoint = "$baseEndpoint/user/$lowerCaseUsername"
        return http.getAsOutcome(endpoint = endpoint) {
            header(HttpHeaders.CacheControl, "no-cache")
        }
    }

    override suspend fun createUser(
        language: String,
        legalVersion: Int,
        username: String,
        firstName: String,
        lastName: String,
        credentials: String,
        email: String,
        hasTutor: Boolean,
        birthday: String,
        interests: Set<Model.Interest>,
        avatar: AvatarModel.Model.Avatar,
    ): Outcome<Boolean, NetworkError> {
        val lowerCaseUsername = username.lowercase()
        val endpoint = "$baseEndpoint/user"
        val outcome = http.postAsHttpOutcome(endpoint = endpoint) {
            header("Content-Type", "application/json")
            val hash = "${configModule.getEnvConfig().secret}$lowerCaseUsername$credentials".md5()
            body = if (hasTutor) {
                CreateTutoredUserApiRequest(
                    language, legalVersion, lowerCaseUsername, firstName, lastName, credentials,
                    email, birthday, interests.mapToSet { it.id }, avatar.id, hash
                )
            } else {
                CreateUserApiRequest(
                    language, legalVersion, lowerCaseUsername, firstName, lastName, credentials,
                    email, birthday, interests.mapToSet { it.id }, avatar.id, hash
                )
            }
        }
        return when (outcome) {
            is Success -> when (outcome.value.status) {
                HttpStatusCode.OK, HttpStatusCode.Created -> success(true)
                else -> failure(NetworkError.Http(
                    statusCode = outcome.value.status.value,
                    method = "POST",
                    url = endpoint,
                    encapsulated = false
                ))
            }
            is Failure -> outcome
        }
    }

    override suspend fun getRandomUsername(): Outcome<String, NetworkError> {
        val endpoint = "$baseEndpoint/generateusername"
        return http.getAsOutcome(endpoint = endpoint, responseField = "username") {
            header("Cache-Control", "no-cache")
        }
    }

    override suspend fun updateUser(
        username: String,
        interests: Set<Int>?,
        language: String?,
        avatar: AvatarModel.Model.Avatar?,
        firstName: String?,
        lastName: String?,
        legalVersion: Int?,
    ): Outcome<User, NetworkError> {
        val lowerCaseUsername = username.lowercase()
        val endpoint = "$baseEndpoint/user/$lowerCaseUsername"
        return http.patchAsOutcome(endpoint = endpoint) {
            header("Content-Type", "application/json")
            body = UpdateUserApiRequest(
                language, firstName, lastName,
                interests?.mapToSet { it }, avatar?.id, legalVersion
            )
        }
    }

    override suspend fun updateUserCredentials(
        username: String,
        oldCredentials: String,
        credentials: String): Outcome<Unit, NetworkError> {
        val lowerCaseUsername = username.lowercase()
        val endpoint = "$baseEndpoint/user-pass/$lowerCaseUsername"
        return http.patchOrError(endpoint = endpoint) {
            header("Content-Type", "application/json")
            body = UpdateUserCredentialsApiRequest(
                oldCredentials, credentials, credentials
            )
        }
    }

    override suspend fun updateLegal(
        username: String,
        legalVersion: Int,
        isDataCollectionAllowed: Boolean,
    ): Outcome<Unit, NetworkError> {
        return updateUser(username = username, legalVersion = legalVersion)
            .map { success() }
    }

    override suspend fun existsUserWithEmail(email: String): Outcome<Boolean, NetworkError> {
        val endpoint = "$baseEndpoint/checkbyemail?value=$email"
        return when (val outcome = http.getOrError(endpoint = endpoint)) {
            is Success -> success(true)
            is Failure ->
                return if (outcome.error is NetworkError.Http && outcome.error.statusCode == 404) {
                    success(false)
                } else {
                    outcome
                }
        }
    }

    override suspend fun existsUserWithUsername(username: String): Outcome<Boolean, NetworkError> {
        val lowerCaseUsername = username.lowercase()
        val endpoint = "$baseEndpoint/checkbyusername?value=$lowerCaseUsername"
        return when (val outcome = http.getOrError(endpoint = endpoint)) {
            is Success -> success(true)
            is Failure ->
                return if (outcome.error is NetworkError.Http && outcome.error.statusCode == 404) {
                    success(false)
                } else {
                    outcome
                }
        }
    }

    override suspend fun addContentToFavorites(id: Int, username: String): Outcome<List<String>, NetworkError> {
        val lowerCaseUsername = username.lowercase()
        val endpoint = "$baseEndpoint/user/$lowerCaseUsername/favorites?contentid=$id"
        return http.postAsOutcome<User>(endpoint = endpoint, responseField = "data")
            .map { it.favorites ?: emptyList() }
    }

    override suspend fun removeContentFromFavorites(id: Int, username: String): Outcome<List<String>, NetworkError> {
        val lowerCaseUsername = username.lowercase()
        val endpoint = "$baseEndpoint/user/$lowerCaseUsername/favorites?contentid=$id"
        return http.deleteAsOutcome<User>(endpoint = endpoint)
            .map { it.favorites ?: emptyList() }
    }

    override suspend fun resetUserPassword(user: String): Outcome<Unit, NetworkError> {
        val lowerCaseUser = user.lowercase()
        val endpoint = "$baseEndpoint/reset-pass/$lowerCaseUser"
        return http.putAsOutcome(endpoint = endpoint)
    }

    override suspend fun deleteAccount(username: String): Outcome<Unit, NetworkError> {
        val lowerCaseUser = username.lowercase()
        val endpoint = "${configModule.getEnvConfig().backendUrl}/iden/admin/users/${configModule.getEnvConfig().apiName}/user/$lowerCaseUser"
        return http.deleteAsOutcome(endpoint = endpoint)
    }

    override suspend fun publishContent(payload: String): Outcome<Unit, NetworkError> {
        val endpoint = "${configModule.getEnvConfig().backendUrl}/sgca/${configModule.getEnvConfig().apiName}/contents-confirm/"
        return http.patchOrError(endpoint = endpoint)
    }
}
package es.cinfo.tiivii.core.content

import es.cinfo.tiivii.core.content.ContentService.Companion.defaultContentSort
import es.cinfo.tiivii.core.content.model.CommentModel.Model.Comment
import es.cinfo.tiivii.core.content.model.CommentModel.Model.Comments
import es.cinfo.tiivii.core.content.model.Model.Content
import es.cinfo.tiivii.core.content.model.Model.CachedContentStatus
import es.cinfo.tiivii.core.content.model.NextContentModel.Model.CachedNextContent
import es.cinfo.tiivii.core.content.model.NextContentModel.Model.NextContent
import es.cinfo.tiivii.core.content.model.RelatedContentModel.Model.RelatedContent
import es.cinfo.tiivii.core.content.model.RelatedContentModel.Model.CachedRelatedContent
import es.cinfo.tiivii.core.content.model.SerialContentModel.Model.SerialContentLoad
import es.cinfo.tiivii.core.content.model.WidgetModel.Model.WidgetContentApiLoad
import es.cinfo.tiivii.core.content.model.report.ContentReportModel.Model.ContentReport
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.sorting.SortModel
import es.cinfo.tiivii.core.user.model.Model.User
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.core.util.TimedCache
import es.cinfo.tiivii.core.util.map
import es.cinfo.tiivii.core.util.success
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

internal interface ContentService {

    companion object {
        // TODO: Add to configuration service
        const val DEFAULT_COMMENT_LIMIT = 5
        const val DEFAULT_COMMENT_SORT = "-date"
        const val DEFAULT_CONTENT_LIMIT = 25
        val defaultContentSort = SortModel.Model.Sort.mostRecentFirst
    }

    /**
     * Gets the [CachedContentStatus] of an item
     */
    suspend fun getCachedContentStatus(id: Int): CachedContentStatus

    /**
     * Retrieves the [Content] related to the given id
     * @param id of the content to retrieve
     * @param language for translatable values
     */
    suspend fun getContent(id: Int, payload: String? = null, hitCache: Boolean = true, language: String, username: String?): Outcome<Content, NetworkError>

    suspend fun getNextContent(id: Int, hitCache: Boolean = true, filters: Map<String, String>? = null, ignoreList: Set<Int>? = null): Outcome<NextContent, NetworkError>

    /**
     * Retrieves related [Content] with the given category and tags
     * @param categoryId to which the contents should be related
     * @param tags optional to which the contents should be related
     * @param filters optional filters for the backend petition
     * @param shuffleContent indicates if the content received should be shuffled
     */
    suspend fun getRelatedContents(
        contentId: Int,
        categoryId: Int,
        tags: List<String>?,
        filters: Map<String, String>?,
        shuffleContent: Boolean
    ): Outcome<List<RelatedContent>, NetworkError>

    /**
     * Retrieves serial [Content] related to the one with the given id
     * @param id of the [Content] to retrieve children/siblings of
     */
    suspend fun getSerialContents(
        id: Int,
        filters: Map<String, String>? = null,
        limit: Int = DEFAULT_CONTENT_LIMIT,
        page: Int,
        sort: String? = defaultContentSort.param
    ): Outcome<SerialContentLoad, NetworkError>

    /**
     * Retrieves the user comments associated with the given content
     * @param contentId id of the content to retrieve the comments of
     * @param limit number of comments to retrieve (default: 10)
     * @param page comment page to retrieve them from
     * @param sort sort method (default: by date descending)
     */
    suspend fun getComments(
        contentId: Int,
        limit: Int = DEFAULT_COMMENT_LIMIT,
        page: Int,
        sort: String = DEFAULT_COMMENT_SORT,
    ): Outcome<Comments, NetworkError>

    /**
     * Requests a new rate for the given content to be registered
     * @param contentId id of the content to be rated
     * @param rating int between 1 and 5 representing the rating to be registered
     */
    suspend fun rateContent(contentId: Int, rating: Int): Outcome<List<User.Rating>, NetworkError>

    /**
     * Requests the user content rating to be deleted
     * @param contentId id of the content with the rating to be deleted
     */
    suspend fun deleteRating(contentId: Int): Outcome<List<User.Rating>, NetworkError>

    /**
     * Requests a new comment to be added to the related content
     * @param contentId id of the content to which a new comment should be added
     * @param text comment text to add
     */
    suspend fun addComment(contentId: Int, text: String): Outcome<Comment, NetworkError>

    /**
     * Request a comment text to be updated
     * @param id of the comment to be updated
     * @param text new comment
     */
    suspend fun updateComment(id: Int, text: String): Outcome<Comment, NetworkError>

    /**
     * Requests the given comment to be removed
     * @param id of the comment to remove
     */
    suspend fun removeComment(id: Int): Outcome<Unit, NetworkError>

    /**
     * Requests a content report
     * @param id of the [Content] to be reported
     * @param reportId report reason id
     * @param userMessage associated message from the user if any
     */
    suspend fun reportContent(id: Int, reportId: Int, userMessage: String? = null): Outcome<Unit, NetworkError>

    /**
     * Requests the contents of the widget with the given id
     * @param id of the widget
     * @param query to filter the contents with if needed
     * @param filters to apply to the request if needed
     * @param limit number of contents per page
     * @param page to retrieve
     * @param sort method to be used on the contents
     */
    suspend fun getWidgetContents(
        id: Int,
        query: String? = null,
        filters: Map<String, String>? = null,
        limit: Int = DEFAULT_CONTENT_LIMIT,
        page: Int,
        sort: String = defaultContentSort.param,
        username: String?,
        language: String
    ): Outcome<WidgetContentApiLoad, NetworkError>

    /**
     * Same as [getWidgetContents] but with no sort, page, limit or query params for home screen to allow
     * server side caching
     */
    suspend fun getHomeWidgetContents(
        id: Int,
        filters: Map<String, String>? = null,
        username: String?,
        language: String
    ): Outcome<WidgetContentApiLoad, NetworkError>

    suspend fun getHomeWidgetContents(
        ids: List<Int>,
        filters: Map<String, String>? = null,
        username: String?,
        language: String
    ): Outcome<List<WidgetContentApiLoad>, NetworkError>

    suspend fun getContentReports(language: String): Outcome<List<ContentReport>, NetworkError>

    suspend fun publishContent(payload: String): Outcome<Boolean, NetworkError>

}

internal class DefaultContentService : ContentService {
    private val contentApi: ContentApi by diContainer.instance()

    private val contentCache = TimedCache<Int, Content>()
    private val nextContentCache = TimedCache<CachedNextContent, NextContent>()
    private val relatedContentCache = TimedCache<CachedRelatedContent, List<RelatedContent>>()
    private lateinit var reportReasons: List<ContentReport>
    private lateinit var reportReasonsLanguage: String

    override suspend fun getCachedContentStatus(id: Int): CachedContentStatus {
        val content = contentCache.getIfNotExpired(id)
        return if (content != null) {
            CachedContentStatus.CACHED
        } else {
            if (contentCache.exist(id)) {
                CachedContentStatus.EXPIRED
            } else {
                CachedContentStatus.MISSING
            }
        }
    }

    override suspend fun getContent(id: Int, payload: String?, hitCache: Boolean, language: String, username: String?): Outcome<Content, NetworkError> {
        return contentCache.get(id, !hitCache,
            renewPredicate = { oldContent ->
                oldContent.language != language
            }, fallback = {
                if (username != null) {
                    contentApi.getContent(id, payload, hitCache).map { it.toModel(language) }
                } else {
                    contentApi.getAnonContent(id, payload, hitCache).map { it.toModel(language) }
                }
            })
    }

    override suspend fun getNextContent(id: Int, hitCache: Boolean, filters: Map<String, String>?, ignoreList: Set<Int>?): Outcome<NextContent, NetworkError> {
        val cachedNextContent = CachedNextContent(id, filters, ignoreList)
        return nextContentCache.get(cachedNextContent, !hitCache,
            fallback = {
                contentApi.getNextContent(id, filters, ignoreList)
                    .map { it.toModel() }
            })
    }

    override suspend fun getRelatedContents(
        contentId: Int,
        categoryId: Int,
        tags: List<String>?,
        filters: Map<String, String>?,
        shuffleContent: Boolean
    ): Outcome<List<RelatedContent>, NetworkError> {
        val cachedRelatedContent = CachedRelatedContent.from(categoryId, tags, filters)
        return relatedContentCache.get(cachedRelatedContent,
            fallback = {
                contentApi.getRelatedContents(contentId, categoryId, tags, filters).map { relatedContents ->
                    val mappedContents = relatedContents.map { it.toModel() }
                    if (shuffleContent) {
                        mappedContents.shuffled()
                    } else {
                        mappedContents
                    }
                }
            })
    }

    override suspend fun getSerialContents(
        id: Int,
        filters: Map<String, String>?,
        limit: Int,
        page: Int,
        sort: String?
    ): Outcome<SerialContentLoad, NetworkError> {
        return contentApi.getSerialContents(id, filters, limit, page, sort ?: defaultContentSort.param)
            .map { it.toModel(id) }
    }

    override suspend fun getComments(
        contentId: Int,
        limit: Int,
        page: Int,
        sort: String,
    ): Outcome<Comments, NetworkError> {
        return contentApi.getComments(contentId, limit, page, sort).map { it.toModel() }
    }

    override suspend fun rateContent(contentId: Int, rating: Int): Outcome<List<User.Rating>, NetworkError> {
        return contentApi.rateContent(contentId, rating).map { ratings -> ratings.map { it.toModel() } }
    }

    override suspend fun deleteRating(contentId: Int): Outcome<List<User.Rating>, NetworkError> {
        return contentApi.deleteRating(contentId).map { ratings -> ratings.map { it.toModel() } }
    }

    override suspend fun addComment(contentId: Int, text: String): Outcome<Comment, NetworkError> {
        return contentApi.addComment(contentId, text).map { it.toModel() }
    }

    override suspend fun updateComment(id: Int, text: String): Outcome<Comment, NetworkError> {
        return contentApi.updateComment(id, text).map { it.toModel() }
    }

    override suspend fun removeComment(id: Int): Outcome<Unit, NetworkError> {
        return contentApi.removeComment(id)
    }

    override suspend fun reportContent(id: Int, reportId: Int, userMessage: String?): Outcome<Unit, NetworkError> {
        return contentApi.reportContent(id, reportId, userMessage)
    }

    override suspend fun getWidgetContents(
        id: Int,
        query: String?,
        filters: Map<String, String>?,
        limit: Int,
        page: Int,
        sort: String,
        username: String?,
        language: String
    ): Outcome<WidgetContentApiLoad, NetworkError> {
        return contentApi.getWidgetContents(id, query, filters, limit, page, sort, username)
            .map { it.toModel(language) }
    }

    override suspend fun getHomeWidgetContents(
        id: Int,
        filters: Map<String, String>?,
        username: String?,
        language: String
    ): Outcome<WidgetContentApiLoad, NetworkError> {
        return contentApi.getHomeWidgetContents(id, filters, username)
            .map { it.toModel(language) }
    }

    override suspend fun getHomeWidgetContents(
        ids: List<Int>,
        filters: Map<String, String>?,
        username: String?,
        language: String
    ): Outcome<List<WidgetContentApiLoad>, NetworkError> {
        return contentApi.getHomeWidgetContents(ids, filters, username)
            .map {
                it.map { widgetLoad ->
                    widgetLoad.toModel(language)
                }
            }
    }

    override suspend fun getContentReports(language: String): Outcome<List<ContentReport>, NetworkError> {
        return if (!::reportReasons.isInitialized || !::reportReasonsLanguage.isInitialized || reportReasonsLanguage != language) {
            contentApi.getContentReports().map { reports ->
                reportReasons = reports.map { it.toModel(language) }
                reportReasonsLanguage = language
                reportReasons
            }
        } else {
            success(reportReasons)
        }
    }

    override suspend fun publishContent(payload: String): Outcome<Boolean, NetworkError> {
        return contentApi.publishContent(payload).map { it.published }
    }
}

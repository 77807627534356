package es.cinfo.tiivii.core.features.catalogue.model

import es.cinfo.tiivii.core.content.model.ApiResponse.Content.Data.Companion.MAX_SCORE_VALUE
import es.cinfo.tiivii.core.content.model.ApiResponse.Content.Data.Companion.MIN_SCORE_VALUE
import es.cinfo.tiivii.core.sorting.SortModel
import es.cinfo.tiivii.core.translation.TranslationModel
import es.cinfo.tiivii.core.util.Model.Node
import es.cinfo.tiivii.core.util.Model.Tree
import es.cinfo.tiivii.core.util.Nestable
import es.cinfo.tiivii.core.util.NodeValue
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.math.max
import kotlin.math.min
import es.cinfo.tiivii.core.content.model.ContentTypeModel.Model.ContentType as ContentTypeModel
import es.cinfo.tiivii.core.content.model.ContentTypeModel.ViewModel.ContentType as ContentTypeViewModel
import es.cinfo.tiivii.core.image.ApiResponse.Image as ImageApiResponse
import es.cinfo.tiivii.core.image.Model.Image as ImageModel
import es.cinfo.tiivii.core.image.Model.Image.Type as ImageType
import es.cinfo.tiivii.core.image.ViewModel.Image as ImageViewModel

sealed class ViewModel {
    /**
     * Representation of the content node (aka section) in the catalogue screen
     */
    data class ContentNode(
        val nodeName: String,
        private val _hasChildren: Boolean
    ): Nestable {
        override fun getName() = nodeName
        override fun hasChildren() = _hasChildren
    }

    /**
     * Representation of the content associated to a catalogue screen section
     */
    data class Content(
        val id: Int,
        val title: String,
        val subtitle: String?,
        val score: Float,
        val canPlay: Boolean,
        val canFav: Boolean,
        var isFav: Boolean,
        val type: ContentTypeViewModel,
        val background: ImageViewModel?,
        val banner: ImageViewModel?,
        val poster: ImageViewModel?,
        val _hasChildren: Boolean,
        val parentId: Int?
    ): Nestable {
        override fun hasChildren() = _hasChildren
        override fun getName() = title
    }
}

internal sealed class Model {

    data class ContentTreeLoad(
        val contents: Tree<ViewModel.Content, Content>,
        val count: Int,
        val page: Int,
        val limit: Int,
        val sort: SortModel.Model.Sort,
        val selectedNode: Node<ViewModel.Content, Content>? = null,
    ) {
        fun hasMoreContent(): Boolean {
            return (page * limit) + limit < count
        }
    }

    data class NodeContentsLoad(
        val contents: List<Content>,
        val count: Int,
        val page: Int,
        val limit: Int,
        val sort: SortModel.Model.Sort
    ) {
        fun hasMoreContent(): Boolean {
            return (page * limit) + limit < count
        }
    }

    data class CatalogueLoad(
        val nodeTree: Tree<ViewModel.ContentNode, ContentNode>,
        val selectedNode: Node<ViewModel.ContentNode, ContentNode>,
        val nodeContents: ContentTreeLoad
    )

    data class ContentNode(
        val id: Int,
        private val name: String,
        val filters: Map<String, String>,
        private val _hasChildren: Boolean
    ): NodeValue<ViewModel.ContentNode> {
        override fun getName() = name
        override fun hasChildren() = _hasChildren
        override fun toViewModel(): ViewModel.ContentNode {
            return ViewModel.ContentNode(
                nodeName = name,
                _hasChildren = _hasChildren
            )
        }
    }

    data class Content(
        val id: Int,
        val title: String,
        val subtitle: String?,
        val score: Float,
        val type: ContentTypeModel,
        val background: ImageModel?,
        val banner: ImageModel?,
        val poster: ImageModel?,
        val parentId: Int?,
        var canPlay: Boolean? = null,
        var canFav: Boolean? = null,
        var isFav: Boolean? = null,
        private val _hasChildren: Boolean
    ): NodeValue<ViewModel.Content> {
        override fun hasChildren() = _hasChildren
        override fun getName() = title
        override fun toViewModel(): ViewModel.Content {
            return ViewModel.Content(
                id = id,
                title = title,
                subtitle = subtitle,
                score = score,
                canPlay = canPlay ?: false,
                canFav = canFav ?: false,
                isFav = isFav ?: false,
                type = type.toViewModel(),
                background = background?.toViewModel(),
                banner = banner?.toViewModel(),
                poster = poster?.toViewModel(),
                _hasChildren = _hasChildren,
                parentId = parentId
            )
        }
    }
}

internal sealed class ApiResponse {
    @Serializable
    data class NodeContentsLoad(
        @SerialName(CONTENTS_PARAM)
        val contents: List<Content>,
        @SerialName(PAGE_PARAM)
        val page: Int,
        @SerialName(LIMIT_PARAM)
        val limit: Int,
        @SerialName(SORT_PARAM)
        val sort: String,
        @SerialName(META_PARAM)
        val meta: Meta
    ) {
        companion object {
            const val CONTENTS_PARAM = "data"
            const val PAGE_PARAM = "page"
            const val LIMIT_PARAM = "limit"
            const val SORT_PARAM = "sort"
            const val META_PARAM = "meta"
        }

        @Serializable
        data class Meta(
            @SerialName(FILTER_COUNT_PARAM)
            val filterCount: Int
        ) {
            companion object {
                const val FILTER_COUNT_PARAM = "filter_count"
            }
        }

        fun toModel(): Model.NodeContentsLoad {
            return Model.NodeContentsLoad(
                contents.map { it.toModel() }, meta.filterCount, page, limit, SortModel.Model.Sort.fromParam(sort)
            )
        }
    }

    @Serializable
    data class ContentNode(
        @SerialName(ID_PARAM)
        val id: Int,
        @SerialName(NAME_PARAM)
        val name: String,
        @SerialName(FILTERS_PARAM)
        val filters: Map<String, String>,
        @SerialName(CHILDREN_PARAM)
        val hasChildren: Boolean,
        @SerialName(TRANSLATIONS_PARAM)
        @Serializable(with = TranslationModel.ApiResponse.Translations.TranslationsDeserializer::class)
        val translations: TranslationModel.ApiResponse.Translations?
    ) {
        companion object {
            const val ID_PARAM = "id"
            const val NAME_PARAM = "name"
            const val FILTERS_PARAM = "filters"
            const val CHILDREN_PARAM = "has_children"
            const val TRANSLATIONS_PARAM = "translations"
        }

        fun toModel(language: String): Model.ContentNode {
            val name = translations?.find(NAME_PARAM, language) ?: name
            return Model.ContentNode(
                id, name, filters, hasChildren
            )
        }
    }

    @Serializable
    data class Content(
        @SerialName(ID_PARAM)
        val id: Int,
        @SerialName(TITLE_PARAM)
        val title: String,
        @SerialName(SUBTITLE_PARAM)
        val subtitle: String?,
        @SerialName(SCORE_PARAM)
        val score: Float,
        @SerialName(TYPE_PARAM)
        val type: String,
        @SerialName(POSTER_PARAM)
        val poster: ImageApiResponse?,
        @SerialName(BANNER_PARAM)
        val banner: ImageApiResponse?,
        @SerialName(BACKGROUND_PARAM)
        val background: ImageApiResponse?,
        @SerialName(SERIAL_PARAM)
        val serial: Boolean,
        @SerialName(PARENT_PARAM)
        val parent: Parent? = null,
    ) {
        companion object {
            const val ID_PARAM = "id"
            const val TITLE_PARAM = "title"
            const val SUBTITLE_PARAM = "subtitle"
            const val SCORE_PARAM = "valoration"
            const val POSTER_PARAM = "poster"
            const val BANNER_PARAM = "banner"
            const val BACKGROUND_PARAM = "background"
            const val TYPE_PARAM = "type"
            const val SERIAL_PARAM = "serial"
            const val PARENT_PARAM = "parent"
        }

        @Serializable
        data class Parent(
            @SerialName(ID_PARAM)
            val id: Int
        ) {
            companion object {
                const val ID_PARAM = "id"
            }
        }

        fun toModel(): Model.Content {
            val parsedType = ContentTypeModel.parse(type)
            return Model.Content(
                id = id,
                title = title,
                subtitle = subtitle,
                score = max(
                    min(score, MAX_SCORE_VALUE),
                    MIN_SCORE_VALUE
                ),
                type = parsedType,
                background = background?.toModel(ImageType.BACKGROUND),
                banner = banner?.toModel(ImageType.BANNER),
                poster = poster?.toModel(ImageType.POSTER),
                parentId = parent?.id,
                _hasChildren = serial && parsedType == ContentTypeModel.CONTAINER
            )
        }
    }

}
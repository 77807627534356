package es.cinfo.tiivii.core.util

import es.cinfo.tiivii.core.error.NetworkError
import io.ktor.client.statement.*
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.int
import kotlinx.serialization.json.jsonObject
import kotlinx.serialization.json.jsonPrimitive

internal expect object HttpService {
    fun add(response: HttpResponse, parsedResponse: JsonElement?)

    inline fun <reified T> read(response: HttpResponse, jsonKey: String? = null): Outcome<T, NetworkError>

    inline fun <reified T> checkSuccess(response: HttpResponse): Outcome<Unit, NetworkError>

    fun setLastTokenRefresh(lastTokenRefreshMs: Long)

    fun getLastTokenRefreshMs(): Long

    fun remove(response: HttpResponse)
}

internal fun getErrorCode(json: JsonElement): Int? {
    return try {
        json.jsonObject["error_error"]?.jsonObject?.get("http_status_code")?.jsonPrimitive?.int
    } catch (e: IllegalArgumentException) {
        null
    }
}

internal fun getErrorBody(json: JsonElement): String? {
    return try {
        json.jsonObject["error_error"]?.jsonObject?.get("http_body")?.jsonPrimitive?.content
    } catch (e: IllegalArgumentException) {
        null
    }
}
package es.cinfo.tiivii.core.userstats

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.userstats.UserStatsModel.Model.Rankings
import es.cinfo.tiivii.core.userstats.UserStatsModel.Model.UserStats
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.core.util.TimedCache
import es.cinfo.tiivii.core.util.map
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

internal interface UserStatsService {

    suspend fun getBoardRankings(id: Int): Outcome<Rankings, NetworkError>

    suspend fun getUserStats(username: String, hitCache: Boolean): Outcome<UserStats, NetworkError>

}

internal class DefaultUserStatsService : UserStatsService {
    private val userStatsApi: UserStatsApi by diContainer.instance()

    private val userStatsCache = TimedCache<String, UserStats>(expirationTimeSeconds = 1 * 60 * 60L)

    override suspend fun getBoardRankings(id: Int): Outcome<Rankings, NetworkError> {
        return userStatsApi.getBoardRankings(id)
            .map { rankingsResponse ->
                Rankings(
                    board = rankingsResponse.board.id,
                    rankings = rankingsResponse.rankings.map { it.toModel("{unknown}") },
                    limit = rankingsResponse.limit,
                    page = rankingsResponse.page
                )
            }
    }

    override suspend fun getUserStats(username: String, hitCache: Boolean): Outcome<UserStats, NetworkError> {
        return userStatsCache.get(
            key = username,
            override = !hitCache
        ) {
            userStatsApi.getUserStats(username)
                .map { userStatsResponse ->
                    val parsedAchievements = userStatsResponse.accomplishments?.achievements?.map { it.content.toModel(it.accomplishmentTimeSec) }
                    val filledRewards = userStatsResponse.accomplishments?.rewards?.map { reward ->
                        val achievement = parsedAchievements?.find { it.id == reward.content.achievement?.id }
                        reward.content.toModel(username).copy(achievement = achievement)
                    }
                    UserStats(
                        achievements = parsedAchievements ?: emptyList(),
                        rewards = filledRewards ?: emptyList(),
                        closestAchievement = userStatsResponse.closestAchievement?.toModel(),
                        boardId = userStatsResponse.consumerRanking?.board?.id,
                        ranking = userStatsResponse.consumerRanking?.ranking?.toModel(
                            username)
                    )
                }
        }
    }

}
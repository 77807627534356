package es.cinfo.tiivii.core.util

import es.cinfo.tiivii.core.modules.cas.model.CasModel
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flowOf
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.jsonObject
import kotlinx.serialization.json.jsonPrimitive

/**
 * Utility function to return a [Success] with no result value
 */
internal fun success(): Success<Unit> = Success(Unit)

/**
 * Utility function to return a [Success] with a result of type [T]
 */
fun <T> success(value: T): Success<T> = Success(value)

/**
 * Utility function to return a [Failure] with an error of type [E]
 */
fun <E> failure(error: E): Failure<E> = Failure(error)

/**
 * Represents an outcome of an operation that can yield two different types of results
 */
sealed class Outcome<out T, out E>

/**
 * Represents an [Outcome] with a successful result
 */
data class Success<out T>(val value: T) : Outcome<T, Nothing>()

/**
 * Represents an [Outcome] with a failure on its execution
 */
data class Failure<out E>(val error: E) : Outcome<Nothing, E>()

/**
 * Allows a block of code to be executed when the [Outcome] is received and returns it with no modifications
 */
internal suspend fun <T, E> Outcome<T, E>.log(block: suspend Outcome<T, E>.() -> Unit): Outcome<T, E> {
    block()
    return this
}

/**
 * Allows a block of code to be executed when the [Outcome] is a [Success] and returns the same [Outcome]
 * without modifications
 */
internal suspend fun <T, E> Outcome<T, E>.on(block: suspend Success<T>.() -> Unit): Outcome<T, E> =
    when (this) {
        is Success -> {
            block()
            this
        }
        is Failure -> this
    }

/**
 * Allows a block of code to be executed when the [Outcome] is a [Failure] and returns the same [Outcome]
 * without modifications
 */
internal suspend fun <T, E> Outcome<T, E>.onError(block: suspend Failure<E>.() -> Unit): Outcome<T, E> =
    when (this) {
        is Success -> this
        is Failure -> {
            block()
            this
        }
    }

/**
 * Maps an [Outcome] with a result of type [T] to an [Outcome] of with a result of type [U]
 */
internal suspend fun <U, T, E> Outcome<T, E>.map(transform: suspend (T) -> U): Outcome<U, E> =
    when (this) {
        is Success -> Success(transform(value))
        is Failure -> this
    }

/**
 * Maps an [Outcome] with an error of type [E] to an [Outcome] with an error of type [U]
 */
internal suspend fun <U, T, E> Outcome<T, E>.mapError(transform: suspend (E) -> U): Outcome<T, U> =
    when (this) {
        is Success -> this
        is Failure -> Failure(transform(error))
    }

internal suspend fun <T, E> Outcome<T, E>.retryWith(transform: suspend (E) -> Outcome<T, E>): Outcome<T, E> =
    when (this) {
        is Success -> this
        is Failure -> transform(error)
    }

/**
 * Maps a [Failure] to a [Success] with the given [transform]
 */
internal suspend fun <T, E> Outcome<T, E>.getOrElse(transform: suspend (E) -> T): Outcome<T, E> =
    when (this) {
        is Success -> this
        is Failure -> Success(transform(error))
    }

/**
 * Obtains the value [T] inside a [Success] of null if a [Failure] is met
 */
internal suspend fun <T, E> Outcome<T, E>.getOrNull(): T? =
    when (this) {
        is Success -> this.value
        is Failure -> null
    }

/**
 * Chains the given [Outcome] allowing to transform its result and return a new [Outcome] with a different
 * result value
 */
internal suspend fun <U, T, E> Outcome<T, E>.andThen(transform: suspend (T) -> Outcome<U, E>): Outcome<U, E> =
    when (this) {
        is Success -> transform(value)
        is Failure -> this
    }

/**
 * Executes the given block of code in the context of an outcome. This allows the usage of the [getOrAbort] function
 * on [TryOutcomeContext]
 */
internal suspend fun <T, E> tryOutcome(block: suspend TryOutcomeContext<E>.() -> Outcome<T, E>): Outcome<T, E> =
    try {
        TryOutcomeContext<E>().block()
    } catch (ex: TryOutcomeAbortion) {
        Failure(ex.err as E)
    }

/**
 * Executes the given block of code in the context of an outcome. This allows the usage of the [getOrAbort] function
 * on [TryOutcomeContext]
 */
internal suspend fun <T, E> tryOutcomeFlow(block: suspend TryOutcomeFlowContext<E>.() -> Flow<Outcome<T, E>>): Flow<Outcome<T, E>> =
    try {
        TryOutcomeFlowContext<E>().block()
    } catch (ex: TryOutcomeAbortion) {
        flowOf(Failure(ex.err as E))
    }


/**
 * Represents a context of an [Outcome] try execution
 */
internal class TryOutcomeContext<E> {
    /**
     * Tries to retrieve the value of an [Outcome] or aborts the execution if the [Outcome] has failed with an error
     */
    fun <T> Outcome<T, E>.getOrAbort(): T =
        when (this) {
            is Success -> value
            is Failure -> throw TryOutcomeAbortion(error as Any)
        }
}

/**
 * Represents a context of an [Outcome] try execution
 */
internal class TryOutcomeFlowContext<E> {
    /**
     * Tries to retrieve the value of an [Outcome] or aborts the execution if the [Outcome] has failed with an error
     */
    fun <T> Outcome<T, E>.getOrAbort(): T =
        when (this) {
            is Success -> value
            is Failure -> throw TryOutcomeAbortion(error as Any)
        }
}

private class TryOutcomeAbortion(val err: Any) : Exception()

internal sealed class ErrorModel {
    data class KrakenError(
        val key: String
    ) {
        companion object {
            fun parseFromJson(json: JsonElement): KrakenError {
                return KrakenError(
                    key = json.jsonObject["error"]!!.jsonObject["key"]!!.jsonPrimitive.content
                )
            }
        }
    }
}

internal fun JsonElement.asKrakenError(): ErrorModel.KrakenError {
    return ErrorModel.KrakenError(
        this.jsonObject["error"]!!.jsonObject["key"]!!.jsonPrimitive.content
    )
}
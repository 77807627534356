package es.cinfo.tiivii.nav.menu.store

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.SuspendExecutor
import es.cinfo.tiivii.core.ComponentId
import es.cinfo.tiivii.core.error.ErrorService
import es.cinfo.tiivii.core.layout.model.section.Model.Section
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.usecase.GetOrderedSections
import es.cinfo.tiivii.core.util.Failure
import es.cinfo.tiivii.core.util.LoadingModel
import es.cinfo.tiivii.core.util.Success
import es.cinfo.tiivii.di.diContainer
import es.cinfo.tiivii.nav.menu.model.MenuModel
import es.cinfo.tiivii.nav.menu.store.MenuStore.*
import es.cinfo.tiivii.nav.menu.usecase.GetAppLink
import es.cinfo.tiivii.nav.menu.usecase.GetConfigValues
import es.cinfo.tiivii.nav.menu.usecase.GetDeclarations
import es.cinfo.tiivii.nav.menu.usecase.GetUiLanguage
import org.kodein.di.instance

internal class MenuStoreFactory(
    private val storeFactory: StoreFactory,
) {

    private val errorService: ErrorService by diContainer.instance()
    private val configModule: ConfigModule by diContainer.instance()

    private sealed class Result {
        data class OrderedSections(val sections: List<Section>) : Result()
        data class UserLanguage(val language: String) : Result()
        data class SelectedSection(val sectionId: String) : Result()
        object LoadingSections : Result()
        object ErrorLoadingSections : Result()
        data class LoadedDeclarations(val declarations: MenuModel.Model.Declarations) : Result()
        data class LoadedConfigValues(val config: MenuModel.Model.Config?) : Result()
        data class LoadedAppLink(val appLink: String): Result()
    }

    fun create(): MenuStore =
        object :
            MenuStore,
            Store<Intent, State, Label> by
            storeFactory.create(
                name = "MenuStore",
                initialState = State(
                    language = configModule.getCoreConfig().signup.defaultLanguage,
                    sections = null,
                    loadingSections = LoadingModel.Model.LoadState.RESET,
                    selectedSection = null,
                    studioUrl = configModule.getEnvConfig().adminUrl,
                    supportEmail = null,
                    tutorialUrl = null,
                    privacyStatementUrl = null,
                    termsAndConditionsUrl = null,
                    legalText = null,
                    iosAppUrl = null,
                    androidAppUrl = null,
                    faqUrl = null
                ),
                bootstrapper = SimpleBootstrapper(
                    Action.GetUserLanguage,
                    Action.GetOrderedSections,
                    Action.GetDeclarations,
                    Action.GetConfigValues,
                    Action.GetAppLink
                ),
                reducer = DefaultReducer,
                executorFactory = ::DefaultExecutor
            ) {
        }

    private object DefaultReducer : Reducer<State, Result> {
        override fun State.reduce(result: Result): State =
            when (result) {
                is Result.OrderedSections -> copy(
                    sections = result.sections,
                    loadingSections = LoadingModel.Model.LoadState.LOADED
                )
                is Result.SelectedSection -> {
                    val section = sections?.find {
                        it.id == result.sectionId
                    }
                    copy(
                        selectedSection = section
                    )
                }
                is Result.UserLanguage -> copy(
                    language = result.language
                )
                Result.LoadingSections -> copy(
                    loadingSections = LoadingModel.Model.LoadState.LOADING
                )
                is Result.ErrorLoadingSections -> copy(
                    loadingSections = LoadingModel.Model.LoadState.RESET
                )
                is Result.LoadedDeclarations -> copy(
                    privacyStatementUrl = result.declarations.privacyStatementUrl,
                    termsAndConditionsUrl = result.declarations.termsAndConditionsUrl,
                    legalText = result.declarations.legalText
                )
                is Result.LoadedConfigValues -> copy(
                    faqUrl = result.config?.faqUrl,
                    supportEmail = result.config?.supportEmail,
                    tutorialUrl = result.config?.tutorialUrl
                )
                is Result.LoadedAppLink -> copy(
                    androidAppUrl = result.appLink,
                    iosAppUrl = result.appLink
                )
            }
    }

    private inner class DefaultExecutor :
        SuspendExecutor<Intent, Action,
                State,
                Result, Label>() {
        override suspend fun executeIntent(intent: Intent, getState: () -> State) {
            when (intent) {
                Intent.Reload -> reload()
                is Intent.SelectSection -> selectSection(intent.sectionId)
            }
        }

        override suspend fun executeAction(action: Action, getState: () -> State) {
            when (action) {
                Action.GetOrderedSections -> getOrderedSections()
                Action.GetUserLanguage -> getUserLanguage()
                Action.GetDeclarations -> getDeclarations()
                Action.GetConfigValues -> getConfigValues()
                Action.GetAppLink -> getAppLink()
            }
        }

        private suspend fun getDeclarations() {
            when (val outcome = GetDeclarations().invokeWith(ComponentId.MENU)) {
                is Success -> dispatch(Result.LoadedDeclarations(outcome.value))
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                GetDeclarations.Error.UnavailableLayoutConfig ->
                                    publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }

        private suspend fun getOrderedSections() {
            dispatch(Result.LoadingSections)
            when (val outcome = GetOrderedSections().invokeWith(ComponentId.MENU)) {
                is Success -> dispatch(Result.OrderedSections(outcome.value))
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = {
                            dispatch(Result.ErrorLoadingSections)
                            publish(Label.UserSessionExpired)
                        },
                        requestTimeoutAction = {
                            dispatch(Result.ErrorLoadingSections)
                            publish(Label.RequestTimedOut)
                        },
                        handler = {
                            when (outcome.error) {
                                is GetOrderedSections.Error.LayoutConfigUnavailable,
                                is GetOrderedSections.Error.UserUnavailable -> {
                                    dispatch(Result.ErrorLoadingSections)
                                    publish(Label.UnexpectedError(outcome.error.getCode()))
                                }
                            }
                        }
                    )
                }
            }
        }

        private suspend fun getUserLanguage() {
            when (val outcome = GetUiLanguage().invoke()) {
                is Success -> dispatch(Result.UserLanguage(outcome.value))
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = { }
                    )
                }
            }
        }

        private suspend fun getConfigValues() {
            when (val outcome = GetConfigValues().invoke()) {
                is Success -> dispatch(Result.LoadedConfigValues(outcome.value))
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = { }
                    )
                }
            }
        }

        private suspend fun getAppLink() {
            when (val outcome = GetAppLink().invokeWith(ComponentId.MENU)) {
                is Success -> {
                    outcome.value?.let {
                        dispatch(Result.LoadedAppLink(it))
                    }
                }
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                is GetAppLink.Error.UnavailableLinkError ->
                                    publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }

        private fun selectSection(sectionId: String) {
            dispatch(Result.SelectedSection(sectionId))
        }

        private suspend fun reload() {
            getUserLanguage()
            getDeclarations()
            getConfigValues()
            getAppLink()
            getOrderedSections()
        }
    }

}
package es.cinfo.tiivii.core.modules.share

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.core.util.TimedCache
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

internal interface ShareService {
    suspend fun getDetailLink(
        contentId: Int,
        contentTitle: String,
        contentDescription: String,
        contentImageLink: String?
    ): Outcome<String, NetworkError>

    suspend fun getPlayerLink(
        contentId: Int,
        contentType: String,
        contentTitle: String,
        contentDescription: String,
        contentImageLink: String?
    ): Outcome<String, NetworkError>

    suspend fun getAppLink(): Outcome<String, NetworkError>
}

internal class FirebaseShareService : ShareService {
    private val shareApi: ShareApi by diContainer.instance()

    private val linkCache = TimedCache<String, String>(expirationTimeSeconds = 10 * 60)

    override suspend fun getDetailLink(
        contentId: Int,
        contentTitle: String,
        contentDescription: String,
        contentImageLink: String?
    ): Outcome<String, NetworkError> {
        return linkCache.get(key = "detailLink") {
            shareApi.getDetailLink(contentId, contentTitle, contentDescription, contentImageLink)
        }
    }

    override suspend fun getPlayerLink(
        contentId: Int,
        contentType: String,
        contentTitle: String,
        contentDescription: String,
        contentImageLink: String?
    ): Outcome<String, NetworkError> {
        return linkCache.get(key = "playerLink") {
            shareApi.getPlayerLink(contentId, contentType, contentTitle, contentDescription, contentImageLink)
        }
    }

    override suspend fun getAppLink(): Outcome<String, NetworkError> {
        return linkCache.get(key = "appLink") {
            shareApi.getAppLink()
        }
    }
}
package es.cinfo.tiivii.home.binder

import es.cinfo.tiivii.home.store.HomeStore.*
import es.cinfo.tiivii.home.view.HomeView.*

internal val STATE_TO_MODEL: State.() -> Model = {
    Model(
        loadedScreen = loadedScreen,
        screenWidgets = screenWidgets.map { it.toViewModel() }
    )
}

internal val EVENT_TO_INTENT: Event.() -> Intent = {
    when (this) {
        is Event.LoadScreen -> Intent.LoadWidgets(id)
        Event.LogHomeView -> Intent.LogHomeView
        Event.LoadDefaultScreen -> Intent.LoadDefaultScreen
        is Event.LogSelectItem -> Intent.LogSelectItem(contentId)
        is Event.AddToFavorites -> Intent.AddToFavorites(contentId)
        is Event.RemoveFromFavorites -> Intent.RemoveFromFavorites(contentId)
    }
}

internal val LABEL_TO_OUTPUT: Label.() -> Output = {
    when (this) {
        Label.UserSessionExpired -> Output.UserSessionExpired
        is Label.UnexpectedError -> Output.UnexpectedError(code)
        is Label.GameActionSent -> Output.GameActionSent(actionResult.toViewModel())
        Label.RequestTimedOut -> Output.RequestTimedOut
    }
}
package es.cinfo.tiivii.core.modules.checkpoint.model

import es.cinfo.tiivii.core.util.jsonObjectOrNull
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.SerializationException
import kotlinx.serialization.json.*

class CheckpointModel {

    sealed class ViewModel {
        /**
         * Represents a checkpoint on forked videos with a user decision to be made
         * @param timestamp when this checkpoint should be shown
         * @param text to be shown to the user
         * @param options related options for the user to select
         * @param type indicates the type of checkpoint
         */
        data class Checkpoint(
            val id: Int,
            val timestamp: Long,
            val text: String,
            val options: List<Option>,
            val type: Type
        ) {
            enum class Type {
                /**
                 * Indicates a checkpoint that makes a jump to another position without asking the user
                 */
                JUMP,

                /**
                 * Indicates a checkpoint with IOT related operations
                 */
                IOT,

                /**
                 * Indicates a checkpoint with user decisions
                 */
                DECISION
            }
        }

        /**
         * Represents an option inside a [Checkpoint]
         * @param text to show to the user for the option
         * @param target of the option (depends on the type, may be a position on the video, an url or the id of another content)
         * @param type of the option
         */
        data class Option(
            val id: Int,
            val text: String,
            val target: String,
            val type: Type
        ) {
            /**
             * Represents the type of [Option]
             * @see Type
             */
            enum class Type {
                /**
                 * Represents an option to move the video position
                 */
                SEEK,
                /**
                 * Represents an option to open an url
                 */
                URL,
                /**
                 * Represents an option to link to another content
                 */
                CONTENT;
            }
        }
    }

    internal sealed class Model {

        data class Checkpoint(
            val id: Int,
            val timestamp: Long,
            val content: String,
            val options: List<Option>,
            val correctAnswerOptionId: Int?,
            val type: Type
        ) {
            fun toViewModel(): ViewModel.Checkpoint =
                ViewModel.Checkpoint(
                    id = id,
                    timestamp = timestamp,
                    text = content,
                    options = options.map { it.toViewModel() },
                    type = type.toViewModel()
                )

            enum class Type {
                JUMP, IOT, DECISION;

                fun toViewModel(): ViewModel.Checkpoint.Type {
                    return when (this) {
                        JUMP -> ViewModel.Checkpoint.Type.JUMP
                        IOT -> ViewModel.Checkpoint.Type.IOT
                        DECISION -> ViewModel.Checkpoint.Type.DECISION
                    }
                }
            }
        }

        data class Option(
            val id: Int,
            val content: String,
            val target: String,
            val type: Type
        ) {
            enum class Type {
                SEEK, URL, CONTENT;

                companion object {
                    fun parse(value: String?): Type {
                        return when (value) {
                            "seek" -> SEEK
                            "url" -> URL
                            "petisco" -> CONTENT
                            else -> throw SerializationException("Unrecognized option type $value")
                        }
                    }
                }

                fun toViewModel(): ViewModel.Option.Type =
                    when (this) {
                        SEEK -> ViewModel.Option.Type.SEEK
                        URL -> ViewModel.Option.Type.URL
                        CONTENT -> ViewModel.Option.Type.CONTENT
                    }
            }

            fun toViewModel(): ViewModel.Option =
                ViewModel.Option(
                    id = id,
                    text = content,
                    target = target,
                    type = type.toViewModel()
                )
        }
    }

    internal sealed class ApiResponse {

        @Serializable
        data class Checkpoints(
            @SerialName(DATA_PARAM)
            val data: JsonArray
        ) {
            companion object {
                const val DATA_PARAM = "data"
            }

            data class CorrectAnswer(
                val nodeId: Int,
                val answer: String
            )

            enum class CheckpointType {
                BRANCH, FAKE_BRANCH;

                companion object {
                    fun parse(checkpointType: String?): CheckpointType? {
                        return when (checkpointType) {
                            "forked-jump-checkpoint" ->
                                FAKE_BRANCH
                            "forked-ab-checkpoint",
                            "forked-3-options-checkpoint",
                            "forked-4-options-checkpoint",
                            "forked-5-options-checkpoint" ->
                                BRANCH
                            else ->
                                null
                        }
                    }
                }

                fun getLogicKey(): String? {
                    return when (this) {
                        BRANCH -> "branch"
                        FAKE_BRANCH -> "fakebranch"
                    }
                }

                fun toModel(): Model.Checkpoint.Type {
                    return when (this) {
                        BRANCH -> Model.Checkpoint.Type.DECISION
                        FAKE_BRANCH -> Model.Checkpoint.Type.JUMP
                    }
                }
            }

            fun toModel(): List<Model.Checkpoint> {
                val checkpoints = mutableListOf<Model.Checkpoint>()
                data.forEach { checkpoint ->
                    // We check the checkpoint type
                    when (val checkpointType = CheckpointType.parse(checkpoint.jsonObject["type"]?.jsonPrimitive?.content)) {
                        CheckpointType.BRANCH,
                        CheckpointType.FAKE_BRANCH -> {
                            val logic = checkpoint.jsonObjectOrNull?.get("forkedlogic")?.jsonObjectOrNull
                                ?: throw SerializationException("Missing forkedlogic for checkpoint type $checkpointType")
                            val nodes = logic["nodes"]?.jsonObject
                            var decisionNodeId: String? = null
                            var correctAnswer: CorrectAnswer? = null
                            nodes?.entries?.forEach { node ->
                                val nodeName = node.value.jsonObject["name"]?.jsonPrimitive?.content
                                // Get the node with the decision logic
                                if (nodeName == checkpointType.getLogicKey()) {
                                    decisionNodeId = node.key
                                }
                                // Get the node with correctanswer
                                if (nodeName == "correctanswer") {
                                    // Control for missing value on "correctanswer" node
                                    val nodeData = node.value.jsonObjectOrNull?.get("data")?.jsonObjectOrNull
                                    nodeData?.let {
                                        val answer = it.jsonObject["answer"]?.jsonPrimitive?.content
                                        val details = answer?.split(", ")
                                        if (details != null && details.size == 2) {
                                            val id = details[0].toInt()
                                            val value = details[1]
                                            correctAnswer = CorrectAnswer(id, value)
                                        }
                                    }
                                }
                            }
                            val options = mutableListOf<Model.Option>()
                            // We get the outputs of the node
                            nodes?.get(decisionNodeId)?.jsonObject?.get("outputs")?.jsonObject?.entries?.forEach { output ->
                                // Get the node id, type and target marker (if any) for each output
                                val optionNodeId = output.value.jsonObject["connections"]?.jsonArray?.get(0)?.jsonObject?.get("node")?.jsonPrimitive?.int.toString()
                                val optionType: String? = nodes[optionNodeId]?.jsonObject?.get("name")?.jsonPrimitive?.content
                                val optionTargetMarker = nodes.jsonObject[optionNodeId]?.jsonObject?.get("data")?.jsonObject?.get("markerid")?.jsonPrimitive?.content
                                var target: String? = null
                                val optionNode = nodes[optionNodeId]?.jsonObject
                                when (optionType) {
                                    "seek" ->
                                        target = data.find { it.jsonObject["id"]?.jsonPrimitive?.content == optionTargetMarker }?.jsonObject?.get("timestamp")?.jsonPrimitive?.int.toString()
                                    "url" ->
                                        target = optionNode?.get("data")?.jsonObject?.get("externalurl")?.jsonPrimitive?.content
                                    "petisco" ->
                                        target = optionNode?.get("data")?.jsonObject?.get("petiscoid")?.jsonPrimitive?.content
                                }
                                val optionId = nodes[optionNodeId]?.jsonObject?.get("id")!!.jsonPrimitive.int
                                val content = when (checkpointType) {
                                    CheckpointType.FAKE_BRANCH -> {
                                        data.find { it.jsonObject["id"]?.jsonPrimitive?.content == optionTargetMarker }?.jsonObject?.get("title")?.jsonPrimitive?.content
                                    }
                                    CheckpointType.BRANCH -> {
                                        nodes[decisionNodeId]?.jsonObject?.get("data")!!.jsonObject[output.key]?.jsonPrimitive?.content
                                    }
                                }
                                // We add one option per output (if params available, should always be)
                                if (target != null && content != null) {
                                    options.add(
                                        Model.Option(
                                            id = optionId,
                                            type = Model.Option.Type.parse(optionType),
                                            target = target,
                                            content = content
                                        )
                                    )
                                }
                            }
                            val correctAnswerId = correctAnswer?.let { answer ->
                                options.find { option ->
                                    option.content == answer.answer
                                }?.id
                            }
                            // We create the checkpoint with its data (if content available, should always be)
                            val timestamp = checkpoint.jsonObject["timestamp"]?.jsonPrimitive?.int?.toLong() ?: -1
                            val content = checkpoint.jsonObject["content"]?.jsonPrimitive?.content
                            val id = checkpoint.jsonObject["id"]?.jsonPrimitive?.int ?: -1
                            if (content != null) {
                                checkpoints.add(
                                    Model.Checkpoint(
                                        timestamp = timestamp,
                                        content = content,
                                        options = options,
                                        id = id,
                                        correctAnswerOptionId = correctAnswerId,
                                        type = checkpointType.toModel()
                                    )
                                )
                            }
                        }
                    }
                }
                return checkpoints
            }
        }
    }
}
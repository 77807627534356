package es.cinfo.tiivii.core.features.ranking.store

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.SuspendExecutor
import es.cinfo.tiivii.core.error.ErrorService
import es.cinfo.tiivii.core.features.ranking.store.RankingStore.*
import es.cinfo.tiivii.core.features.ranking.usecase.LoadUserStats
import es.cinfo.tiivii.core.userstats.UserStatsModel.Model.RankingStats
import es.cinfo.tiivii.core.util.Failure
import es.cinfo.tiivii.core.util.LoadingModel.Model.LoadState
import es.cinfo.tiivii.core.util.Success
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

internal class RankingStoreFactory(
    private val storeFactory: StoreFactory,
) {

    private val errorService: ErrorService by diContainer.instance()

    private sealed class Result {
        object LoadingStats: Result()
        object ErrorLoadingStats: Result()
        data class LoadedStats(val stats: RankingStats): Result()
    }

    fun create(): RankingStore =
        object :
            RankingStore,
            Store<Intent, State, Label> by
            storeFactory.create(
                name = "RankingStore",
                initialState = State(
                    userAvatar = null,
                    boardId = null,
                    userRanking = null,
                    rankings = emptyList(),
                    achievements = emptyList(),
                    rewards = emptyList(),
                    closestAchievement = null,
                    loadingStats = LoadState.RESET
                ),
                bootstrapper = SimpleBootstrapper(Action.LoadUserStats),
                reducer = DefaultReducer,
                executorFactory = ::DefaultExecutor
            ) {
        }

    private object DefaultReducer : Reducer<State, Result> {
        override fun State.reduce(result: Result): State =
            when (result) {
                Result.LoadingStats -> copy(
                    loadingStats = LoadState.LOADING
                )
                Result.ErrorLoadingStats -> copy(
                    loadingStats = LoadState.RESET
                )
                is Result.LoadedStats -> copy(
                    userAvatar = result.stats.userAvatar,
                    boardId = result.stats.rankings?.board,
                    userRanking = result.stats.userStats.ranking,
                    rankings = result.stats.rankings?.rankings ?: emptyList(),
                    achievements = result.stats.userStats.achievements,
                    rewards = result.stats.userStats.rewards,
                    closestAchievement = result.stats.userStats.closestAchievement,
                    loadingStats = LoadState.LOADED
                )
            }
    }

    private inner class DefaultExecutor :
        SuspendExecutor<Intent, Action,
                State,
                Result, Label>() {
        override suspend fun executeIntent(intent: Intent, getState: () -> State) {
            when (intent) {
                Intent.ReloadUserStats -> loadUserStats(getState().loadingStats, true)
            }
        }

        override suspend fun executeAction(action: Action, getState: () -> State) {
            when (action) {
                Action.LoadUserStats -> loadUserStats(getState().loadingStats, false)
            }
        }

        private suspend fun loadUserStats(loadingStats: LoadState, forceUpdate: Boolean) {
            if (loadingStats != LoadState.LOADING) {
                dispatch(Result.LoadingStats)
                when (val outcome = LoadUserStats(forceUpdate).invoke()) {
                    is Success -> dispatch(Result.LoadedStats(outcome.value))
                    is Failure -> {
                        errorService.handleError(
                            error = outcome.error,
                            sessionExpiredAction = { publish(Label.UserSessionExpired) },
                            requestTimeoutAction = { publish(Label.RequestTimedOut) },
                            handler = {
                                when (outcome.error) {
                                    LoadUserStats.Error.NoUserSession,
                                    is LoadUserStats.Error.RankingsUnavailable,
                                    is LoadUserStats.Error.UserStatsUnavailable -> {
                                        dispatch(Result.ErrorLoadingStats)
                                        publish(Label.UnexpectedError(outcome.error.getCode()))
                                    }
                                    LoadUserStats.Error.GamificationModuleDisabled -> {
                                        // Implicit call, no need to notify the UI
                                    }
                                }
                            }
                        )
                    }
                }
            }
        }

    }

}
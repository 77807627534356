package es.cinfo.tiivii.core.util

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.di.diContainer
import io.ktor.client.statement.*
import io.ktor.http.*
import io.ktor.util.date.*
import kotlinx.serialization.SerializationException
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.decodeFromJsonElement
import kotlinx.serialization.json.jsonObject
import org.kodein.di.instance

internal actual object HttpService {
    private val httpResponses = mutableMapOf<GMTDate, JsonElement?>()
    private var lastTokenRefreshMs = 0L

    actual fun add(response: HttpResponse, parsedResponse: JsonElement?) {
        httpResponses[response.responseTime] = parsedResponse
    }

    actual inline fun <reified T> read(response: HttpResponse, jsonKey: String?): Outcome<T, NetworkError> {
        val existsResponse = httpResponses.containsKey(response.responseTime)
        val parsedResponse = httpResponses[response.responseTime]
        return if (!existsResponse) {
            failure(NetworkError.Execution(IllegalStateException("Missing response from HttpService")))
        } else {
            if (!response.status.isSuccess()) {
                failure(NetworkError.Http(
                    response.status.value,
                    response.request.method.toString(),
                    response.request.url.toString(),
                    false
                ))
            } else {
                if (parsedResponse == null) {
                    failure(NetworkError.Execution(SerializationException("Response content is null")))
                } else {
                    val json: Json by diContainer.instance()
                    // Check Krakend internal error
                    val errorCode = getErrorCode(parsedResponse)
                    if (errorCode != null) {
                        val jsonError = getErrorBody(parsedResponse)?.let {
                            json.decodeFromString<JsonElement>(it)
                        }
                        failure(NetworkError.Http(
                            errorCode,
                            response.request.method.toString(),
                            response.request.url.toString(),
                            true,
                            jsonError
                        ))
                    } else {
                        try {
                            if (jsonKey == null) {
                                success(json.decodeFromJsonElement(parsedResponse))
                            } else {
                                success(json.decodeFromJsonElement(parsedResponse.jsonObject[jsonKey]!!))
                            }
                        } catch (exception: SerializationException) {
                            failure(NetworkError.Execution(exception))
                        }
                    }
                }
            }
        }
    }

    actual fun remove(response: HttpResponse) {
        httpResponses.remove(response.responseTime)
    }

    actual inline fun <reified T> checkSuccess(response: HttpResponse): Outcome<Unit, NetworkError> {
        val existsResponse = httpResponses.containsKey(response.responseTime)
        val parsedResponse = httpResponses[response.responseTime]
        return if (!existsResponse) {
            failure(NetworkError.Execution(IllegalStateException("Missing response from HttpService")))
        } else {
            if (!response.status.isSuccess()) {
                failure(NetworkError.Http(
                    response.status.value,
                    response.request.method.toString(),
                    response.request.url.toString(),
                    false
                ))
            } else {
                if (parsedResponse == null) {
                    failure(NetworkError.Execution(SerializationException("Response content is null")))
                } else {
                    val json: Json by diContainer.instance()
                    // Check Krakend internal error
                    val errorCode = getErrorCode(parsedResponse)
                    if (errorCode != null) {
                        val jsonError = getErrorBody(parsedResponse)?.let {
                            json.decodeFromString<JsonElement>(it)
                        }
                        failure(NetworkError.Http(
                            errorCode,
                            response.request.method.toString(),
                            response.request.url.toString(),
                            true,
                            jsonError
                        ))
                    } else {
                        success()
                    }
                }
            }
        }
    }

    actual fun setLastTokenRefresh(lastTokenRefreshMs: Long) {
        this.lastTokenRefreshMs = lastTokenRefreshMs
    }

    actual fun getLastTokenRefreshMs(): Long = lastTokenRefreshMs

}
package es.cinfo.tiivii.user.profile.binder

import es.cinfo.tiivii.core.util.mapToSet
import es.cinfo.tiivii.user.profile.store.UserStore.*
import es.cinfo.tiivii.user.profile.view.UserView.*

internal val STATE_TO_MODEL: State.() -> Model = {
    Model(
        loadingUser = loadingUser.toViewModel(),
        availableRoles = availableRoles.mapToSet { it.toViewModel() },
        username = username,
        email = email,
        birthday = birthday,
        availableLanguages = availableLanguages,
        language = language,
        availableAvatars = availableAvatars.mapToSet { it.toViewModel() },
        avatar = avatar?.toViewModel(),
        availableInterests = availableInterests.mapToSet { it.toViewModel() },
        interests = interests.mapToSet { it.toViewModel() },
        credentials = credentials,
        firstName = firstName,
        lastName = lastName,
        loadingUserStats = loadingUserStats.toViewModel(),
        ranking = ranking?.toViewModel(),
        achievements = achievements.map { it.toViewModel() },
        rewards = rewards.map { it.toViewModel() },
        loadingProducts = loadingProducts.toViewModel(),
        products = products.map { it.toViewModel() },
        userProducts = userProducts.map { it.toViewModel() },
        loadingProductCheckout = loadingProductCheckout.toViewModel(),
        latestProductCheckout = latestProductCheckout?.toViewModel(),
        acceptedLegalConditions = acceptedLegalConditions,
        loadingProductsDashboard = loadingProductsDashboard.toViewModel(),
        latestProductsDashboard = latestProductsDashboard?.toViewModel()
    )
}

internal val EVENT_TO_INTENT: Event.() -> Intent = {
    when (this) {
        Event.Logout -> Intent.Logout
        Event.GetAvailableInterests -> Intent.GetAvailableInterests
        Event.GetAvailableAvatars -> Intent.GetAvailableAvatars
        is Event.UpdateInterest -> Intent.UpdateInterests(interests.toSet().mapToSet { it.toModel() })
        is Event.UpdateLanguage -> Intent.UpdateLanguage(language)
        is Event.UpdateAvatar -> Intent.UpdateAvatar(avatar.toModel())
        is Event.UpdateCredentials -> Intent.UpdateCredentials(oldCredentials, newCredentials)
        is Event.UpdateFirstName -> Intent.UpdateFirstName(firstName)
        is Event.UpdateLastName -> Intent.UpdateLastName(lastName)
        Event.DeleteAccount -> Intent.DeleteAccount
        Event.ReloadUserData -> Intent.ReloadUserData
        Event.ReloadUserStats -> Intent.ReloadUserStats
        Event.LogUserProfileView -> Intent.LogUserProfileView
        is Event.PublishContent -> Intent.PublishContent(contentId, payload)
        is Event.GetProductCheckout -> Intent.GetProductCheckout(id, successUrl, cancelUrl)
        Event.ReloadProducts -> Intent.ReloadProducts
        Event.LoadMoreUserProducts -> Intent.LoadMoreUserProducts
        is Event.CheckCredentialsSecurity -> Intent.CheckCredentialsSecurity(credentials)
        is Event.GetProductsDashboard -> Intent.GetProductsDashboard(returnUrl)
    }
}

internal val LABEL_TO_OUTPUT: Label.() -> Output = {
    when (this) {
        is Label.UnexpectedError -> Output.UnexpectedError(code)
        Label.UserSessionExpired -> Output.UserSessionExpired
        Label.RequestTimedOut -> Output.RequestTimedOut
        Label.LoggedOut -> Output.LoggedOut
        Label.InvalidCredentials -> Output.InvalidCredentials
        Label.InsecureCredentials -> Output.InsecureCredentials
        Label.FirstNameUpdateFailed -> Output.FirstNameUpdateFailed
        Label.LastNameUpdateFailed -> Output.LastNameUpdateFailed
        Label.AvatarUpdateFailed -> Output.AvatarUpdateFailed
        Label.InterestsUpdateFailed -> Output.InterestsUpdateFailed
        Label.LanguageUpdateFailed -> Output.LanguageUpdateFailed
        Label.CredentialsUpdateFailed -> Output.CredentialsUpdateFailed
        Label.UserDataLoadFailed -> Output.UserDataLoadFailed
        Label.AvatarsRetrievalFailed -> Output.AvatarsRetrievalFailed
        Label.InterestsRetrievalFailed -> Output.InterestsRetrievalFailed
        Label.NoUserLoggedIn -> Output.NoUserLoggedIn
        Label.DeleteAccountFailed -> Output.DeleteAccountFailed
        Label.AccountDeleted -> Output.AccountDeleted
        is Label.ContentPublication -> Output.ContentPublication(granted)
        is Label.IllegalOperationError -> Output.IllegalOperationError(reason)
        Label.SecureCredentials -> Output.SecureCredentials
    }
}

package es.cinfo.tiivii.user.signup.usecase

import es.cinfo.tiivii.core.ComponentId
import es.cinfo.tiivii.core.ErrorId
import es.cinfo.tiivii.core.UseCaseId
import es.cinfo.tiivii.core.modules.analytics.LogEvent
import es.cinfo.tiivii.core.modules.analytics.model.AnalyticsModel.Action
import es.cinfo.tiivii.core.modules.auth.AuthService
import es.cinfo.tiivii.core.modules.avatar.model.AvatarModel.Model.Avatar
import es.cinfo.tiivii.core.date.DateService
import es.cinfo.tiivii.core.error.CodedError
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.error.asErrorId
import es.cinfo.tiivii.core.interest.model.Model.Interest
import es.cinfo.tiivii.core.layout.LayoutService
import es.cinfo.tiivii.core.modules.config.ConfigModel
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.profile.ProfileModel.Model.Profile
import es.cinfo.tiivii.core.user.UserService
import es.cinfo.tiivii.core.util.*
import es.cinfo.tiivii.di.diContainer
import es.cinfo.tiivii.user.role.Role
import es.cinfo.tiivii.user.signup.SignupService
import org.kodein.di.instance

internal class GetAvailableLanguages : UseCase<Set<String>> {
    private val configModule: ConfigModule by diContainer.instance()

    override suspend fun invoke(): Set<String> {
        return configModule.getCoreConfig().signup.availableLanguages.toSet()
    }
}

internal class GetDefaultLanguage : UseCase<String> {
    private val configModule: ConfigModule by diContainer.instance()

    override suspend fun invoke(): String {
        return configModule.getCoreConfig().signup.defaultLanguage
    }
}

internal class IsUsernameValid(private val username: String) : OutcomeUseCase<Boolean, IsUsernameValid.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.SIGNUP, UseCaseId.IS_USERNAME_VALID_SIGNUP, errorId, networkError) {
        object BlankUsername : Error(
            asErrorId<BlankUsername>(1)
        )
        object UsernameInUse : Error(
            asErrorId<UsernameInUse>(2)
        )
        data class UsernameCheckUnavailable(val error: NetworkError) : Error(
            asErrorId<UsernameCheckUnavailable>(3),
            error
        )
    }

    private val userService: UserService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Boolean, Error>
        get() = {
            if (username.isNotBlank()) {
                val existsUser = userService.existsUserWithUsername(username)
                    .mapError { Error.UsernameCheckUnavailable(it) }
                    .getOrAbort()
                if (existsUser) {
                    failure(Error.UsernameInUse)
                } else {
                    success(true)
                }
            } else {
                failure(Error.BlankUsername)
            }
        }
}

internal class IsEmailValid(private val email: String, private val birthday: String?) : OutcomeUseCase<Boolean, IsEmailValid.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.SIGNUP, UseCaseId.IS_EMAIL_VALID, errorId, networkError) {
        object BlankEmail : Error(
            asErrorId<BlankEmail>(1)
        )
        object EmailInUse : Error(
            asErrorId<EmailInUse>(2)
        )
        data class EmailCheckUnavailable(val error: NetworkError) : Error(
            asErrorId<EmailCheckUnavailable>(3),
            error
        )
        object MissingRequiredBirthday : Error(
            asErrorId<MissingRequiredBirthday>(4)
        )
    }

    private val configModule: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Boolean, Error>
        get() = {
            when (configModule.getCoreConfig().signup.mode) {
                ConfigModel.Model.SignupConfig.Mode.ByAge -> {
                    if (birthday == null) {
                        failure(Error.MissingRequiredBirthday)
                    } else {
                        IsEmailValidByAge(email, birthday).invoke()
                    }
                }
                is ConfigModel.Model.SignupConfig.Mode.Simple ->
                    IsEmailValidSimple(email).invoke()
            }
        }
}

internal class IsEmailValidByAge(private val email: String, private val birthday: String) : OutcomeUseCase<Boolean, IsEmailValid.Error>() {
    private val userService: UserService by diContainer.instance()
    private val dateService: DateService by diContainer.instance()

    override val work: suspend TryOutcomeContext<IsEmailValid.Error>.() -> Outcome<Boolean, IsEmailValid.Error>
        get() = {
            if (email.isNotBlank()) {
                val checkExistsEmail = dateService.yearsUntil(birthday) >= 14
                val existsUser = if (checkExistsEmail) {
                    userService.existsUserWithEmail(email)
                        .mapError { IsEmailValid.Error.EmailCheckUnavailable(it) }
                        .getOrAbort()
                } else {
                    false
                }
                if (existsUser) {
                    failure(IsEmailValid.Error.EmailInUse)
                } else {
                    success(true)
                }
            } else {
                failure(IsEmailValid.Error.BlankEmail)
            }
        }
}

internal class IsEmailValidSimple(private val email: String) : OutcomeUseCase<Boolean, IsEmailValid.Error>() {
    private val userService: UserService by diContainer.instance()

    override val work: suspend TryOutcomeContext<IsEmailValid.Error>.() -> Outcome<Boolean, IsEmailValid.Error>
        get() = {
            if (email.isNotBlank()) {
                val existsUser = userService.existsUserWithEmail(email)
                    .mapError { IsEmailValid.Error.EmailCheckUnavailable(it) }
                    .getOrAbort()
                if (existsUser) {
                    failure(IsEmailValid.Error.EmailInUse)
                } else {
                    success(true)
                }
            } else {
                failure(IsEmailValid.Error.BlankEmail)
            }
        }
}

/**
 * Checks if the given credentials comply with the security measures
 *
 * The credentials must have at least:
 * * 8 characters
 * * 1 letter
 * * 1 number
 * * 1 special character (Any of the ones inside the quotes "__!%*#?&__")
 */
internal class AreCredentialsValid(private val credentials: String) : UseCase<Boolean> {
    override suspend fun invoke(): Boolean {
        // 8 chars, at least 1 letter, 1 number, 1 special character
        val validator =
            "^(?=.*[A-Za-z])(?=.*\\d)(?=.*[!\"#\$%&'()*+,-./:;<=>?@\\[\\]^_`{|}~])[A-Za-z\\d!\"#\$%&'()*+,-./:;<=>?@\\[\\]^_`{|}~]{8,}\$".toRegex()
        return validator.matches(credentials)
    }
}

internal class IsBirthdayValid(private val birthday: String) : UseCase<Boolean> {
    private val dateService: DateService by diContainer.instance()

    override suspend fun invoke(): Boolean {

        return if (birthday.isNotBlank()) {
            try {
                val age = dateService.yearsUntil(birthday)
                age >= 0
            } catch (e: RuntimeException) {
                false
            }
        } else {
            false
        }
    }
}

internal class GetRole(private val birthday: String) : OutcomeUseCase<Role, GetRole.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.SIGNUP, UseCaseId.GET_ROLE, errorId, networkError) {
        object InvalidAge: Error(
            asErrorId<InvalidAge>(1)
        )
    }
    private val dateService: DateService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Role, Error>
        get() = {
            try {
                val age = dateService.yearsUntil(birthday)
                val role = Role.fromAge(age)
                success(role)
            } catch (e: RuntimeException) {
                failure(Error.InvalidAge)
            }
        }
}

internal class IsNameValid(private val name: String) : UseCase<Boolean> {
    override suspend fun invoke(): Boolean {
        return name.isNotBlank()
    }
}

internal class GetRandomUsername : OutcomeUseCase<String, GetRandomUsername.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.SIGNUP, UseCaseId.GET_RANDOM_USERNAME, errorId, networkError) {
            data class UnavailableRandomizer(val error: NetworkError): Error(
                asErrorId<UnavailableRandomizer>(1),
                error
            )
    }
    private val userService: UserService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<String, Error>
        get() = {
            userService.getRandomUsername().mapError {
                Error.UnavailableRandomizer(it)
            }
        }
}

internal class CreateUser(
    private val username: String?,
    private val avatar: Avatar?,
    private val language: String?,
    private val credentials: String?,
    private val firstName: String?,
    private val lastName: String?,
    private val email: String?,
    private val birthday: String?,
    private val interests: Set<Interest>,
) : OutcomeUseCase<Boolean, CreateUser.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.SIGNUP, UseCaseId.CREATE_USER, errorId, networkError) {
        data class InvalidProperty(val invalidProperties: List<String>) : Error(
            asErrorId<InvalidProperty>(1)
        )
        data class UserCreationUnavailable(val error: NetworkError) : Error(
            asErrorId<UserCreationUnavailable>(2),
            error
        )
        data class UnavailableLegalConditions(val error: NetworkError) : Error(
            asErrorId<UnavailableLegalConditions>(3),
            error
        )
        object InvalidHash : Error(
            asErrorId<InvalidHash>(4)
        )
    }

    private val configService: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Boolean, Error>
        get() = {
            when (val mode = configService.getCoreConfig().signup.mode) {
                ConfigModel.Model.SignupConfig.Mode.ByAge -> {
                    CreateUserByAge(username, avatar, language, credentials, firstName, lastName, email, birthday, interests).invoke()
                }
                is ConfigModel.Model.SignupConfig.Mode.Simple -> {
                    val birthday = mode.defaultBirthday
                    CreateUserSimple(username, avatar, language, credentials, firstName, lastName, email, birthday, interests).invoke()
                }
            }
        }
}


internal class CreateUserByAge(
    private val username: String?,
    private val avatar: Avatar?,
    private val language: String?,
    private val credentials: String?,
    private val firstName: String?,
    private val lastName: String?,
    private val email: String?,
    private val birthday: String?,
    private val interests: Set<Interest>,
) : OutcomeUseCase<Boolean, CreateUser.Error>() {
    private val dateService: DateService by diContainer.instance()
    private val signupService: SignupService by diContainer.instance()
    private val layoutService: LayoutService by diContainer.instance()
    private val authService: AuthService by diContainer.instance()
    private val userService: UserService by diContainer.instance()
    private val configService: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<CreateUser.Error>.() -> Outcome<Boolean, CreateUser.Error>
        get() = {
            var userProfile = configService.getCoreConfig().signup.defaultProfile
            var userLanguage = configService.getCoreConfig().signup.defaultLanguage
            val auth = authService.getStoredAuth()
            if (auth != null) {
                val user = userService.getUser(auth.username)
                    .mapError { CreateUser.Error.UserCreationUnavailable(it) }.getOrAbort()
                userProfile = user.profile
                userLanguage = user.preferredLanguage
            }
            val legalVersion = layoutService.getLayoutConfig(userProfile, userLanguage)
                .mapError { CreateUser.Error.UnavailableLegalConditions(it) }.getOrAbort()
                .legal.version
            val invalidProperties = getInvalidProperties(
                username, avatar, language, credentials, firstName, lastName,
                email, birthday
            )
            if (invalidProperties.isEmpty()) {
                val hasTutor = dateService.yearsUntil(birthday!!) < 14
                signupService.signupUser(
                    language!!, legalVersion, username!!, firstName!!, lastName!!, credentials!!,
                    email!!, hasTutor, birthday, interests, avatar!!
                )
                    .on {
                        // Signup event log
                        LogEvent(Action.SignUp).invoke()
                    }
                    .mapError {
                        if (it.getHttpStatusCode() == 401) {
                            CreateUser.Error.InvalidHash
                        } else {
                            CreateUser.Error.UserCreationUnavailable(it)
                        }
                    }
            } else {
                failure(CreateUser.Error.InvalidProperty(invalidProperties))
            }
        }

    private fun getInvalidProperties(
        username: String?,
        avatar: Avatar?,
        language: String?,
        credentials: String?,
        firstName: String?,
        lastName: String?,
        email: String?,
        birthday: String?,
    ): List<String> {
        val invalidProperties = mutableListOf<String>()
        if (username == null) {
            invalidProperties.add("username")
        }
        if (avatar == null) {
            invalidProperties.add("avatar")
        }
        if (language == null) {
            invalidProperties.add("language")
        }
        if (credentials == null) {
            invalidProperties.add("credentials")
        }
        if (firstName == null) {
            invalidProperties.add("firstName")
        }
        if (lastName == null) {
            invalidProperties.add("lastName")
        }
        if (email == null) {
            invalidProperties.add("email")
        }
        if (birthday == null) {
            invalidProperties.add("birthday")
        }
        return invalidProperties
    }
}

internal class CreateUserSimple(
    private val username: String?,
    private val avatar: Avatar?,
    private val language: String?,
    private val credentials: String?,
    private val firstName: String?,
    private val lastName: String?,
    private val email: String?,
    private val birthday: String,
    private val interests: Set<Interest>,
) : OutcomeUseCase<Boolean, CreateUser.Error>() {
    private val signupService: SignupService by diContainer.instance()
    private val layoutService: LayoutService by diContainer.instance()
    private val authService: AuthService by diContainer.instance()
    private val userService: UserService by diContainer.instance()
    private val configService: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<CreateUser.Error>.() -> Outcome<Boolean, CreateUser.Error>
        get() = {
            var userProfile = configService.getCoreConfig().signup.defaultProfile
            var userLanguage = configService.getCoreConfig().signup.defaultLanguage
            val auth = authService.getStoredAuth()
            if (auth != null) {
                val user = userService.getUser(auth.username)
                    .mapError { CreateUser.Error.UserCreationUnavailable(it) }.getOrAbort()
                userProfile = user.profile
                userLanguage = user.preferredLanguage
            }
            val legalVersion = layoutService.getLayoutConfig(userProfile, userLanguage)
                .mapError { CreateUser.Error.UnavailableLegalConditions(it) }.getOrAbort()
                .legal.version
            val invalidProperties = getInvalidProperties(
                username, avatar, language, credentials, firstName, lastName,
                email
            )
            if (invalidProperties.isEmpty()) {
                signupService.signupUser(
                    language!!, legalVersion, username!!, firstName!!, lastName!!, credentials!!,
                    email!!, false, birthday, interests, avatar!!
                )
                    .on {
                        // Signup event log
                        LogEvent(Action.SignUp).invoke()
                    }
                    .mapError {
                        if (it.getHttpStatusCode() == 401) {
                            CreateUser.Error.InvalidHash
                        } else {
                            CreateUser.Error.UserCreationUnavailable(it)
                        }
                    }
            } else {
                failure(CreateUser.Error.InvalidProperty(invalidProperties))
            }
        }

    private fun getInvalidProperties(
        username: String?,
        avatar: Avatar?,
        language: String?,
        credentials: String?,
        firstName: String?,
        lastName: String?,
        email: String?
    ): List<String> {
        val invalidProperties = mutableListOf<String>()
        if (username == null) {
            invalidProperties.add("username")
        }
        if (avatar == null) {
            invalidProperties.add("avatar")
        }
        if (language == null) {
            invalidProperties.add("language")
        }
        if (credentials == null) {
            invalidProperties.add("credentials")
        }
        if (firstName == null) {
            invalidProperties.add("firstName")
        }
        if (lastName == null) {
            invalidProperties.add("lastName")
        }
        if (email == null) {
            invalidProperties.add("email")
        }
        return invalidProperties
    }
}
package es.cinfo.tiivii.search.store

import com.arkivanov.mvikotlin.core.store.Store
import es.cinfo.tiivii.core.util.LoadingModel
import es.cinfo.tiivii.search.model.Search.Model.SearchResult.SearchResultEntry
import es.cinfo.tiivii.search.store.SearchStore.*
import es.cinfo.tiivii.search.view.SearchView

internal interface SearchStore : Store<Intent, State, Label> {

    sealed class Intent {
        data class GetSuggestions(
            val searchQuery: String
        ) : Intent()
        object ClearSuggestions : Intent()
        data class PerformSearch(
            val searchQuery: String
        ) : Intent()
        object ClearSearch : Intent()
        object LogSearchView : Intent()
        data class LogSelectItem(val contentId: Int) : Intent()
    }

    data class State(
        val searchLoading: LoadingModel.Model.LoadState,
        val suggestionsLoading: LoadingModel.Model.LoadState,
        val searchResults: List<SearchResultEntry>,
        val suggestions: List<SearchResultEntry>,
    )

    sealed class Label {
        object UserSessionExpired: Label()
        object RequestTimeOut: Label()
        data class UnexpectedError(val code: String) : Label()
    }
}
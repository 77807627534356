package es.cinfo.tiivii.home.view

import com.arkivanov.mvikotlin.core.view.MviView
import es.cinfo.tiivii.core.content.model.WidgetModel
import es.cinfo.tiivii.core.modules.game.model.GameModel
import es.cinfo.tiivii.home.view.HomeView.Event
import es.cinfo.tiivii.home.view.HomeView.Model

/**
 * View implementation for the home screen
 */
interface HomeView : MviView<Model, Event> {

    /**
     * Operations available to be requested for the home screen
     * @see LoadDefaultScreen
     * @see LoadScreen
     * @see AddToFavorites
     * @see RemoveFromFavorites
     * @see LogHomeView
     * @see LogSelectItem
     */
    sealed class Event {
        /**
         * Request the default home screen to be loaded
         */
        object LoadDefaultScreen: Event()

        /**
         * Request the screen with the given id to be loaded
         * @param id of the screen to be loaded
         */
        data class LoadScreen(
            val id: Int
        ) : Event()

        /**
         * Requests the content with the given id to be added as a favorite
         * @param contentId to be added to the favorites list
         */
        data class AddToFavorites(
            val contentId: Int
        ): Event()

        /**
         * Requests the content with the given id to be removed from favorites
         * @param contentId to be removed from the favorites list
         */
        data class RemoveFromFavorites(
            val contentId: Int
        ): Event()

        /**
         * Request a home screen view log to be made. It should be send
         * whenever the home screen is viewed. This event is exposed for more grained control
         * for the UI
         */
        object LogHomeView : Event()

        /**
         * Request a home content selection log to be made. It should be send
         * whenever the user selects a content in the home screen. This event is exposed for more grained control
         * for the UI
         */
        data class LogSelectItem(val contentId: Int) : Event()
    }

    /**
     * The model of the home screen exposed to the UI
     * @param loadedScreen identifies the screen that has been loaded by its id (if any)
     * @param screenWidgets contains the current list of widgets loaded or being loaded
     */
    data class Model(
        val loadedScreen: Int?,
        val screenWidgets: List<WidgetModel.ViewModel.WidgetContentLoad>
    )

    /**
     * One-time notifications that can be received on the home screen
     * @see UnexpectedError
     * @see UserSessionExpired
     */
    sealed class Output {

        /**
         * Unexpected error happened
         * @param code string representation of a internal error code to be shown with the generic error to the user
         */
        data class UnexpectedError(val code: String) : Output()

        /**
         * A request has timed out. This may indicate a problem with the internet connection
         */
        object RequestTimedOut : Output()

        /**
         * A game action has been sent. The user points, ranking or achievements may have changed
         * @param actionResult indicates if the game action has been successful or has failed with an
         * [GameModel.ViewModel.ActionResult]
         */
        data class GameActionSent(
            val actionResult: GameModel.ViewModel.ActionResult
        ): Output()

        /**
         * User session has expired, user needs to log in again
         */
        object UserSessionExpired : Output()
    }

}

package es.cinfo.tiivii.user.profile.store

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.SuspendExecutor
import es.cinfo.tiivii.core.ComponentId
import es.cinfo.tiivii.core.error.ErrorService
import es.cinfo.tiivii.core.interest.model.Model.Interest
import es.cinfo.tiivii.core.modules.analytics.LogEvent
import es.cinfo.tiivii.core.modules.analytics.model.AnalyticsModel
import es.cinfo.tiivii.core.modules.avatar.model.AvatarModel.Model.Avatar
import es.cinfo.tiivii.core.modules.product.model.ProductModel
import es.cinfo.tiivii.core.modules.product.model.ProductModel.Model.Product
import es.cinfo.tiivii.core.modules.product.model.ProductModel.Model.ProductCheckout
import es.cinfo.tiivii.core.usecase.*
import es.cinfo.tiivii.core.user.model.Model.User
import es.cinfo.tiivii.core.userstats.UserStatsModel.Model.UserStats
import es.cinfo.tiivii.core.util.Failure
import es.cinfo.tiivii.core.util.LoadingModel.Model.LoadState
import es.cinfo.tiivii.core.util.Success
import es.cinfo.tiivii.di.diContainer
import es.cinfo.tiivii.user.legal.usecase.GetLegalConditionsVersion
import es.cinfo.tiivii.user.legal.usecase.LegalConditions
import es.cinfo.tiivii.user.profile.store.UserStore.*
import es.cinfo.tiivii.user.profile.usecase.*
import es.cinfo.tiivii.user.role.Role
import es.cinfo.tiivii.user.signup.usecase.AreCredentialsValid
import es.cinfo.tiivii.user.signup.usecase.GetAvailableLanguages
import es.cinfo.tiivii.user.signup.usecase.GetDefaultLanguage
import org.kodein.di.instance

internal class UserStoreFactory(
    private val storeFactory: StoreFactory,
) {

    private val errorService: ErrorService by diContainer.instance()

    private sealed class Result {
        data class LoadedUser(val user: User, val stats: UserStats?, val availableRoles: Set<Role>) : Result()
        object LoadingUserData : Result()
        object ErrorLoadingUserData : Result()
        data class AvailableInterests(val interests: Set<Interest>) : Result()
        data class AvailableAvatars(val avatars: Set<Avatar>) : Result()
        data class InterestsUpdated(val interests: Set<Interest>) : Result()
        data class LanguageUpdated(val language: String) : Result()
        data class FirstNameUpdated(val firstName: String) : Result()
        data class LastNameUpdated(val lastName: String) : Result()
        data class AvatarUpdated(val avatar: Avatar) : Result()
        data class CredentialsUpdated(val credentials: String) : Result()
        object LoggedOut : Result()
        object AccountDeleted : Result()
        data class LoadedUserStats(val userStats: UserStats) : Result()
        object LoadingUserStats : Result()
        object ErrorLoadingUserStats : Result()
        object LoadingProductCheckout: Result()
        object ErrorLoadingProductCheckout: Result()
        data class ProductCheckoutLoaded(val productCheckout: ProductCheckout): Result()
        object LoadingProducts: Result()
        object ErrorLoadingProducts: Result()
        data class ProductsLoaded(val products: List<Product>, val userProducts: ProductModel.Model.ProductsLoad): Result()
        data class AvailableLanguages(val languages: Set<String>) : Result()
        data class DefaultLanguage(val defaultLanguage: String) : Result()
        data class AcceptedLegalConditions(val legalConditions: LegalConditions): Result()
        object LoadingProductsDashboard: Result()
        object ErrorLoadingProductsDashboard: Result()
        data class ProductsDashboardLoaded(val productsDashboard: ProductModel.Model.ProductsDashboard): Result()
    }

    fun create(): UserStore =
        object :
            UserStore,
            Store<Intent, State, Label> by
            storeFactory.create(
                name = "UserStore",
                initialState = State(
                    loadingUser = LoadState.RESET,
                    availableRoles = emptySet(),
                    username = null,
                    email = null,
                    birthday = null,
                    availableLanguages = emptySet(),
                    language = null,
                    availableAvatars = emptySet(),
                    avatar = null,
                    availableInterests = emptySet(),
                    interests = emptySet(),
                    credentials = null,
                    firstName = null,
                    lastName = null,
                    loadingUserStats = LoadState.RESET,
                    ranking = null,
                    achievements = emptyList(),
                    rewards = emptyList(),
                    loadingProducts = LoadState.RESET,
                    products = emptyList(),
                    userProducts = emptyList(),
                    hasMoreUserProducts = false,
                    loadingProductCheckout = LoadState.RESET,
                    latestProductCheckout = null,
                    acceptedLegalConditionsVersion = null,
                    acceptedLegalConditions = null,
                    loadingProductsDashboard = LoadState.RESET,
                    latestProductsDashboard = null
                ),
                bootstrapper = SimpleBootstrapper(Action.GetAvailableLanguages, Action.LoadUserData, Action.LoadUserProducts),
                reducer = DefaultReducer,
                executorFactory = ::DefaultExecutor
            ) {
        }

    private object DefaultReducer : Reducer<State, Result> {
        override fun State.reduce(result: Result): State =
            when (result) {
                is Result.LoadedUser -> copy(
                    loadingUser = LoadState.LOADED,
                    availableRoles = result.availableRoles,
                    username = result.user.username,
                    email = result.user.email,
                    birthday = result.user.birthday,
                    language = result.user.preferredLanguage,
                    interests = result.user.interests,
                    avatar = result.user.avatar,
                    firstName = result.user.firstName,
                    lastName = result.user.lastName,
                    ranking = result.stats?.ranking,
                    achievements = result.stats?.achievements ?: emptyList(),
                    rewards = result.stats?.rewards ?: emptyList(),
                    acceptedLegalConditionsVersion = result.user.signedLegalVersion
                )
                Result.LoadingUserData -> copy(
                    loadingUser = LoadState.LOADING,
                    username = null,
                    email = null,
                    birthday = null,
                    language = null,
                    avatar = null,
                    interests = emptySet(),
                    credentials = null,
                    firstName = null,
                    lastName = null,
                    ranking = null,
                    achievements = emptyList(),
                    rewards = emptyList(),
                    acceptedLegalConditions = null,
                    acceptedLegalConditionsVersion = null
                )
                is Result.ErrorLoadingUserData -> copy(
                    loadingUser = LoadState.RESET
                )
                is Result.AvailableInterests -> copy(
                    availableInterests = result.interests
                )
                is Result.AvailableAvatars -> copy(
                    availableAvatars = result.avatars
                )
                is Result.InterestsUpdated -> copy(
                    interests = result.interests
                )
                is Result.LanguageUpdated -> copy(
                    language = result.language
                )
                is Result.FirstNameUpdated -> copy(
                    firstName = result.firstName
                )
                is Result.LastNameUpdated -> copy(
                    lastName = result.lastName
                )
                is Result.AvatarUpdated -> copy(
                    avatar = result.avatar
                )
                is Result.CredentialsUpdated -> copy(
                    credentials = result.credentials
                )
                Result.LoggedOut -> State(
                    loadingUser = LoadState.RESET,
                    availableRoles = emptySet(),
                    username = null,
                    email = null,
                    birthday = null,
                    availableLanguages = emptySet(),
                    language = null,
                    availableAvatars = emptySet(),
                    avatar = null,
                    availableInterests = emptySet(),
                    interests = emptySet(),
                    credentials = null,
                    firstName = null,
                    lastName = null,
                    loadingUserStats = LoadState.RESET,
                    ranking = null,
                    achievements = emptyList(),
                    rewards = emptyList(),
                    loadingProducts = LoadState.RESET,
                    products = emptyList(),
                    userProducts = emptyList(),
                    hasMoreUserProducts = false,
                    loadingProductCheckout = LoadState.RESET,
                    latestProductCheckout = null,
                    acceptedLegalConditionsVersion = null,
                    acceptedLegalConditions = null,
                    loadingProductsDashboard = LoadState.RESET,
                    latestProductsDashboard = null
                )
                Result.AccountDeleted -> State(
                    loadingUser = LoadState.RESET,
                    availableRoles = emptySet(),
                    username = null,
                    email = null,
                    birthday = null,
                    availableLanguages = emptySet(),
                    language = null,
                    availableAvatars = emptySet(),
                    avatar = null,
                    availableInterests = emptySet(),
                    interests = emptySet(),
                    credentials = null,
                    firstName = null,
                    lastName = null,
                    loadingUserStats = LoadState.RESET,
                    ranking = null,
                    achievements = emptyList(),
                    rewards = emptyList(),
                    loadingProducts = LoadState.RESET,
                    products = emptyList(),
                    userProducts = emptyList(),
                    hasMoreUserProducts = false,
                    loadingProductCheckout = LoadState.RESET,
                    latestProductCheckout = null,
                    acceptedLegalConditionsVersion = null,
                    acceptedLegalConditions = null,
                    loadingProductsDashboard = LoadState.RESET,
                    latestProductsDashboard = null
                )
                is Result.LoadedUserStats -> copy(
                    loadingUserStats = LoadState.LOADED,
                    ranking = result.userStats.ranking,
                    achievements = result.userStats.achievements,
                    rewards = result.userStats.rewards
                )
                Result.ErrorLoadingUserStats -> copy(
                    loadingUserStats = LoadState.RESET
                )
                Result.LoadingUserStats -> copy(
                    loadingUserStats = LoadState.LOADING,
                    ranking = null,
                    achievements = emptyList(),
                    rewards = emptyList()
                )
                Result.ErrorLoadingProductCheckout -> copy(
                    loadingProductCheckout = LoadState.RESET
                )
                Result.LoadingProductCheckout -> copy(
                    loadingProductCheckout = LoadState.LOADING,
                    latestProductCheckout = null
                )
                is Result.ProductCheckoutLoaded -> copy(
                    loadingProductCheckout = LoadState.LOADED,
                    latestProductCheckout = result.productCheckout
                )
                Result.ErrorLoadingProducts -> copy(
                    loadingProducts = LoadState.RESET
                )
                Result.LoadingProducts -> copy(
                    loadingProducts = LoadState.LOADING,
                    products = emptyList(),
                    userProducts = emptyList(),
                    hasMoreUserProducts = false
                )
                is Result.ProductsLoaded -> copy(
                    loadingProducts = LoadState.LOADED,
                    products = result.products,
                    userProducts = result.userProducts.products,
                    hasMoreUserProducts = result.userProducts.hasMoreContent()
                )
                is Result.AvailableLanguages -> copy(
                    availableLanguages = result.languages
                )
                is Result.DefaultLanguage -> copy(
                    language = result.defaultLanguage
                )
                is Result.AcceptedLegalConditions -> copy(
                    acceptedLegalConditions = result.legalConditions.text
                )
                Result.ErrorLoadingProductsDashboard -> copy(
                    loadingProductsDashboard = LoadState.RESET,
                    latestProductsDashboard = null
                )
                Result.LoadingProductsDashboard -> copy(
                    loadingProductsDashboard = LoadState.LOADING,
                    latestProductsDashboard = null
                )
                is Result.ProductsDashboardLoaded -> copy(
                    loadingProductsDashboard = LoadState.LOADED,
                    latestProductsDashboard = result.productsDashboard
                )
            }
    }

    private inner class DefaultExecutor :
        SuspendExecutor<Intent, Action,
                State,
                Result, Label>() {
        override suspend fun executeIntent(intent: Intent, getState: () -> State) {
            when (intent) {
                Intent.Logout -> logout()
                Intent.DeleteAccount -> deleteAccount()
                Intent.GetAvailableInterests -> getAvailableInterests()
                Intent.GetAvailableAvatars -> getAvailableAvatars()
                is Intent.UpdateInterests -> updateInterests(getState().username!!, intent.interests)
                is Intent.UpdateLanguage -> updateLanguage(getState().username!!, intent.language, getState().acceptedLegalConditionsVersion!!)
                is Intent.UpdateAvatar -> updateAvatar(getState().username!!, intent.avatar)
                is Intent.UpdateCredentials -> updateCredentials(
                    getState().username!!,
                    intent.oldCredentials,
                    intent.newCredentials
                )
                is Intent.UpdateFirstName -> updateFirstName(getState().username!!, intent.firstName)
                is Intent.UpdateLastName -> updateLastName(getState().username!!, intent.lastName)
                Intent.ReloadUserData -> getUserData()
                Intent.ReloadUserStats -> reloadUserStats()
                Intent.LogUserProfileView -> logUserProfileView()
                is Intent.PublishContent -> publishContent(intent.payload)
                is Intent.GetProductCheckout -> getProductCheckout(intent.id, intent.successUrl, intent.cancelUrl)
                Intent.ReloadProducts -> loadProducts(false)
                Intent.LoadMoreUserProducts -> loadMoreUserProducts()
                is Intent.CheckCredentialsSecurity -> checkCredentialsSecurity(intent.credentials)
                is Intent.GetProductsDashboard -> getProductsDashboard(intent.returnUrl)
            }
        }

        override suspend fun executeAction(action: Action, getState: () -> State) {
            when (action) {
                Action.LoadUserData -> getUserData()
                Action.LoadUserProducts -> loadProducts(true)
                Action.GetAvailableLanguages -> getAvailableLanguages()
            }
        }

        private suspend fun getAvailableLanguages() {
            val languages = GetAvailableLanguages().invoke()
            dispatch(Result.AvailableLanguages(languages))
        }

        private suspend fun getFallbackLanguage() {
            val defaultLanguage = GetDefaultLanguage().invoke()
            dispatch(Result.DefaultLanguage(defaultLanguage))
        }

        private suspend fun checkCredentialsSecurity(credentials: String) {
            val areCredentialsValid = AreCredentialsValid(credentials).invoke()
            if (areCredentialsValid) {
                publish(Label.SecureCredentials)
            } else {
                publish(Label.InsecureCredentials)
            }
        }

        private suspend fun getUserData() {
            dispatch(Result.LoadingUserData)
            val roles = GetAvailableRoles().invoke()
            when (val outcome = GetCurrentUser().invokeWith(ComponentId.PROFILE)) {
                is Success -> {
                    dispatch(Result.LoadedUser(outcome.value.user, outcome.value.stats, roles))
                    getAcceptedLegalConditions(outcome.value.user.signedLegalVersion)
                }
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = {
                            dispatch(Result.ErrorLoadingUserData)
                            publish(Label.UserSessionExpired)
                        },
                        requestTimeoutAction = {
                            dispatch(Result.ErrorLoadingUserData)
                            publish(Label.RequestTimedOut)
                        },
                        handler = {
                            dispatch(Result.ErrorLoadingUserData)
                            when (outcome.error) {
                                is GetCurrentUser.Error.AuthDataUnavailable -> {
                                    getFallbackLanguage()
                                    publish(Label.NoUserLoggedIn)
                                }
                                is GetCurrentUser.Error.UserUnavailable -> {
                                    publish(Label.UserDataLoadFailed)
                                }
                            }
                        }
                    )
                }
            }
        }

        private suspend fun getAcceptedLegalConditions(version: Int) {
            when (val outcome = GetLegalConditionsVersion(version, null).invoke()) {
                is Success -> dispatch(Result.AcceptedLegalConditions(outcome.value))
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                GetLegalConditionsVersion.Error.MissingLegalConditions,
                                is GetLegalConditionsVersion.Error.UnavailableLegalConditions -> {
                                    publish(Label.UnexpectedError(outcome.error.getCode()))
                                }
                                GetLegalConditionsVersion.Error.LegalModuleDisabled -> {
                                    // Implicit call, no need to notify the UI
                                }
                            }
                        }
                    )
                }
            }
        }

        private suspend fun reloadUserStats() {
            dispatch(Result.LoadingUserStats)
            when (val outcome = ReloadUserStats().invokeWith(ComponentId.PROFILE)) {
                is Success -> dispatch(Result.LoadedUserStats(outcome.value))
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = {
                            dispatch(Result.ErrorLoadingUserStats)
                            publish(Label.UserSessionExpired)
                        },
                        requestTimeoutAction = {
                            dispatch(Result.ErrorLoadingUserStats)
                            publish(Label.RequestTimedOut)
                        },
                        handler = {
                            dispatch(Result.ErrorLoadingUserData)
                            when (outcome.error) {
                                is ReloadUserStats.Error.UserUnavailable,
                                is ReloadUserStats.Error.AuthDataUnavailable -> { }
                                is ReloadUserStats.Error.GamificationModuleDisabled -> {
                                    // Implicit call, no need to notify the UI
                                }
                            }
                        }
                    )
                }
            }
        }

        private suspend fun getAvailableInterests() {
            when (val outcome = GetAvailableInterests().invokeWith(ComponentId.PROFILE)) {
                is Success -> dispatch(Result.AvailableInterests(outcome.value))
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = {
                            publish(Label.UserSessionExpired)
                        },
                        requestTimeoutAction = {
                            publish(Label.RequestTimedOut)
                        },
                        handler = {
                            when (outcome.error) {
                                is GetAvailableInterests.Error.UnavailableInterests ->
                                    publish(Label.InterestsRetrievalFailed)
                            }
                        }
                    )
                }
            }
        }

        private suspend fun getAvailableAvatars() {
            when (val outcome = GetAvailableAvatars().invokeWith(ComponentId.PROFILE)) {
                is Success -> dispatch(Result.AvailableAvatars(outcome.value))
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = {
                            publish(Label.UserSessionExpired)
                        },
                        requestTimeoutAction = {
                            publish(Label.RequestTimedOut)
                        },
                        handler = {
                            when (outcome.error) {
                                is GetAvailableAvatars.Error.UnavailableAvatars ->
                                    publish(Label.AvatarsRetrievalFailed)
                            }
                        }
                    )
                }
            }
        }

        private suspend fun updateInterests(username: String, interests: Set<Interest>) {
            when (val outcome = UpdateInterests(username = username, interests = interests).invoke()) {
                is Success -> dispatch(Result.InterestsUpdated(interests))
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = {
                            publish(Label.UserSessionExpired)
                        },
                        requestTimeoutAction = {
                            publish(Label.RequestTimedOut)
                        },
                        handler = {
                            when (outcome.error) {
                                is UpdateInterests.Error.UnavailableUserUpdate ->
                                    publish(Label.InterestsUpdateFailed)
                            }
                        }
                    )
                }
            }
        }

        private suspend fun updateLanguage(username: String, language: String, legalVersion: Int) {
            when (val outcome = UpdateLanguage(username = username, language = language).invoke()) {
                is Success -> {
                    getAcceptedLegalConditions(legalVersion)
                    dispatch(Result.LanguageUpdated(language))
                }
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = {
                            publish(Label.UserSessionExpired)
                        },
                        requestTimeoutAction = {
                            publish(Label.RequestTimedOut)
                        },
                        handler = {
                            when (outcome.error) {
                                is UpdateLanguage.Error.UnavailableUserUpdate ->
                                    publish(Label.LanguageUpdateFailed)
                            }
                        }
                    )
                }
            }
        }

        private suspend fun updateAvatar(username: String, avatar: Avatar) {
            when (val outcome = UpdateAvatar(username = username, avatar = avatar).invoke()) {
                is Success -> dispatch(Result.AvatarUpdated(avatar))
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = {
                            publish(Label.UserSessionExpired)
                        },
                        requestTimeoutAction = {
                            publish(Label.RequestTimedOut)
                        },
                        handler = {
                            when (outcome.error) {
                                is UpdateAvatar.Error.UnavailableUserUpdate ->
                                    publish(Label.AvatarUpdateFailed)
                            }
                        }
                    )
                }
            }
        }

        private suspend fun updateCredentials(username: String, oldCredentials: String, newCredentials: String) {
            when (val outcome = UpdateCredentials(
                username = username,
                oldCredentials = oldCredentials,
                newCredentials = newCredentials
            ).invoke()) {
                is Success -> dispatch(Result.CredentialsUpdated(newCredentials))
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = {
                            publish(Label.UserSessionExpired)
                        },
                        requestTimeoutAction = {
                            publish(Label.RequestTimedOut)
                        },
                        handler = {
                            when (outcome.error) {
                                UpdateCredentials.Error.InsecureCredentials ->
                                    publish(Label.InsecureCredentials)
                                UpdateCredentials.Error.InvalidCredentials ->
                                    publish(Label.InvalidCredentials)
                                is UpdateCredentials.Error.UnavailableCredentialsUpdate ->
                                    publish(Label.CredentialsUpdateFailed)
                            }
                        }
                    )
                }
            }
        }

        private suspend fun updateFirstName(username: String, firstName: String) {
            when (val outcome = UpdateFirstName(
                username = username,
                firstName = firstName
            ).invoke()) {
                is Success -> dispatch(Result.FirstNameUpdated(firstName))
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = {
                            publish(Label.UserSessionExpired)
                        },
                        requestTimeoutAction = {
                            publish(Label.RequestTimedOut)
                        },
                        handler = {
                            when (outcome.error) {
                                is UpdateFirstName.Error.UnavailableUpdateUser ->
                                    publish(Label.FirstNameUpdateFailed)
                            }
                        }
                    )
                }
            }
        }

        private suspend fun updateLastName(username: String, lastName: String) {
            when (val outcome = UpdateLastName(
                username = username,
                lastName = lastName
            ).invoke()) {
                is Success -> dispatch(Result.LastNameUpdated(lastName))
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = {
                            publish(Label.UserSessionExpired)
                        },
                        requestTimeoutAction = {
                            publish(Label.RequestTimedOut)
                        },
                        handler = {
                            when (outcome.error) {
                                is UpdateLastName.Error.UnavailableUserUpdate ->
                                    publish(Label.LastNameUpdateFailed)
                            }
                        }
                    )
                }
            }
        }

        private suspend fun logUserProfileView() {
            LogEvent(AnalyticsModel.Action.ViewProfile, logIfAnonymous = false).invoke()
        }

        private suspend fun logout() {
            Logout().invoke()
            dispatch(Result.LoggedOut)
            publish(Label.LoggedOut)
        }

        private suspend fun deleteAccount() {
            when (DeleteAccount().invoke()) {
                is Success -> dispatch(Result.AccountDeleted)
                is Failure -> {
                    publish(Label.DeleteAccountFailed)
                }
            }
        }

        private suspend fun publishContent(payload: String) {
            when (val outcome = PublishContent(payload).invoke()) {
                is Success -> {
                    publish(Label.ContentPublication(outcome.value))
                }
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = {
                            publish(Label.UserSessionExpired)
                        },
                        requestTimeoutAction = {
                            publish(Label.RequestTimedOut)
                        },
                        handler = {
                            when (outcome.error) {
                                is PublishContent.Error.UnavailableContentPublication ->
                                    publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }

        private suspend fun loadProducts(isStoreAction: Boolean) {
            dispatch(Result.LoadingProducts)
            when (val outcome = LoadProducts().invoke()) {
                is Success -> dispatch(Result.ProductsLoaded(outcome.value.products, outcome.value.userProducts))
                is Failure -> {
                    dispatch(Result.ErrorLoadingProducts)
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = {
                            publish(Label.UserSessionExpired)
                        },
                        requestTimeoutAction = {
                            publish(Label.RequestTimedOut)
                        },
                        handler = {
                            when (outcome.error) {
                                LoadProducts.Error.NoUserSession,
                                is LoadProducts.Error.UnavailableProducts,
                                is LoadProducts.Error.UnavailableUserProducts -> {
                                    publish(Label.UnexpectedError(outcome.error.getCode()))
                                }
                            }
                        }
                    )
                }
            }
        }

        private fun loadMoreUserProducts() {
            val error = "User product pagination not yet implemented"
            publish(Label.IllegalOperationError(error))
        }

        private suspend fun getProductCheckout(id: Int, successUrl: String?, cancelUrl: String?) {
            if (successUrl.isNullOrBlank()) {
                val error = "Missing successUrl. Ensure that the successUrl param of GetProductCheckout is fulfilled"
                publish(Label.IllegalOperationError(error))
            } else if (cancelUrl.isNullOrBlank()) {
                val error = "Missing cancelUrl. Ensure that the cancelUrl param of GetProductCheckout is fulfilled"
                publish(Label.IllegalOperationError(error))
            } else {
                dispatch(Result.LoadingProductCheckout)
                when (val outcome = GetProductCheckout(id, successUrl, cancelUrl).invoke()) {
                    is Success -> dispatch(Result.ProductCheckoutLoaded(outcome.value))
                    is Failure -> {
                        dispatch(Result.ErrorLoadingProductCheckout)
                        errorService.handleError(
                            error = outcome.error,
                            sessionExpiredAction = {
                                publish(Label.UserSessionExpired)
                            },
                            requestTimeoutAction = {
                                publish(Label.RequestTimedOut)
                            },
                            handler = {
                                when (outcome.error) {
                                    GetProductCheckout.Error.NoUserSession,
                                    is GetProductCheckout.Error.UnavailableProductCheckout -> {
                                        publish(Label.UnexpectedError(outcome.error.getCode()))
                                    }
                                    GetProductCheckout.Error.ProductModuleDisabled -> {
                                        val error = "Product module is disabled for current compilation. No product operations can be done"
                                        publish(Label.IllegalOperationError(error))
                                    }
                                }
                            }
                        )
                    }
                }
            }
        }

        private suspend fun getProductsDashboard(returnUrl: String) {
            if (returnUrl.isBlank()) {
                val error = "Missing returnUrl. Ensure that the returnUrl param of GetProductsDashboard is fulfilled"
                publish(Label.IllegalOperationError(error))
            } else {
                dispatch(Result.LoadingProductsDashboard)
                when (val outcome = GetProductDashboard(returnUrl).invoke()) {
                    is Success -> dispatch(Result.ProductsDashboardLoaded(outcome.value))
                    is Failure -> {
                        dispatch(Result.ErrorLoadingProductsDashboard)
                        errorService.handleError(
                            error = outcome.error,
                            sessionExpiredAction = {
                                publish(Label.UserSessionExpired)
                            },
                            requestTimeoutAction = {
                                publish(Label.RequestTimedOut)
                            },
                            handler = {
                                when (outcome.error) {
                                    GetProductDashboard.Error.NoUserSession,
                                    is GetProductDashboard.Error.UnavailableProductDashboard -> {
                                        publish(Label.UnexpectedError(outcome.error.getCode()))
                                    }
                                }
                            }
                        )
                    }
                }
            }
        }


    }

}
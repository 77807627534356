package es.cinfo.tiivii.core.modules.auth

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.modules.auth.model.AuthModel.Model.Auth
import es.cinfo.tiivii.core.util.*
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

internal class RefreshStoredAuthData : OutcomeUseCase<Auth, RefreshStoredAuthData.Error>() {
    sealed class Error {
        object NoAuthStored : Error()
        data class AuthRefreshError(val error: NetworkError) : Error()
    }

    private val authService: AuthService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Auth, Error>
        get() = {
            val storedAuthData = authService.getStoredAuth()
            if (storedAuthData != null) {
                authService.refreshAuth(storedAuthData)
                    .mapError { Error.AuthRefreshError(it) }
            } else {
                failure(Error.NoAuthStored)
            }
        }
}


//TODO: Add doc
internal class GetStoredAuth {
    private val authService: AuthService by diContainer.instance()

    fun invoke(): Auth? {
        return authService.getStoredAuthSync()
    }
}
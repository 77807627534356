package es.cinfo.tiivii.core.content.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

sealed class CommentModel {
    /**
     * Content related representations for the UI's
     */
    sealed class ViewModel {

        /**
         * Representation of the comments of a content
         */
        data class Comments(
            val comments: List<Comment>,
            val sort: String,
        )

        /**
         * Representation of a user comment on a content
         */
        data class Comment(
            val id: Int,
            val text: String,
            val user: String,
            val publishDate: String,
        )

    }

    internal sealed class Model {

        data class Comments(
            val comments: List<Comment>,
            val limit: Int,
            val page: Int,
            val sort: String,
            val count: Int
        ) {
            fun areMoreCommentsAvailable(): Boolean = page * limit < count

            fun toViewModel(): ViewModel.Comments =
                ViewModel.Comments(
                    comments.map { it.toViewModel() }, sort
                )
        }

        data class Comment(
            val id: Int,
            val text: String,
            val user: String,
            val publishDate: String,
        ) {
            fun toViewModel(): ViewModel.Comment =
                ViewModel.Comment(
                    id,
                    text,
                    user,
                    publishDate
                )
        }

    }

    internal sealed class ApiResponse {

        @Serializable
        data class Comments(
            @SerialName(COMMENTS_PARAM)
            val comments: List<Comment>,
            @SerialName(LIMIT_PARAM)
            val limit: Int,
            @SerialName(PAGE_PARAM)
            val page: Int,
            @SerialName(SORT_PARAM)
            val sort: String,
            @SerialName(METADATA_PARAM)
            val metadata: Metadata,
        ) {
            companion object {
                const val COMMENTS_PARAM = "comments"
                const val LIMIT_PARAM = "limit"
                const val PAGE_PARAM = "page"
                const val SORT_PARAM = "sort"
                const val METADATA_PARAM = "meta"
            }

            @Serializable
            data class Metadata(
                @SerialName(FILTER_COUNT_PARAM)
                val filterCount: Int
            ) {
                companion object {
                    const val FILTER_COUNT_PARAM = "filter_count"
                }
            }

            fun toModel(): Model.Comments =
                Model.Comments(
                    comments = comments.map { it.toModel() },
                    limit = limit,
                    page = page,
                    sort = sort,
                    count = metadata.filterCount
                )
        }

        @Serializable
        data class Comment(
            @SerialName(ID_PARAM)
            val id: Int,
            @SerialName(PUBLISH_DATE_PARAM)
            val publishDate: String,
            @SerialName(TEXT_PARAM)
            val text: String,
            @SerialName(USER_PARAM)
            val username: String,
        ) {
            companion object {
                const val ID_PARAM = "id"
                const val PUBLISH_DATE_PARAM = "date"
                const val TEXT_PARAM = "text"
                const val USER_PARAM = "username"
            }

            fun toModel(): Model.Comment =
                Model.Comment(
                    id, text, username, publishDate
                )
        }

    }
}
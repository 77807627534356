package es.cinfo.tiivii.core.features.content.usecase

import es.cinfo.tiivii.core.features.content.model.Model.Content
import es.cinfo.tiivii.core.features.content.model.Model.ContentTreeLoad
import es.cinfo.tiivii.core.features.content.model.ViewModel
import es.cinfo.tiivii.core.ComponentId
import es.cinfo.tiivii.core.ErrorId
import es.cinfo.tiivii.core.UseCaseId
import es.cinfo.tiivii.core.content.ContentService
import es.cinfo.tiivii.core.error.CodedError
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.error.asErrorId
import es.cinfo.tiivii.core.modules.rating.RatingModel.Model.Companion.RATING_FILTER
import es.cinfo.tiivii.core.sorting.SortModel.Model.Sort
import es.cinfo.tiivii.core.usecase.GetRatingFilter
import es.cinfo.tiivii.core.util.*
import es.cinfo.tiivii.core.util.Model.Node
import es.cinfo.tiivii.core.util.Model.Tree
import es.cinfo.tiivii.di.diContainer
import es.cinfo.tiivii.search.data.SearchService
import org.kodein.di.instance

const val ID_FILTER = "[parent.id]"

internal class LoadContentsById(
    private val id: Int,
    private val sort: Sort
) : OutcomeUseCase<ContentTreeLoad, LoadContentsById.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.CONTENT, UseCaseId.LOAD_CONTENT_BY_ID, errorId, networkError) {
        data class UnavailableContents(val error: NetworkError) : Error(
            asErrorId<UnavailableContents>(1),
            error
        )
    }

    private val contentService: ContentService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<ContentTreeLoad, Error>
        get() = {
            val contentLoad: ContentTreeLoad
            val ratingFilter = GetRatingFilter().invokeWith(ComponentId.CONTENT).mapError {
                when (it) {
                    is GetRatingFilter.Error.UnavailableRatings -> Error.UnavailableContents(it.error)
                }
            }.getOrAbort()
            val searchFilters = if (ratingFilter != null) {
                mutableMapOf(RATING_FILTER to ratingFilter)
            } else {
                null
            }
            val widgetContentLoad = contentService.getSerialContents(
                id = id,
                filters = searchFilters,
                page = 1,
                sort = sort.param
            )
                .mapError {
                    Error.UnavailableContents(it)
                }.getOrAbort()
            val widgetContents = widgetContentLoad.contents.map {
                Content(
                    id = it.id,
                    title = it.title,
                    type = it.type,
                    background = it.background,
                    banner = it.banner,
                    poster = it.poster,
                    votes = it.votes,
                    score = it.score,
                    rating = it.rating.code,
                    // TODO: Revisit if nested support needed
                    _hasChildren = false
                )
            }
            val contentTree = Tree(
                widgetContents
            )
            contentLoad = ContentTreeLoad(
                contentTree,
                widgetContentLoad.count,
                widgetContentLoad.page,
                widgetContentLoad.limit,
                widgetContentLoad.sort,
                // TODO: Revisit when support for serial is completed
                title = "Serial"
            )
            success(contentLoad)
        }
}

internal class LoadContentPageById(
    private val id: Int,
    private val contentTree: Tree<ViewModel.Content, Content>,
    private val selectedContent: Node<ViewModel.Content, Content>? = null,
    private val page: Int,
    private val sort: Sort
) : OutcomeUseCase<ContentTreeLoad, LoadContentPageById.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.CONTENT, UseCaseId.LOAD_MORE_CONTENT_BY_ID, errorId, networkError) {
        data class UnavailableContents(val error: NetworkError) : Error(
            asErrorId<UnavailableContents>(1),
            error
        )
    }

    private val contentService: ContentService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<ContentTreeLoad, Error>
        get() = {
            val contentLoad: ContentTreeLoad
            val contents : List<Content>
            val contentsCount: Int
            val contentsPage: Int
            val contentsLimit: Int
            val contentsSort: Sort
            val contentId: Int = selectedContent?.value?.id ?: id
            val ratingFilter = GetRatingFilter().invokeWith(ComponentId.CONTENT).mapError {
                when (it) {
                    is GetRatingFilter.Error.UnavailableRatings -> Error.UnavailableContents(it.error)
                }
            }.getOrAbort()
            val searchFilters = if (ratingFilter != null) {
                mutableMapOf(RATING_FILTER to ratingFilter)
            } else {
                null
            }
            // TODO: Revisit if nested support needed (missing query filter on serial contents endpoint)
            val serialContents = contentService.getSerialContents(
                id = contentId,
                filters = searchFilters,
                page = page,
                sort = sort.param
            )
                .mapError { Error.UnavailableContents(it) }.getOrAbort()
            contents = serialContents.contents.map {
                Content(
                    id = it.id,
                    title = it.title,
                    type = it.type,
                    background = it.background,
                    banner = it.banner,
                    poster = it.poster,
                    votes = it.votes,
                    score = it.score,
                    rating = it.rating.code,
                    _hasChildren = it.hasChildren
                )
            }
            if (selectedContent != null) {
                contentTree.addChildren(selectedContent.coordinates, contents)
            } else {
                contentTree.addRootNodes(contents)
            }
            contentsCount = serialContents.count
            contentsPage = serialContents.page
            contentsLimit = serialContents.limit
            contentsSort = serialContents.sort
            // TODO: Revisit when support for serial is completed
            val contentsTitle = "Serial"
            contentLoad = ContentTreeLoad(
                contentTree,
                contentsCount,
                contentsPage,
                contentsLimit,
                contentsSort,
                contentsTitle
            )
            success(contentLoad)
        }
}

internal class SortContentForId(
    private val id: Int,
    private val sort: Sort
) : OutcomeUseCase<ContentTreeLoad, SortContentForId.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.CONTENT, UseCaseId.SORT_CONTENT_FOR_ID, errorId, networkError) {
        data class SortUnavailable(val error: NetworkError) : Error(
            asErrorId<SortUnavailable>(1),
            error
        )
    }

    private val contentService: ContentService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<ContentTreeLoad, Error>
        get() = {
            val contentLoad = contentService.getSerialContents(
                id = id,
                sort = sort.param,
                page = 1)
                .mapError {
                    Error.SortUnavailable(it)
                }.getOrAbort()
            val contents = contentLoad.contents.map {
                Content(
                    id = it.id,
                    title = it.title,
                    type = it.type,
                    background = it.background,
                    banner = it.banner,
                    poster = it.poster,
                    votes = it.votes,
                    score = it.score,
                    rating = it.rating.code,
                    // TODO: Revisit if nested support needed
                    _hasChildren = false
                )
            }
            val contentTree = Tree(contents)
            success(
                ContentTreeLoad(
                    contentTree,
                    contentLoad.count,
                    contentLoad.page,
                    contentLoad.limit,
                    contentLoad.sort,
                    // TODO: Revisit when support for serial is completed
                    "Serial"
                )
            )
        }
}

internal class FilterContentsForId(
    private val id: Int,
    private val query: String?,
    private val sort: Sort
) : OutcomeUseCase<ContentTreeLoad, FilterContentsForId.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.CONTENT, UseCaseId.FILTER_CONTENT_FOR_ID, errorId, networkError) {
        data class FilterUnavailable(val error: NetworkError) : Error(
            asErrorId<FilterUnavailable>(1),
            error
        )
    }

    private val searchService: SearchService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<ContentTreeLoad, Error>
        get() = {
            val searchFilters = mutableMapOf(ID_FILTER to id.toString())
            val ratingFilter = GetRatingFilter().invokeWith(ComponentId.CONTENT).mapError {
                when (it) {
                    is GetRatingFilter.Error.UnavailableRatings -> Error.FilterUnavailable(it.error)
                }
            }.getOrAbort()
            if (ratingFilter != null) {
                searchFilters[RATING_FILTER] = ratingFilter
            }
            val searchResults = searchService.search(query, filters = searchFilters, sort = sort.param, page = 1)
                .mapError {
                    Error.FilterUnavailable(it)
                }.getOrAbort()
            val contents = searchResults.searchResultEntries.map {
                Content(
                    id = it.id,
                    title = it.title,
                    type = it.type,
                    background = it.background,
                    banner = it.banner,
                    poster = it.poster,
                    votes = it.votes,
                    score = it.score,
                    rating = it.rating,
                    _hasChildren = it.hasChildren
                )
            }
            val contentTree = Tree(contents)
            success(
                ContentTreeLoad(
                    contentTree,
                    searchResults.count,
                    searchResults.page,
                    searchResults.limit,
                    searchResults.sort,
                    // TODO: Revisit when support for categories is completed
                    "Serial"
                )
            )
        }
}
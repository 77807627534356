package es.cinfo.tiivii.core.error

import es.cinfo.tiivii.core.ComponentId
import es.cinfo.tiivii.core.ErrorId
import es.cinfo.tiivii.core.UseCaseId
import io.ktor.client.features.*
import io.ktor.http.*
import io.ktor.network.sockets.*
import kotlinx.serialization.json.JsonElement

/**
 * Error class to be received by the UI's
 * @param id identifier to be translated to a user error to be shown
 * @param className error class name for tracing
 */
data class Error(
    val id: String,
    val className: String? = null
)

internal open class CodedError(
    val component: ComponentId,
    val useCase: UseCaseId,
    val errorId: ErrorId,
    val networkError: NetworkError? = null
) {
    fun getCode(): String {
        var detail = ""
        val http = (networkError as? NetworkError.Http)?.statusCode
        val throwable = (networkError as? NetworkError.Execution)?.throwable
        if (http != null) {
            detail = "-[$http]"
        } else if (throwable != null) {
            detail = "-[$throwable]"
        }
        return "${component.id}-${useCase.id}-${errorId.id}$detail"
    }

    fun isSessionExpired(): Boolean {
        return networkError?.getHttpStatusCode() == 401
    }

    fun hasTimedOut(): Boolean {
        val throwable = (networkError as? NetworkError.Execution)?.throwable
        return if (throwable != null) {
            throwable is HttpRequestTimeoutException ||
            throwable is ConnectTimeoutException ||
            throwable is SocketTimeoutException
        } else {
            false
        }
    }
}

/**
 * Represents network error to be use by the API's
 */
internal sealed class NetworkError {
    /**
     * HTTP error (300, 400, 500...)
     */
    data class Http(
        val statusCode: Int,
        val method: String,
        val url: String,
        val encapsulated: Boolean,
        val json: JsonElement? = null) : NetworkError()

    /**
     * Unexpected execution error (server connection, timeout, serialization...)
     */
    data class Execution(val throwable: Throwable) : NetworkError()

    fun hasSessionExpired(): Boolean = getHttpStatusCode() == 401

    fun getHttpStatusCode(): Int? = (this as? Http)?.statusCode
}

internal inline fun <reified T> asErrorId(id: Int): ErrorId {
    return ErrorId(id, T::class.simpleName!!)
}
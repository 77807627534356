package es.cinfo.tiivii.core.features.ranking.store

import com.arkivanov.mvikotlin.core.store.Store
import es.cinfo.tiivii.core.features.ranking.store.RankingStore.*
import es.cinfo.tiivii.core.modules.avatar.model.AvatarModel.Model.Avatar
import es.cinfo.tiivii.core.modules.game.model.GameModel.Model.Achievement
import es.cinfo.tiivii.core.modules.game.model.GameModel.Model.Reward
import es.cinfo.tiivii.core.userstats.UserStatsModel.Model.Ranking
import es.cinfo.tiivii.core.util.LoadingModel.Model.LoadState

internal interface RankingStore :
    Store<Intent, State, Label> {

    sealed class Action {
        object LoadUserStats : Action()
    }

    sealed class Intent {
        object ReloadUserStats : Intent()
    }

    data class State(
        val userAvatar: Avatar?,
        val boardId: Int?,
        val userRanking: Ranking?,
        val rankings: List<Ranking>,
        val achievements: List<Achievement>,
        val rewards: List<Reward>,
        val closestAchievement: Achievement?,
        val loadingStats: LoadState
    )

    sealed class Label {
        object UserSessionExpired : Label()
        object RequestTimedOut : Label()
        data class UnexpectedError(val code: String) : Label()
    }

}
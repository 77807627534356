package es.cinfo.tiivii.core.date

import kotlinx.datetime.*

internal actual class DateService {

    private val clock = Clock.System
//    private val dateRegex = Regex("^([\\+-]?\\d{4}(?!\\d{2}\\b))((-?)((0[1-9]|1[0-2])(\\3([12]\\d|0[1-9]|3[01]))?|W([0-4]\\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\\d|[12]\\d{2}|3([0-5]\\d|6[1-6])))([T\\s]((([01]\\d|2[0-3])((:?)[0-5]\\d)?|24\\:?00)([\\.,]\\d+(?!:))?)?(\\17[0-5]\\d([\\.,]\\d+)?)?([zZ]|([\\+-])([01]\\d|2[0-3]):?([0-5]\\d)?)?)?)?\$")

    actual fun yearsUntil(date: String): Int {
        val birthdayDate = date.toLocalDate()
        return birthdayDate.yearsUntil(clock.todayAt(TimeZone.UTC))
    }

    actual fun currentEpochMillis(): Long {
        return clock.now().toEpochMilliseconds()
    }

    actual fun parseAsEpochMillis(timestamp: String): Long {
        // TODO: Add final implementation
        val dateAsIso = timestamp.replace(" ", "T")
//        val isValid = dateAsIso.matches(dateRegex)
        return dateAsIso.toLocalDateTime().toInstant(TimeZone.UTC).toEpochMilliseconds()
//        return if (isValid) {
//        } else {
//            -1
//        }
    }
}
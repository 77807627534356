package es.cinfo.tiivii.core.image

import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

/**
 * Image handling module with operations related to image retrieval, url building, etc
 */
interface ImageModule {
    /**
     * Base image url endpoint to build upon
     */
    val baseEndpoint: String
}

/**
 * Default [ImageModule] using [ConfigModule]
 */
class DefaultImageModule: ImageModule {

    private val configModule: ConfigModule by diContainer.instance()

    override val baseEndpoint: String
        get() = "${configModule.getEnvConfig().backendUrl}/reme"

}
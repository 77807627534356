package es.cinfo.tiivii.core.features.playback.view

import com.arkivanov.mvikotlin.core.view.MviView
import es.cinfo.tiivii.core.content.model.ContentTypeModel
import es.cinfo.tiivii.core.features.playback.native.model.PlaybackModel.ViewModel.PlaybackLockReason
import es.cinfo.tiivii.core.features.playback.view.PlaybackView.Event
import es.cinfo.tiivii.core.features.playback.view.PlaybackView.Model
import es.cinfo.tiivii.core.util.LoadingModel.ViewModel.LoadState

/**
 * View implementation for the playback screen
 */
interface PlaybackView : MviView<Model, Event> {

    sealed class Event {
        data class LoadVideoUrl(val contentId: Int, val ignoreList: String? = null) : Event()
        object LogPlaybackView : Event()
    }

    data class Model(
        val videoUrl: String?,
        val shareUrl: String?,
        val contentType: ContentTypeModel.ViewModel.ContentType?,
        val loading: LoadState
    )

    sealed class Output {
        /**
         * Unexpected error
         */
        data class UnexpectedError(val code: String) : Output()

        /**
         * User session has expired, new login is required
         */
        object UserSessionExpired : Output()

        /**
         * A request has timed out. This may indicate a problem with the internet connection
         */
        object RequestTimedOut : Output()

        /**
         * Error indicating that the playback is locked with the given reason
         * @param reason [PlaybackLockReason] as to why the playback is being locked
         */
        data class PlaybackLocked(val reason: PlaybackLockReason) : Output()
    }

}

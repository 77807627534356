package es.cinfo.tiivii.core.content.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal data class RateApiRequest(
    @SerialName(RATING_PARAM)
    val rating: Int,
    @SerialName(CONTENT_ID_PARAM)
    val contentId: Int,
) {
    companion object {
        const val RATING_PARAM = "rating"
        const val CONTENT_ID_PARAM = "contentId"
    }
}

@Serializable
internal data class AddCommentApiRequest(
    @SerialName(TEXT_PARAM)
    val text: String,
    @SerialName(CONTENT_ID_PARAM)
    val contentId: Int,
) {
    companion object {
        const val TEXT_PARAM = "text"
        const val CONTENT_ID_PARAM = "contentId"
    }
}

@Serializable
internal data class UpdateCommentApiRequest(
    @SerialName(TEXT_PARAM)
    val text: String,
) {
    companion object {
        const val TEXT_PARAM = "text"
    }
}

@Serializable
internal data class ReportContent(
    @SerialName(REASON_PARAM)
    val reason: Int,
    @SerialName(USER_MESSAGE_PARAM)
    val userMessage: String?,
) {
    companion object {
        const val REASON_PARAM = "reason"
        const val USER_MESSAGE_PARAM = "message"
    }
}

package es.cinfo.tiivii.core.modules.analytics.model

internal sealed class AnalyticsModel {

    sealed class Action {
        object ApiCall : Action() {
            const val HTTP_REQUEST_KEY: String = "request"

            override fun toString() = "apiCall"
        }

        object PageView : Action() {
            const val PAGE_ID_KEY: String = "page"

            const val HOME_VALUE: String = "home"
            const val SIGN_UP_VALUE: String = "signUp"
            const val LOGIN_VALUE: String = "login"
            const val SEARCH_VALUE: String = "search"
            const val CATALOGUE_VALUE: String = "catalogue"
            const val CONTENT_VALUE: String = "content"
            const val DETAIL_VALUE: String = "detail"
            const val PLAYER_VALUE: String = "player"

            override fun toString() = "pageView"
        }

        object Login : Action() {
            override fun toString() = "login"
        }

        object Logout : Action() {
            override fun toString() = "logout"
        }

        object SignUp : Action() {
            override fun toString() = "signUp"
        }

        object ViewProfile : Action() {
            override fun toString() = "viewProfile"
        }

        object ChangeLanguage : Action() {
            const val NEW_LANGUAGE_KEY: String = "newLanguage"

            override fun toString() = "changeLanguage"
        }

        object UpdateAvatar : Action() {
            const val AVATAR_KEY: String = "avatarId"

            override fun toString() = "updateAvatar"
        }

        object UpdateInterests : Action() {
            const val INTERESTS_KEY: String = "interests"

            override fun toString() = "updateInterests"
        }

        object UpdatePassword : Action() {
            override fun toString() = "updatePassword"
        }

        data class Play(val percentage: Int) : Action() {
            override fun toString() = "played$percentage"
        }

        object PlayLive : Action() {
            override fun toString() = "playedlive"
        }

        object Resume : Action() {
            override fun toString() = "resume"
        }

        object Pause : Action() {
            override fun toString() = "pause"
        }

        object Stop : Action() {
            override fun toString() = "stop"
        }

        object Finished : Action() {
            override fun toString() = "finished"
        }

        object Search : Action() {
            const val QUERY_KEY: String = "query"

            override fun toString() = "search"
        }

        object SelectItem : Action() {
            override fun toString() = "selectItem"
        }

        object ExecuteItem : Action() {
            override fun toString() = "executeItem"
        }

        object SendComment : Action() {
            const val TEXT_KEY: String = "text"

            override fun toString() = "sendComment"
        }

        object RemoveComment : Action() {
            const val COMMENT_KEY: String = "commentId"

            override fun toString() = "removeComment"
        }

        object UpdateComment : Action() {
            const val TEXT_KEY: String = "text"

            override fun toString() = "updateComment"
        }

        object SendRating : Action() {
            const val SCORE_KEY: String = "rating"

            override fun toString() = "sendValoration"
        }

        object RemoveRating : Action() {
            override fun toString() = "removeValoration"
        }

        object SetFavorite : Action() {
            override fun toString() = "setFavorite"
        }

        object UnsetFavorite : Action() {
            override fun toString() = "unsetFavorite"
        }

        object ReportContent : Action() {
            const val REASON_KEY: String = "reason"

            override fun toString() = "reportContent"
        }
    }

    data class Event(val url: String)
}
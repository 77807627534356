package es.cinfo.tiivii.core.user

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.interest.InterestApi
import es.cinfo.tiivii.core.interest.model.Model
import es.cinfo.tiivii.core.modules.avatar.AvatarApi
import es.cinfo.tiivii.core.modules.avatar.model.AvatarModel
import es.cinfo.tiivii.core.modules.bookmark.model.BookmarkModel
import es.cinfo.tiivii.core.user.model.Model.User
import es.cinfo.tiivii.core.util.*
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

/**
 * Service exposing user related operations
 */
internal interface UserService {

    /**
     * Retrieves the user data for the given username and caches the user on memory
     * @param username login username to retrieve the data for
     * @param forceRefresh forces to retrieve the user data again
     * @return A [User] model with all the data retrieved from backend
     */
    suspend fun getUser(username: String, forceRefresh: Boolean = false): Outcome<User, NetworkError>

    /**
     * Retrieves the user data cached from the first login. This variable will only be initialized if the user
     * has logged in. To be used only for token auth renewal
     */
    suspend fun getCachedUser(): User?

    /**
     * Updates the user data for the given username.
     *
     * If any parameter is null or omitted no modifications
     * will be done to it
     * @param username user to update
     * @param interests new interests for the user
     * @param language new preferred language of the user
     * @param avatar new selected avatar of the user
     * @param firstName new first name of the user
     * @param lastName new last name of the user
     * @return true if the update was successful, false otherwise
     */
    suspend fun updateUser(
        username: String,
        interests: Set<Model.Interest>? = null,
        language: String? = null,
        avatar: AvatarModel.Model.Avatar? = null,
        firstName: String? = null,
        lastName: String? = null,
    ): Outcome<Unit, NetworkError>

    suspend fun updateUserCredentials(
        username: String,
        oldCredentials: String,
        credentials: String,
    ): Outcome<Unit, NetworkError>

    /**
     * Retrieves a random username
     * @return A random username obtained from backend
     */
    suspend fun getRandomUsername(): Outcome<String, NetworkError>

    /**
     * Signs the legal conditions version for the given user
     */
    suspend fun signLegal(
        username: String,
        legalVersion: Int,
        isDataCollectionAllowed: Boolean,
    ): Outcome<Unit, NetworkError>

    /**
     * Clears any user data stored in memory
     */
    fun clearUserData()

    /**
     * Checks if already exists an user registered with the given email
     * @return true if it exists, false otherwise
     */
    suspend fun existsUserWithEmail(email: String): Outcome<Boolean, NetworkError>

    /**
     * Checks if already exists an user registered with the given username
     * @return true if it exists, false otherwise
     */
    suspend fun existsUserWithUsername(username: String): Outcome<Boolean, NetworkError>

    /**
     * Requests the content to be added to the user's favorite lists
     * @param id of the content
     * @param username to add the content to favorites
     */
    suspend fun addContentToFavorites(id: Int, username: String): Outcome<List<String>, NetworkError>

    /**
     * Requests the content to be removed from the user's favorite lists
     * @param id of the content
     * @param username to add the content to favorites
     */
    suspend fun removeContentFromFavorites(id: Int, username: String): Outcome<List<String>, NetworkError>

    /**
     * Updates the current [User] stored ratings with the given ones
     * @param ratings New [User.Rating]s to be stored for the user
     */
    suspend fun updateCachedUserRatings(ratings: List<User.Rating>)

    /**
     * Updates the current [User] stored favorites with the given ones
     * @param favorites New favorites to be stored for the user
     */
    suspend fun updateCachedUserFavorites(favorites: List<String>)

    suspend fun updateCachedUserBookmarks(bookmarks: List<BookmarkModel.Model.Bookmark>)

    suspend fun resetUserPassword(user: String): Outcome<Unit, NetworkError>

    suspend fun deleteAccount(username: String): Outcome<Unit, NetworkError>

    suspend fun publishContent(payload: String): Outcome<Unit, NetworkError>

}

/**
 * Default implementation of [UserService]
 */
internal class DefaultUserService : UserService {
    private val userApi: UserApi by diContainer.instance()
    private val interestApi: InterestApi by diContainer.instance()
    private val avatarApi: AvatarApi by diContainer.instance()

    private var isUserInitialized: Boolean = false
    private lateinit var user: User

    override suspend fun getUser(username: String, forceRefresh: Boolean): Outcome<User, NetworkError> {
        return if (!isUserInitialized || forceRefresh) {
            retrieveUser(username)
                .map {
                    this.user = it
                    this.isUserInitialized = true
                    it
                }
        } else {
            success(user)
        }
    }

    override suspend fun getCachedUser(): User? {
        if(!isUserInitialized)
            return null
        return user
    }


    override suspend fun updateUser(
        username: String,
        interests: Set<Model.Interest>?,
        language: String?,
        avatar: AvatarModel.Model.Avatar?,
        firstName: String?,
        lastName: String?,
    ): Outcome<Unit, NetworkError> {
        return userApi.updateUser(
            username = username,
            interests = interests?.mapToSet { it.id },
            language = language,
            avatar = avatar,
            firstName = firstName,
            lastName = lastName
        )
            .map {
                this.user = this.user.copy(
                    interests = interests ?: user.interests,
                    preferredLanguage = language ?: user.preferredLanguage,
                    avatar = avatar ?: user.avatar,
                    firstName = firstName ?: user.firstName,
                    lastName = lastName ?: user.lastName
                )
            }
    }

    override suspend fun updateUserCredentials(
        username: String,
        oldCredentials: String,
        credentials: String
    ): Outcome<Unit, NetworkError> {
        return userApi.updateUserCredentials(username, oldCredentials, credentials)
    }

    override suspend fun getRandomUsername(): Outcome<String, NetworkError> {
        return userApi.getRandomUsername()
    }

    override suspend fun signLegal(
        username: String,
        legalVersion: Int,
        isDataCollectionAllowed: Boolean,
    ): Outcome<Unit, NetworkError> {
        return userApi.updateLegal(username, legalVersion, isDataCollectionAllowed)
            .map {
                this.user = this.user.copy(
                    signedLegalVersion = legalVersion
                )
            }
    }

    override fun clearUserData() {
        // Mark user as non initialized for update on next retrieval
        this.isUserInitialized = false
    }

    override suspend fun existsUserWithUsername(username: String): Outcome<Boolean, NetworkError> {
        return userApi.existsUserWithUsername(username)
    }

    override suspend fun addContentToFavorites(id: Int, username: String): Outcome<List<String>, NetworkError> {
        return userApi.addContentToFavorites(id, username)
    }

    override suspend fun removeContentFromFavorites(id: Int, username: String): Outcome<List<String>, NetworkError> {
        return userApi.removeContentFromFavorites(id, username)
    }

    override suspend fun updateCachedUserRatings(ratings: List<User.Rating>) {
        this.user = user.copy(ratings = ratings)
    }

    override suspend fun updateCachedUserFavorites(favorites: List<String>) {
        this.user = user.copy(favorites = favorites)
    }

    override suspend fun updateCachedUserBookmarks(bookmarks: List<BookmarkModel.Model.Bookmark>) {
        this.user = user.copy(bookmarks = bookmarks)
    }

    override suspend fun resetUserPassword(user: String): Outcome<Unit, NetworkError> {
        return userApi.resetUserPassword(user)
    }

    override suspend fun deleteAccount(username: String): Outcome<Unit, NetworkError> {
        return userApi.deleteAccount(username)
    }

    override suspend fun publishContent(payload: String): Outcome<Unit, NetworkError> {
        return userApi.publishContent(payload)
    }

    override suspend fun existsUserWithEmail(email: String): Outcome<Boolean, NetworkError> {
        return userApi.existsUserWithEmail(email)
    }

    private suspend fun retrieveUser(username: String): Outcome<User, NetworkError> {
        val outcome = userApi.getUser(username)
        return outcome.map { userApiResponse ->
            //TODO: What happens when the selected avatar is no longer available? Add logic
            val avatarId = userApiResponse.avatarImageId
            var avatar: AvatarModel.Model.Avatar? = null
            avatarId?.let {
                avatar = avatarApi.getAvatar(userApiResponse.avatarImageId).getOrNull()?.toModel()
            }
            var interests: Set<Model.Interest>? = null
            if (userApiResponse.interests != null) {
                interests = interestApi.getInterests(userApiResponse.interests).getOrNull()?.mapToSet { it.toModel() }
            }
            val user = userApiResponse.toModel(avatar, interests ?: emptySet())
            user
        }
    }
}
package es.cinfo.tiivii.core.sorting

import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.di.diContainer
import kotlinx.serialization.SerializationException
import org.kodein.di.instance

sealed class SortModel {
    sealed class ViewModel {
        data class Sort(
            val id: String,
        )
    }
    internal sealed class Model {
        data class Sort(
            val id: String,
            val param: String
        ) {
            companion object {
                private const val MOST_RECENT_FIRST_ID = "most_recent_first"
                private const val MOST_RECENT_FIRST_PARAM = "-created_on"

                private const val OLDEST_FIRST_ID = "oldest_first"
                private const val OLDEST_FIRST_PARAM = "created_on"

                private const val NAME_ALPHABETICAL_ID = "name_alphabetical"
                private const val NAME_ALPHABETICAL_PARAM = "name"

                private const val NAME_ALPHABETICAL_REVERSED_ID = "name_alphabetical_reversed"
                private const val NAME_ALPHABETICAL_REVERSED_PARAM = "-name"


                private const val TITLE_ALPHABETICAL_ID = "title_alphabetical"
                private const val TITLE_ALPHABETICAL_PARAM = "title"

                private const val TITLE_ALPHABETICAL_REVERSED_ID = "title_alphabetical_reversed"
                private const val TITLE_ALPHABETICAL_REVERSED_PARAM = "-title"

                private const val MOST_POPULAR_ID = "most_popular"
                private const val MOST_POPULAR_PARAM = "-votes"

                private const val LEAST_POPULAR_ID = "least_popular"
                private const val LEAST_POPULAR_PARAM = "votes"

                private const val HIGHEST_SCORE_ID = "highest_score"
                private const val HIGHEST_SCORE_PARAM = "-valoration"

                private const val LOWEST_SCORE_ID = "lowest_score"
                private const val LOWEST_SCORE_PARAM = "valoration"

                private const val BY_EPISODE_ID = "by_episode"
                private const val BY_EPISODE_PARAM = "episode"

                val mostRecentFirst: Sort = Sort(
                    MOST_RECENT_FIRST_ID,
                    MOST_RECENT_FIRST_PARAM
                )

                val titleAlphabetical: Sort = Sort(
                    TITLE_ALPHABETICAL_ID,
                    TITLE_ALPHABETICAL_PARAM
                )

                val mostPopular: Sort = Sort(
                    MOST_POPULAR_ID,
                    MOST_POPULAR_PARAM
                )

                val lowestScore: Sort = Sort(
                    LOWEST_SCORE_ID,
                    LOWEST_SCORE_PARAM
                )

                val byEpisode: Sort = Sort(
                    BY_EPISODE_ID,
                    BY_EPISODE_PARAM
                )

                fun defaultSorts(): List<Sort> {
                    val configModule: ConfigModule by diContainer.instance()
                    return configModule.getCoreConfig().content.orders
                }

                fun fromId(id: String): Sort {
                    val param = when (id) {
                        MOST_RECENT_FIRST_ID -> MOST_RECENT_FIRST_PARAM
                        OLDEST_FIRST_ID -> OLDEST_FIRST_PARAM
                        TITLE_ALPHABETICAL_ID -> TITLE_ALPHABETICAL_PARAM
                        TITLE_ALPHABETICAL_REVERSED_ID -> TITLE_ALPHABETICAL_REVERSED_PARAM
                        NAME_ALPHABETICAL_ID -> NAME_ALPHABETICAL_PARAM
                        NAME_ALPHABETICAL_REVERSED_ID -> NAME_ALPHABETICAL_REVERSED_PARAM
                        MOST_POPULAR_ID -> MOST_POPULAR_PARAM
                        LEAST_POPULAR_ID -> LEAST_POPULAR_PARAM
                        HIGHEST_SCORE_ID -> HIGHEST_SCORE_PARAM
                        LOWEST_SCORE_ID -> LOWEST_SCORE_PARAM
                        BY_EPISODE_ID -> BY_EPISODE_PARAM
                        else -> throw SerializationException("Unrecognized sorting: $id")
                    }
                    return Sort(id, param)
                }

                fun fromParam(param: String): Sort {
                    val id = when (param) {
                        MOST_RECENT_FIRST_PARAM -> MOST_RECENT_FIRST_ID
                        OLDEST_FIRST_PARAM -> OLDEST_FIRST_ID
                        TITLE_ALPHABETICAL_PARAM -> TITLE_ALPHABETICAL_ID
                        TITLE_ALPHABETICAL_REVERSED_PARAM -> TITLE_ALPHABETICAL_REVERSED_ID
                        NAME_ALPHABETICAL_PARAM -> NAME_ALPHABETICAL_ID
                        NAME_ALPHABETICAL_REVERSED_PARAM -> NAME_ALPHABETICAL_REVERSED_ID
                        MOST_POPULAR_PARAM -> MOST_POPULAR_ID
                        LEAST_POPULAR_PARAM -> LEAST_POPULAR_ID
                        HIGHEST_SCORE_PARAM -> HIGHEST_SCORE_ID
                        LOWEST_SCORE_PARAM -> LOWEST_SCORE_ID
                        BY_EPISODE_PARAM -> BY_EPISODE_ID
                        else -> throw SerializationException("Unrecognized sorting: $param")
                    }
                    return Sort(id, param)
                }
            }

            fun toViewModel(): ViewModel.Sort {
                return ViewModel.Sort(id)
            }
        }
    }
}

//internal sealed class Model {
//    data class Sort(
//        val id: String,
//        val param: String
//    ) {
//        companion object {
//            private const val MOST_RECENT_FIRST_ID = "most_recent_first"
//            private const val MOST_RECENT_FIRST_PARAM = "-created_on"
//
//            private const val OLDEST_FIRST_ID = "oldest_first"
//            private const val OLDEST_FIRST_PARAM = "created_on"
//
//            private const val NAME_ALPHABETICAL_ID = "name_alphabetical"
//            private const val NAME_ALPHABETICAL_PARAM = "name"
//
//            private const val NAME_ALPHABETICAL_REVERSED_ID = "name_alphabetical_reversed"
//            private const val NAME_ALPHABETICAL_REVERSED_PARAM = "-name"
//
//
//            private const val TITLE_ALPHABETICAL_ID = "title_alphabetical"
//            private const val TITLE_ALPHABETICAL_PARAM = "title"
//
//            private const val TITLE_ALPHABETICAL_REVERSED_ID = "title_alphabetical_reversed"
//            private const val TITLE_ALPHABETICAL_REVERSED_PARAM = "-title"
//
//            private const val MOST_POPULAR_ID = "most_popular"
//            private const val MOST_POPULAR_PARAM = "-votes"
//
//            private const val LEAST_POPULAR_ID = "least_popular"
//            private const val LEAST_POPULAR_PARAM = "votes"
//
//            private const val HIGHEST_SCORE_ID = "highest_score"
//            private const val HIGHEST_SCORE_PARAM = "-valoration"
//
//            private const val LOWEST_SCORE_ID = "lowest_score"
//            private const val LOWEST_SCORE_PARAM = "valoration"
//
//            private const val BY_EPISODE_ID = "by_episode"
//            private const val BY_EPISODE_PARAM = "episode"
//
//            val mostRecentFirst: Sort = Sort(
//                MOST_RECENT_FIRST_ID,
//                MOST_RECENT_FIRST_PARAM
//            )
//
//            val titleAlphabetical: Sort = Sort(
//                TITLE_ALPHABETICAL_ID,
//                TITLE_ALPHABETICAL_PARAM
//            )
//
//            val mostPopular: Sort = Sort(
//                MOST_POPULAR_ID,
//                MOST_POPULAR_PARAM
//            )
//
//            val lowestScore: Sort = Sort(
//                LOWEST_SCORE_ID,
//                LOWEST_SCORE_PARAM
//            )
//
//            val byEpisode: Sort = Sort(
//                BY_EPISODE_ID,
//                BY_EPISODE_PARAM
//            )
//
//            suspend fun defaultSorts(): List<Sort> {
//                val configModule: ConfigModule by diContainer.instance()
//                return configModule.getCoreConfig().content.orders
//            }
//
//            fun fromId(id: String): Sort {
//                val param = when (id) {
//                    MOST_RECENT_FIRST_ID -> MOST_RECENT_FIRST_PARAM
//                    OLDEST_FIRST_ID -> OLDEST_FIRST_PARAM
//                    TITLE_ALPHABETICAL_ID -> TITLE_ALPHABETICAL_PARAM
//                    TITLE_ALPHABETICAL_REVERSED_ID -> TITLE_ALPHABETICAL_REVERSED_PARAM
//                    NAME_ALPHABETICAL_ID -> NAME_ALPHABETICAL_PARAM
//                    NAME_ALPHABETICAL_REVERSED_ID -> NAME_ALPHABETICAL_REVERSED_PARAM
//                    MOST_POPULAR_ID -> MOST_POPULAR_PARAM
//                    LEAST_POPULAR_ID -> LEAST_POPULAR_PARAM
//                    HIGHEST_SCORE_ID -> HIGHEST_SCORE_PARAM
//                    LOWEST_SCORE_ID -> LOWEST_SCORE_PARAM
//                    BY_EPISODE_ID -> BY_EPISODE_PARAM
//                    else -> throw SerializationException("Unrecognized sorting: $id")
//                }
//                return Sort(id, param)
//            }
//
//            fun fromParam(param: String): Sort {
//                val id = when (param) {
//                    MOST_RECENT_FIRST_PARAM -> MOST_RECENT_FIRST_ID
//                    OLDEST_FIRST_PARAM -> OLDEST_FIRST_ID
//                    TITLE_ALPHABETICAL_PARAM -> TITLE_ALPHABETICAL_ID
//                    TITLE_ALPHABETICAL_REVERSED_PARAM -> TITLE_ALPHABETICAL_REVERSED_ID
//                    NAME_ALPHABETICAL_PARAM -> NAME_ALPHABETICAL_ID
//                    NAME_ALPHABETICAL_REVERSED_PARAM -> NAME_ALPHABETICAL_REVERSED_ID
//                    MOST_POPULAR_PARAM -> MOST_POPULAR_ID
//                    LEAST_POPULAR_PARAM -> LEAST_POPULAR_ID
//                    HIGHEST_SCORE_PARAM -> HIGHEST_SCORE_ID
//                    LOWEST_SCORE_PARAM -> LOWEST_SCORE_ID
//                    BY_EPISODE_PARAM -> BY_EPISODE_ID
//                    else -> throw SerializationException("Unrecognized sorting: $param")
//                }
//                return Sort(id, param)
//            }
//        }
//    }
//}
package es.cinfo.tiivii.di

import com.russhwolf.settings.Settings
import es.cinfo.tiivii.core.content.ContentApi
import es.cinfo.tiivii.core.content.ContentService
import es.cinfo.tiivii.core.content.DefaultContentApi
import es.cinfo.tiivii.core.content.DefaultContentService
import es.cinfo.tiivii.core.date.DateService
import es.cinfo.tiivii.core.error.DefaultErrorService
import es.cinfo.tiivii.core.error.ErrorService
import es.cinfo.tiivii.core.image.DefaultImageModule
import es.cinfo.tiivii.core.image.ImageModule
import es.cinfo.tiivii.core.interest.DefaultInterestApi
import es.cinfo.tiivii.core.interest.DefaultInterestService
import es.cinfo.tiivii.core.interest.InterestApi
import es.cinfo.tiivii.core.interest.InterestService
import es.cinfo.tiivii.core.layout.*
import es.cinfo.tiivii.core.modules.analytics.*
import es.cinfo.tiivii.core.modules.auth.*
import es.cinfo.tiivii.core.modules.avatar.AvatarApi
import es.cinfo.tiivii.core.modules.avatar.AvatarService
import es.cinfo.tiivii.core.modules.avatar.DefaultAvatarApi
import es.cinfo.tiivii.core.modules.avatar.DefaultAvatarService
import es.cinfo.tiivii.core.modules.cas.CasApi
import es.cinfo.tiivii.core.modules.cas.CasService
import es.cinfo.tiivii.core.modules.cas.DefaultCasApi
import es.cinfo.tiivii.core.modules.cas.DefaultCasService
import es.cinfo.tiivii.core.modules.chat.ChatApi
import es.cinfo.tiivii.core.modules.chat.ChatService
import es.cinfo.tiivii.core.modules.chat.DefaultChatApi
import es.cinfo.tiivii.core.modules.chat.DefaultChatService
import es.cinfo.tiivii.core.modules.checkpoint.CheckpointApi
import es.cinfo.tiivii.core.modules.checkpoint.CheckpointService
import es.cinfo.tiivii.core.modules.checkpoint.DefaultCheckpointApi
import es.cinfo.tiivii.core.modules.checkpoint.DefaultCheckpointService
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.config.DefaultConfigModule
import es.cinfo.tiivii.core.modules.game.DefaultGameApi
import es.cinfo.tiivii.core.modules.game.DefaultGameService
import es.cinfo.tiivii.core.modules.game.GameApi
import es.cinfo.tiivii.core.modules.game.GameService
import es.cinfo.tiivii.core.modules.geolocation.DefaultLocationApi
import es.cinfo.tiivii.core.modules.geolocation.LocationApi
import es.cinfo.tiivii.core.modules.network.HttpModule
import es.cinfo.tiivii.core.modules.product.DefaultProductApi
import es.cinfo.tiivii.core.modules.product.DefaultProductService
import es.cinfo.tiivii.core.modules.product.ProductApi
import es.cinfo.tiivii.core.modules.product.ProductService
import es.cinfo.tiivii.core.modules.pusher.PusherService
import es.cinfo.tiivii.core.modules.rating.DefaultRatingApi
import es.cinfo.tiivii.core.modules.rating.DefaultRatingService
import es.cinfo.tiivii.core.modules.rating.RatingApi
import es.cinfo.tiivii.core.modules.rating.RatingService
import es.cinfo.tiivii.core.modules.share.FirebaseShareApi
import es.cinfo.tiivii.core.modules.share.FirebaseShareService
import es.cinfo.tiivii.core.modules.share.ShareApi
import es.cinfo.tiivii.core.modules.share.ShareService
import es.cinfo.tiivii.core.modules.timeline.DefaultTimelineApi
import es.cinfo.tiivii.core.modules.timeline.TimelineApi
import es.cinfo.tiivii.core.modules.timeline.TimelineService
import es.cinfo.tiivii.core.modules.video.DefaultVideoApi
import es.cinfo.tiivii.core.modules.video.DefaultVideoService
import es.cinfo.tiivii.core.modules.video.VideoApi
import es.cinfo.tiivii.core.modules.video.VideoService
import es.cinfo.tiivii.core.node.DefaultNodeApi
import es.cinfo.tiivii.core.node.DefaultNodeService
import es.cinfo.tiivii.core.node.NodeApi
import es.cinfo.tiivii.core.node.NodeService
import es.cinfo.tiivii.core.notifications.DefaultNotificationsApi
import es.cinfo.tiivii.core.notifications.DefaultNotificationsService
import es.cinfo.tiivii.core.notifications.NotificationsApi
import es.cinfo.tiivii.core.notifications.NotificationsService
import es.cinfo.tiivii.core.qr.QRService
import es.cinfo.tiivii.core.user.DefaultUserApi
import es.cinfo.tiivii.core.user.DefaultUserService
import es.cinfo.tiivii.core.user.UserApi
import es.cinfo.tiivii.core.user.UserService
import es.cinfo.tiivii.core.userstats.DefaultUserStatsApi
import es.cinfo.tiivii.core.userstats.DefaultUserStatsService
import es.cinfo.tiivii.core.userstats.UserStatsApi
import es.cinfo.tiivii.core.userstats.UserStatsService
import es.cinfo.tiivii.core.util.*
import es.cinfo.tiivii.search.data.DefaultSearchApi
import es.cinfo.tiivii.search.data.DefaultSearchService
import es.cinfo.tiivii.search.data.SearchApi
import es.cinfo.tiivii.search.data.SearchService
import es.cinfo.tiivii.user.signup.DefaultSignupService
import es.cinfo.tiivii.user.signup.SignupService
import io.ktor.client.*
import io.ktor.client.features.*
import io.ktor.client.features.json.*
import io.ktor.client.features.json.serializer.*
import io.ktor.client.features.logging.*
import io.ktor.client.request.*
import io.ktor.http.*
import kotlinx.serialization.json.Json
import org.kodein.di.DI
import org.kodein.di.bind
import org.kodein.di.instance
import org.kodein.di.singleton

/**
 * Dependency injection module for network related components
 */
internal fun networkModule() = DI.Module("network") {
    bind<Json>() with singleton {
        buildJson()
    }
    bind<HttpClient>() with singleton {
        val loggingEnabled = instance<ConfigModule>().getEnvConfig().loggingEnabled
        val backendUrl = instance<ConfigModule>().getEnvConfig().backendUrl
        HttpClient {
            expectSuccess = false
            if (loggingEnabled) {
                install(Logging) {
                    logger = Logger.SIMPLE
                    level = LogLevel.ALL
                }
            }
            install(JsonFeature) {
                serializer = KotlinxSerializer(
                    instance()
                )
            }
            install(HttpTimeout) {
                requestTimeoutMillis = 10000
            }
            install(TokenRenewal) {
                refreshToken = {
                    when (val newAuthData = RefreshStoredAuthData().invoke()) {
                        is Success -> newAuthData.value.params.accessToken
                        is Failure -> null
                    }
                }
            }
//            install(ApiCallLog) {
//                logApiCall = { url, _ ->
//                    LogApiCall(url).invoke()
//                }
//            }
            defaultRequest {
                var isAuthRequired = true
                val currentUrl = url.clone().build()
                val auth = GetStoredAuth().invoke()
                this.attributes.allKeys.forEach {
                    if (it.name == "authRequired") {
                        isAuthRequired = false
                    }
                }
                if (currentUrl.toString().startsWith(backendUrl) && auth != null && isAuthRequired) {
                    header("Authorization", "Bearer ${auth.params.accessToken}")
                }
            }
        }
    }
    bind<HttpModule>() with singleton {
        HttpModule()
    }
    bind<HttpService>() with singleton {
        HttpService
    }
    bind<DateService>() with singleton {
        DateService()
    }
    bind<PusherService>() with singleton {
        PusherService()
    }
    bind<ErrorService>() with singleton {
        DefaultErrorService()
    }
    bind<ConfigModule>() with singleton {
        DefaultConfigModule()
    }
    bind<ImageModule>() with singleton {
        DefaultImageModule()
    }
}

/**
 * Dependency injection module for authentication related components
 */
internal fun authModule() = DI.Module("auth") {
    bind<AuthService>() with singleton {
        DefaultAuthService()
    }
    bind<AuthStorage>() with singleton {
        DefaultAuthStorage()
    }
    bind<AuthApi>() with singleton {
        DefaultAuthApi()
    }
}

/**
 * Dependency injection module for settings related components
 */
internal fun settingsModule() = DI.Module("settings") {
    bind<Settings>() with singleton {
        Settings()
    }
}

/**
 * Dependency injection module for layout related components
 */
internal fun layoutModule() = DI.Module("layout") {
    bind<LayoutService>() with singleton {
        DefaultLayoutService()
    }
    bind<LayoutStorage>() with singleton {
        DefaultLayoutStorage()
    }
    bind<LayoutApi>() with singleton {
        DefaultLayoutApi()
    }
}

/**
 * Dependency injection module for user related components
 */
internal fun userModule() = DI.Module("user") {
    bind<UserService>() with singleton {
        DefaultUserService()
    }
    bind<UserApi>() with singleton {
        DefaultUserApi()
    }
    bind<UserStatsApi>() with singleton {
        DefaultUserStatsApi()
    }
    bind<UserStatsService>() with singleton {
        DefaultUserStatsService()
    }
    bind<RatingApi>() with singleton {
        DefaultRatingApi()
    }
    bind<RatingService>() with singleton {
        DefaultRatingService()
    }
}

/**
 * Dependency injection module for avatar related components
 */
internal fun avatarModule() = DI.Module("avatar") {
    bind<AvatarService>() with singleton {
        DefaultAvatarService()
    }
    bind<AvatarApi>() with singleton {
        DefaultAvatarApi()
    }
}

/**
 * Dependency injection module for interests related components
 */
internal fun interestModule() = DI.Module("interest") {
    bind<InterestService>() with singleton {
        DefaultInterestService()
    }
    bind<InterestApi>() with singleton {
        DefaultInterestApi()
    }
}

/**
 * Dependency injection module for analytics related components
 */
internal fun analyticsModule() = DI.Module("analytics") {
    bind<AnalyticsService>() with singleton {
        DefaultAnalyticsService()
    }
    bind<AnalyticsApi>() with singleton {
        DefaultAnalyticsApi()
    }
}

/**
 * Dependency injection module for search related components
 */
internal fun searchModule() = DI.Module("search") {
    bind<SearchService>() with singleton {
        DefaultSearchService()
    }
    bind<SearchApi>() with singleton {
        DefaultSearchApi()
    }
}

/**
 * Dependency injection module for signup related components
 */
internal fun signupModule() = DI.Module("signup") {
    bind<SignupService>() with singleton {
        DefaultSignupService()
    }
}

/**
 * Dependency injection module for notifications related components
 */
internal fun notificationsModule() = DI.Module("notifications") {
    bind<NotificationsService>() with singleton {
        DefaultNotificationsService()
    }
    bind<NotificationsApi>() with singleton {
        DefaultNotificationsApi()
    }
}

/**
 * Dependency injection module for content related components
 */
internal fun contentModule() = DI.Module("content") {
    bind<ContentService>() with singleton {
        DefaultContentService()
    }
    bind<ContentApi>() with singleton {
        DefaultContentApi()
    }
    bind<NodeService>() with singleton {
        DefaultNodeService()
    }
    bind<NodeApi>() with singleton {
        DefaultNodeApi()
    }
    bind<TimelineService>() with singleton {
        TimelineService()
    }
    bind<TimelineApi>() with singleton {
        DefaultTimelineApi()
    }
    bind<CheckpointService>() with singleton {
        DefaultCheckpointService()
    }
    bind<CheckpointApi>() with singleton {
        DefaultCheckpointApi()
    }
}

/**
 * Dependency injection module for location related components
 */
internal fun locationModule() = DI.Module("location") {
    bind<LocationApi>() with singleton {
        DefaultLocationApi()
    }
}

internal fun qrModule() = DI.Module("QR") {
    bind<QRService>() with singleton {
        QRService()
    }
}

internal fun shareModule() = DI.Module("Links") {
    bind<ShareService>() with singleton {
        FirebaseShareService()
    }
    bind<ShareApi>() with singleton {
        FirebaseShareApi()
    }
}

internal fun playbackModule() = DI.Module("playback") {
    bind<VideoService>() with singleton {
        DefaultVideoService()
    }
    bind<VideoApi>() with singleton {
        DefaultVideoApi()
    }
    bind<ChatService>() with singleton {
        DefaultChatService()
    }
    bind<ChatApi>() with singleton {
        DefaultChatApi()
    }
    bind<CasService>() with singleton {
        DefaultCasService()
    }
    bind<CasApi>() with singleton {
        DefaultCasApi()
    }
}

internal fun gameModule() = DI.Module("game") {
    bind<GameService>() with singleton {
        DefaultGameService()
    }
    bind<GameApi>() with singleton {
        DefaultGameApi()
    }
}

internal fun productModule() = DI.Module("product") {
    bind<ProductService>() with singleton {
        DefaultProductService()
    }
    bind<ProductApi>() with singleton {
        DefaultProductApi()
    }
}

internal fun coreContainer() = DI {
    import(networkModule())
    import(authModule())
    import(settingsModule())
    import(layoutModule())
    import(userModule())
    import(interestModule())
    import(avatarModule())
    import(analyticsModule())
    import(searchModule())
    import(signupModule())
    import(contentModule())
    import(locationModule())
    import(qrModule())
    import(shareModule())
    import(playbackModule())
    import(gameModule())
    import(notificationsModule())
    import(productModule())
}

/**
 * Dependency injection container
 */
internal expect var diContainer : DI

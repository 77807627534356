package es.cinfo.tiivii.core.features.content.usecase

import es.cinfo.tiivii.core.ComponentId
import es.cinfo.tiivii.core.ErrorId
import es.cinfo.tiivii.core.UseCaseId
import es.cinfo.tiivii.core.content.ContentService
import es.cinfo.tiivii.core.content.model.WidgetModel
import es.cinfo.tiivii.core.error.CodedError
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.error.asErrorId
import es.cinfo.tiivii.core.features.content.model.Model.Content
import es.cinfo.tiivii.core.features.content.model.Model.ContentTreeLoad
import es.cinfo.tiivii.core.features.content.model.ViewModel
import es.cinfo.tiivii.core.modules.auth.AuthService
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.rating.RatingModel
import es.cinfo.tiivii.core.sorting.SortModel.Model.Sort
import es.cinfo.tiivii.core.usecase.GetRatingFilter
import es.cinfo.tiivii.core.user.UserService
import es.cinfo.tiivii.core.util.*
import es.cinfo.tiivii.core.util.Model.Node
import es.cinfo.tiivii.core.util.Model.Tree
import es.cinfo.tiivii.di.diContainer
import kotlinx.serialization.json.jsonObject
import kotlinx.serialization.json.jsonPrimitive
import org.kodein.di.instance

const val WIDGET_FILTER = "[id]"

internal class LoadContentsByWidget(
    private val id: Int,
    private val sort: Sort
) : OutcomeUseCase<ContentTreeLoad, LoadContentsByWidget.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.CONTENT, UseCaseId.LOAD_CONTENT_BY_WIDGET, errorId, networkError) {
        data class UnavailableContents(val error: NetworkError) : Error(
            asErrorId<UnavailableContents>(1),
            error
        )
        object UnsupportedWidgetType : Error(
            asErrorId<UnsupportedWidgetType>(2)
        )
    }

    private val contentService: ContentService by diContainer.instance()
    private val authService: AuthService by diContainer.instance()
    private val userService: UserService by diContainer.instance()
    private val configService: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<ContentTreeLoad, Error>
        get() = {
            val contentLoad: ContentTreeLoad
            val ratingFilter = GetRatingFilter().invokeWith(ComponentId.SEARCH).mapError {
                when (it) {
                    is GetRatingFilter.Error.UnavailableRatings -> Error.UnavailableContents(it.error)
                }
            }.getOrAbort()
            val searchFilters = if (ratingFilter != null) {
                mutableMapOf(RatingModel.Model.RATING_FILTER to ratingFilter)
            } else {
                null
            }
            var language = configService.getCoreConfig().signup.defaultLanguage
            val widgetContentLoad = contentService.getWidgetContents(id, filters = searchFilters, page = 1, sort = sort.param, username = null, language = language)
                .retryWith {
                    if (it is NetworkError.Http) {
                        val isUserRequired = it.json?.jsonObject?.get("key")?.jsonPrimitive?.content == "missing-username-queryparam"
                        val username = authService.getStoredAuth()?.username
                        if (username != null && isUserRequired) {
                            userService.getUser(username).getOrNull()?.let { user ->
                                language = user.preferredLanguage
                            }
                            contentService.getWidgetContents(id, filters = searchFilters, page = 1, sort = sort.param, username = username, language = language)
                        } else {
                            failure(it)
                        }
                    } else {
                        failure(it)
                    }
                }
                .mapError {
                    Error.UnavailableContents(it)
                }
                .getOrAbort()
            if (widgetContentLoad.widget is WidgetModel.Model.Widget.ContentWidget) {
                val widget = widgetContentLoad.widget
                val widgetContents = widget.content.map {
                    Content(
                        id = it.id,
                        title = it.title,
                        type = it.type,
                        background = it.background,
                        banner = it.banner,
                        poster = it.poster,
                        score = it.score,
                        votes = it.votes,
                        rating = it.rating,
                        // TODO: Revisit if nested support needed
                        _hasChildren = false
                    )
                }
                val contentTree = Tree(
                    widgetContents
                )
                contentLoad = ContentTreeLoad(
                    contentTree,
                    widgetContentLoad.count,
                    widgetContentLoad.page,
                    widgetContentLoad.limit,
                    widgetContentLoad.sort,
                    widget.title
                )
                success(contentLoad)
            } else {
                failure(Error.UnsupportedWidgetType)
            }
        }
}

internal class LoadContentPageByWidget(
    private val id: Int,
    private val contentTree: Tree<ViewModel.Content, Content>,
    private val selectedContent: Node<ViewModel.Content, Content>? = null,
    private val queryFilter: String?,
    private val page: Int,
    private val sort: Sort
) : OutcomeUseCase<ContentTreeLoad, LoadContentPageByWidget.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.CONTENT, UseCaseId.LOAD_MORE_CONTENT_BY_WIDGET, errorId, networkError) {
        data class UnavailableContents(val error: NetworkError) : Error(
            asErrorId<UnavailableContents>(1),
            error
        )
        object UnsupportedWidgetType : Error(
            asErrorId<UnsupportedWidgetType>(2)
        )
    }

    private val contentService: ContentService by diContainer.instance()
    private val authService: AuthService by diContainer.instance()
    private val userService: UserService by diContainer.instance()
    private val configService: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<ContentTreeLoad, Error>
        get() = {
            val contentLoad: ContentTreeLoad
            val widgetContents : List<Content>
            val contentsCount: Int
            val contentsPage: Int
            val contentsLimit: Int
            val contentsSort: Sort
            val contentsTitle: String
            if (selectedContent != null) {
                val ratingFilter = GetRatingFilter().invokeWith(ComponentId.CONTENT).mapError {
                    when (it) {
                        is GetRatingFilter.Error.UnavailableRatings -> Error.UnavailableContents(it.error)
                    }
                }.getOrAbort()
                val searchFilters = if (ratingFilter != null) {
                    mutableMapOf(RatingModel.Model.RATING_FILTER to ratingFilter)
                } else {
                    null
                }
                // TODO: Revisit if nested support needed (missing query filter on serial contents endpoint)
                val childrenContentLoad = contentService.getSerialContents(
                    id = selectedContent.value.id,
                    filters = searchFilters,
                    page = page,
                    sort = sort.param
                )
                    .mapError { Error.UnavailableContents(it) }.getOrAbort()
                widgetContents = childrenContentLoad.contents.map {
                    Content(
                        id = it.id,
                        title = it.title,
                        type = it.type,
                        background = it.background,
                        banner = it.banner,
                        poster = it.poster,
                        score = it.score,
                        votes = it.votes,
                        rating = it.rating.code,
                        _hasChildren = it.hasChildren
                    )
                }
                contentsCount = childrenContentLoad.count
                contentsPage = childrenContentLoad.page
                contentsLimit = childrenContentLoad.limit
                contentsSort = childrenContentLoad.sort
                // TODO: Revisit when support for serial is completed
                contentsTitle = "Serial"
                contentTree.addChildren(selectedContent.coordinates, widgetContents)
                contentLoad = ContentTreeLoad(
                    contentTree,
                    contentsCount,
                    contentsPage,
                    contentsLimit,
                    contentsSort,
                    contentsTitle
                )
                success(contentLoad)
            } else {
                val ratingFilter = GetRatingFilter().invokeWith(ComponentId.SEARCH).mapError {
                    when (it) {
                        is GetRatingFilter.Error.UnavailableRatings -> Error.UnavailableContents(it.error)
                    }
                }.getOrAbort()
                val searchFilters = if (ratingFilter != null) {
                    mutableMapOf(RatingModel.Model.RATING_FILTER to ratingFilter)
                } else {
                    null
                }
                var language = configService.getCoreConfig().signup.defaultLanguage
                val widgetContentLoad = contentService.getWidgetContents(id, queryFilter, filters = searchFilters, page = page, sort = sort.param, username = null, language = language)
                    .retryWith {
                        if (it is NetworkError.Http) {
                            val isUserRequired = it.json?.jsonObject?.get("key")?.jsonPrimitive?.content == "missing-username-queryparam"
                            val username = authService.getStoredAuth()?.username
                            if (username != null && isUserRequired) {
                                userService.getUser(username).getOrNull()?.let { user ->
                                    language = user.preferredLanguage
                                }
                                contentService.getWidgetContents(id, filters = searchFilters, page = 1, sort = sort.param, username = username, language = language)
                            } else {
                                failure(it)
                            }
                        } else {
                            failure(it)
                        }
                    }
                    .mapError {
                        Error.UnavailableContents(it)
                    }
                    .getOrAbort()
                if (widgetContentLoad.widget is WidgetModel.Model.Widget.ContentWidget) {
                    val widget = widgetContentLoad.widget
                    widgetContents = widget.content.map {
                        Content(
                            id = it.id,
                            title = it.title,
                            type = it.type,
                            background = it.background,
                            banner = it.banner,
                            poster = it.poster,
                            score = it.score,
                            votes = it.votes,
                            rating = it.rating,
                            // TODO: Revisit if nested support needed
                            _hasChildren = false
                        )
                    }
                    contentsCount = widgetContentLoad.count
                    contentsPage = widgetContentLoad.page
                    contentsLimit = widgetContentLoad.limit
                    contentsSort = widgetContentLoad.sort
                    contentsTitle = widget.title
                    contentTree.addRootNodes(widgetContents)
                    contentLoad = ContentTreeLoad(
                        contentTree,
                        contentsCount,
                        contentsPage,
                        contentsLimit,
                        contentsSort,
                        contentsTitle
                    )
                    success(contentLoad)
                } else {
                    failure(Error.UnsupportedWidgetType)
                }
            }
        }
}

internal class SortContentForWidget(
    private val id: Int,
    private val query: String?,
    private val sort: Sort
) : OutcomeUseCase<ContentTreeLoad, SortContentForWidget.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.CONTENT, UseCaseId.SORT_CONTENT_FOR_WIDGET, errorId, networkError) {
        data class SortUnavailable(val error: NetworkError) : Error(
            asErrorId<SortUnavailable>(1),
            error
        )
        object UnsupportedWidgetType : Error(
            asErrorId<UnsupportedWidgetType>(2)
        )
    }

    private val contentService: ContentService by diContainer.instance()
    private val authService: AuthService by diContainer.instance()
    private val userService: UserService by diContainer.instance()
    private val configService: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<ContentTreeLoad, Error>
        get() = {
            val searchFilters = mutableMapOf(WIDGET_FILTER to id.toString())
            val ratingFilter = GetRatingFilter().invokeWith(ComponentId.SEARCH).mapError {
                when (it) {
                    is GetRatingFilter.Error.UnavailableRatings -> Error.SortUnavailable(it.error)
                }
            }.getOrAbort()
            if (ratingFilter != null) {
                searchFilters[RatingModel.Model.RATING_FILTER] = ratingFilter
            }
            var language = configService.getCoreConfig().signup.defaultLanguage
            val contentLoad = contentService.getWidgetContents(id, query = query, filters = searchFilters, page = 1, sort = sort.param, username = null, language = language)
                .retryWith {
                    if (it is NetworkError.Http) {
                        val isUserRequired = it.json?.jsonObject?.get("key")?.jsonPrimitive?.content == "missing-username-queryparam"
                        val username = authService.getStoredAuth()?.username
                        if (username != null && isUserRequired) {
                            userService.getUser(username).getOrNull()?.let { user ->
                                language = user.preferredLanguage
                            }
                            contentService.getWidgetContents(id, filters = searchFilters, page = 1, sort = sort.param, username = username, language = language)
                        } else {
                            failure(it)
                        }
                    } else {
                        failure(it)
                    }
                }
                .mapError {
                    Error.SortUnavailable(it)
                }
                .getOrAbort()
            if (contentLoad.widget is WidgetModel.Model.Widget.ContentWidget) {
                val widget = contentLoad.widget
                val contents = widget.content.map {
                    Content(
                        id = it.id,
                        title = it.title,
                        type = it.type,
                        background = it.background,
                        banner = it.banner,
                        poster = it.poster,
                        score = it.score,
                        votes = it.votes,
                        rating = it.rating,
                        // TODO: Revisit if nested support needed
                        _hasChildren = false
                    )
                }
                val contentTree = Tree(contents)
                success(
                    ContentTreeLoad(
                        contentTree,
                        contentLoad.count,
                        contentLoad.page,
                        contentLoad.limit,
                        contentLoad.sort,
                        widget.title
                    )
                )
            } else {
                failure(Error.UnsupportedWidgetType)
            }
        }
}

internal class FilterContentsForWidget(
    private val id: Int,
    private val query: String?,
    private val sort: Sort
) : OutcomeUseCase<ContentTreeLoad, FilterContentsForWidget.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.CONTENT, UseCaseId.FILTER_CONTENT_FOR_WIDGET, errorId, networkError) {
        data class FilterUnavailable(val error: NetworkError) : Error(
            asErrorId<FilterUnavailable>(1),
            error
        )
        object UnsupportedWidgetType : Error(
            asErrorId<UnsupportedWidgetType>(2)
        )
    }

    private val contentService: ContentService by diContainer.instance()
    private val authService: AuthService by diContainer.instance()
    private val userService: UserService by diContainer.instance()
    private val configService: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<ContentTreeLoad, Error>
        get() = {
            val searchFilters = mutableMapOf(WIDGET_FILTER to id.toString())
            val ratingFilter = GetRatingFilter().invokeWith(ComponentId.SEARCH).mapError {
                when (it) {
                    is GetRatingFilter.Error.UnavailableRatings -> Error.FilterUnavailable(it.error)
                }
            }.getOrAbort()
            if (ratingFilter != null) {
                searchFilters[RatingModel.Model.RATING_FILTER] = ratingFilter
            }
            var language = configService.getCoreConfig().signup.defaultLanguage
            val contentLoad = contentService.getWidgetContents(
                id = id,
                query = query,
                filters = searchFilters,
                sort = sort.param,
                page = 1,
                username = null,
                language = language
            )
                .retryWith {
                    if (it is NetworkError.Http) {
                        val isUserRequired = it.json?.jsonObject?.get("key")?.jsonPrimitive?.content == "missing-username-queryparam"
                        val username = authService.getStoredAuth()?.username
                        if (username != null && isUserRequired) {
                            userService.getUser(username).getOrNull()?.let {  user ->
                                language = user.preferredLanguage
                            }
                            contentService.getWidgetContents(id, filters = searchFilters, page = 1, sort = sort.param, username = username, language = language)
                        } else {
                            failure(it)
                        }
                    } else {
                        failure(it)
                    }
                }
                .mapError {
                    Error.FilterUnavailable(it)
                }
                .getOrAbort()
            if (contentLoad.widget is WidgetModel.Model.Widget.ContentWidget) {
                val widget = contentLoad.widget
                val contents = widget.content.map {
                    Content(
                        id = it.id,
                        title = it.title,
                        type = it.type,
                        background = it.background,
                        banner = it.banner,
                        poster = it.poster,
                        score = it.score,
                        votes = it.votes,
                        rating = it.rating,
                        // TODO: Revisit if nested support needed
                        _hasChildren = false
                    )
                }
                val contentTree = Tree(contents)
                success(
                    ContentTreeLoad(
                        contentTree,
                        contentLoad.count,
                        contentLoad.page,
                        contentLoad.limit,
                        contentLoad.sort,
                        widget.title
                    )
                )
            } else {
                failure(Error.UnsupportedWidgetType)
            }
        }
}
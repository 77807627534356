package es.cinfo.tiivii.core.modules.analytics

import es.cinfo.tiivii.core.modules.analytics.model.AnalyticsModel
import es.cinfo.tiivii.core.modules.auth.AuthService
import es.cinfo.tiivii.core.util.UseCase
import es.cinfo.tiivii.di.diContainer
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import org.kodein.di.instance

/**
 * Request a log of the given api call to the analytics endpoint
 * @param httpRequest String with the request to log
 */
internal class LogApiCall(private val httpRequest: String) : UseCase<Unit> {
    private val analyticsService: AnalyticsService by diContainer.instance()
    private val authService: AuthService by diContainer.instance()

    override suspend fun invoke() {
        val username = authService.getStoredAuth()?.username
        CoroutineScope(Dispatchers.Main).launch {
            analyticsService.logApiCall(username = username, httpRequest)
        }
    }
}

/**
 * Request a log of the given action to the analytics endpoint
 * @param action [AnalyticsModel.Action] to be logged
 * @param contentId to be logged associated to the [action] if any
 * @param keyValue Optional pair of key and values that will be registered as "key" and "value" params on the query
 * @param logIfAnonymous Boolean indicating if the user is not logged in, the log should be sent either way. By default true
 */
internal class LogEvent(
    private val action: AnalyticsModel.Action,
    private val contentId: Int? = null,
    private val keyValue: Pair<String, String>? = null,
    private val logIfAnonymous: Boolean = true,
) : UseCase<Unit> {
    private val analyticsService: AnalyticsService by diContainer.instance()
    private val authService: AuthService by diContainer.instance()

    override suspend fun invoke() {
        val username = authService.getStoredAuth()?.username
        if (username != null || logIfAnonymous) {
            CoroutineScope(Dispatchers.Main).launch {
                    analyticsService.logEvent(
                        username = username,
                        contentId = contentId,
                        action = action,
                        keyValue = keyValue)
                }
        }
    }
}
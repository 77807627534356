package es.cinfo.tiivii.core.features.content.model

import es.cinfo.tiivii.core.image.Model
import es.cinfo.tiivii.core.sorting.SortModel
import es.cinfo.tiivii.core.util.Model.Tree
import es.cinfo.tiivii.core.util.Nestable
import es.cinfo.tiivii.core.util.NodeValue
import es.cinfo.tiivii.core.content.model.ContentTypeModel.Model.ContentType as ContentTypeModel
import es.cinfo.tiivii.core.content.model.ContentTypeModel.ViewModel.ContentType as ContentTypeViewModel
import es.cinfo.tiivii.core.image.ViewModel.Image as ImageViewModel

sealed class ViewModel {

    /**
     * Representation of the content associated to the content screen section
     */
    data class Content(
        val id: Int,
        val title: String,
        val type: ContentTypeViewModel,
        val background: ImageViewModel?,
        val banner: ImageViewModel?,
        val poster: ImageViewModel?,
        val score: Float,
        val votes: Int,
        val rating: String,
        val _hasChildren: Boolean
    ): Nestable {
        override fun hasChildren() = _hasChildren
        override fun getName() = title
    }
}

internal sealed class Model {

    data class ContentTreeLoad(
        val contents: Tree<ViewModel.Content, Content>,
        val count: Int,
        val page: Int,
        val limit: Int,
        val sort: SortModel.Model.Sort,
        val title: String
    ) {
        fun hasMoreContent(): Boolean {
            return page * limit < count
        }
    }

    data class Content(
        val id: Int,
        val title: String,
        val type: ContentTypeModel,
        val score: Float,
        val votes: Int,
        val rating: String,
        val background: Model.Image?,
        val banner: Model.Image?,
        val poster: Model.Image?,
        private val _hasChildren: Boolean
    ): NodeValue<ViewModel.Content> {
        override fun hasChildren() = _hasChildren
        override fun getName() = title
        override fun toViewModel(): ViewModel.Content {
            return ViewModel.Content(
                id = id,
                title = title,
                type = type.toViewModel(),
                background = background?.toViewModel(),
                banner = banner?.toViewModel(),
                poster = poster?.toViewModel(),
                _hasChildren = _hasChildren,
                score = score,
                votes = votes,
                rating = rating
            )
        }
    }
}
package es.cinfo.tiivii.core.features.playback.native.model

import es.cinfo.tiivii.core.content.model.Model.Content
import es.cinfo.tiivii.core.image.Model.Image
import es.cinfo.tiivii.core.modules.checkpoint.model.CheckpointModel
import es.cinfo.tiivii.core.modules.game.model.GameModel
import es.cinfo.tiivii.core.modules.product.model.ProductModel
import es.cinfo.tiivii.core.modules.timeline.model.TimelineModel
import es.cinfo.tiivii.core.modules.video.model.VideoModel

sealed class PlaybackModel {
    sealed class ViewModel {
        /**
         * Represents the content that is going to be played
         * @param id of the content to be played
         */
        data class PlaybackContent(
            val id: Int,
            val videoUrl: String
        )

        /**
         * Represents the information available from the playback
         */
        data class Playback(
            val posMs: Long,
            val durationMs: Long?,
            val speedRate: Float,
            val contentId: Int,
            val timespots: List<TimelineModel.ViewModel.Timespot>,
            val error: PlaybackError? = null
        )

        /**
         * Represents and error with the [Playback]
         */
        data class PlaybackError(
            val recoverable: Boolean,
            val message: String
        )

        /**
         * Represents the current playback status and indicates if the playback is ready
         */
        enum class PlaybackStatus {
            /**
             * A playback has not been prepared on the player
             */
            NOT_READY,
            /**
             * A playback is being prepared
             */
            PREPARING,
            /**
             * A playback is preapred to start
             */
            PREPARED;

        }

        /**
         * Represents a lock reason as to why the user can not watch some content
         */
        sealed class PlaybackLockReason {
            /**
             * The user has not received the required achievements to play the content
             * @param achievements that the user needs to accomplish to play the content
             */
            data class MissingAchievements(val achievements: List<GameModel.ViewModel.Achievement>): PlaybackLockReason()

            /**
             * The user has not purchase one of the required products to play the content
             * @param products that the user may purchase to play the content
             */
            data class ProductNotPurchased(val products: List<ProductModel.ViewModel.Product>): PlaybackLockReason()

            /**
             * The user can't see this content due to age rating lock
             */
            object RatingLocked: PlaybackLockReason()

            /**
             * The CAS service does not allow the user to watch the content, reason unknown
             */
            object Cas: PlaybackLockReason()

            /**
             * No user session has been found
             */
            object UserSessionRequired: PlaybackLockReason()
        }
    }
    internal class Model {

        data class CheckpointSelectedResult(
            val correctAnswerSelected: Boolean
        )

        // TODO f2p-options: maybe remove?
//        sealed class PlayerPlaybackSupport {
//            object Ready: PlayerPlaybackSupport()
//            sealed class Locked: PlayerPlaybackSupport() {
//                object MissingAchievements: Locked()
//                object MissingProduct: Locked()
//                object Other: Locked()
//            }
//            sealed class Unavailable: PlayerPlaybackSupport() {
//                object ServiceUnavailable: Unavailable()
//                object MissingSmil: Unavailable()
//            }
//            sealed class Unsupported: PlayerPlaybackSupport() {
//                object Unrecognised: Unsupported()
//                object NoPlatformSupport: Unsupported()
//            }
//        }

        data class EnhancedPlayback(
            val posMs: Long,
            val durationMs: Long?,
            val speedRate: Float,
            val contentId: Int,
            val timespots: List<TimelineModel.Model.Timespot>,
            val checkpoint: CheckpointModel.Model.Checkpoint?,
            val error: PlaybackError? = null
        ) {
            private fun currentPercentage(): Int? {
                if (posMs == 0L || durationMs == 0L) {
                    return 0
                }
                val percent = durationMs?.let {
                    posMs * 100 / durationMs
                }
                return percent?.toInt()
            }

            fun hasReachedEnd(minPercent: Int = 99): Boolean {
                val percent = currentPercentage()
                return percent != null && percent >= minPercent
            }
        }

        data class Playback(
            val posMs: Long,
            val durationMs: Long?,
            val speedRate: Float,
            val contentId: Int,
            val error: PlaybackError? = null
        ) {
            fun enhance(timespots: List<TimelineModel.Model.Timespot>,
                        checkpoint: CheckpointModel.Model.Checkpoint?
            ): EnhancedPlayback {
                return EnhancedPlayback(
                    posMs = posMs,
                    durationMs = durationMs,
                    speedRate = speedRate,
                    contentId = contentId,
                    timespots = timespots,
                    checkpoint = checkpoint,
                    error = error
                )
            }
        }
        data class PlaybackError(
            val recoverable: Boolean,
            val message: String
        )
        data class PlaybackContent(
            val content: Content,
            val backgroundImage: Image?,
            val video: VideoModel.Model.ProcessedVideo,
            val checkpoints: List<CheckpointModel.Model.Checkpoint>
        ) {
            fun toViewModel(): ViewModel.PlaybackContent =
                ViewModel.PlaybackContent(
                    content.id,
                    video.url
                )
        }
        data class PlayerPrepared(
            val content: PlaybackContent,
            val startPercent: Int,
            val shareQrBase64: String?
        )
        sealed class PlaybackLockReason {
            data class MissingAchievements(val achievements: List<GameModel.Model.Achievement>): PlaybackLockReason() {
                override fun toViewModel(): ViewModel.PlaybackLockReason =
                    ViewModel.PlaybackLockReason.MissingAchievements(achievements.map { it.toViewModel() })
            }

            data class ProductNotPurchased(val products: List<ProductModel.Model.Product>): PlaybackLockReason() {
                override fun toViewModel(): ViewModel.PlaybackLockReason =
                    ViewModel.PlaybackLockReason.ProductNotPurchased(products.map { it.toViewModel() })
            }

            object RatingLocked: PlaybackLockReason() {
                override fun toViewModel(): ViewModel.PlaybackLockReason =
                    ViewModel.PlaybackLockReason.RatingLocked
            }

            object Cas: PlaybackLockReason() {
                override fun toViewModel(): ViewModel.PlaybackLockReason =
                    ViewModel.PlaybackLockReason.Cas
            }

            object UserSessionRequired: PlaybackLockReason() {
                override fun toViewModel(): ViewModel.PlaybackLockReason =
                    ViewModel.PlaybackLockReason.UserSessionRequired
            }

            abstract fun toViewModel(): ViewModel.PlaybackLockReason
        }
        enum class PlaybackStatus {
            NOT_READY, PREPARING, PREPARED;

            fun toViewModel(): ViewModel.PlaybackStatus {
                return when (this) {
                    NOT_READY -> ViewModel.PlaybackStatus.NOT_READY
                    PREPARING -> ViewModel.PlaybackStatus.PREPARING
                    PREPARED -> ViewModel.PlaybackStatus.PREPARED
                }
            }
        }
    }
}
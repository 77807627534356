package es.cinfo.tiivii.user.splash.store

import com.arkivanov.mvikotlin.core.store.Store
import es.cinfo.tiivii.core.layout.model.layoutconfig.Model
import es.cinfo.tiivii.core.modules.game.model.GameModel
import es.cinfo.tiivii.core.util.LoadingModel
import es.cinfo.tiivii.user.screen.ScreenModel.Model.Screen
import es.cinfo.tiivii.user.splash.store.SplashStore.*

/**
 * [Store] implementation for the splash screen
 */
internal interface SplashStore : Store<Intent, State, Label> {

    sealed class Action { }

    sealed class Intent {
        object GetFirstScreen : Intent()
        data class HandleLoginFailure(val error: String) : Intent()
        data class StoreLogin(val accessToken: String, val refreshToken: String) : Intent()
        object LogLoginView : Intent()
    }

    data class State(
        val loadingNextScreen: LoadingModel.Model.LoadState,
        val nextScreen: Screen?,
        val iosAppUrl: String?,
        val androidAppUrl: String?,
        val auth: Screen.Auth?,
        val styles: Model.LayoutConfig.StyleConfig?,
        val keycloakParams: Screen.KeycloakParams?
    )

    sealed class Label {
        data class GameActionSent(
            val actionResult: GameModel.Model.ActionResult
        ) : Label()
        object UserSessionExpired: Label()
        object RequestTimedOut: Label()
        data class UnexpectedError(val code: String): Label()
    }

}
package es.cinfo.tiivii.core.features.ranking.usecase

import es.cinfo.tiivii.core.ComponentId
import es.cinfo.tiivii.core.ErrorId
import es.cinfo.tiivii.core.UseCaseId
import es.cinfo.tiivii.core.error.CodedError
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.error.asErrorId
import es.cinfo.tiivii.core.modules.auth.AuthService
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.user.UserService
import es.cinfo.tiivii.core.userstats.UserStatsModel.Model.RankingStats
import es.cinfo.tiivii.core.userstats.UserStatsService
import es.cinfo.tiivii.core.util.*
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

internal class LoadUserStats(private val forceUpdate: Boolean): OutcomeUseCase<RankingStats, LoadUserStats.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.RANKING, UseCaseId.LOAD_USER_STATS, errorId, networkError) {
        data class UserStatsUnavailable(val error: NetworkError) : Error(
            asErrorId<UserStatsUnavailable>(1),
            error)
        data class RankingsUnavailable(val error: NetworkError) : Error(
            asErrorId<RankingsUnavailable>(2),
            error)
        object NoUserSession : Error(
            asErrorId<NoUserSession>(3),
        )
        object GamificationModuleDisabled : Error(
            asErrorId<GamificationModuleDisabled>(4),
        )
    }

    private val userStatsService: UserStatsService by diContainer.instance()
    private val authService: AuthService by diContainer.instance()
    private val userService: UserService by diContainer.instance()
    private val configService: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<RankingStats, Error>
        get() = {
            if (!configService.getCoreConfig().gamification.enabled) {
                failure(Error.GamificationModuleDisabled)
            } else {
                val username = authService.getStoredAuth()?.username
                if (username == null) {
                    failure(Error.NoUserSession)
                } else {
                    val avatar = userService.getCachedUser()!!.avatar
                    val userStats = userStatsService.getUserStats(username, !forceUpdate)
                        .mapError {
                            Error.UserStatsUnavailable(it)
                        }.getOrAbort()
                    val rankings = userStats.boardId?.let {
                        userStatsService.getBoardRankings(userStats.boardId)
                            .mapError {
                                Error.RankingsUnavailable(it)
                            }.getOrAbort()
                    }
                    success(RankingStats(avatar, rankings, userStats))
                }
            }
        }
}
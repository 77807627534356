package es.cinfo.tiivii.search.data

import es.cinfo.tiivii.core.sorting.SortModel.Model
import es.cinfo.tiivii.core.content.model.ContentTypeModel
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.util.*
import es.cinfo.tiivii.di.diContainer
import es.cinfo.tiivii.search.data.SearchService.Companion.defaultSort
import es.cinfo.tiivii.search.model.Search.Model.SearchResult
import org.kodein.di.instance
import kotlin.random.Random

internal interface SearchService {

    companion object {
        // TODO: Add to configuration service
        val defaultSort = Model.Sort.mostRecentFirst
        const val DEFAULT_SEARCH_LIMIT = 100
    }

    suspend fun search(
        searchQuery: String? = null,
        sort: String? = null,
        filters: Map<String, String>? = null,
        page: Int,
        limit: Int = DEFAULT_SEARCH_LIMIT
    ): Outcome<SearchResult, NetworkError>

    suspend fun getSuggestions(searchQuery: String): Outcome<List<SearchResult.SearchResultEntry>, NetworkError>
}

internal class DefaultSearchService : SearchService {
    private val searchApi: SearchApi by diContainer.instance()
    private val suggestionsCache = TimedCache<Int, List<SearchResult.SearchResultEntry>>()

    override suspend fun search(
        searchQuery: String?,
        sort: String?,
        filters: Map<String, String>?,
        page: Int,
        limit: Int
    ): Outcome<SearchResult, NetworkError> {
        val sortParam = sort ?: defaultSort.param
        return searchApi.performSearch(searchQuery, sortParam, filters, page, limit)
            .map {
                it.toModel()
            }
    }

    override suspend fun getSuggestions(searchQuery: String): Outcome<List<SearchResult.SearchResultEntry>, NetworkError> {
        return suggestionsCache.get(1) {
            //TODO: Replace suggestions mock
            success(generateMockSuggestions())
        }
    }

    private fun generateMockSuggestions(): List<SearchResult.SearchResultEntry> {
        return generateMockSearch(Random.nextInt(500))
    }

    private fun generateMockSearch(size: Int): List<SearchResult.SearchResultEntry> {
        val searches = mutableListOf<SearchResult.SearchResultEntry>()
        for (index in 1..size) {
            val id = Random.nextInt(1000)
            val title = Random.nextString(10)
            val subtitle = Random.nextString(20)
            val score = Random.nextFloat() * 10 / 2
            val votes = Random.nextInt(1000)
            val rating = "TP"
            val type = ContentTypeModel.Model.ContentType.values()[Random.nextInt(ContentTypeModel.Model.ContentType.values().size)]
            val author = Random.nextString(8)
            val background = es.cinfo.tiivii.core.image.Model.Image(
                id = Random.nextInt(1000),
                width = 1280,
                height = 720,
                url = "https://via.placeholder.com/1280x720?text=${title}",
                type = es.cinfo.tiivii.core.image.Model.Image.Type.BACKGROUND
            )
            val banner = es.cinfo.tiivii.core.image.Model.Image(
                id = Random.nextInt(1000),
                width = 320,
                height = 180,
                url = "https://via.placeholder.com/320x180?text=${title}",
                type = es.cinfo.tiivii.core.image.Model.Image.Type.BANNER
            )
            val poster = es.cinfo.tiivii.core.image.Model.Image(
                id = Random.nextInt(1000),
                width = 200,
                height = 500,
                url = "https://via.placeholder.com/200x500?text=${title}",
                type = es.cinfo.tiivii.core.image.Model.Image.Type.POSTER
            )
            val hasChildren = false
            val parentId = null
            searches.add(
                SearchResult.SearchResultEntry(
                    id = id,
                    title = title,
                    subtitle = subtitle,
                    score = score,
                    votes = votes,
                    rating = rating,
                    type = type,
                    author = author,
                    background = background,
                    banner = banner,
                    poster = poster,
                    hasChildren = hasChildren,
                    parentId = parentId
                )
            )
        }
        return searches
    }
}
package es.cinfo.tiivii.core.modules.analytics

import es.cinfo.tiivii.core.modules.analytics.model.AnalyticsModel
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.network.HttpModule
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.core.util.map
import es.cinfo.tiivii.di.diContainer
import io.ktor.client.features.*
import io.ktor.client.statement.*
import io.ktor.http.*
import io.ktor.util.*
import org.kodein.di.instance

internal interface AnalyticsApi {

    /**
     * Sends the log of an event to backend
     * @param username The username associated with the event or null if anonymous
     * @param contentId The id of the content related to the action if any
     * @param action [AnalyticsModel.Action] that is to be logged
     * @param keyValue Pair of "key" and "value" params ot register with the query
     */
    suspend fun logEvent(
        username: String? = null,
        contentId: Int? = null,
        action: AnalyticsModel.Action,
        keyValue: Pair<String, String>? = null,
    ): Outcome<AnalyticsModel.Event, NetworkError>

    suspend fun logApiCall(
        username: String?,
        url: String
    )
}

internal class DefaultAnalyticsApi : AnalyticsApi {
    private val http: HttpModule by diContainer.instance()

    private val baseEndpoint: String by lazy {
        val configModule: ConfigModule by diContainer.instance()
        "${configModule.getEnvConfig().backendUrl}/analytics/${configModule.getEnvConfig().apiName}"
    }

    override suspend fun logEvent(
        username: String?,
        contentId: Int?,
        action: AnalyticsModel.Action,
        keyValue: Pair<String, String>?,
    ): Outcome<AnalyticsModel.Event, NetworkError> {
        var userPath = "anonymous"
        if (username != null) {
            userPath = "user/$username"
        }
        var endpoint = "$baseEndpoint/$userPath?action=$action"
        if (contentId != null) {
            endpoint += "&contentid=$contentId"
        }
        if (keyValue != null) {
            var value = keyValue.second
            try {
                val valueUrl = Url(keyValue.second)
                if (valueUrl.parameters.contains("key")) {
                    val filteredParams = valueUrl.parameters.filter { key, _ ->
                        key != "key" && key != "value" && key != "action" && key != "contentId"
                    }
                    val paramBuilder = ParametersBuilder()
                    paramBuilder.appendAll(filteredParams)
                    val params = paramBuilder.build()
                    value = valueUrl.copy(parameters = params).toString()
                }
            } catch (e: URLParserException) {
                value = keyValue.second
            }
            endpoint += "&key=${keyValue.first}&value=${value}"
        }
        return http.getOrError(endpoint = endpoint).map { AnalyticsModel.Event(endpoint) }
    }

    /**
     * Sends the log of an api call that has been done
     * @param username The username associated with the event or null if anonymous
     * @param url That has been called
     */
    override suspend fun logApiCall(username: String?, url: String) {
        var userPath = "anonymous"
        if (username != null) {
            userPath = "user/$username"
        }
        try {
            var encodedUrl = Url(url)
            if (encodedUrl.parameters.contains("key")) {
                val filteredParams = encodedUrl.parameters.filter { key, _ ->
                    key != "key" && key != "value" && key != "action"
                }
                val paramBuilder = ParametersBuilder()
                paramBuilder.appendAll(filteredParams)
                val params = paramBuilder.build()
                encodedUrl = encodedUrl.copy(parameters = params)
            }
            val endpoint = "$baseEndpoint/$userPath?" +
                    "action=${AnalyticsModel.Action.ApiCall}&" +
                    "key=${AnalyticsModel.Action.ApiCall.HTTP_REQUEST_KEY}&" +
                    "value=${encodedUrl}"
            http.getAsOutcome<HttpResponse>(endpoint = endpoint) {
                expectSuccess = false
            }
        } catch (e: URLParserException) {
            // We do nothing if the url is not valid
        }
    }

}
package es.cinfo.tiivii.core.modules.rating

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.SerializationException

sealed class RatingModel {
    class Model {
        companion object {
            const val RATING_FILTER = "[rating][in]"
        }
        data class AgeRating(
            val id: Int?,
            val code: String,
            val minAge: Int,
            val isAdultContent: Boolean
        ) {
            companion object {
                fun parse(id: Int?, code: String): AgeRating {
                    return if (code.equals("TP", true) || code == "0") {
                        AgeRating(
                            id = id,
                            code = code,
                            minAge = 0,
                            isAdultContent = false
                        )
                    } else if (code.equals("X", false)) {
                        AgeRating(
                            id = id,
                            code = code,
                            minAge = 18,
                            isAdultContent = true
                        )
                    } else {
                        val userRatingSplit = code.split(">")
                        return if (userRatingSplit.size != 2) {
                            throw SerializationException("Unrecognized rating $code")
                        } else {
                            val minAge = userRatingSplit[1].toInt()
                            AgeRating(
                                id = id,
                                code = code,
                                minAge = minAge,
                                isAdultContent = false
                            )
                        }
                    }
                }
            }
        }
    }

    class ApiResponse {

        @Serializable
        data class AgeRatings(
            @SerialName(RATINGS_PARAM)
            val ratings: List<AgeRating>
        ) {
            companion object {
                const val RATINGS_PARAM = "data"
            }

            fun toModel(): List<Model.AgeRating> {
                return ratings.map {
                    Model.AgeRating.parse(it.id, it.code)
                }
            }
        }

        @Serializable
        data class AgeRating(
            @SerialName(ID_PARAM)
            val id: Int? = null,
            @SerialName(CODE_PARAM)
            val code: String,
        ) {
            companion object {
                const val ID_PARAM = "id"
                const val CODE_PARAM = "code"
            }

            fun toModel(): Model.AgeRating =
                Model.AgeRating.parse(id, code)
        }
    }
}
package es.cinfo.tiivii.core.features.content.binder

import es.cinfo.tiivii.core.features.content.store.ContentStore.*
import es.cinfo.tiivii.core.features.content.view.ContentView.*

internal val STATE_TO_MODEL: State.() -> Model = {
    Model(
        title = title,
        isSearchAvailable = isSearchAvailable,
        contents = contents?.toViewModel(),
        hasMoreContents = hasMoreContents,
        queryFilter = queryFilter,
        selectedSort = selectedSort?.id,
        availableSorts = availableSorts?.map { it.id },
        areContentsLoading = loadingContents.toViewModel(),
    )
}

internal val EVENT_TO_INTENT: Event.() -> Intent = {
    when (this) {
        is Event.LoadContentByWidget -> Intent.LoadContentByWidget(id)
        is Event.LoadContentByParticipant -> Intent.LoadContentByParticipant(id)
        is Event.LoadContentByCategory -> Intent.LoadContentByCategory(id)
        is Event.LoadContentById -> Intent.LoadContentById(id)
        is Event.Search -> Intent.Search(query)
        Event.ClearSearch -> Intent.ClearSearch
        is Event.Sort -> Intent.Sort(sortId)
        Event.LoadMoreContent -> Intent.LoadMoreContent
        Event.LogView -> Intent.LogView
    }
}

internal val LABEL_TO_OUTPUT: Label.() -> Output = {
    when (this) {
        is Label.UnexpectedError -> Output.UnexpectedError(code)
        Label.UserSessionExpired -> Output.UserSessionExpired
        Label.RequestTimedOut -> Output.RequestTimedOut
        is Label.IllegalOperationError -> Output.IllegalOperationError(reason)
    }
}

package es.cinfo.tiivii.core.modules.geolocation

import es.cinfo.tiivii.core.util.map
import es.cinfo.tiivii.di.diContainer
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import org.kodein.di.instance

internal actual class LocationService {
    //TODO: Add to configuration service
    companion object {
        const val LOCATION_RETRIEVAL_INTERVAL_MS = 5000
    }

    private val locationApi: LocationApi by diContainer.instance()

    private var started: Boolean = false

    fun start(user: String, updateIntervalMs: Int) {
        if (!started) {
            started = true
            startLocationRetrieval(
                object : LocationCallback {
                    override fun onLocation(latitude: String, longitude: String) {
                        if (started) {
                            CoroutineScope(Dispatchers.Main).launch {
                                locationApi.getLocationHash(user, latitude.toDouble(), longitude.toDouble())
                                    .map {
                                        locationApi.postLocation(it, user, latitude.toDouble(), longitude.toDouble())
                                    }
                            }
                        }
                    }

                    override fun onError(error: String) { }
                }, updateIntervalMs
            )
        }
    }

    fun stop() {
        stopLocationRetrieval()
        started = false
    }
}

interface LocationCallback {
    @JsName("onLocation")
    fun onLocation(latitude: String, longitude: String)
    @JsName("onError")
    fun onError(error: String)
}

// TODO: Improve maintainability with some external JS declarations
fun startLocationRetrieval(callback: LocationCallback, intervalMs: Int) {
    js(
        """
                if ("geolocation" in navigator) {
                    navigator.geolocation.getCurrentPosition(
                        function(position) {
                            window.locationInterval = setInterval(function(){
                                navigator.geolocation.getCurrentPosition(
                                    function(position) {
                                        callback.onLocation(position.coords.latitude, position.coords.longitude)
                                    },
                                    function() {
                                        clearInterval(window.locationInterval)
                                        callback.onError("Location access denied")
                                    });
                            }, intervalMs);
                        },
                        function() {
                            callback.onError("Location access denied")
                        });
                } else {
                    callback.onError("No Location available")
                }
        """
    )
}

// TODO: Improve maintainability with some external JS declarations
fun stopLocationRetrieval() {
    js(
        """
            clearInterval(window.locationInterval)
        """
    )
}
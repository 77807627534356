package es.cinfo.tiivii.core.content.model.report

import es.cinfo.tiivii.core.translation.TranslationModel
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

class ContentReportModel {
    class ViewModel {
        /**
         * Representation of a content report reason
         * @param id of the report reason
         * @param name text to be presented to the user for the report reason
         */
        data class ContentReport(
            val id: Int,
            val name: String
        )
    }

    internal sealed class Model {
        data class ContentReport(
            val id: Int,
            val name: String
        ) {
            fun toViewModel(): ViewModel.ContentReport {
                return ViewModel.ContentReport(
                    id, name
                )
            }
        }
    }

    internal sealed class ApiResponse {
        @Serializable
        data class ContentReport(
            @SerialName(ID_PARAM)
            val id: Int,
            @SerialName(NAME_PARAM)
            val name: String,
            @SerialName(TRANSLATIONS_PARAM)
            @Serializable(with = TranslationModel.ApiResponse.Translations.TranslationsDeserializer::class)
            val translations: TranslationModel.ApiResponse.Translations?
        ) {
            companion object {
                const val ID_PARAM = "id"
                const val NAME_PARAM = "name"
                const val TRANSLATIONS_PARAM = "translations"
            }

            fun toModel(language: String): Model.ContentReport {
                val name = translations?.find(NAME_PARAM, language) ?: name
                return Model.ContentReport(
                    id, name
                )
            }
        }
    }
}
package es.cinfo.tiivii.core.user.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal data class CreateUserApiRequest(
    @SerialName(LANGUAGE_PARAM)
    private val language: String,
    @SerialName(LEGAL_VERSION_PARAM)
    private val legalVersion: Int,
    @SerialName(USERNAME_PARAM)
    private val username: String,
    @SerialName(FIRST_NAME_PARAM)
    private val firstName: String,
    @SerialName(LAST_NAME_PARAM)
    private val lastName: String,
    @SerialName(CREDENTIALS_PARAM)
    private val credentials: String,
    @SerialName(EMAIL_PARAM)
    private val email: String,
    @SerialName(BIRTHDAY_PARAM)
    private val birthday: String,
    @SerialName(INTERESTS_PARAM)
    private val interests: Set<Int>,
    @SerialName(AVATAR_PARAM)
    private val avatar: Int,
    @SerialName(HASH_PARAM)
    private val hash: String,
) {
    companion object {
        const val LANGUAGE_PARAM = "language"
        const val LEGAL_VERSION_PARAM = "acceptedLegalVersion"
        const val USERNAME_PARAM = "username"
        const val FIRST_NAME_PARAM = "firstName"
        const val CREDENTIALS_PARAM = "credentials"
        const val LAST_NAME_PARAM = "lastName"
        const val EMAIL_PARAM = "email"
        const val BIRTHDAY_PARAM = "birthdate"
        const val INTERESTS_PARAM = "interests"
        const val AVATAR_PARAM = "avatar"
        const val HASH_PARAM = "hash"
    }
}

@Serializable
internal data class CreateTutoredUserApiRequest(
    @SerialName(LANGUAGE_PARAM)
    private val language: String,
    @SerialName(LEGAL_VERSION_PARAM)
    private val legalVersion: Int,
    @SerialName(USERNAME_PARAM)
    private val username: String,
    @SerialName(FIRST_NAME_PARAM)
    private val firstName: String,
    @SerialName(LAST_NAME_PARAM)
    private val lastName: String,
    @SerialName(CREDENTIALS_PARAM)
    private val credentials: String,
    @SerialName(TUTOR_EMAIL_PARAM)
    private val tutorEmail: String,
    @SerialName(BIRTHDAY_PARAM)
    private val birthday: String,
    @SerialName(INTERESTS_PARAM)
    private val interests: Set<Int>,
    @SerialName(AVATAR_PARAM)
    private val avatar: Int,
    @SerialName(HASH_PARAM)
    private val hash: String,
) {
    companion object {
        const val LANGUAGE_PARAM = "language"
        const val LEGAL_VERSION_PARAM = "acceptedLegalVersion"
        const val USERNAME_PARAM = "username"
        const val FIRST_NAME_PARAM = "firstName"
        const val CREDENTIALS_PARAM = "credentials"
        const val LAST_NAME_PARAM = "lastName"
        const val TUTOR_EMAIL_PARAM = "tutor"
        const val BIRTHDAY_PARAM = "birthdate"
        const val INTERESTS_PARAM = "interests"
        const val AVATAR_PARAM = "avatar"
        const val HASH_PARAM = "hash"
    }
}

@Serializable
internal data class UpdateUserApiRequest(
    @SerialName(LANGUAGE_PARAM)
    private val language: String? = null,
    @SerialName(FIRST_NAME_PARAM)
    private val firstName: String? = null,
    @SerialName(LAST_NAME_PARAM)
    private val lastName: String? = null,
    @SerialName(INTERESTS_PARAM)
    private val interests: Set<Int>? = null,
    @SerialName(AVATAR_PARAM)
    private val avatar: Int? = null,
    @SerialName(LEGAL_PARAM)
    private val legalVersion: Int? = null
) {
    companion object {
        const val LANGUAGE_PARAM = "language"
        const val FIRST_NAME_PARAM = "firstName"
        const val LAST_NAME_PARAM = "lastName"
        const val INTERESTS_PARAM = "interests"
        const val AVATAR_PARAM = "avatar"
        const val LEGAL_PARAM = "acceptedLegalVersion"
    }
}

@Serializable
internal data class UpdateUserCredentialsApiRequest(
    @SerialName(OLD_CREDENTIALS_PARAM)
    private val oldCredentials: String? = null,
    @SerialName(CREDENTIALS_PARAM)
    private val credentials: String? = null,
    @SerialName(CONFIRMATION_PARAM)
    private val credentialsConfirmation: String? = null
) {
    companion object {
        const val OLD_CREDENTIALS_PARAM = "currentPassword"
        const val CREDENTIALS_PARAM = "newPassword"
        const val CONFIRMATION_PARAM = "confirmation"
    }
}

package es.cinfo.tiivii.core.modules.share.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

internal class ShareModel {
    internal sealed class ApiResponse {
        @Serializable
        data class ShortLink(
            @SerialName(LINK_KEY)
            val link: String
        ) {
            companion object {
                const val LINK_KEY = "shortLink"
            }
        }
    }
}
package es.cinfo.tiivii.core.modules.game

import es.cinfo.tiivii.core.date.DateService
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.modules.game.model.GameModel
import es.cinfo.tiivii.core.modules.game.model.GameModel.Model.Action
import es.cinfo.tiivii.core.modules.game.model.GameModel.ApiResponse.Action as ActionApiResponse
import es.cinfo.tiivii.core.util.*
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

internal interface GameService {
    sealed class GameServiceError {
        data class UnavailableService(val error: NetworkError): GameServiceError()
        data class ActionError(
            val defaultAction: NetworkError? = null,
            val inGameAction: NetworkError? = null,
            val gameBunchAction: NetworkError? = null,
        ): GameServiceError()
    }

    suspend fun sendAction(action: String, username: String, contentId: Int? = null, inGameActionId: Int? = null, gameBunch: String? = null): Outcome<Action, GameServiceError>
}

internal class DefaultGameService : GameService {
    private val gameApi: GameApi by diContainer.instance()
    private val dateService: DateService by diContainer.instance()

    override suspend fun sendAction(action: String, username: String, contentId: Int?, inGameActionId: Int?, gameBunch: String?): Outcome<Action, GameService.GameServiceError> {
        val timestamp = dateService.currentEpochMillis()
        return when (val outcome = gameApi.getHash(action, timestamp, username)) {
            is Failure ->
                failure(GameService.GameServiceError.UnavailableService(outcome.error))
            is Success -> {
                val hash = outcome.value
                var actionError: GameService.GameServiceError.ActionError? = null
                val actionOutcome = gameApi.postAction(action, timestamp, username, hash, contentId)
                    .onError {
                        actionError = aggregateError(actionError, this.error, ErrorType.DEFAULT)
                    }
                var contentActionOutcome: Outcome<ActionApiResponse, NetworkError>? = null
                var gameBunchActionOutcome: Outcome<ActionApiResponse, NetworkError>? = null
                if (contentId != null) {
                    if (inGameActionId != null) {
                        val contentAction = "$action-$inGameActionId"
                        when (val inGameOutcome = gameApi.getHash(contentAction, timestamp, username)) {
                            is Failure -> {
                                failure(GameService.GameServiceError.UnavailableService(inGameOutcome.error))
                            }
                            is Success -> {
                                val inGameHash = inGameOutcome.value
                                contentActionOutcome = gameApi.postAction(contentAction, timestamp, username, inGameHash, contentId)
                                    .onError {
                                        actionError = aggregateError(actionError, this.error, ErrorType.IN_GAME)
                                    }
                            }
                        }
                    }
                    if (gameBunch != null) {
                        val gameBunchAction = "$action-$gameBunch-$contentId"
                        when (val gameBunchOutcome = gameApi.getHash(gameBunchAction, timestamp, username)) {
                            is Failure -> {
                                failure(GameService.GameServiceError.UnavailableService(gameBunchOutcome.error))
                            }
                            is Success -> {
                                val gameBunchHash = gameBunchOutcome.value
                                gameBunchActionOutcome = gameApi.postAction(gameBunchAction, timestamp, username, gameBunchHash, contentId)
                                    .onError {
                                        actionError = aggregateError(actionError, this.error, ErrorType.GAME_BUNCH)
                                    }
                            }
                        }
                    }
                }
                if (actionError != null) {
                    failure(actionError!!)
                } else {
                    val actionResult = actionOutcome.getOrNull()?.toModel(username)
                    val contentActionResult = contentActionOutcome?.getOrNull()?.toModel(username)
                    val gameBunchActionResult = gameBunchActionOutcome?.getOrNull()?.toModel(username)
                    val achievements = aggregateAchievements(actionResult, contentActionResult, gameBunchActionResult)
                    val rewards = aggregateRewards(actionResult, contentActionResult, gameBunchActionResult)
                    success(Action(achievements, rewards))
                }
            }
        }
    }

    enum class ErrorType {
        DEFAULT, IN_GAME, GAME_BUNCH
    }

    private fun aggregateError(
        action: GameService.GameServiceError.ActionError?,
        error: NetworkError,
        errorType: ErrorType
    ): GameService.GameServiceError.ActionError {
        return if (action == null) {
            when (errorType) {
                ErrorType.DEFAULT -> GameService.GameServiceError.ActionError(defaultAction = error)
                ErrorType.IN_GAME -> GameService.GameServiceError.ActionError(inGameAction = error)
                ErrorType.GAME_BUNCH -> GameService.GameServiceError.ActionError(gameBunchAction = error)
            }
        } else {
            when (errorType) {
                ErrorType.DEFAULT -> action.copy(defaultAction = error)
                ErrorType.IN_GAME -> action.copy(inGameAction = error)
                ErrorType.GAME_BUNCH -> action.copy(gameBunchAction = error)
            }
        }
    }

    private fun aggregateRewards(vararg actionResults: Action?): List<GameModel.Model.Reward> {
        val rewards = mutableListOf<GameModel.Model.Reward>()
        for (action in actionResults) {
            action?.rewards?.let {
                rewards.addAll(it)
            }
        }
        return rewards
    }

    private fun aggregateAchievements(vararg actionResults: Action?): List<GameModel.Model.Achievement> {
        val achievements = mutableListOf<GameModel.Model.Achievement>()
        for (action in actionResults) {
            action?.achievements?.let {
                achievements.addAll(it)
            }
        }
        return achievements
    }

}
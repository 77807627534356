package es.cinfo.tiivii.core.modules.pusher

import es.cinfo.tiivii.core.modules.pusher.model.SubscriptionEvent
import kotlinx.coroutines.flow.Flow

actual class PusherService {
    actual fun subscribe(
        channelName: String,
        eventName: String
    ): Flow<SubscriptionEvent> {
        TODO("Not yet implemented")
    }

    actual fun unsubscribe(channelName: String, eventName: String) {
    }

}
package es.cinfo.tiivii.core.modules.auth.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal data class LoginApiRequest(
    @SerialName(CLIENT_ID_PARAM)
    val clientId: String,
    @SerialName(USERNAME_PARAM)
    val username: String,
    @SerialName(PASSWORD_PARAM)
    val password: String
) {
    companion object {
        const val CLIENT_ID_PARAM = "client_id"
        const val USERNAME_PARAM = "username"
        const val PASSWORD_PARAM = "password"
    }
}

@Serializable
internal data class LogoutApiRequest(
    @SerialName(CLIENT_ID_PARAM)
    val clientId: String,
    @SerialName(REFRESH_TOKEN_PARAM)
    val refreshToken: String,
) {
    companion object {
        const val CLIENT_ID_PARAM = "client_id"
        const val REFRESH_TOKEN_PARAM = "refresh_token"
    }
}
package es.cinfo.tiivii.user.signup.binder

import es.cinfo.tiivii.core.util.mapToSet
import es.cinfo.tiivii.user.signup.store.SignupStore.*
import es.cinfo.tiivii.user.signup.view.SignupView.*

internal val STATE_TO_MODEL: State.() -> Model = {
    Model(
        availableRoles.mapToSet { it.toViewModel() },
        availableLanguages,
        availableAvatars.mapToSet { it.toViewModel() },
        availableInterests.mapToSet { it.toViewModel() },
        randomUsername,
        role?.toViewModel(),
        username,
        avatar?.toViewModel(),
        language,
        credentials,
        firstName,
        lastName,
        email,
        birthday,
        interests.mapToSet { it.toViewModel() },
        legalConditions,
        isDataCollectionAllowed,
        errors?.mapToSet (LABEL_TO_OUTPUT)
    )
}

internal val EVENT_TO_INTENT: Event.() -> Intent = {
    when (this) {
        is Event.SetUsername -> Intent.SetUsername(username)
        is Event.SetAvatar -> Intent.SetAvatar(avatar.toModel())
        is Event.SetFirstName -> Intent.SetFirstName(firstName)
        is Event.SetLastName -> Intent.SetLastName(lastName)
        is Event.AcceptLegalConditions -> Intent.AcceptLegalConditions(isDataCollectionAllowed)
        Event.Signup -> Intent.SignUp
        Event.RollUsername -> Intent.RollUsername
        is Event.SetEmail -> Intent.SetEmail(email)
        is Event.SetCredentials -> Intent.SetCredentials(credentials)
        is Event.SetBirthday -> Intent.SetBirthday(birthday)
        Event.GetAvatars -> Intent.GetAvatars
        Event.GetInterests -> Intent.GetInterests
        is Event.AddInterest -> Intent.AddInterest(interest.toModel())
        is Event.RemoveInterest -> Intent.RemoveInterest(interest.toModel())
        Event.GetLanguages -> Intent.GetLanguages
        Event.GetLegalConditions -> Intent.GetLegalConditions
        is Event.SetPreferredLanguage -> Intent.SetPreferredLanguage(language)
        Event.LogSignupView -> Intent.LogSignupView
    }
}

internal val LABEL_TO_OUTPUT: Label.() -> Output = {
    when (this) {
        is Label.UnexpectedError -> Output.UnexpectedError(code)
        Label.InvalidEmail -> Output.InvalidEmail
        Label.InvalidCredentials -> Output.InvalidCredentials
        Label.InvalidBirthday -> Output.InvalidBirthday
        Label.InvalidUsername -> Output.InvalidUsername
        Label.UserCreated -> Output.UserCreated
        Label.InvalidFirstName -> Output.InvalidFirstName
        Label.InvalidLastName -> Output.InvalidLastName
        Label.RequestTimedOut -> Output.RequestTimedOut
        Label.UserSessionExpired -> Output.UserSessionExpired
        is Label.IllegalOperationError -> Output.IllegalOperationError(reason)
        Label.ValidEmail -> Output.ValidEmail
        Label.ValidUsername -> Output.ValidUsername
    }
}

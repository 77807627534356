package es.cinfo.tiivii.core.layout.model.section

import es.cinfo.tiivii.core.layout.model.section.ViewModel.Section.Type
import es.cinfo.tiivii.core.layout.model.section.ViewModel.Section.Type.*
import es.cinfo.tiivii.core.modules.platform.PlatformModel.Model.Platform
import es.cinfo.tiivii.core.modules.status.StatusModel
import es.cinfo.tiivii.core.translation.TranslationModel
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

sealed class ViewModel {
    /**
     * Represents a section available in the menu navigation view
     * @param iconId id of the associated section icon
     * @param name string representation of the name to be shown if needed for the section
     * @param screenId id of the screen associated with the section if any
     * @param type [Type] of the section
     */
    data class Section(
        val id: String,
        val iconId: String,
        val name: String,
        val screenId: Int?,
        val type: Type
    ) {
        /**
         * Identifies the type of any section
         * @see CATALOGUE
         * @see SEARCH
         * @see PROFILE
         * @see DYNAMIC
         * @see UNKNOWN
         */
        enum class Type {
            /**
             * The section represents the link with the catalogue screen
             */
            CATALOGUE,
            /**
             * The section represents the link with the search screen
             */
            SEARCH,
            /**
             * The section is dynamic and should have an associated screen id and link to
             * a home screen identified with that screen id
             */
            DYNAMIC,
            /**
             * The section represents the link with the user screen
             */
            PROFILE,
            /**
             * The section represents the link with the user stats & ranking screen
             */
            RANKING,
            /**
             * The section is unrecognised by the app
             */
            UNKNOWN;

            internal fun toModel(): Model.Section.ContentPage {
                return when (this) {
                    CATALOGUE -> Model.Section.ContentPage.CATALOGUE
                    SEARCH -> Model.Section.ContentPage.SEARCH
                    DYNAMIC -> Model.Section.ContentPage.DYNAMIC
                    PROFILE -> Model.Section.ContentPage.PROFILE
                    RANKING -> Model.Section.ContentPage.RANKING
                    UNKNOWN -> Model.Section.ContentPage.UNKNOWN
                }
            }
        }

        internal fun toModel(): Model.Section {
            return Model.Section(
                iconId = iconId,
                id = id,
                name = name,
                screenId = screenId,
                contentPage = type.toModel(),
                platforms = emptyList(),
                status = StatusModel.Model.Status.PUBLISHED
            )
        }
    }
}

internal sealed class Model {
    data class Section(
        val id: String,
        val iconId: String,
        val name: String,
        val screenId: Int? = null,
        val contentPage: ContentPage,
        val platforms: List<Platform>,
        val status: StatusModel.Model.Status
    ) {

        enum class ContentPage {
            CATALOGUE, SEARCH, DYNAMIC, PROFILE, RANKING, UNKNOWN;

            companion object {
                fun parse(name: String, screenId: Int?): ContentPage {
                    return if (screenId != null) {
                        DYNAMIC
                    } else {
                        when (name.lowercase()) {
                            "profile" -> PROFILE
                            "search" -> SEARCH
                            "discover" -> CATALOGUE
                            "ranking" -> RANKING
                            else -> UNKNOWN
                        }
                    }
                }
            }

            fun toViewModel(): Type {
                return when (this) {
                    CATALOGUE -> Type.CATALOGUE
                    SEARCH -> Type.SEARCH
                    DYNAMIC -> Type.DYNAMIC
                    PROFILE -> Type.PROFILE
                    RANKING -> Type.RANKING
                    UNKNOWN -> Type.UNKNOWN
                }
            }
        }

        fun toViewModel(): ViewModel.Section {
            return ViewModel.Section(
                id = id,
                iconId = iconId,
                name = name,
                screenId = screenId,
                type = contentPage.toViewModel()
            )
        }
    }
}

internal sealed class ApiResponse {
    @Serializable
    data class Section(
        @SerialName(MENU_PARAM)
        val menu: Menu?
    ) {
        companion object {
            const val MENU_PARAM = "interfacemenus_id"
        }

        @Serializable
        data class Menu(
            @SerialName(NAME_PARAM)
            val name: String,
            @SerialName(ICON_ID_PARAM)
            val iconId: String,
            @SerialName(SCREEN_ID_PARAM)
            val screenId: Screen?,
            @SerialName(DEVICE_PARAM)
            val device: String,
            @SerialName(STATUS_PARAM)
            val status: String,
            @SerialName(TRANSLATIONS_PARAM)
            @Serializable(with = TranslationModel.ApiResponse.Translations.TranslationsDeserializer::class)
            val translations: TranslationModel.ApiResponse.Translations?
        ) {
            companion object {
                const val NAME_PARAM = "name"
                const val ICON_ID_PARAM = "icon"
                const val SCREEN_ID_PARAM = "screen"
                const val DEVICE_PARAM = "device"
                const val STATUS_PARAM = "status"
                const val TRANSLATIONS_PARAM = "translations"
            }

            @Serializable
            data class Screen(
                @SerialName(ID_PARAM)
                val id: Int
            ) {
                companion object {
                    const val ID_PARAM = "id"
                }
            }
        }

        fun toModel(language: String): Model.Section {
            require(menu != null)
            val name = menu.translations?.find(Menu.NAME_PARAM, language) ?: menu.name
            val devices: List<Platform> = when (menu.device) {
                "android" -> listOf(Platform.ANDROID)
                "android-tv" -> listOf(Platform.ANDROID_TV)
                "web" -> listOf(Platform.WEB)
                "ios" -> listOf(Platform.IOS)
                "apps" -> listOf(Platform.ANDROID, Platform.ANDROID_TV, Platform.IOS)
                "all" -> listOf(Platform.ANDROID, Platform.ANDROID_TV, Platform.WEB, Platform.IOS)
                else -> listOf(Platform.ANDROID, Platform.ANDROID_TV, Platform.WEB, Platform.IOS)
            }
            return Model.Section(
                iconId = menu.iconId,
                id = menu.name,
                name = name,
                screenId = menu.screenId?.id,
                contentPage = Model.Section.ContentPage.parse(menu.name, menu.screenId?.id),
                platforms = devices,
                status = StatusModel.Model.Status.parse(menu.status)
            )
        }
    }
}
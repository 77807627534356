package es.cinfo.tiivii.core.features.detail.binder

import es.cinfo.tiivii.core.features.detail.store.DetailStore.*
import es.cinfo.tiivii.core.features.detail.view.DetailView.*

internal val STATE_TO_MODEL: State.() -> Model = {
    Model(
        content = content?.toViewModel(),
        purchaseInfo = purchaseInfo?.toViewModel(),
        nextContent = nextContent?.toViewModel(),
        canReport = canReport,
        playable = playable?.toViewModel(),
        playbackSupport = playbackSupport?.toViewModel(),
        playableChild = playableChild?.toViewModel(),
        canFav = canFav,
        isFavorite = isFavorite,
        canRate = canRate,
        userRating = userRating,
        availableReportReasons = availableReportReasons.map { it.toViewModel() },
        editUrl = editUrl,
        shareUrl = shareUrl,
        shareQrBase64 = shareQrBase64,
        isContentLoading = isContentLoading.toViewModel(),
        productsUrl = productsUrl,
        serialContentsTitle = serialContentsTitle?.toViewModel(),

        serialContent = serialContent.map { it.toViewModel() },
        isSerialContentLoading = isSerialContentLoading.toViewModel(),

        relatedContent = relatedContent.map { it.toViewModel() },
        isRelatedContentLoading = isRelatedContentLoading.toViewModel(),

        comments = comments?.toViewModel(),
        areCommentsLoading = areCommentsLoading.toViewModel(),
        areMoreCommentsAvailable = areMoreCommentsAvailable
    )
}

internal val EVENT_TO_INTENT: Event.() -> Intent = {
    when (this) {
        is Event.LoadContent -> Intent.LoadContent(id, payload)
        Event.AddToFavorites -> Intent.AddToFavorites
        Event.RemoveFromFavorites -> Intent.RemoveFromFavorites
        is Event.Rate -> Intent.Rate(rating)
        is Event.AddComment -> Intent.AddComment(comment)
        is Event.RemoveComment -> Intent.RemoveComment(id)
        is Event.UpdateComment -> Intent.UpdateComment(id, text)
        Event.LoadMoreComments -> Intent.LoadMoreComments
        Event.DeleteRating -> Intent.DeleteRating
        Event.RefreshComments -> Intent.RefreshComments
        is Event.ReportContent -> Intent.ReportContent(reason, userMessage)
        Event.LogDetailView -> Intent.LogDetailView
        is Event.LogSelectItem -> Intent.LogSelectItem(contentId)
    }
}

internal val LABEL_TO_OUTPUT: Label.() -> Output = {
    when (this) {
        is Label.UnexpectedError -> Output.UnexpectedError(code)
        Label.ContentReported -> Output.ContentReported
        is Label.GameActionSent -> Output.GameActionSent(actionResult.toViewModel())
        Label.UserSessionExpired -> Output.UserSessionExpired
        is Label.IllegalOperationError -> Output.IllegalOperationError(reason)
        Label.RequestTimedOut -> Output.RequestTimedOut
    }
}

package es.cinfo.tiivii.core.modules.video.model

import io.ktor.http.LinkHeader.Parameters.Type
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

internal sealed class VideoModel {
    sealed class Model {
        data class Video(
            val url: String,
            val type: Type
        )

        data class ProcessedVideo(
            val url: String,
            val vastUrl: String?,
            val type: Type
        )

        enum class Type {
            HLS, DASH
        }
    }

    sealed class ApiResponse {
        @Serializable
        data class VideoUrls(
//            @SerialName(DASH_PARAM)
//            val dash: List<String>,
            @SerialName(DASH_SSL_PARAM)
            val dashSsl: List<String>,
//            @SerialName(HLS_PARAM)
//            val hls: List<String>,
            @SerialName(HLS_SSL_PARAM)
            val hlsSsl: List<String>,
        ) {
            companion object {
//                const val DASH_PARAM = "dash"
                const val DASH_SSL_PARAM = "ssl_dash"
//                const val HLS_PARAM = "hls"
                const val HLS_SSL_PARAM = "ssl_hls"
            }

            fun toModel(type: Model.Type? = null): List<Model.Video> {
                val videos = mutableListOf<Model.Video>()
                when (type) {
                    Model.Type.HLS -> {
                        hlsSsl.forEach {
                            videos.add(Model.Video(it, Model.Type.HLS))
                        }
                    }
                    Model.Type.DASH -> {
                        dashSsl.forEach {
                            videos.add(Model.Video(it, Model.Type.DASH))
                        }
                    }
                    null -> {
                        dashSsl.forEach {
                            videos.add(Model.Video(it, Model.Type.DASH))
                        }
                        hlsSsl.forEach {
                            videos.add(Model.Video(it, Model.Type.HLS))
                        }
                    }
                }

                return videos
            }
        }
    }
}
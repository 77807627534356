package es.cinfo.tiivii.core.layout.model.layoutconfig

import es.cinfo.tiivii.core.image.ImageModule
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.status.StatusModel
import es.cinfo.tiivii.core.profile.ProfileModel.Model.Profile
import es.cinfo.tiivii.core.translation.TranslationModel
import es.cinfo.tiivii.di.diContainer
import es.cinfo.tiivii.footer.model.Footer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonTransformingSerializer
import org.kodein.di.instance
import es.cinfo.tiivii.core.image.ApiResponse.Image as ImageApiResponse
import es.cinfo.tiivii.core.image.Model.Image as ImageModel
import es.cinfo.tiivii.core.image.Model.Image.Type as ImageType
import es.cinfo.tiivii.core.image.ViewModel.Image as ImageViewModel
import es.cinfo.tiivii.core.layout.model.section.ApiResponse.Section as SectionApiResponse
import es.cinfo.tiivii.core.layout.model.section.Model.Section as SectionModel

sealed class ViewModel {
    /**
     * Object containing styling parameters
     * @param logo Reference to the logo [ImageViewModel] of the application
     * @param loginBgImage Url reference to the image used for styling login screen background
     * @param titleFont Identifier of the font used for default title styling
     * @param titleColor Hex color (with alpha) code for default title styling
     * @param textFont Identifier of the font used for default text styling
     * @param textColor Hex color (with alpha) code for default text styling
     * @param appColor Hex color (with alpha) code of the primary app color used for styling different components
     * @param hoverColor Hex color (with alpha) code used for styling hover actions over different components
     * @param focusColor Hex color (with alpha) code used for styling focus actions over different components
     * @param backgroundColor Hex color (with alpha) code used for styling the default background color of the app
     * @param backgroundImage Url reference to the image used for styling the default background of the app
     * (takes preference over [backgroundColor])
     * @param tagBackgroundColor Hex color (with alpha) code for content tags background styling
     * @param tagTextColor Hex color (with alpha) code for content tags text styling
     * @param filterTitleColor Hex color (with alpha) code for filter title styling
     * @param liveSectionIconUrl Url reference to the icon image used for styling live section button
     * @param defaultButtonColor Hex color (with alpha) code for default buttons background styling
     * @param defaultButtonBorderColor Hex color (with alpha) code for default buttons border styling
     * @param defaultButtonTextColor Hex color (with alpha) code for default buttons text styling
     * @param hoverButtonColor Hex color (with alpha) code for button background styling when hovering
     * @param hoverButtonBorderColor Hex color (with alpha) code for button border styling when hovering
     * @param hoverButtonTextColor Hex color (with alpha) code for button text styling when hovering
     * @param focusedButtonColor Hex color (with alpha) code for button background styling when focused
     * @param focusedButtonBorderColor Hex color (with alpha) code for button border styling when focused
     * @param focusedButtonTextColor Hex color (with alpha) code for button text styling when focused
     * @param rowTitleFont Identifier of the font used for widget title styling
     * @param rowTitleColor Hex color (with alpha) code for widget title styling
     * @param rowTextFont Identifier of the font used for widget text styling
     * @param rowTextColor Hex color (with alpha) code for widget text styling
     * @param featuredWidgetTitleColor Hex color (with alpha) code for featured widget title styling
     * @param featuredWidgetTextColor Hex color (with alpha) code for featured widget text styling
     * @param featuredWidgetBgColor Hex color (with alpha) code for featured widget background styling
     * @param bannerWidgetTitleColor Hex color (with alpha) code for banner widget title styling
     * @param bannerWidgetTextColor Hex color (with alpha) code for banner widget text styling
     * @param bannerWidgetBgColor Hex color (with alpha) code for banner widget background styling
     * @param bannerWidgetImageColor Url reference to the image used for styling the banner widget background
     * (takes preference over [bannerWidgetBgColor])
     * @param bannerWidgetBorderRadius Pixel value for border corner rounding on banner widgets
     * @param catalogueHeadBackgroundColor Hex color (with alpha) code for catalogue header background styling
     * @param footerBackgroundColor Hex color (with alpha) code for footer background styling
     * @param detailPageBgColor Hex color (with alpha) code for content detail background styling
     * @param detailPageBgImage Url reference to the image used for styling content detail background
     * (takes preference over [detailPageBgColor])
     * @param rankingCellColor Hex color (with alpha) code for ranking/achievement cells background color in web section
     * @param rankingUserBannerColor Hex color (with alpha) code for user banner background in web section
     * (takes preference over [detailPageBgColor])
     */
    data class StyleConfig(
        // App
        val logo: ImageViewModel,
        val loginBgImage: String?,
        val titleFont: String?,
        val titleColor: String?,
        val textFont: String?,
        val textColor: String?,
        val appColor: String?,
        val hoverColor: String?,
        val focusColor: String?,
        val backgroundColor: String?,
        val backgroundImage: String?,
        val tagBackgroundColor: String?,
        val tagTextColor: String?,
        val filterTitleColor: String?,
        val liveSectionIconUrl: String?,
        // Buttons
        val defaultButtonColor: String?,
        val defaultButtonBorderColor: String?,
        val defaultButtonTextColor: String,
        val hoverButtonColor: String?,
        val hoverButtonBorderColor: String?,
        val hoverButtonTextColor: String?,
        val focusedButtonColor: String?,
        val focusedButtonBorderColor: String?,
        val focusedButtonTextColor: String?,
        // Home
        val rowTitleFont: String?,
        val rowTitleColor: String?,
        val rowTextFont: String?,
        val rowTextColor: String?,
        val featuredWidgetTitleColor: String?,
        val featuredWidgetTextColor: String?,
        val featuredWidgetBgColor: String?,
        val bannerWidgetTitleColor: String?,
        val bannerWidgetTextColor: String?,
        val bannerWidgetBgColor: String?,
        val bannerWidgetImageColor: String?,
        val bannerWidgetBorderRadius: String,
        // Catalogue
        val catalogueHeadBackgroundColor: String?,
        // Footer
        val footerBackgroundColor: String?,
        // Detail
        val detailPageBgColor: String?,
        val detailPageBgImage: String?,
        // Ranking
        val rankingCellColor: String?,
        val rankingUserBannerColor: String?,
    )
}

internal sealed class Model {
    data class LayoutConfig(
        val apiBase: String,
        val legal: Legal,
        val isGuestModeAvailable: Boolean,
        val sections: List<SectionModel>,
        val sponsors: List<Footer.Model.Sponsor>,
        val profile: Profile,
        val privacyStatementUrl: TranslationModel.Model.LocalizedString?,
        val termsAndConditionsUrl: TranslationModel.Model.LocalizedString?,
        val styleConfig: StyleConfig,
        val subContentsLabel: TranslationModel.Model.LocalizedString?
    ) {
        data class Legal(
            val version: Int,
            val text: TranslationModel.Model.LocalizedString
        )

        data class StyleConfig(
            val logo: ImageModel,
            val titleFont: String?,
            val titleColor: String?,
            val rowTitleFont: String?,
            val rowTitleColor: String?,
            val textFont: String?,
            val textColor: String?,
            val appColor: String?,
            val hoverColor: String?,
            val focusColor: String?,
            val backgroundColor: String?,
            val backgroundImage: String? = null,
            val rowTextFont: String?,
            val rowTextColor: String?,
            val catalogueHeadBackgroundColor: String?,
            val footerBackgroundColor: String?,
            val tagBackgroundColor: String?,
            val tagTextColor: String?,
            val filterTitleColor: String?,
            val defaultButtonStyle: ButtonStyle,
            val hoverButtonStyle: ButtonStyle,
            val focusedButtonStyle: ButtonStyle,
            val featuredWidgetStyle: WidgetStyle,
            val bannerWidgetStyle: WidgetStyle,
            val detailPageBgColor: String?,
            val detailPageBgImage: ImageModel?,
            val rankingCellColor: String?,
            val rankingUserBannerColor: String?,
            val loginBgImage: ImageModel?,
            val liveSectionIconUrl: ImageModel?
        ) {

            data class ButtonStyle(
                val color: String?,
                val borderColor: String?,
                val textColor: String,
            )

            data class WidgetStyle(
                val titleColor: String?,
                val textColor: String?,
                val backgroundColor: String?,
                val imageColor: String?,
                val borderRadius: String
            )

            fun toViewModel(): ViewModel.StyleConfig {
                return ViewModel.StyleConfig(
                    titleFont = titleFont,
                    titleColor = titleColor,
                    rowTitleFont = rowTitleFont,
                    rowTitleColor = rowTitleColor,
                    textFont = textFont,
                    textColor = textColor,
                    appColor = appColor,
                    hoverColor = hoverColor,
                    focusColor = focusColor,
                    backgroundColor = backgroundColor,
                    backgroundImage = backgroundImage,
                    rowTextFont = rowTextFont,
                    rowTextColor = rowTextColor,
                    catalogueHeadBackgroundColor = catalogueHeadBackgroundColor,
                    footerBackgroundColor = footerBackgroundColor,
                    tagBackgroundColor = tagBackgroundColor,
                    tagTextColor = tagTextColor,
                    filterTitleColor = filterTitleColor,
                    defaultButtonColor = defaultButtonStyle.color,
                    defaultButtonBorderColor = defaultButtonStyle.borderColor,
                    defaultButtonTextColor = defaultButtonStyle.textColor,
                    hoverButtonColor = hoverButtonStyle.color,
                    hoverButtonBorderColor = hoverButtonStyle.borderColor,
                    hoverButtonTextColor = hoverButtonStyle.textColor,
                    focusedButtonColor = focusedButtonStyle.color,
                    focusedButtonBorderColor = focusedButtonStyle.borderColor,
                    focusedButtonTextColor = focusedButtonStyle.textColor,
                    featuredWidgetBgColor = featuredWidgetStyle.backgroundColor,
                    featuredWidgetTextColor = featuredWidgetStyle.textColor,
                    featuredWidgetTitleColor = featuredWidgetStyle.titleColor,
                    bannerWidgetBgColor = bannerWidgetStyle.backgroundColor,
                    bannerWidgetImageColor = bannerWidgetStyle.imageColor,
                    bannerWidgetTextColor = bannerWidgetStyle.textColor,
                    bannerWidgetTitleColor = bannerWidgetStyle.titleColor,
                    bannerWidgetBorderRadius = bannerWidgetStyle.borderRadius,
                    detailPageBgColor = detailPageBgColor,
                    detailPageBgImage = detailPageBgImage?.url,
                    loginBgImage = loginBgImage?.url,
                    logo = logo.toViewModel(),
                    liveSectionIconUrl = liveSectionIconUrl?.url,
                    rankingCellColor = rankingCellColor,
                    rankingUserBannerColor = rankingUserBannerColor
                )
            }
        }
    }
}

internal sealed class ApiResponse {
    @Serializable
    internal data class LayoutConfig(
        @SerialName(ID_PARAM)
        val id: String,
        @SerialName(API_BASE_PARAM)
        val apiBase: String,
        @SerialName(LOGO_PARAM)
        val logo: ImageApiResponse,
        @SerialName(LEGAL_PARAM)
        val legal: Legal,
        @SerialName(GUEST_MODE_PARAM)
        val isGuestModeAvailable: Boolean,
        @SerialName(SECTIONS_PARAM)
        val sections: List<SectionApiResponse>,
        @SerialName(SPONSORS_PARAM)
        @Serializable(with = Sponsor.SponsorDeserializer::class)
        val sponsors: List<Sponsor>,
        @SerialName(PRIVACY_URL_PARAM)
        val privacyStatementUrl: String?,
        @SerialName(TERMS_CONDITIONS_URL_PARAM)
        val termsAndConditionsUrl: String?,
        @SerialName(APP_COLOR_PARAM)
        val appColor: String,
        @SerialName(BACKGROUND_COLOR_PARAM)
        val backgroundColor: String?,
        @SerialName(HOVER_COLOR_PARAM)
        val hoverColor: String?,
        @SerialName(FOCUS_COLOR_PARAM)
        val focusColor: String?,
        @SerialName(BACKGROUND_IMAGE_PARAM)
        val backgroundImage: ImageApiResponse? = null,
        @SerialName(FONT_PARAM)
        val font: String?,
        @SerialName(TEXT_COLOR_PARAM)
        val textColor: String?,
        @SerialName(TITLE_FONT_PARAM)
        val titleFont: String?,
        @SerialName(TITLE_COLOR_PARAM)
        val titleColor: String?,
        @SerialName(ROW_TITLE_FONT_PARAM)
        val rowTitleFont: String?,
        @SerialName(ROW_TITLE_COLOR_PARAM)
        val rowTitleColor: String?,
        @SerialName(ROW_FONT_PARAM)
        val rowTextFont: String?,
        @SerialName(ROW_COLOR_PARAM)
        val rowTextColor: String?,
        @SerialName(CATALOGUE_HEAD_BG_COLOR_PARAM)
        val catalogueHeadBackgroundColor: String?,
        @SerialName(FOOTER_BG_COLOR_PARAM)
        val footerBackgroundColor: String?,
        @SerialName(TAG_BG_COLOR_PARAM)
        val tagBackgroundColor: String?,
        @SerialName(TAG_TEXT_COLOR_PARAM)
        val tagTextColor: String?,
        @SerialName(FILTER_TITLE_COLOR_PARAM)
        val filterTitleColor: String?,
        @SerialName(DEFAULT_BUTTON_COLOR_PARAM)
        val defaultButtonColor: String?,
        @SerialName(DEFAULT_BUTTON_BORDER_COLOR_PARAM)
        val defaultButtonBorderColor: String?,
        @SerialName(DEFAULT_BUTTON_TEXT_COLOR_PARAM)
        val defaultButtonTextColor: String?,
        @SerialName(FOCUSED_BUTTON_COLOR_PARAM)
        val focusedButtonColor: String?,
        @SerialName(FOCUSED_BUTTON_BORDER_COLOR_PARAM)
        val focusedButtonBorderColor: String?,
        @SerialName(FOCUSED_BUTTON_TEXT_COLOR_PARAM)
        val focusedButtonTextColor: String?,
        @SerialName(HOVER_BUTTON_COLOR_PARAM)
        val hoverButtonColor: String?,
        @SerialName(HOVER_BUTTON_BORDER_COLOR_PARAM)
        val hoverButtonBorderColor: String?,
        @SerialName(HOVER_BUTTON_TEXT_COLOR_PARAM)
        val hoverButtonTextColor: String?,
        @SerialName(WIDGET_DESTACADO_TITLE_COLOR_PARAM)
        val widgetFeaturedTitleColor: String?,
        @SerialName(WIDGET_DESTACADO_TEXT_COLOR_PARAM)
        val widgetFeaturedTextColor: String?,
        @SerialName(WIDGET_DESTACADO_BG_COLOR_PARAM)
        val widgetFeaturedBgColor: String?,
        @SerialName(WIDGET_BANNER_TITLE_COLOR_PARAM)
        val widgetBannerTitleColor: String?,
        @SerialName(WIDGET_BANNER_TEXT_COLOR_PARAM)
        val widgetBannerTextColor: String?,
        @SerialName(WIDGET_BANNER_BG_COLOR_PARAM)
        val widgetBannerBgColor: String?,
        @SerialName(WIDGET_BANNER_IMAGE_COLOR_PARAM)
        val widgetBannerImageColor: String?,
        @SerialName(WIDGET_BANNER_BORDER_RADIUS_PARAM)
        val widgetBannerBorderRadius: Int,
        @SerialName(RANKING_CELL_COLOR)
        val rankingCellColor: String? = null,
        @SerialName(RANKING_USER_BANNER_COLOR)
        val rankingUserBannerColor: String? = null,
        @SerialName(PROFILE_PARAM)
        val profile: String,
        @SerialName(DETAIL_PAGE_BG_COLOR_PARAM)
        val detailPageBgColor: String?,
        @SerialName(DETAIL_PAGE_BG_IMAGE_PARAM)
        val detailPageBgImage: ImageApiResponse? = null,
        @SerialName(LOGIN_BG_IMAGE_PARAM)
        val loginBgImage: ImageApiResponse? = null,
        @SerialName(LIVE_SECTION_ICON_URL_PARAM)
        val liveSectionIconUrl: ImageApiResponse? = null,
        @SerialName(SUB_CONTENTS_LABEL_PARAM)
        val subContentsLabel: String? = null,
        @SerialName(TRANSLATIONS_PARAM)
        @Serializable(with = TranslationModel.ApiResponse.Translations.TranslationsDeserializer::class)
        val translations: TranslationModel.ApiResponse.Translations? = null
    ) {
        companion object {
            const val ID_PARAM = "id"
            const val API_BASE_PARAM = "apibase"
            const val LOGO_PARAM = "logo"
            const val LEGAL_PARAM = "legal"
            const val GUEST_MODE_PARAM = "guestmodeavailable"
            const val SECTIONS_PARAM = "menu"
            const val SPONSORS_PARAM = "sponsors"
            const val PRIVACY_URL_PARAM = "privacy_url"
            const val TERMS_CONDITIONS_URL_PARAM = "terms_conditions_url"

            const val FONT_PARAM = "font"
            const val BACKGROUND_COLOR_PARAM = "backgroundcolor"
            const val FOCUS_COLOR_PARAM = "focuscolor"
            const val HOVER_COLOR_PARAM = "hovercolor"
            const val TEXT_COLOR_PARAM = "textcolor"
            const val TITLE_FONT_PARAM = "titlefont"
            const val TITLE_COLOR_PARAM = "titlecolor"
            const val APP_COLOR_PARAM = "appcolor"
            const val BACKGROUND_IMAGE_PARAM = "backgroundimage"
            const val ROW_TITLE_FONT_PARAM = "rowtitlefont"
            const val ROW_TITLE_COLOR_PARAM = "rowtitlecolor"
            const val ROW_FONT_PARAM = "rowtextfont"
            const val ROW_COLOR_PARAM = "rowtextcolor"
            const val CATALOGUE_HEAD_BG_COLOR_PARAM = "catalogueheadbackgroundcolor"
            const val FOOTER_BG_COLOR_PARAM = "footerbackgroundcolor"
            const val TAG_BG_COLOR_PARAM = "tagbackgroundcolor"
            const val TAG_TEXT_COLOR_PARAM = "tagtextcolor"
            const val FILTER_TITLE_COLOR_PARAM = "filtertitlecolor"

            const val DEFAULT_BUTTON_COLOR_PARAM = "buttondefaultbackgroundcolor"
            const val DEFAULT_BUTTON_BORDER_COLOR_PARAM = "buttondefaultbordercolor"
            const val DEFAULT_BUTTON_TEXT_COLOR_PARAM = "buttondefaulttextcolor"

            const val FOCUSED_BUTTON_COLOR_PARAM = "buttonfocusedbackgroundcolor"
            const val FOCUSED_BUTTON_BORDER_COLOR_PARAM = "buttonfocusedbordercolor"
            const val FOCUSED_BUTTON_TEXT_COLOR_PARAM = "buttonfocusedtextcolor"

            const val HOVER_BUTTON_COLOR_PARAM = "buttonhoverbackgroundcolor"
            const val HOVER_BUTTON_BORDER_COLOR_PARAM = "buttonhoverbordercolor"
            const val HOVER_BUTTON_TEXT_COLOR_PARAM = "buttonhovertextcolor"

            const val WIDGET_DESTACADO_TITLE_COLOR_PARAM = "widgetdestacadotitlecolor"
            const val WIDGET_DESTACADO_TEXT_COLOR_PARAM = "widgetdestacadotextcolor"
            const val WIDGET_DESTACADO_BG_COLOR_PARAM = "widgetdestacadobgcolor"

            const val WIDGET_BANNER_TITLE_COLOR_PARAM = "widgetbannertitlecolor"
            const val WIDGET_BANNER_TEXT_COLOR_PARAM = "widgetbannertextcolor"
            const val WIDGET_BANNER_BG_COLOR_PARAM = "widgetbannerbgcolor"
            const val WIDGET_BANNER_IMAGE_COLOR_PARAM = "widgetbannerimagecolor"
            const val WIDGET_BANNER_BORDER_RADIUS_PARAM = "bannerborderradius"

            const val RANKING_CELL_COLOR = "rankingcellcolor"
            const val RANKING_USER_BANNER_COLOR = "rankinguserbannercolor"

            const val DETAIL_PAGE_BG_COLOR_PARAM = "detailpagebgcolor"
            const val DETAIL_PAGE_BG_IMAGE_PARAM = "detailpagebgimage"
            const val LOGIN_BG_IMAGE_PARAM = "loginbgimage"

            const val LIVE_SECTION_ICON_URL_PARAM = "livesectioniconurl"

            const val PROFILE_PARAM = "profile"
            const val TRANSLATIONS_PARAM = "translations"
            const val SUB_CONTENTS_LABEL_PARAM = "subcontents_label"
        }

        @Serializable
        data class Legal(
            @SerialName(VERSION_PARAM)
            val version: Int,
            @SerialName(TEXT_PARAM)
            val text: String,
            @SerialName(TRANSLATIONS_PARAM)
            @Serializable(with = TranslationModel.ApiResponse.Translations.TranslationsDeserializer::class)
            val translations: TranslationModel.ApiResponse.Translations? = null
        ) {

            companion object {
                const val VERSION_PARAM = "version"
                const val TEXT_PARAM = "text"
                const val TRANSLATIONS_PARAM = "translations"
            }

            fun toModel(): Model.LayoutConfig.Legal {
                val text = translations?.getAll(key = TEXT_PARAM, fallbackValue = text) ?: TranslationModel.Model.LocalizedString.empty(text)
                return Model.LayoutConfig.Legal(
                    version = version,
                    text = text
                )
            }
        }

        @Serializable
        data class Legals(
            @SerialName(DATA_PARAM)
            val data: List<Legal> = emptyList()
        ) {
            companion object {
                const val DATA_PARAM = "data"
            }
        }

        @Serializable
        data class Sponsor(
            @SerialName(SPONSORS_ID_PARAM)
            val sponsorsId: SponsorsId?
        ) {
            companion object {
                const val SPONSORS_ID_PARAM = "sponsors_id"
            }

            object SponsorDeserializer
                : JsonTransformingSerializer<List<Sponsor>>(
                ListSerializer(serializer())
            ) {
                // If response is not an array, then it is a single object that should be wrapped into the array
                override fun transformDeserialize(element: JsonElement): JsonElement =
                    if (element !is JsonArray) JsonArray(listOf(element)) else element
            }

            @Serializable
            data class SponsorsId(
                @SerialName(LOGO_PARAM)
                val logo: Logo,
                @SerialName(COMPANY_PARAM)
                val company: String,
                @SerialName(URL_PARAM)
                val url: String? = null
            ) {
                companion object {
                    const val COMPANY_PARAM = "company"
                    const val LOGO_PARAM = "logo"
                    const val URL_PARAM = "onclickurl"
                }

                @Serializable
                data class Logo(
                    @SerialName(ID_PARAM)
                    val id: Int,
                    @SerialName(PRIVATE_HASH_PARAM)
                    val privateHash: String
                ) {
                    companion object {
                        const val ID_PARAM = "id"
                        const val PRIVATE_HASH_PARAM = "private_hash"
                    }
                }

                fun toModel(): Footer.Model.Sponsor {
                    val imageModule: ImageModule by diContainer.instance()
                    val configModule: ConfigModule by diContainer.instance()
                    return Footer.Model.Sponsor(
                        "${imageModule.baseEndpoint}/${configModule.getEnvConfig().apiName}/assets/${this.logo.privateHash}",
                        this.url,
                        this.company
                    )
                }
            }
        }

        fun toModel(language: String): Model.LayoutConfig? {
            val parsedProfile = Profile.parseOrNull(profile)
            return if (parsedProfile == null) {
                null
            } else {
                //TODO: Remove mock when real version available
                val liveSectionIconUrl = when (parsedProfile) {
                    Profile.ADULT -> {
                        ImageModel(
                            1,
                            100,
                            100,
                            "https://via.placeholder.com/100/000000/FFFFFF?text=Live",
                            ImageType.OTHER
                        )
                    }
                    Profile.KID -> {
                        ImageModel(
                            1,
                            100,
                            100,
                            "https://via.placeholder.com/100/3b83bd/FFFFFF?text=Live",
                            ImageType.OTHER
                        )
                    }
                }
                val imageModule: ImageModule by diContainer.instance()
                return Model.LayoutConfig(
                    apiBase = apiBase,
                    legal = legal.toModel(),
                    isGuestModeAvailable = isGuestModeAvailable,
                    sponsors = sponsors.mapNotNull { it.sponsorsId?.toModel() },
                    privacyStatementUrl = privacyStatementUrl?.let {
                        translations?.getAll(key = PRIVACY_URL_PARAM, fallbackValue = it) ?: TranslationModel.Model.LocalizedString.empty(privacyStatementUrl)
                    },
                    termsAndConditionsUrl = termsAndConditionsUrl?.let {
                        translations?.getAll(key = TERMS_CONDITIONS_URL_PARAM, fallbackValue = it) ?: TranslationModel.Model.LocalizedString.empty(termsAndConditionsUrl)
                    },
                    sections = sections
                        .filter { it.menu != null }
                        .map { it.toModel(language) }
                        .filter { it.status == StatusModel.Model.Status.PUBLISHED},
                    profile = parsedProfile,
                    styleConfig = Model.LayoutConfig.StyleConfig(
                        titleFont = titleFont,
                        titleColor = titleColor.parseAlphaHex(),
                        rowTitleFont = rowTitleFont,
                        rowTitleColor = rowTitleColor.parseAlphaHex(),
                        textFont = font,
                        textColor = textColor.parseAlphaHex(),
                        appColor = appColor.parseAlphaHex(),
                        hoverColor = hoverColor.parseAlphaHexOrNull(),
                        focusColor = focusColor.parseAlphaHexOrNull(),
                        backgroundColor = backgroundColor.parseAlphaHexOrNull(),
                        backgroundImage = backgroundImage?.data?.fullUrl,
                        rowTextFont = rowTextFont,
                        rowTextColor = rowTextColor.parseAlphaHex(),
                        catalogueHeadBackgroundColor = catalogueHeadBackgroundColor.parseAlphaHexOrNull(),
                        footerBackgroundColor = footerBackgroundColor.parseAlphaHexOrNull(),
                        tagBackgroundColor = tagBackgroundColor.parseAlphaHexOrNull(),
                        tagTextColor = tagTextColor.parseAlphaHexOrNull(),
                        filterTitleColor = filterTitleColor.parseAlphaHex(),
                        defaultButtonStyle = Model.LayoutConfig.StyleConfig.ButtonStyle(
                            color = defaultButtonColor.parseAlphaHexOrNull(),
                            borderColor = defaultButtonBorderColor.parseAlphaHexOrNull(),
                            textColor = defaultButtonTextColor.parseAlphaHex()
                        ),
                        hoverButtonStyle = Model.LayoutConfig.StyleConfig.ButtonStyle(
                            color = hoverButtonColor.parseAlphaHexOrNull(),
                            borderColor = hoverButtonBorderColor.parseAlphaHexOrNull(),
                            textColor = hoverButtonTextColor.parseAlphaHex()
                        ),
                        focusedButtonStyle = Model.LayoutConfig.StyleConfig.ButtonStyle(
                            color = focusedButtonColor.parseAlphaHexOrNull(),
                            borderColor = focusedButtonBorderColor.parseAlphaHexOrNull(),
                            textColor = focusedButtonTextColor.parseAlphaHex()
                        ),
                        featuredWidgetStyle = Model.LayoutConfig.StyleConfig.WidgetStyle(
                            titleColor = widgetFeaturedTitleColor.parseAlphaHexOrNull(),
                            textColor = widgetFeaturedTextColor.parseAlphaHexOrNull(),
                            backgroundColor = widgetFeaturedBgColor.parseAlphaHexOrNull(),
                            imageColor = null,
                            borderRadius = 0.parseAsPixels()
                        ),
                        bannerWidgetStyle = Model.LayoutConfig.StyleConfig.WidgetStyle(
                            titleColor = widgetBannerTitleColor.parseAlphaHexOrNull(),
                            textColor = widgetBannerTextColor.parseAlphaHexOrNull(),
                            backgroundColor = widgetBannerBgColor.parseAlphaHexOrNull(),
                            imageColor = widgetBannerImageColor.parseAlphaHexOrNull(),
                            borderRadius = widgetBannerBorderRadius.parseAsPixels()
                        ),
                        detailPageBgColor = detailPageBgColor.parseAlphaHexOrNull(),
                        detailPageBgImage = detailPageBgImage?.let {
                            ImageModel(
                                it.id,
                                it.width,
                                it.height,
                                "${imageModule.baseEndpoint}${it.data.assetUrl}",
                                ImageType.OTHER
                            )
                        },
                        loginBgImage = loginBgImage?.let {
                            ImageModel(
                                it.id,
                                it.width,
                                it.height,
                                "${imageModule.baseEndpoint}${it.data.assetUrl}",
                                ImageType.OTHER
                            )
                        },
                        logo = ImageModel(
                            logo.id,
                            logo.width,
                            logo.height,
                            "${imageModule.baseEndpoint}${logo.data.assetUrl}",
                            ImageType.OTHER
                        ),
                        liveSectionIconUrl = liveSectionIconUrl,
//                    liveSectionIconUrl = liveSectionIconUrl?.let {
//                        ImageModel(
//                            it.id,
//                            it.width,
//                            it.height,
//                            "${imageService.remeEndpoint}${it.data.assetUrl}",
//                            ImageType.OTHER
//                        )
//                    }
                        rankingCellColor = rankingCellColor.parseAlphaHexOrNull(),
                        rankingUserBannerColor = rankingUserBannerColor.parseAlphaHexOrNull()
                    ),
                    subContentsLabel = subContentsLabel?.let {
                        translations?.getAll(key = SUB_CONTENTS_LABEL_PARAM, fallbackValue = it) ?: TranslationModel.Model.LocalizedString.empty(subContentsLabel)
                    })
            }
        }
    }
}

fun String?.parseAlphaHex(): String {
    return if (this == null) {
        "#ffffff00"
    } else {
        "${this.lowercase()}ff"
    }
}

fun String?.parseAlphaHexOrNull(): String? {
    return if (this == null) {
        null
    } else {
        "${this.lowercase()}ff"
    }
}

fun Int.parseAsPixels(): String {
    return "${this}px"
}
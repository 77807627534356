package es.cinfo.tiivii.core.notifications

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

sealed class NotificationsModel {
    sealed class ViewModel {
        /**
         * Represents the single notification information
         * @param id Unique identifier of the notification
         * @param title Brief title of the notification
         * @param description Detailed text of the notification
         * @param isNew `true` if the notification has not been marked as read, `false` otherwise
         */
        data class Notification(
            val id: Int,
            val title: String,
            val description: String,
            //val image: es.cinfo.tiivii.core.image.ViewModel.Image?,
            val isNew: Boolean
        )
    }

    internal sealed class Model {
        data class Notification(
            val id: Int,
            val title: String,
            val description: String,
            //val image: es.cinfo.tiivii.core.image.Model.Image?,
            val isNew: Boolean
        ) {
            fun toViewModel(): ViewModel.Notification {
                return ViewModel.Notification(id, title, description, /*image?.toViewModel(),*/ isNew)
            }
        }
    }

    internal sealed class ApiResponse {
        @Serializable
        data class Notifications(
            @SerialName(NOTIFICATIONS_PARAM)
            val notifications: List<Notification>,
        ) {
            companion object {
                const val NOTIFICATIONS_PARAM = "notifications"
            }

            @Serializable
            data class Notification(
                @SerialName(ID_PARAM)
                val id: Int,
                @SerialName(TITLE_PARAM)
                val title: String,
                @SerialName(DESCRIPTION_PARAM)
                val description: String,
                /*@SerialName(IMAGE_PARAM)
                val image: es.cinfo.tiivii.core.image.ApiResponse.Image,*/
                @SerialName(NEW_PARAM)
                val isNew: String
            ) {
                companion object {
                    const val ID_PARAM = "id"
                    const val TITLE_PARAM = "title"
                    const val DESCRIPTION_PARAM = "content"
                    const val IMAGE_PARAM = "image"
                    const val NEW_PARAM = "status"
                }

                fun toModel(): Model.Notification {
                    val isNewValue = when(isNew) {
                        "sent" -> true
                        "read" -> false
                        else -> true
                    }
                    return Model.Notification(
                        id,
                        title,
                        description,
                        /*es.cinfo.tiivii.core.image.Model.Image(
                            image.id,
                            image.width,
                            image.height,
                            "",
                            es.cinfo.tiivii.core.image.Model.Image.Type.OTHER
                        ),*/ //FIXME: How to obtain/save image??
                        isNewValue
                    )
                }
            }

            fun toModel(): List<Model.Notification> {
                val notificationsModel = mutableListOf<Model.Notification>()
                notifications.forEach {
                    notificationsModel.add(it.toModel())
                }
                return notificationsModel
            }
        }
    }
}
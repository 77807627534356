package es.cinfo.tiivii.core.layout

import com.russhwolf.settings.Settings
import com.russhwolf.settings.get
import es.cinfo.tiivii.core.layout.model.LegalSign
import es.cinfo.tiivii.di.diContainer
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.kodein.di.instance

/**
 * Local storage datasource for layout configuration data and operations
 */
internal interface LayoutStorage {
    companion object {
        /**
         * Storage key for signed legal version conditions
         */
        const val LEGAL_KEY = "legal"
    }

    /**
     * The last version of legal conditions signed by the user if any
     */
    suspend fun getSignedLegal(): LegalSign?

    /**
     * Signs the given legal conditions and stores it on disk
     */
    suspend fun storeLegal(legal: LegalSign)
}

/**
 * Default implementation of [LayoutStorage] using [Settings]
 */
internal class DefaultLayoutStorage() : LayoutStorage {
    private val settings: Settings by diContainer.instance()
    private val json: Json by diContainer.instance()

    override suspend fun getSignedLegal(): LegalSign? {
        val legalSign: String? = settings[LayoutStorage.LEGAL_KEY]
        return if (legalSign != null) {
            json.decodeFromString(legalSign)
        } else {
            null
        }
    }

    override suspend fun storeLegal(legal: LegalSign) {
        settings.putString(LayoutStorage.LEGAL_KEY, json.encodeToString(legal))
    }
}
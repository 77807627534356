package es.cinfo.tiivii.core.modules.timeline

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.network.HttpModule
import es.cinfo.tiivii.core.modules.timeline.model.TimelineModel
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

/**
 * Network datasource for content timeline related operations
 */
internal interface TimelineApi {

    suspend fun getTimespots(contentId: Int): Outcome<List<TimelineModel.ApiResponse.Timespot>, NetworkError>

}

/**
 * Default implementation of [TimelineApi] using Ktor
 */
internal class DefaultTimelineApi : TimelineApi {
    private val http: HttpModule by diContainer.instance()

    private val baseEndpoint: String by lazy {
        val configModule: ConfigModule by diContainer.instance()
        "${configModule.getEnvConfig().backendUrl}/sgca/${configModule.getEnvConfig().apiName}/timespots"
    }

    override suspend fun getTimespots(contentId: Int): Outcome<List<TimelineModel.ApiResponse.Timespot>, NetworkError> {
        val endpoint = "$baseEndpoint/$contentId"
       return http.getAsOutcome(endpoint = endpoint, responseField = "data")
    }

}
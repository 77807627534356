package es.cinfo.tiivii.user.legal.view

import com.arkivanov.mvikotlin.core.view.MviView
import es.cinfo.tiivii.core.util.LoadingModel
import es.cinfo.tiivii.user.legal.view.LegalView.Event
import es.cinfo.tiivii.user.legal.view.LegalView.Model

/**
 * View implementation for the login selection screen
 */
interface LegalView : MviView<Model, Event> {

    /**
     * Operations available to be requested to the splash store
     */
    sealed class Event {
        data class ReloadLegalConditions(val language: String): Event()
        data class AcceptConditions(val isDataCollectionAllowed: Boolean) : Event()
        object RejectConditions : Event()
    }

    /**
     * Model of information available to the UI components
     */
    data class Model(
        /**
         * Legal conditions user text
         */
        val legalText: String?,
        val legalTextLanguage: String?,
        val loadingLegalText: LoadingModel.ViewModel.LoadState
    )

    /**
     * One-time notifications received from the store
     */
    sealed class Output {
        /**
         * Legal conditions have been accepted
         */
        object AcceptedLegalConditions: Output()

        /**
         * Unexpected error happened
         */
        data class UnexpectedError(val code: String) : Output()

        /**
         * User session has expired. Re-log is necessary
         */
        object UserSessionExpired : Output()

        /**
         * A request has timed out. This may indicate a problem with the internet connection
         */
        object RequestTimedOut : Output()

        /**
         * An operation has been request through an [Event] but the operation cannot be done due to some requirements
         * not met. This may happen if an [Event] is requested when is not due. This usually means that the usage of the
         * internal of the component is wrong and should be checked
         * @param reason explains why the operation cannot be executed
         */
        data class IllegalOperationError(val reason: String) : Output()
    }

}

package es.cinfo.tiivii.core.modules.geolocation

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.network.HttpModule
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.di.diContainer
import io.ktor.client.request.*
import org.kodein.di.instance

internal interface LocationApi {

    suspend fun getLocationHash(username: String, latitude: Double, longitude: Double): Outcome<String, NetworkError>

    suspend fun postLocation(hash: String, username: String, latitude: Double, longitude: Double): Outcome<Unit, NetworkError>
}

internal class DefaultLocationApi : LocationApi {
    private val http: HttpModule by diContainer.instance()

    private val baseEndpoint: String by lazy {
        val configModule: ConfigModule by diContainer.instance()
        "${configModule.getEnvConfig().backendUrl}/game/${configModule.getEnvConfig().apiName}"
    }

    override suspend fun getLocationHash(
        username: String,
        latitude: Double,
        longitude: Double
    ): Outcome<String, NetworkError> {
        val endpoint = "$baseEndpoint/${username}/hash?latitude=$latitude&longitude=$longitude"
        return http.getAsOutcome(endpoint = endpoint, responseField = "hash")
    }

    override suspend fun postLocation(hash: String, username: String, latitude: Double, longitude: Double): Outcome<Unit, NetworkError> {
        val endpoint = "$baseEndpoint/${username}/geolocation"
        return http.postOrError(endpoint = endpoint) {
            header("Content-Type", "application/json")
            body = SendLocationApiRequest(latitude, longitude, hash)
        }
    }
}
package es.cinfo.tiivii.core.features.playback.store

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.SuspendExecutor
import es.cinfo.tiivii.core.content.model.ContentTypeModel
import es.cinfo.tiivii.core.error.ErrorService
import es.cinfo.tiivii.core.features.playback.model.Model
import es.cinfo.tiivii.core.features.playback.store.PlaybackStore.*
import es.cinfo.tiivii.core.features.playback.usecase.GetVideoUrl
import es.cinfo.tiivii.core.features.playback.usecase.StopLocationTracking
import es.cinfo.tiivii.core.modules.analytics.LogEvent
import es.cinfo.tiivii.core.modules.analytics.model.AnalyticsModel
import es.cinfo.tiivii.core.util.Failure
import es.cinfo.tiivii.core.util.LoadingModel.Model.LoadState
import es.cinfo.tiivii.core.util.Success
import es.cinfo.tiivii.di.diContainer
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import org.kodein.di.instance

internal class PlaybackStoreFactory constructor(
    private val storeFactory: StoreFactory,
) {

    private val errorService: ErrorService by diContainer.instance()

    private sealed class Result {
        object LoadingUrl : Result()
        data class VideoInfoLoaded(val url: String,
                                   val type: ContentTypeModel.Model.ContentType) : Result()
        data class ArInfoLoaded(val shareUrl: String) : Result()
        object ErrorLoadingVideoInfo : Result()
    }

    fun create(): PlaybackStore =
        object :
            PlaybackStore,
            Store<Intent, State, Label> by
            storeFactory.create(
                name = "PlaybackStore",
                initialState = State(
                    videoUrl = null,
                    shareUrl = null,
                    contentType = null,
                    loading = LoadState.RESET
                ),
                reducer = DefaultReducer,
                executorFactory = ::DefaultExecutor,
            ) {
            override fun dispose() {
                CoroutineScope(Dispatchers.Main).launch {
                    StopLocationTracking().invoke()
                }
            }
        }

    private object DefaultReducer : Reducer<State, Result> {
        override fun State.reduce(result: Result): State =
            when (result) {
                Result.LoadingUrl -> copy(
                    loading = LoadState.LOADING
                )
                is Result.VideoInfoLoaded -> copy(
                    loading = LoadState.LOADED,
                    contentType = result.type,
                    videoUrl = result.url
                )
                Result.ErrorLoadingVideoInfo -> copy(
                    loading = LoadState.RESET
                )
                is Result.ArInfoLoaded -> copy(
                    loading = LoadState.LOADED,
                    contentType = ContentTypeModel.Model.ContentType.AR,
                    shareUrl = result.shareUrl
                )
            }
    }

    private inner class DefaultExecutor :
        SuspendExecutor<Intent, Action,
                State,
                Result, Label>() {
        override suspend fun executeIntent(intent: Intent, getState: () -> State) {
            when (intent) {
                is Intent.LoadVideoUrl -> getVideoUrl(intent.contentId, intent.ignoreList)
                Intent.LogPlaybackView -> logPlaybackView()
            }
        }

        override suspend fun executeAction(action: Action, getState: () -> State) { }

        private suspend fun getVideoUrl(contentId: Int, ignoreList: String?) {
            dispatch(Result.LoadingUrl)
            when (val outcome = GetVideoUrl(contentId, ignoreList).invoke()) {
                is Success -> {
                    when (outcome.value) {
                        is Model.ContentLoad.Ar -> {
                            dispatch(Result.ArInfoLoaded(outcome.value.shareUrl))
                        }
                        is Model.ContentLoad.Video -> {
                            dispatch(Result.VideoInfoLoaded(outcome.value.url, outcome.value.type))
                        }
                    }
                }
                is Failure -> {
                    dispatch(Result.ErrorLoadingVideoInfo)
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                is GetVideoUrl.Error.PlaybackLocked ->
                                    publish(Label.PlaybackLocked(outcome.error.reason))
                                is GetVideoUrl.Error.ContentUnavailable,
                                is GetVideoUrl.Error.CasUnavailable,
                                is GetVideoUrl.Error.ContentNotSupported ->
                                    publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }
    }

    private suspend fun logPlaybackView() {
        LogEvent(
            action = AnalyticsModel.Action.PageView,
            keyValue = AnalyticsModel.Action.PageView.PAGE_ID_KEY to AnalyticsModel.Action.PageView.PLAYER_VALUE
        ).invoke()
    }
}

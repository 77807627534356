package es.cinfo.tiivii.core.modules.rating

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.core.util.map
import es.cinfo.tiivii.core.util.success
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

internal interface RatingService {
    suspend fun getRatingsForAge(age: Int): Outcome<List<RatingModel.Model.AgeRating>, NetworkError>
}

internal class DefaultRatingService: RatingService {

    private val ratingApi: RatingApi by diContainer.instance()
    private lateinit var globalRatings: List<RatingModel.Model.AgeRating>

    override suspend fun getRatingsForAge(age: Int): Outcome<List<RatingModel.Model.AgeRating>, NetworkError> {
        return if (!::globalRatings.isInitialized) {
            ratingApi.getGlobalRatings().map {
                globalRatings = it.toModel()
                globalRatings.filter { rating ->
                    rating.minAge <= age
                }
            }
        } else {
            success(
                globalRatings.filter { rating ->
                    rating.minAge <= age
                }
            )
        }
    }

}
package es.cinfo.tiivii.core.modules.timeline.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

class TimelineModel {
    sealed class ViewModel {
        /**
         * Represents and event in the timeline of a playback
         * @param timestamp of the event inside the playback
         * (e.g: 20 means an event in the second 20 of the playback) or the epoch if the event is live
         * @param title a title for the event
         */
        abstract class Timespot(
            val timestamp: Long,
            val title: String,
            val streamType: StreamType
        ) {

            enum class StreamType {
                VOD, LIVE
            }

            override fun equals(other: Any?): Boolean {
                if (this === other) return true
                if (other == null || this::class != other::class) return false

                other as Timespot

                if (timestamp != other.timestamp) return false
                if (title != other.title) return false
                if (streamType != other.streamType) return false

                return true
            }

            override fun hashCode(): Int {
                var result = timestamp.hashCode()
                result = 31 * result + title.hashCode()
                result = 31 * result + streamType.hashCode()
                return result
            }
        }

        /**
         * Represents a [Timespot] of type message
         * @param title of the timespot
         * @param content text of the message
         */
        class Message(
            timestamp: Long,
            title: String,
            streamType: StreamType,
            val content: String?
        ): Timespot(timestamp, title, streamType) {
            override fun equals(other: Any?): Boolean {
                if (this === other) return true
                if (other == null || this::class != other::class) return false
                if (!super.equals(other)) return false

                other as Message

                if (content != other.content) return false

                return true
            }

            override fun hashCode(): Int {
                var result = super.hashCode()
                result = 31 * result + (content?.hashCode() ?: 0)
                return result
            }
        }

        /**
         * Represents a [Timespot] of type button
         * @param content text for the button
         * @param buttonColor to be presented with
         * @param buttonMessage for the button
         * @param buttonUrl to redirect to
         */
        class Click(
            timestamp: Long,
            title: String,
            streamType: StreamType,
            content: String?,
            val buttonUrl: String?,
            val buttonColor: String?,
            val buttonMessage: String?,
        ): Timespot(timestamp, title, streamType) {
            override fun equals(other: Any?): Boolean {
                if (this === other) return true
                if (other == null || this::class != other::class) return false
                if (!super.equals(other)) return false

                other as Click

                if (buttonUrl != other.buttonUrl) return false
                if (buttonColor != other.buttonColor) return false
                if (buttonMessage != other.buttonMessage) return false

                return true
            }

            override fun hashCode(): Int {
                var result = super.hashCode()
                result = 31 * result + (buttonUrl?.hashCode() ?: 0)
                result = 31 * result + (buttonColor?.hashCode() ?: 0)
                result = 31 * result + (buttonMessage?.hashCode() ?: 0)
                return result
            }
        }

        /**
         * Represents a [Timespot] of type image
         * @param url of the image to present
         */
        class Image(
            timestamp: Long,
            title: String,
            streamType: StreamType,
            val url: String?,
        ): Timespot(timestamp, title, streamType) {
            override fun equals(other: Any?): Boolean {
                if (this === other) return true
                if (other == null || this::class != other::class) return false
                if (!super.equals(other)) return false

                other as Image

                if (url != other.url) return false

                return true
            }

            override fun hashCode(): Int {
                var result = super.hashCode()
                result = 31 * result + (url?.hashCode() ?: 0)
                return result
            }
        }

        /**
         * Represents a [Timespot] of type embed
         * @param html of the embed to present
         */
        class Embed(
            timestamp: Long,
            title: String,
            streamType: StreamType,
            val html: String?,
        ): Timespot(timestamp, title, streamType) {
            override fun equals(other: Any?): Boolean {
                if (this === other) return true
                if (other == null || this::class != other::class) return false
                if (!super.equals(other)) return false

                other as Embed

                if (html != other.html) return false

                return true
            }

            override fun hashCode(): Int {
                var result = super.hashCode()
                result = 31 * result + (html?.hashCode() ?: 0)
                return result
            }
        }

        enum class TimelineSubscriptionStatus {
            DISCONNECTED, CONNECTING, CONNECTED
        }
    }

    internal sealed class Model {
        abstract class Timespot(
            val timestamp: Long,
            val title: String,
            val streamType: StreamType
        ) {
            abstract fun toViewModel(): ViewModel.Timespot

            enum class StreamType {
                VOD, LIVE;

                fun toViewModel(): ViewModel.Timespot.StreamType {
                    return when (this) {
                        VOD -> ViewModel.Timespot.StreamType.VOD
                        LIVE -> ViewModel.Timespot.StreamType.LIVE
                    }
                }
            }
        }

        class Message(
            timestamp: Long,
            title: String,
            streamType: StreamType,
            val content: String?
        ): Timespot(timestamp, title, streamType) {
            override fun toViewModel(): ViewModel.Timespot =
                ViewModel.Message(
                    timestamp, title, streamType.toViewModel(), content
                )
        }

        class Click(
            timestamp: Long,
            title: String,
            streamType: StreamType,
            val content: String?,
            val buttonUrl: String?,
            val buttonColor: String?,
            val buttonMessage: String?,
        ): Timespot(timestamp, title, streamType) {
            override fun toViewModel(): ViewModel.Timespot =
                ViewModel.Click(
                    timestamp, title, streamType.toViewModel(), content, buttonUrl, buttonColor, buttonMessage
                )
        }

        class Image(
            timestamp: Long,
            title: String,
            streamType: StreamType,
            val url: String?,
        ): Timespot(timestamp, title, streamType) {
            override fun toViewModel(): ViewModel.Timespot =
                ViewModel.Image(
                    timestamp, title, streamType.toViewModel(), url
                )
        }

        class Embed(
            timestamp: Long,
            title: String,
            streamType: StreamType,
            val html: String?,
        ): Timespot(timestamp, title, streamType) {
            override fun toViewModel(): ViewModel.Timespot =
                ViewModel.Embed(
                    timestamp, title, streamType.toViewModel(), html
                )
        }

        enum class TimelineSuscriptionStatus {
            DISCONNECTED, CONNECTING, CONNECTED;

            fun toViewModel(): ViewModel.TimelineSubscriptionStatus =
                when (this) {
                    DISCONNECTED -> ViewModel.TimelineSubscriptionStatus.DISCONNECTED
                    CONNECTING -> ViewModel.TimelineSubscriptionStatus.CONNECTING
                    CONNECTED -> ViewModel.TimelineSubscriptionStatus.CONNECTED
                }
        }
    }
    internal sealed class ApiResponse {
        @Serializable
        data class Timespot(
            @SerialName(CONTENT_ID_PARAM)
            val contentId: Int,
            @SerialName(TIMESTAMP_PARAM)
            val timestamp: String,
            @SerialName(TYPE_PARAM)
            val type: String,
            @SerialName(TITLE_PARAM)
            val title: String,
            @SerialName(CONTENT_PARAM)
            val content: String?,
            @SerialName(BUTTON_URL_PARAM)
            val buttonUrl: String?,
            @SerialName(BUTTON_COLOR_PARAM)
            val buttonColor: String?,
            @SerialName(BUTTON_MESSAGE_PARAM)
            val buttonMessage: String?,
            @SerialName(IMG_URL_PARAM)
            val imgUrl: String?,
            @SerialName(HTML_PARAM)
            val html: String?,
        ) {
            companion object {
                const val CONTENT_ID_PARAM = "content_id"
                const val TIMESTAMP_PARAM = "timestamp"
                const val TYPE_PARAM = "type"
                const val TITLE_PARAM = "title"
                const val CONTENT_PARAM = "content"
                const val BUTTON_URL_PARAM = "button_url"
                const val BUTTON_COLOR_PARAM = "button_color"
                const val BUTTON_MESSAGE_PARAM = "button_message"
                const val IMG_URL_PARAM = "imgurl"
                const val HTML_PARAM = "html_code"
            }

            fun toModel(): Model.Timespot? {
                return when (type) {
                    "message" -> Model.Message(
                        timestamp.toLong(),
                        title,
                        Model.Timespot.StreamType.VOD,
                        content
                    )
                    "click" -> Model.Click(
                        timestamp.toLong(),
                        title,
                        Model.Timespot.StreamType.VOD,
                        content,
                        buttonUrl,
                        buttonColor,
                        buttonMessage
                    )
                    "image" -> Model.Image(
                        timestamp.toLong(),
                        title,
                        Model.Timespot.StreamType.VOD,
                        imgUrl
                    )
                    "embed" -> Model.Embed(
                        timestamp.toLong(),
                        title,
                        Model.Timespot.StreamType.VOD,
                        html
                    )
                    else -> null
                }
            }
        }
    }
}
package es.cinfo.tiivii.core.features.notifications.store

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.SuspendExecutor
import es.cinfo.tiivii.core.error.ErrorService
import es.cinfo.tiivii.core.features.notifications.usecase.DeleteNotification
import es.cinfo.tiivii.core.features.notifications.usecase.LoadNotifications
import es.cinfo.tiivii.core.features.notifications.usecase.ReadNotification
import es.cinfo.tiivii.core.notifications.NotificationsModel
import es.cinfo.tiivii.core.util.Failure
import es.cinfo.tiivii.core.util.LoadingModel.Model.LoadState
import es.cinfo.tiivii.core.util.Success
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

internal class NotificationsStoreFactory(
    private val storeFactory: StoreFactory,
) {

    private val errorService: ErrorService by diContainer.instance()

    private sealed class Result {
        object LoadingNotifications : Result()
        object ErrorLoadingNotifications : Result()
        object ErrorDeletingNotifications : Result()
        object ErrorReadingNotifications : Result()
        data class LoadedNotifications(val notifications: List<NotificationsModel.Model.Notification>) : Result()
        data class DeleteNotification(val id: Int) : Result()
        data class ReadNotification(val id: Int) : Result()
    }

    fun create(): NotificationsStore =
        object :
            NotificationsStore,
            Store<NotificationsStore.Intent, NotificationsStore.State, NotificationsStore.Label> by
            storeFactory.create(
                name = "NotificationsStore",
                initialState = NotificationsStore.State(
                    numNotifications = 0,
                    notifications = emptyList(),
                    loadingStats = LoadState.RESET
                ),
                bootstrapper = SimpleBootstrapper(NotificationsStore.Action.LoadNotifications),
                reducer = DefaultReducer,
                executorFactory = ::DefaultExecutor
            ) {
        }

    private object DefaultReducer : Reducer<NotificationsStore.State, Result> {
        override fun NotificationsStore.State.reduce(result: Result): NotificationsStore.State =
            when (result) {
                Result.LoadingNotifications -> copy(
                    loadingStats = LoadState.LOADING
                )
                is Result.LoadedNotifications -> copy(
                    numNotifications = result.notifications.filter { it.isNew }.size,
                    notifications = result.notifications,
                    loadingStats = LoadState.LOADED
                )
                is Result.DeleteNotification -> {
                    val updatedNotifications = mutableListOf<NotificationsModel.Model.Notification>()
                    updatedNotifications.addAll(notifications)
                    val notificationDeleted = updatedNotifications.find { it.id == result.id }
                    updatedNotifications.remove(notificationDeleted)
                    copy(
                        numNotifications = notifications.filter { it.isNew }.size,
                        notifications = updatedNotifications,
                        loadingStats = LoadState.LOADED
                    )
                }
                is Result.ReadNotification -> {
                    val updatedNotifications = mutableListOf<NotificationsModel.Model.Notification>()
                    updatedNotifications.addAll(notifications)
                    val notificationRead = updatedNotifications.find { it.id == result.id }
                    val newNotificationValue = NotificationsModel.Model.Notification(
                        id = notificationRead!!.id,
                        title = notificationRead.title,
                        description = notificationRead.description,
                        isNew = false
                    )
                    val index = updatedNotifications.indexOf(notificationRead)
                    updatedNotifications[index] = newNotificationValue
                    copy(
                        numNotifications = updatedNotifications.filter { it.isNew }.size,
                        notifications = updatedNotifications,
                        loadingStats = LoadState.LOADED
                    )
                }
                Result.ErrorLoadingNotifications -> copy(
                    numNotifications = 0,
                    notifications = emptyList(),
                    loadingStats = LoadState.RESET
                )
                Result.ErrorDeletingNotifications -> copy(
                    loadingStats = LoadState.RESET
                )
                Result.ErrorReadingNotifications -> copy(
                    loadingStats = LoadState.RESET
                )
            }
    }

    private inner class DefaultExecutor :
        SuspendExecutor<NotificationsStore.Intent, NotificationsStore.Action,
                NotificationsStore.State,
                Result, NotificationsStore.Label>() {
        override suspend fun executeIntent(
            intent: NotificationsStore.Intent,
            getState: () -> NotificationsStore.State
        ) {
            when (intent) {
                NotificationsStore.Intent.ReloadNotifications -> loadNotifications(getState().loadingStats)
                is NotificationsStore.Intent.DeleteNotification -> deleteNotification(intent.id)
                is NotificationsStore.Intent.ReadNotification -> readNotification(
                    getState().notifications.find { it.id == intent.id }
                )
            }
        }

        override suspend fun executeAction(
            action: NotificationsStore.Action,
            getState: () -> NotificationsStore.State
        ) {
            when (action) {
                NotificationsStore.Action.LoadNotifications -> loadNotifications(getState().loadingStats)
            }
        }

        private suspend fun loadNotifications(loadingStats: LoadState) {
            if (loadingStats != LoadState.LOADING) {
                dispatch(Result.LoadingNotifications)
                when (val outcome = LoadNotifications().invoke()) {
                    is Success -> dispatch(Result.LoadedNotifications(outcome.value))
                    is Failure -> {
                        dispatch(Result.ErrorLoadingNotifications)
                        errorService.handleError(
                            error = outcome.error,
                            sessionExpiredAction = { publish(NotificationsStore.Label.UserSessionExpired) },
                            requestTimeoutAction = { publish(NotificationsStore.Label.RequestTimedOut) },
                            handler = {
                                when (outcome.error) {
                                    is LoadNotifications.Error.UnavailableNotifications,
                                    LoadNotifications.Error.UserSessionUnavailable ->
                                        publish(NotificationsStore.Label.UnexpectedError(outcome.error.getCode()))
                                }
                            }
                        )
                    }
                }
            }
        }

        private suspend fun deleteNotification(id: Int) {
            when (val outcome = DeleteNotification(id).invoke()) {
                is Success -> dispatch(Result.DeleteNotification(id))
                is Failure -> {
                    dispatch(Result.ErrorDeletingNotifications)
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(NotificationsStore.Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(NotificationsStore.Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                DeleteNotification.Error.EmptyNotifications,
                                is DeleteNotification.Error.UnavailableNotifications ->
                                    publish(NotificationsStore.Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }

        private suspend fun readNotification(notification: NotificationsModel.Model.Notification?) {
            if (notification == null) {
                val error = "Notification has not been load, mark as read is not available"
                publish(NotificationsStore.Label.IllegalOperationError(error))
            } else if (notification.isNew) {
                when (val outcome = ReadNotification(notification.id).invoke()) {
                    is Success -> dispatch(Result.ReadNotification(notification.id))
                    is Failure -> {
                        dispatch(Result.ErrorReadingNotifications)
                        errorService.handleError(
                            error = outcome.error,
                            sessionExpiredAction = { publish(NotificationsStore.Label.UserSessionExpired) },
                            requestTimeoutAction = { publish(NotificationsStore.Label.RequestTimedOut) },
                            handler = {
                                when (outcome.error) {
                                    is ReadNotification.Error.UnavailableNotifications ->
                                        publish(NotificationsStore.Label.UnexpectedError(outcome.error.getCode()))
                                }
                            }
                        )
                    }
                }
            }
        }
    }
}
package es.cinfo.tiivii.core.modules.status

import kotlinx.serialization.SerializationException

class StatusModel {
    internal sealed class Model {
        enum class Status {
            DRAFT, PROCESSING, DELETED, PUBLISHED;

            companion object {
                fun parse(value: String): Status {
                    return when (value) {
                        "published" -> PUBLISHED
                        "draft" -> DRAFT
                        "processing" -> PROCESSING
                        "deleted" -> DELETED
                        else -> throw SerializationException("Unrecognized status $value")
                    }
                }
            }
        }
    }
}
package es.cinfo.tiivii.core.modules.chat

import es.cinfo.tiivii.core.date.DateService
import es.cinfo.tiivii.core.modules.chat.model.ChatModel
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.pusher.PusherService
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.di.diContainer
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.map
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.jsonObject
import kotlinx.serialization.json.jsonPrimitive
import org.kodein.di.instance

internal interface ChatService {
    suspend fun sendMessage(message: String, username: String, contentId: Int): Outcome<Unit, NetworkError>
    suspend fun connect(contentId: Int): Flow<ChatModel.Model.Message>
    suspend fun disconnect(contentId: Int)
}

internal class DefaultChatService: ChatService {
    private val json: Json by diContainer.instance()
    private val chatApi: ChatApi by diContainer.instance()
    private val pusherService: PusherService by diContainer.instance()
    private val dateService: DateService by diContainer.instance()
    private val configModule: ConfigModule by diContainer.instance()

    override suspend fun sendMessage(message: String, username: String, contentId: Int): Outcome<Unit, NetworkError> {
        return chatApi.sendMessage(message, username, configModule.getEnvConfig().apiName, "chat-$contentId")
    }

    override suspend fun connect(contentId: Int): Flow<ChatModel.Model.Message> {
        return pusherService.subscribe(configModule.getEnvConfig().apiName, "chat-$contentId")
            .filter {
                it.json.jsonObject.containsKey("username") &&
                it.json.jsonObject.containsKey("message")
            }
            .map {
                val username = it.json.jsonObject["username"]?.jsonPrimitive?.content!!
                val message = it.json.jsonObject["message"]?.jsonPrimitive?.content!!
                ChatModel.Model.Message(
                    dateService.currentEpochMillis(),
                    username,
                    message
                )
            }
    }

    override suspend fun disconnect(contentId: Int) {
        pusherService.unsubscribe(configModule.getEnvConfig().apiName, "chat-$contentId")
    }
}
package es.cinfo.tiivii.core.modules.bookmark.model

import es.cinfo.tiivii.core.util.jsonObjectOrNull
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.SerializationException
import kotlinx.serialization.json.*

internal sealed class BookmarkModel {

    sealed class Model {
        sealed class Bookmark {
            companion object {
                const val CURRENT_VIDEO_POSITION_PARAM = "videoCurrentPosition"
                const val FINISHED_VIDEO_PARAM = "finishedVideo"

                fun findLatestVideoPositionViewed(contentId: Int, bookmarks: List<Bookmark>?): LatestVideoPositionViewed? {
                    return bookmarks?.find {
                        it is LatestVideoPositionViewed && it.contentId == contentId
                    } as? LatestVideoPositionViewed
                }
            }

            data class LatestVideoPositionViewed(val contentId: Int, val latestPositionSec: Int, val durationSec: Int): Bookmark()
            data class VideoFinished(val contentId: Int): Bookmark()
        }
    }

    sealed class ApiResponse {
        @Serializable
        data class Bookmarks(
            @SerialName(BOOKMARKS_PARAM)
            val bookmarks: List<JsonElement> = emptyList()
        ) {
            companion object {
                const val BOOKMARKS_PARAM = "bookmarks"

                fun toModel(bookmarks: List<JsonElement>): List<Model.Bookmark> {
                    return bookmarks.mapNotNull {
                        when (it.jsonObject["key"]?.jsonPrimitive?.content) {
                            Model.Bookmark.CURRENT_VIDEO_POSITION_PARAM -> {
                                val contentId = it.jsonObject["contentid"]?.jsonPrimitive?.intOrNull
                                val value = it.jsonObject["value"]?.jsonObjectOrNull
                                val posSec = if (value == null) {
                                    null
                                } else {
                                    value.jsonObject["posSec"]?.jsonPrimitive?.intOrNull
                                }
                                val durationSec = if (value == null) {
                                    null
                                } else {
                                    value.jsonObject["durationSec"]?.jsonPrimitive?.floatOrNull
                                }
                                if (contentId == null ||
                                    posSec == null ||
                                    durationSec == null
                                ) {
                                    throw SerializationException(
                                        "Unrecognized ${Model.Bookmark.CURRENT_VIDEO_POSITION_PARAM} bookmark format: $it"
                                    )
                                } else {
                                    Model.Bookmark.LatestVideoPositionViewed(
                                        contentId = contentId,
                                        latestPositionSec = posSec,
                                        durationSec = durationSec.toInt()
                                    )
                                }
                            }
                            Model.Bookmark.FINISHED_VIDEO_PARAM -> {
                                val contentId = it.jsonObject["contentid"]?.jsonPrimitive?.intOrNull
                                val hasFinished = it.jsonObject["value"]?.jsonPrimitive?.booleanOrNull
                                if (contentId == null ||
                                    hasFinished == null ||
                                    !hasFinished
                                ) {
                                    throw SerializationException(
                                        "Unrecognized ${Model.Bookmark.FINISHED_VIDEO_PARAM} bookmark format: $it"
                                    )
                                } else {
                                    Model.Bookmark.VideoFinished(
                                        contentId = contentId
                                    )
                                }
                            }
                            else -> null
                        }
                    }
                }
            }

            fun toModel(): List<Model.Bookmark> {
                return toModel(bookmarks)
            }
        }

        @Serializable
        data class WrappedUserBookmarks(
            @SerialName(WRAPPER_PARAM)
            val bookmarks: Bookmarks
        ) {
            companion object {
                const val WRAPPER_PARAM = "data"
            }
        }
    }

}


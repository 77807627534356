package es.cinfo.tiivii.core.modules.checkpoint

import es.cinfo.tiivii.core.modules.checkpoint.model.CheckpointModel
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.core.util.getOrNull
import es.cinfo.tiivii.core.util.map
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

internal interface CheckpointService {
    suspend fun getCheckpoints(contentId: Int): Outcome<List<CheckpointModel.Model.Checkpoint>, NetworkError>

    suspend fun getCheckpoint(contentId: Int, posSec: Int): CheckpointModel.Model.Checkpoint?
}

internal class DefaultCheckpointService: CheckpointService {
    private val checkpointApi: CheckpointApi by diContainer.instance()
    private var checkpointsMap: MutableMap<Int, List<CheckpointModel.Model.Checkpoint>> = mutableMapOf()

    override suspend fun getCheckpoints(contentId: Int): Outcome<List<CheckpointModel.Model.Checkpoint>, NetworkError> {
        return checkpointApi.getCheckpoints(contentId).map {
            val checkpoints = it.toModel().sortedBy { checkpoint -> checkpoint.timestamp }
            checkpointsMap[contentId] = checkpoints
            checkpoints
        }
    }

    override suspend fun getCheckpoint(contentId: Int, posSec: Int): CheckpointModel.Model.Checkpoint? {
        if (!checkpointsMap.containsKey(contentId)) {
            val checkpoints = checkpointApi.getCheckpoints(contentId).map { it.toModel() }.getOrNull()
                ?.sortedBy { it.timestamp }
            if (checkpoints != null) {
                checkpointsMap[contentId] = checkpoints
            } else {
                checkpointsMap[contentId] = emptyList()
            }
        }
        val checkpoints = checkpointsMap[contentId]
        return checkpoints?.find { it.timestamp == posSec.toLong() }
    }
}
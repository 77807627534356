package es.cinfo.tiivii.core.user.model

import es.cinfo.tiivii.core.date.DateService
import es.cinfo.tiivii.core.interest.model.Model.Interest
import es.cinfo.tiivii.core.modules.avatar.model.AvatarModel.Model.Avatar
import es.cinfo.tiivii.core.modules.bookmark.model.BookmarkModel
import es.cinfo.tiivii.core.modules.product.model.ProductModel
import es.cinfo.tiivii.core.modules.product.model.ProductModel.Model.Product
import es.cinfo.tiivii.core.modules.rating.RatingModel.Model.AgeRating as AgeRatingModel
import es.cinfo.tiivii.core.modules.rating.RatingModel.ApiResponse.AgeRating as AgeRatingApiResponse
import es.cinfo.tiivii.core.profile.ProfileModel.Model.Profile
import es.cinfo.tiivii.core.userstats.UserStatsModel.Model.UserStats
import es.cinfo.tiivii.di.diContainer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.SerializationException
import kotlinx.serialization.json.JsonElement
import org.kodein.di.instance
import kotlin.math.max
import kotlin.math.min
import kotlin.math.roundToInt

internal sealed class Model {

    data class UserLoad(
        val user: User,
        val stats: UserStats?
    )

    /**
     * Defines the user model for the app
     */
    data class User(
        val username: String,
        val preferredLanguage: String,
        val interests: Set<Interest>,
        val avatar: Avatar?,
        val firstName: String,
        val lastName: String,
        val email: String,
        val birthday: String,
        val signedLegalVersion: Int,
        val favorites: List<String>?,
        val ratings: List<Rating>?,
        val bookmarks: List<BookmarkModel.Model.Bookmark> = emptyList(),
        val profile: Profile,
        val allowedAgeRatings: List<AgeRatingModel>?
    ) {
        data class Rating(
            val contentId: Int,
            val rating: Int,
        )
    }

    data class ProductsLoad(
        val products: List<Product>,
        val userProducts: ProductModel.Model.ProductsLoad,
    )

}

internal sealed class ApiResponse {
    /**
     * Defines the response obtained from backend when retrieving user data
     */
    @Serializable
    data class User(
        @SerialName(ID_PARAM)
        val id: String,
        @SerialName(USERNAME_PARAM)
        val username: String,
        @SerialName(FIRST_NAME_PARAM)
        val firstName: String,
        @SerialName(LAST_NAME_PARAM)
        val lastName: String,
        @SerialName(EMAIL_PARAM)
        val email: String? = null,
        @SerialName(LANGUAGE_PARAM)
        val preferredLanguage: String,
        @SerialName(BIRTHDAY_PARAM)
        val birthday: String,
        @SerialName(LEGAL_PARAM)
        val acceptedLegalVersion: Int,
        @SerialName(ROLE_PARAM)
        val role: String,
        @SerialName(TUTOR_EMAIL_PARAM)
        val tutorEmail: String? = null,
        @SerialName(AVATAR_PARAM)
        val avatarImageId: Int? = null,
        @SerialName(INTERESTS_PARAM)
        val interests: Set<Int>?,
        @SerialName(FAVORITES_PARAM)
        val favorites: List<String>?,
        @SerialName(RATINGS_PARAM)
        val ratings: List<Rating>?,
        @SerialName(BOOKMARKS_PARAM)
        val bookmarks: List<JsonElement> = emptyList(),
        @SerialName(ALLOWED_AGE_RATINGS_PARAM)
        val allowedAgeRatings: List<AgeRatingApiResponse>? = null
    ) {
        companion object {
            const val ID_PARAM = "id"
            const val USERNAME_PARAM = "username"
            const val FIRST_NAME_PARAM = "firstname"
            const val LAST_NAME_PARAM = "lastname"
            const val EMAIL_PARAM = "email"
            const val LANGUAGE_PARAM = "language"
            const val BIRTHDAY_PARAM = "birthdate"
            const val LEGAL_PARAM = "acceptedlegalversion"
            const val ROLE_PARAM = "role"
            const val TUTOR_EMAIL_PARAM = "tutor"
            const val AVATAR_PARAM = "avatar"
            const val INTERESTS_PARAM = "interests"
            const val FAVORITES_PARAM = "favorites"
            const val RATINGS_PARAM = "ratings"
            const val BOOKMARKS_PARAM = "bookmarks"
            const val ALLOWED_AGE_RATINGS_PARAM = "allowedRatings"
        }

        @Serializable
        data class Rating(
            @SerialName(CONTENT_ID_PARAM)
            val contentId: Int,
            @SerialName(RATING_PARAM)
            val rating: Float,
        ) {
            companion object {
                const val CONTENT_ID_PARAM = "contentid"
                const val RATING_PARAM = "rating"
            }

            fun toModel(): Model.User.Rating =
                Model.User.Rating(contentId, max(min(rating.roundToInt(), 5), 0))
        }

        fun toModel(avatar: Avatar? = null, interests: Set<Interest>): Model.User {
            val parsedBirthday = birthday.split("T").first()
            val finalEmail = email ?: tutorEmail ?: throw SerializationException("Missing '${EMAIL_PARAM}' or '${TUTOR_EMAIL_PARAM}' field for user")
            return Model.User(
                username = username,
                preferredLanguage = preferredLanguage,
                interests = interests,
                firstName = firstName,
                lastName = lastName,
                email = finalEmail,
                avatar = avatar,
                birthday = parsedBirthday,
                signedLegalVersion = acceptedLegalVersion,
                favorites = favorites,
                ratings = ratings?.map { it.toModel() },
                bookmarks = BookmarkModel.ApiResponse.Bookmarks.toModel(bookmarks),
                profile = getProfileByAge(parsedBirthday),
                allowedAgeRatings = if (allowedAgeRatings.isNullOrEmpty()) null else allowedAgeRatings.map { it.toModel() }
            )
        }

        private fun getProfileByAge(birthday: String): Profile {
            val dateService: DateService by diContainer.instance()
            val age = dateService.yearsUntil(birthday)
            return Profile.byAge(age)
        }
    }
}
package es.cinfo.tiivii.core.userstats

import es.cinfo.tiivii.core.modules.avatar.model.AvatarModel
import es.cinfo.tiivii.core.modules.game.model.GameModel.Model.Achievement as AchievementModel
import es.cinfo.tiivii.core.modules.game.model.GameModel.Model.Reward as RewardModel
import es.cinfo.tiivii.core.modules.game.model.GameModel.ApiResponse.Achievement as AchievementApiResponse
import es.cinfo.tiivii.core.modules.game.model.GameModel.ApiResponse.Reward as RewardApiResponse
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

sealed class UserStatsModel {
    sealed class ViewModel {
        /**
         * Represents the ranking information of any user
         * @param username to which the ranking belongs to
         * @param position in the ranking
         * @param points attached score value of the ranking
         */
        data class Ranking(
            val username: String,
            val position: Int,
            val points: Int
        )
    }

    internal sealed class Model {
        data class RankingStats(
            val userAvatar: AvatarModel.Model.Avatar?,
            val rankings: Rankings?,
            val userStats: UserStats
        )
        data class Rankings(
            val board: Int,
            val rankings: List<Ranking>,
            val limit: Int,
            val page: Int
        )
        data class UserStats(
            val achievements: List<AchievementModel>,
            val rewards: List<RewardModel>,
            val closestAchievement: AchievementModel? = null,
            val boardId: Int?,
            val ranking: Ranking?
        )
        data class Ranking(
            val username: String,
            val position: Int,
            val points: Int
        ) {
            fun toViewModel(): ViewModel.Ranking {
                return ViewModel.Ranking(
                    username, position, points
                )
            }
        }
    }

    internal sealed class ApiResponse {

        @Serializable
        data class UserStats(
            @SerialName(ACCOMPLISHMENTS_PARAM)
            val accomplishments: Accomplishments? = null,
            @SerialName(CLOSEST_ACHIEVEMENT_PARAM)
            val closestAchievement: AchievementApiResponse? = null,
            @SerialName(CONSUMER_RANKING_PARAM)
            val consumerRanking: ConsumerRanking? = null,
        ) {
            companion object {
                const val ACCOMPLISHMENTS_PARAM = "accomplishments"
                const val CLOSEST_ACHIEVEMENT_PARAM = "closest_achievement"
                const val CONSUMER_RANKING_PARAM = "consumer_ranking_position"
            }

            @Serializable
            data class Accomplishments(
                @SerialName(ACHIEVEMENTS_PARAM)
                val achievements: List<AchievementWrapper>,
                @SerialName(REWARDS_PARAM)
                val rewards: List<RewardWrapper>
            ) {
                companion object {
                    const val ACHIEVEMENTS_PARAM = "achievements"
                    const val REWARDS_PARAM = "rewards"
                }
            }

            @Serializable
            data class ConsumerRanking(
                @SerialName(BOARD_PARAM)
                val board: Board,
                @SerialName(RANKING_PARAM)
                val ranking: Ranking? = null
            ) {
                companion object {
                    const val BOARD_PARAM = "board"
                    const val RANKING_PARAM = "ranking"
                }
            }
        }

        @Serializable
        data class Board(
            @SerialName(ID_PARAM)
            val id: Int
        ) {
            companion object {
                const val ID_PARAM = "id"
            }
        }

        @Serializable
        data class Rankings(
            @SerialName(BOARD_PARAM)
            val board: Board,
            @SerialName(RANKINGS_PARAM)
            val rankings: List<Ranking>,
            @SerialName(LIMIT_PARAM)
            val limit: Int,
            @SerialName(PAGE_PARAM)
            val page: Int
        ) {
            companion object {
                const val BOARD_PARAM = "board"
                const val RANKINGS_PARAM = "ranking"
                const val LIMIT_PARAM = "limit"
                const val PAGE_PARAM = "page"
            }
        }

        @Serializable
        data class Ranking(
            @SerialName(USER_PARAM)
            val username: String? = null,
            @SerialName(POINTS_PARAM)
            val points: String,
            @SerialName(POSITION_PARAM)
            val position: Int
        ) {
            companion object {
                const val USER_PARAM = "username"
                const val POINTS_PARAM = "points"
                const val POSITION_PARAM = "position"
            }

            fun toModel(user: String): Model.Ranking {
                val username: String = username ?: user
                return Model.Ranking(
                    username, position, points.toInt()
                )
            }
        }

        @Serializable
        data class AchievementWrapper(
            @SerialName(CONTENT_PARAM)
            val content: AchievementApiResponse,
            @SerialName(ACCOMPLISHMENT_TIME_PARAM)
            val accomplishmentTimeSec: Long? = null
        ) {
            companion object {
                const val CONTENT_PARAM = "achievement_id"
                const val ACCOMPLISHMENT_TIME_PARAM = "obtainment_datetime"
            }
        }

        @Serializable
        data class RewardWrapper(
            @SerialName(CONTENT_PARAM)
            val content: RewardApiResponse
        ) {
            companion object {
                const val CONTENT_PARAM = "reward_id"
            }
        }

    }
}
package es.cinfo.tiivii.core.node

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.features.catalogue.model.ApiResponse.ContentNode
import es.cinfo.tiivii.core.features.catalogue.model.ApiResponse.NodeContentsLoad
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.network.HttpModule
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

internal interface NodeApi {

    suspend fun getRootNodes(): Outcome<List<ContentNode>, NetworkError>

    suspend fun getChildrenNodes(parentNodeId: Int): Outcome<List<ContentNode>, NetworkError>

    suspend fun getContents(nodeId: Int, filters: Map<String, String>?, limit: Int, page: Int, sort: String): Outcome<NodeContentsLoad, NetworkError>
}

internal class DefaultNodeApi : NodeApi {
    private val http: HttpModule by diContainer.instance()

    private val baseEndpoint: String by lazy {
        val configModule: ConfigModule by diContainer.instance()
        "${configModule.getEnvConfig().backendUrl}/sgca/${configModule.getEnvConfig().apiName}"
    }

    override suspend fun getRootNodes(): Outcome<List<ContentNode>, NetworkError> {
        val endpoint = "$baseEndpoint/nodes-root"
        return http.getAsOutcome(endpoint = endpoint, responseField = "data")
    }

    override suspend fun getChildrenNodes(parentNodeId: Int): Outcome<List<ContentNode>, NetworkError> {
        val endpoint = "$baseEndpoint/nodes-children/$parentNodeId"
        return http.getAsOutcome(endpoint = endpoint, responseField = "data")
    }

    override suspend fun getContents(
        nodeId: Int,
        filters: Map<String, String>?,
        limit: Int,
        page: Int,
        sort: String
    ): Outcome<NodeContentsLoad, NetworkError> {
        var endpoint = "$baseEndpoint/nodes-contents/$nodeId?limit=$limit&page=$page&sort=$sort"
        filters?.forEach {
            endpoint += "&filter${it.key}=${it.value}"
        }
        return http.getAsOutcome(endpoint = endpoint)
    }
}
package es.cinfo.tiivii.user.signup.store

import com.arkivanov.essenty.instancekeeper.InstanceKeeper
import com.arkivanov.mvikotlin.core.store.Store
import es.cinfo.tiivii.core.interest.model.Model.Interest
import es.cinfo.tiivii.core.modules.avatar.model.AvatarModel.Model.Avatar
import es.cinfo.tiivii.user.role.Role
import es.cinfo.tiivii.user.signup.store.SignupStore.*
import es.cinfo.tiivii.user.signup.view.SignupView

internal interface SignupStore :
    Store<Intent, State, Label>, InstanceKeeper.Instance {

    sealed class Action {
        object GetRoles : Action()
        object GetInterests : Action()
        object GetAvatars : Action()
        object GetLanguages : Action()
    }

    sealed class Intent {
        object GetAvatars : Intent()
        object GetInterests : Intent()
        object GetLanguages : Intent()
        object GetLegalConditions : Intent()
        object RollUsername : Intent()
        data class SetPreferredLanguage(val language: String) : Intent()
        data class SetUsername(val username: String) : Intent()
        data class SetAvatar(val avatar: Avatar) : Intent()
        data class SetEmail(val email: String) : Intent()
        data class SetCredentials(val credentials: String) : Intent()
        data class SetBirthday(val birthday: String) : Intent()
        data class SetFirstName(val firstName: String) : Intent()
        data class SetLastName(val lastName: String) : Intent()
        data class AddInterest(val interest: Interest) : Intent()
        data class RemoveInterest(val interest: Interest) : Intent()
        data class AcceptLegalConditions(val isDataCollectionAllowed: Boolean) : Intent()
        object SignUp : Intent()
        object LogSignupView : Intent()
    }

    data class State(
        val availableRoles: Set<Role> = emptySet(),
        val availableLanguages: Set<String> = emptySet(),
        val availableAvatars: Set<Avatar> = emptySet(),
        val availableInterests: Set<Interest> = emptySet(),
        val randomUsername: String? = null,
        val role: Role? = null,
        val username: String? = null,
        val avatar: Avatar? = null,
        val language: String? = null,
        val credentials: String? = null,
        val firstName: String? = null,
        val lastName: String? = null,
        val email: String? = null,
        val birthday: String? = null,
        val interests: Set<Interest> = emptySet(),
        val legalConditions: String? = null,
        val isDataCollectionAllowed: Boolean = false,
        val errors: Set<Label>? = null
    )

    sealed class Label {
        data class UnexpectedError(val code: String) : Label()
        data class IllegalOperationError(val reason: String) : Label()
        object UserSessionExpired: Label()
        object RequestTimedOut: Label()
        object InvalidEmail : Label()
        object ValidEmail: Label()
        object InvalidCredentials : Label()
        object InvalidBirthday : Label()
        object InvalidUsername : Label()
        object ValidUsername: Label()
        object InvalidFirstName : Label()
        object InvalidLastName : Label()
        object UserCreated : Label()
    }

}
package es.cinfo.tiivii.core.features.detail.store

import com.arkivanov.mvikotlin.core.store.Store
import es.cinfo.tiivii.core.content.model.CommentModel
import es.cinfo.tiivii.core.content.model.Model
import es.cinfo.tiivii.core.content.model.Model.Content
import es.cinfo.tiivii.core.content.model.Model.Playable
import es.cinfo.tiivii.core.content.model.NextContentModel
import es.cinfo.tiivii.core.content.model.RelatedContentModel.Model.RelatedContent
import es.cinfo.tiivii.core.content.model.SerialContentModel.Model.SerialContent
import es.cinfo.tiivii.core.content.model.report.ContentReportModel.Model.ContentReport
import es.cinfo.tiivii.core.features.detail.model.DetailModel.Model.ContentPurchaseInfo
import es.cinfo.tiivii.core.features.detail.store.DetailStore.*
import es.cinfo.tiivii.core.modules.game.model.GameModel
import es.cinfo.tiivii.core.translation.TranslationModel
import es.cinfo.tiivii.core.util.LoadingModel.Model.LoadState

internal interface DetailStore :
    Store<Intent, State, Label> {

    sealed class Action {}

    sealed class Intent {
        data class LoadContent(
            val id: Int,
            val payload: String? = null
        ) : Intent()

        object RefreshComments : Intent()

        object LoadMoreComments : Intent()

        object AddToFavorites : Intent()

        object RemoveFromFavorites : Intent()

        data class Rate(
            val rating: Float,
        ) : Intent()

        object DeleteRating : Intent()

        data class AddComment(
            val comment: String,
        ) : Intent()

        data class RemoveComment(
            val id: Int,
        ) : Intent()

        data class UpdateComment(
            val id: Int,
            val text: String,
        ) : Intent()

        data class ReportContent(val reportId: Int, val userMessage: String? = null): Intent()

        object LogDetailView: Intent()

        data class LogSelectItem(val contentId: Int) : Intent()
    }

    data class State(
        val content: Content?,
        val purchaseInfo: ContentPurchaseInfo?,
        val nextContent: NextContentModel.Model.NextContent?,
        val canReport: Boolean?,
        val playable: Playable?,
        val playbackSupport: Model.PlaybackSupport?,
        val playableChild: Model.PlayableChild?,
        val canFav: Boolean?,
        val isFavorite: Boolean?,
        val canRate: Boolean?,
        val userRating: Int?,
        val availableReportReasons: List<ContentReport>,
        val editUrl: String?,
        val shareUrl: String?,
        val shareQrBase64: String?,
        val isContentLoading: LoadState,
        val productsUrl: String?,
        val serialContentsTitle: TranslationModel.Model.LocalizedString?,

        val serialContent: List<SerialContent>,
        val isSerialContentLoading: LoadState,

        val relatedContent: List<RelatedContent>,
        val isRelatedContentLoading: LoadState,

        val comments: CommentModel.Model.Comments?,
        val areMoreCommentsAvailable: Boolean,
        val areCommentsLoading: LoadState,
    )

    sealed class Label {
        data class UnexpectedError(val code: String) : Label()
        data class IllegalOperationError(val reason: String) : Label()
        object UserSessionExpired : Label()
        object RequestTimedOut : Label()
        data class GameActionSent(
            val actionResult: GameModel.Model.ActionResult
        ) : Label()
        object ContentReported : Label()
    }

}

package es.cinfo.tiivii.core.content

import es.cinfo.tiivii.core.content.model.*
import es.cinfo.tiivii.core.content.model.ApiResponse.Content
import es.cinfo.tiivii.core.content.model.CommentModel.ApiResponse.Comment
import es.cinfo.tiivii.core.content.model.CommentModel.ApiResponse.Comments
import es.cinfo.tiivii.core.content.model.NextContentModel.ApiResponse.NextContent
import es.cinfo.tiivii.core.content.model.RelatedContentModel.ApiResponse.RelatedContent
import es.cinfo.tiivii.core.content.model.SerialContentModel.ApiResponse.SerialContentLoad
import es.cinfo.tiivii.core.content.model.WidgetModel.ApiResponse.WidgetContentApiLoad
import es.cinfo.tiivii.core.content.model.report.ContentReportModel.ApiResponse.ContentReport
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.network.HttpModule
import es.cinfo.tiivii.core.user.model.ApiResponse.User
import es.cinfo.tiivii.core.util.Failure
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.core.util.map
import es.cinfo.tiivii.di.diContainer
import io.ktor.client.request.*
import io.ktor.util.*
import org.kodein.di.instance

/**
 * Network datasource for content operations
 */
internal interface ContentApi {

    /**
     * Retrieves the [Content] with the given id
     * @param id of the content to retrieve
     */
    suspend fun getContent(id: Int, payload: String?, hitCache: Boolean): Outcome<Content, NetworkError>

    suspend fun getAnonContent(id: Int, payload: String?, hitCache: Boolean): Outcome<Content, NetworkError>

    suspend fun getNextContent(id: Int, filters: Map<String, String>?, ignoreList: Set<Int>?): Outcome<NextContent, NetworkError>

    /**
     * Retrieves related [Content] with the given category and tags
     * @param categoryId to which the contents should be related
     * @param tags optional to which the contents should be related
     */
    suspend fun getRelatedContents(
        contentId: Int,
        categoryId: Int,
        tags: List<String>?,
        filters: Map<String, String>?
    ): Outcome<List<RelatedContent>, NetworkError>

    /**
     * Retrieves serial [Content] related to the one with the given id
     * @param id of the [Content] to retrieve children/siblings of
     */
    suspend fun getSerialContents(
        id: Int,
        filters: Map<String, String>?,
        limit: Int,
        page: Int,
        sort: String
    ): Outcome<SerialContentLoad, NetworkError>

    /**
     * Retrieves the user comments associated with the given content
     * @param contentId id of the content to retrieve the comments of
     * @param limit number of comments to retrieve
     * @param page comment page to retrieve
     * @param sort sort method
     */
    suspend fun getComments(contentId: Int, limit: Int, page: Int, sort: String): Outcome<Comments, NetworkError>

    /**
     * Requests a new comment to be added to the related content
     * @param contentId id of the content to which a new comment should be added
     * @param text comment text to add
     */
    suspend fun addComment(contentId: Int, text: String): Outcome<Comment, NetworkError>

    /**
     * Request a comment text to be updated
     * @param id of the comment to be updated
     * @param text new comment
     */
    suspend fun updateComment(id: Int, text: String): Outcome<Comment, NetworkError>

    /**
     * Requests the given comment to be removed
     * @param id of the comment to remove
     */
    suspend fun removeComment(id: Int): Outcome<Unit, NetworkError>

    /**
     * Requests a new rate for the given content to be registered
     * @param contentId id of the content to be rated
     * @param rating int between 1 and 5 representing the rating to be registered
     * @return List of the updated [User.Rating]s of the user
     */
    suspend fun rateContent(contentId: Int, rating: Int): Outcome<List<User.Rating>, NetworkError>

    /**
     * Requests the user content rating to be deleted
     * @param contentId id of the content with the rating to be deleted
     * @return List of the updated [User.Rating]s of the user
     */
    suspend fun deleteRating(contentId: Int): Outcome<List<User.Rating>, NetworkError>

    /**
     * Requests a content report
     * @param id of the [Content] to be reported
     * @param reportId report reason id
     * @param userMessage associated report message from the user
     */
    suspend fun reportContent(id: Int, reportId: Int, userMessage: String? = null): Outcome<Unit, NetworkError>

    /**
     * Obtains the available report reasons from backend
     */
    suspend fun getContentReports(): Outcome<List<ContentReport>, NetworkError>

    /**
     * Requests the contents of the widget with the given id
     * @param id of the widget
     * @param query to filter the contents with if needed
     * @param filters to apply to the request if needed
     * @param limit number of contents per page
     * @param page to retrieve
     * @param sort method to be used on the contents
     */
    suspend fun getWidgetContents(
        id: Int,
        query: String?,
        filters: Map<String, String>?,
        limit: Int,
        page: Int,
        sort: String,
        username: String?
    ): Outcome<WidgetContentApiLoad, NetworkError>

    /**
     * Same as [getWidgetContents] but with no sort, page, limit or query params for home screen to allow
     * server side caching
     */
    suspend fun getHomeWidgetContents(
        id: Int,
        filters: Map<String, String>?,
        username: String?
    ): Outcome<WidgetContentApiLoad, NetworkError>

    suspend fun getHomeWidgetContents(
        ids: List<Int>,
        filters: Map<String, String>?,
        username: String?
    ): Outcome<List<WidgetContentApiLoad>, NetworkError>

    suspend fun publishContent(
        payload: String
    ): Outcome<ApiResponse.ContentPublish, NetworkError>
}

/**
 * Default implementation of [ContentApi] using Ktor
 */
internal class DefaultContentApi : ContentApi {
    private val http: HttpModule by diContainer.instance()

    private val baseEndpoint: String by lazy {
        val configModule: ConfigModule by diContainer.instance()
        "${configModule.getEnvConfig().backendUrl}/sgca/${configModule.getEnvConfig().apiName}"
    }

    override suspend fun getContent(id: Int, payload: String?, hitCache: Boolean): Outcome<Content, NetworkError> {
        var endpoint = "$baseEndpoint/contents/$id"
        if (payload != null) {
           endpoint += "?payload=$payload"
        }
        return http.getAsOutcome(endpoint = endpoint) {
            if (!hitCache) {
                header("Cache-Control", "no-cache")
            }
        }
    }

    override suspend fun getAnonContent(id: Int, payload: String?, hitCache: Boolean): Outcome<Content, NetworkError> {
        var endpoint = "$baseEndpoint/anon-contents/$id"
        if (payload != null) {
            endpoint += "?payload=$payload"
        }
        return http.getAsOutcome(endpoint = endpoint) {
            if (!hitCache) {
                header("Cache-Control", "no-cache")
            }
        }
    }

    override suspend fun getNextContent(id: Int, filters: Map<String, String>?, ignoreList: Set<Int>?): Outcome<NextContent, NetworkError> {
        var endpoint = "$baseEndpoint/contents-next/$id?filter[status]=published"
        filters?.forEach {
            endpoint += "&filter${it.key}=${it.value}"
        }
        if (!ignoreList.isNullOrEmpty()) {
            val ignoreString = ignoreList.joinToString(separator = ",", prefix = "", postfix = "")
            endpoint += "&ignore=$ignoreString"
        }
        return http.getAsOutcome(endpoint = endpoint)
    }

    override suspend fun getRelatedContents(
        contentId: Int,
        categoryId: Int,
        tags: List<String>?,
        filters: Map<String, String>?
    ): Outcome<List<RelatedContent>, NetworkError> {
        var endpoint = "$baseEndpoint/contents-related?category=$categoryId&filter[status]=published&filter[id][neq]=$contentId"
        if (tags != null && tags.isNotEmpty()) {
            endpoint = "$endpoint&tags="
            tags.forEach {
                endpoint = "$endpoint$it,"
            }
            endpoint = endpoint.removeSuffix(",")
        }
        filters?.forEach {
            endpoint += "&filter${it.key}=${it.value}"
        }
        return http.getAsOutcome(endpoint = endpoint, responseField = "collection")
    }

    override suspend fun getSerialContents(
        id: Int,
        filters: Map<String, String>?,
        limit: Int,
        page: Int,
        sort: String
    ): Outcome<SerialContentLoad, NetworkError> {
        var endpoint = "$baseEndpoint/contents-items/${id}?limit=$limit&page=$page&sort=$sort&filter[status]=published"
        filters?.forEach {
            endpoint += "&filter${it.key}=${it.value}"
        }
        return http.getAsOutcome(endpoint = endpoint)
    }

    override suspend fun getComments(
        contentId: Int,
        limit: Int,
        page: Int,
        sort: String,
    ): Outcome<Comments, NetworkError> {
        val endpoint = "$baseEndpoint/comments/content/$contentId?limit=$limit&page=$page&sort=$sort"
        return http.getAsOutcome(endpoint = endpoint) {
            header("Cache-Control", "no-cache")
        }
    }

    override suspend fun addComment(contentId: Int, text: String): Outcome<Comment, NetworkError> {
        val endpoint = "$baseEndpoint/comments"
        return http.postAsOutcome(endpoint = endpoint) {
            header("Content-Type", "application/json")
            body = AddCommentApiRequest(text, contentId)
        }
    }

    override suspend fun updateComment(id: Int, text: String): Outcome<Comment, NetworkError> {
        val endpoint = "$baseEndpoint/comments/$id"
        return http.patchAsOutcome(endpoint = endpoint) {
            header("Content-Type", "application/json")
            body = UpdateCommentApiRequest(text)
        }
    }

    override suspend fun removeComment(id: Int): Outcome<Unit, NetworkError> {
        val endpoint = "$baseEndpoint/comments/$id"
        return http.deleteOrError(endpoint = endpoint)
    }

    override suspend fun rateContent(
        contentId: Int,
        rating: Int
    ): Outcome<List<User.Rating>, NetworkError> {
        val endpoint = "$baseEndpoint/rate"
        return http.postAsOutcome<User>(endpoint = endpoint) {
            header("Content-Type", "application/json")
            body = RateApiRequest(rating, contentId)
        }.map {
            it.ratings ?: emptyList()
        }
    }

    override suspend fun deleteRating(contentId: Int): Outcome<List<User.Rating>, NetworkError> {
        val endpoint = "$baseEndpoint/rate?contentid=$contentId"
        return http.deleteAsOutcome<User>(endpoint = endpoint)
            .map {
                it.ratings ?: emptyList()
            }
    }

    override suspend fun reportContent(id: Int, reportId: Int, userMessage: String?): Outcome<Unit, NetworkError> {
        val endpoint = "$baseEndpoint/contents-report/$id"
        return http.postAsOutcome(endpoint = endpoint) {
            header("Content-Type", "application/json")
            body = ReportContent(reportId, userMessage)
        }
    }

    override suspend fun getContentReports(): Outcome<List<ContentReport>, NetworkError> {
        val endpoint = "$baseEndpoint/report-types"
        return http.getAsOutcome(endpoint = endpoint, responseField = "data")
    }

    override suspend fun getWidgetContents(
        id: Int,
        query: String?,
        filters: Map<String, String>?,
        limit: Int,
        page: Int,
        sort: String,
        username: String?
    ): Outcome<WidgetContentApiLoad, NetworkError> {
        var endpoint = "$baseEndpoint/widgets/$id/content?"
        if (!username.isNullOrBlank()) {
            endpoint += "username=$username&"
        }
        endpoint += "limit=$limit&page=$page&sort=$sort&filter[status]=published"
        if (query != null) {
            endpoint += "&filter[title][contains]=$query"
        }
        filters?.forEach {
            endpoint += "&filter${it.key}=${it.value}"
        }
        return http.getAsOutcome(endpoint = endpoint)
    }

    override suspend fun getHomeWidgetContents(
        id: Int,
        filters: Map<String, String>?,
        username: String?
    ): Outcome<WidgetContentApiLoad, NetworkError> {
        var endpoint = "$baseEndpoint/widgets/$id/content?"
        if (!username.isNullOrBlank()) {
            endpoint += "username=$username&"
        }
        endpoint += "filter[status]=published"
        filters?.forEach {
            endpoint += "&filter${it.key}=${it.value}"
        }
        return http.getAsOutcome(endpoint = endpoint) {
            if (username == null) {
                setAttributes {
                    put(AttributeKey("authRequired"), false)
                }
            }
        }
    }

    override suspend fun getHomeWidgetContents(
        ids: List<Int>,
        filters: Map<String, String>?,
        username: String?
    ): Outcome<List<WidgetContentApiLoad>, NetworkError> {
        val parsedIds = ids.joinToString(separator = ",")
        var endpoint = "$baseEndpoint/widgets/$parsedIds/content?"
        if (!username.isNullOrBlank()) {
            endpoint += "username=$username&"
        }
        endpoint += "filter[status]=published"
        filters?.forEach {
            endpoint += "&filter${it.key}=${it.value}"
        }
        return http.getAsOutcome(endpoint = endpoint) {
            if (username == null) {
                setAttributes {
                    put(AttributeKey("authRequired"), false)
                }
            }
        }
    }

    override suspend fun publishContent(payload: String): Outcome<ApiResponse.ContentPublish, NetworkError> {
        val endpoint = "$baseEndpoint/contents-confirm?payload=$payload"
        var outcome: Outcome<ApiResponse.ContentPublish, NetworkError> = http.getAsOutcome(endpoint = endpoint)
        if (outcome is Failure) {
            val httpStatusCode = outcome.error.getHttpStatusCode()
            // Retry once
            if (httpStatusCode in 500..599) {
                outcome = http.getAsOutcome(endpoint = endpoint)
            }
        }
        return outcome
    }

}
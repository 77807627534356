package es.cinfo.tiivii.core.modules.game.model

import es.cinfo.tiivii.core.error.NetworkError

object GameActions {
    const val LOGIN = "login"
    const val FAV = "favorito"
    const val COMMENT = "comment"
    const val CONTENT_START = "on-start"
    const val CONTENT_FINISH = "on-finish"
    const val CORRECT_ANSWER = "on-correct-node"
}

internal data class GameActionRequest(
    val hasDefaultFailed: NetworkError? = null,
    val hasInGameFailed: NetworkError? = null,
    val hasGameBunchFailed: NetworkError? = null,
) {
    fun hasFailed(): Boolean = hasDefaultFailed != null || hasInGameFailed != null || hasGameBunchFailed != null
}
package es.cinfo.tiivii.core.features.notifications.store

import com.arkivanov.mvikotlin.core.store.Store
import es.cinfo.tiivii.core.features.notifications.view.NotificationsView
import es.cinfo.tiivii.core.notifications.NotificationsModel
import es.cinfo.tiivii.core.util.LoadingModel.Model.LoadState

internal interface NotificationsStore :
    Store<NotificationsStore.Intent, NotificationsStore.State, NotificationsStore.Label> {

    sealed class Action {
        object LoadNotifications : Action()
    }

    sealed class Intent {
        object ReloadNotifications : Intent()
        data class DeleteNotification(val id: Int): Intent()
        data class ReadNotification(val id: Int): Intent()
    }

    data class State(
        val numNotifications: Int,
        val notifications: List<NotificationsModel.Model.Notification>,
        val loadingStats: LoadState
    )

    sealed class Label {
        object UserSessionExpired : Label()
        object RequestTimedOut : Label()
        data class UnexpectedError(val code: String) : Label()
        data class IllegalOperationError(val reason: String) : Label()
    }
}
package es.cinfo.tiivii.user.legal.store

import com.arkivanov.mvikotlin.core.store.Store
import es.cinfo.tiivii.core.util.LoadingModel
import es.cinfo.tiivii.user.legal.store.LegalStore.*

internal interface LegalStore :
    Store<Intent, State, Label> {

    sealed class Action {
        object GetLegalText : Action()
    }

    sealed class Intent {
        data class AcceptConditions(val isDataRecollectionAllowed: Boolean) : Intent()
        object RejectConditions : Intent()
        data class ReloadLegalConditions(val language: String): Intent()
    }

    data class State(
        val legalText: String?,
        val legalTextLanguage: String?,
        val loadingLegalText: LoadingModel.Model.LoadState,
    )

    sealed class Label {
        object AcceptedLegalConditions: Label()
        data class UnexpectedError(val code: String) : Label()
        object UserSessionExpired : Label()
        object RequestTimedOut : Label()
        data class IllegalOperationError(val reason: String) : Label()
    }

}
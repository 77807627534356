package es.cinfo.tiivii.user.splash

import com.arkivanov.mvikotlin.core.binder.BinderLifecycleMode
import com.arkivanov.mvikotlin.core.lifecycle.LifecycleRegistry
import com.arkivanov.mvikotlin.core.lifecycle.destroy
import com.arkivanov.mvikotlin.core.lifecycle.resume
import com.arkivanov.mvikotlin.extensions.reaktive.bind
import com.arkivanov.mvikotlin.extensions.reaktive.events
import com.arkivanov.mvikotlin.extensions.reaktive.labels
import com.arkivanov.mvikotlin.extensions.reaktive.states
import com.arkivanov.mvikotlin.logging.logger.DefaultLogFormatter
import com.arkivanov.mvikotlin.logging.store.LoggingStoreFactory
import com.arkivanov.mvikotlin.main.store.DefaultStoreFactory
import com.badoo.reaktive.observable.distinctUntilChanged
import com.badoo.reaktive.observable.mapNotNull
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.util.View
import es.cinfo.tiivii.di.diContainer
import es.cinfo.tiivii.user.splash.binder.EVENT_TO_INTENT
import es.cinfo.tiivii.user.splash.binder.LABEL_TO_OUTPUT
import es.cinfo.tiivii.user.splash.binder.STATE_TO_MODEL
import es.cinfo.tiivii.user.splash.view.SplashView
import es.cinfo.tiivii.user.splash.view.SplashView.*
import org.kodein.di.instance
import es.cinfo.tiivii.user.splash.store.SplashStore as Store
import es.cinfo.tiivii.user.splash.store.SplashStoreFactory as StoreFactory

/**
 * Controller for the core components related to the splash screen
 *
 * ## Description
 * This class allows for different operations to access the core functionalities implements to support
 * the splash screen of the app
 *
 * ## Usage
 * ### bind()
 * On the first [bind] call the internal core components will be built and a new store for the splash will be
 * created. After this, subsequent calls to
 * [bind] will allow for more bindings to be created to the same store
 *
 * ### unbind()
 * When [unbind] is called the controller will unbind the previously given references to the [bind] function
 * avoiding that any new notifications and update from the core to be received. Also de controller will check
 * if no more bindings are available and, in that case, the internal store will be disposed and all the state
 * associated cleared
 *
 * ### dispatch()
 * The [dispatch] function allows the UI to send [SplashView.Event]'s to the core
 * in order to initiate operations. The results of these operations will be received in the bind references
 * through the [bind] function
 */
class SplashController {
    private var store: Store? = null
    private val bindings = mutableListOf<Binding>()

    /**
     * Last [SplashView.Model] emitted by the core or null if no model has been
     * emitted
     */
    val model: Model?
        get() = bindings.firstOrNull()?.view?.currentModel

    /**
     * Function that allows the UI to send [SplashView.Event] to the core
     * @param event to be send to the core
     */
    @JsName("dispatch")
    fun dispatch(event: Event) {
        bindings.firstOrNull()?.view?.dispatch(event)
    }

    /**
     * Binds the given functions to the core which will allow receiving notifications of the
     * [SplashView.Model] and [SplashView.Output]'s
     * @param modelHandler function that will be executed when a new model is published by the core
     * @param outputHandler function that will be executed when a new notification is published by the core
     * @return An id that identifies the bind made that should be used to unbind the references through [unbind]
     */
    @JsName("bind")
    fun bind(
        modelHandler: (Model) -> Unit,
        outputHandler: (Output) -> Unit,
    ): String {
        if (store == null) {
            store = buildStore()
        }
        return buildBinding(modelHandler, outputHandler)
    }

    /**
     * Unbinds the bind identified by the given id, avoiding new core notifications and updates to be received
     * to that references
     * @param id of the binding to be destroyed
     */
    @JsName("unbind")
    fun unbind(id: String) {
        val binding = bindings.find { it.id == id }
        binding?.let {
            destroyBinding(it.id)
        }
        if (bindings.isEmpty()) {
            destroyStore()
        }
    }

    private fun buildStore(): Store {
        val configModule: ConfigModule by diContainer.instance()
        return if (configModule.getEnvConfig().loggingEnabled) {
            StoreFactory(
                storeFactory = LoggingStoreFactory(
                    DefaultStoreFactory,
                    logFormatter = DefaultLogFormatter(10_000)
                )
            ).create()
        } else {
            StoreFactory(storeFactory = DefaultStoreFactory).create()
        }
    }

    private fun destroyStore() {
        store?.dispose()
        store = null
    }

    private fun buildBinding(
        updateModel: (Model) -> Unit,
        handleOutput: (Output) -> Unit,
    ): String {
        val binding = Binding(
            "bindings-${bindings.size}",
            store!!,
            View(updateModel, handleOutput)
        )
        bindings.add(binding)
        return binding.id
    }

    private fun destroyBinding(id: String) {
        val binding = bindings.find { it.id == id }
        binding?.let {
            it.destroy()
            bindings.remove(it)
        }
    }

    internal data class Binding (
        val id: String,
        val store: Store,
        val view : View<Event, Model, Output>) {
        private val lifecycle = LifecycleRegistry()

        init {
            lifecycle.resume()
            bind(lifecycle, BinderLifecycleMode.RESUME_PAUSE) {
                view.events.mapNotNull(EVENT_TO_INTENT) bindTo store
                store.states.mapNotNull(STATE_TO_MODEL).distinctUntilChanged() bindTo view
                store.labels.mapNotNull(LABEL_TO_OUTPUT) bindTo view::handleOutput
            }
        }

        fun destroy() {
            lifecycle.destroy()
        }
    }
}
package es.cinfo.tiivii.core.modules.rating

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.network.HttpModule
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

internal interface RatingApi {

    suspend fun getGlobalRatings(): Outcome<RatingModel.ApiResponse.AgeRatings, NetworkError>
}

internal class DefaultRatingApi: RatingApi {
    private val http: HttpModule by diContainer.instance()

    private val baseEndpoint: String by lazy {
        val configModule: ConfigModule by diContainer.instance()
        "${configModule.getEnvConfig().backendUrl}/sgca/${configModule.getEnvConfig().apiName}"
    }

    override suspend fun getGlobalRatings(): Outcome<RatingModel.ApiResponse.AgeRatings, NetworkError> {
        val endpoint = "$baseEndpoint/ratings"
        return http.getAsOutcome(endpoint = endpoint)
    }
}
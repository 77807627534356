package es.cinfo.tiivii.search.store

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.SuspendExecutor
import es.cinfo.tiivii.core.error.ErrorService
import es.cinfo.tiivii.core.modules.analytics.LogEvent
import es.cinfo.tiivii.core.modules.analytics.model.AnalyticsModel
import es.cinfo.tiivii.core.modules.analytics.model.AnalyticsModel.Action.PageView.SEARCH_VALUE
import es.cinfo.tiivii.core.util.Failure
import es.cinfo.tiivii.core.util.LoadingModel
import es.cinfo.tiivii.core.util.Success
import es.cinfo.tiivii.di.diContainer
import es.cinfo.tiivii.search.model.Search.Model.SearchResult
import es.cinfo.tiivii.search.store.SearchStore.*
import es.cinfo.tiivii.search.usecase.GetSuggestions
import es.cinfo.tiivii.search.usecase.PerformSearch
import org.kodein.di.instance

internal class SearchStoreFactory(
    private val storeFactory: StoreFactory,
) {

    private val errorService: ErrorService by diContainer.instance()

    private sealed class Result {
        object LoadingSuggestions : Result()
        data class SuggestionsLoaded(val suggestions: SearchResult) : Result()
        object SuggestionsCleared : Result()
        object ErrorLoadingSuggestions : Result()

        object Searching : Result()
        data class SearchSuccessful(val searchResult: SearchResult) : Result()
        object ErrorSearching : Result()
        object SearchCleared : Result()
    }

    fun create(): SearchStore =
        object :
            SearchStore,
            Store<Intent, State, Label> by
            storeFactory.create(
                name = "SearchStore",
                initialState = State(
                    suggestionsLoading = LoadingModel.Model.LoadState.RESET,
                    suggestions = emptyList(),
                    searchResults = emptyList(),
                    searchLoading = LoadingModel.Model.LoadState.RESET
                ),
                reducer = DefaultReducer,
                executorFactory = ::DefaultExecutor
            ) {

        }

    private object DefaultReducer : Reducer<State, Result> {
        override fun State.reduce(result: Result): State =
            when (result) {
                is Result.SearchSuccessful -> copy(
                    searchLoading = LoadingModel.Model.LoadState.LOADED,
                    searchResults = result.searchResult.searchResultEntries
                )
                Result.SearchCleared -> copy(
                    searchLoading = LoadingModel.Model.LoadState.RESET,
                    searchResults = emptyList()
                )
                Result.Searching -> copy(
                    searchLoading = LoadingModel.Model.LoadState.LOADING
                )
                is Result.ErrorSearching -> copy(
                    searchLoading = LoadingModel.Model.LoadState.RESET
                )
                Result.ErrorLoadingSuggestions -> copy(
                    suggestionsLoading = LoadingModel.Model.LoadState.RESET
                )
                Result.LoadingSuggestions -> copy(
                    suggestionsLoading = LoadingModel.Model.LoadState.LOADING
                )
                Result.SuggestionsCleared -> copy(
                    suggestionsLoading = LoadingModel.Model.LoadState.RESET,
                    suggestions = emptyList()
                )
                is Result.SuggestionsLoaded -> copy(
                    suggestionsLoading = LoadingModel.Model.LoadState.LOADED,
                    suggestions = result.suggestions.searchResultEntries
                )
            }
    }

    private inner class DefaultExecutor : SuspendExecutor<Intent, Nothing, State, Result, Label>() {
        override suspend fun executeIntent(intent: Intent, getState: () -> State) {
            when (intent) {
                is Intent.GetSuggestions -> getSuggestions(intent.searchQuery)
                Intent.ClearSuggestions -> clearSuggestions()
                is Intent.PerformSearch -> performSearch(intent.searchQuery)
                Intent.ClearSearch -> clearSearch()
                Intent.LogSearchView -> logSearchView()
                is Intent.LogSelectItem -> logSelectItem(intent.contentId)
            }
        }

        private suspend fun logSelectItem(contentId: Int) {
            LogEvent(
                action = AnalyticsModel.Action.SelectItem,
                contentId = contentId
            ).invoke()
        }

        private suspend fun logSearchView() {
            LogEvent(
                action = AnalyticsModel.Action.PageView,
                keyValue = AnalyticsModel.Action.PageView.PAGE_ID_KEY to SEARCH_VALUE
            ).invoke()
        }

        private suspend fun performSearch(searchQuery: String) {
            dispatch(Result.Searching)
            when (val outcome = PerformSearch(searchQuery).invoke()) {
                is Success -> {
                    dispatch(Result.SearchSuccessful(outcome.value))
                }
                is Failure -> {
                    dispatch(Result.ErrorSearching)
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = {
                            publish(Label.UserSessionExpired)
                        },
                        requestTimeoutAction = {
                            publish(Label.RequestTimeOut)
                        },
                        handler = {
                            when (outcome.error) {
                                PerformSearch.Error.MissingSearchChars -> { }
                                is PerformSearch.Error.SearchUnavailable -> {
                                    publish(Label.UnexpectedError(outcome.error.getCode()))
                                }
                            }
                        }
                    )
                }
            }
        }

        private suspend fun getSuggestions(searchQuery: String) {
            if (searchQuery.isNotBlank()) {
                dispatch(Result.LoadingSuggestions)
                when (val outcome = GetSuggestions(searchQuery).invoke()) {
                    is Success -> {
                        dispatch(Result.SuggestionsLoaded(outcome.value))
                    }
                    is Failure -> {
                        dispatch(Result.ErrorLoadingSuggestions)
                        errorService.handleError(
                            error = outcome.error,
                            sessionExpiredAction = {
                                publish(Label.UserSessionExpired)
                            },
                            requestTimeoutAction = {
                                publish(Label.RequestTimeOut)
                            },
                            handler = {
                                when (outcome.error) {
                                    is GetSuggestions.Error.SuggestionsUnavailable ->
                                        publish(Label.UnexpectedError(outcome.error.getCode()))
                                }
                            }
                        )
                    }
                }
            } else {
                clearSuggestions()
            }
        }

        private fun clearSearch() {
            dispatch(Result.SearchCleared)
        }

        private fun clearSuggestions() {
            dispatch(Result.SuggestionsCleared)
        }
    }
}
package es.cinfo.tiivii.core.modules.config

import es.cinfo.tiivii.core.modules.config.ConfigModel.Model.ContentConfig.RefreshUserValuesType.*
import es.cinfo.tiivii.core.modules.config.ConfigModel.ViewModel.SignupConfig.Mode
import es.cinfo.tiivii.core.modules.platform.PLATFORM_ID
import es.cinfo.tiivii.core.modules.platform.PlatformModel
import es.cinfo.tiivii.core.profile.ProfileModel
import es.cinfo.tiivii.core.sorting.SortModel
import es.cinfo.tiivii.core.sorting.SortModel.Model.Sort.Companion.fromId
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.SerializationException
import kotlin.js.JsName
import kotlin.math.max
import kotlin.math.min

class ConfigModel {
    sealed class ViewModel {
        /**
         * Object containing core config values established at compilation time
         */
        data class CoreConfig(
            /**
             * Contains config values related with the signup & user process
             */
            val signup: SignupConfig,
            /**
             * Contains config values related with content information & processes
             */
            val content: ContentConfig,
            /**
             * Contains support and utility related config values
             */
            val support: SupportConfig,
            /**
             * Contains config values related with legal conditions
             */
            val legal: LegalConfig,
            /**
             * Contains config values related with the gamification module
             */
            val gamification: GamificationConfig,
            /**
             * Contains config values related with the gamification module
             */
            val user: UserConfig
        )

        data class SignupConfig(
            /**
             * Indicates the signup mode to be used. One of
             * [Mode.Simple], [Mode.ByAge]
             */
            val mode: Mode,
            /**
             * Indicates the list of available user [ProfileModel.ViewModel.Profile]
             */
            val availableProfiles: List<ProfileModel.ViewModel.Profile>,
            /**
             * Indicates user [ProfileModel.ViewModel.Profile] to be used as default
             */
            val defaultProfile: ProfileModel.ViewModel.Profile,
            /**
             * Indicates the user default language as an iso code
             */
            val defaultLanguage: String,
            /**
             * Indicates the list of available languages for the application
             */
            val availableLanguages: List<String>,
            /**
             * Indicates if the on-boarding process should be shown
             */
            val enableOnBoarding: Boolean,
            /**
             * Indicates if the android app must start logged as a Guest
             */
            val startAsGuest: Boolean
        ) {
            sealed class Mode {
                /**
                 * Indicates that a simple standard sign-up process (user/pass/email)
                 * @param defaultBirthday indicates the default birthday that will be used to register all users
                 */
                data class Simple(val defaultBirthday: String): Mode() {
                    @JsName("name")
                    fun name() = Simple::class.simpleName
                }

                /**
                 * Indicates that a more complex user birthday based sign-up process must be used
                 */
                object ByAge: Mode() {
                    @JsName("name")
                    fun name() = ByAge::class.simpleName
                }
            }
        }

        data class ContentConfig(
            /**
             * Indicates if the product buyout operations are enabled
             */
            val productBuyoutEnabled: Boolean,
            /**
             * The list of available sort methods for the catalogue page
             */
            val orders: List<SortModel.ViewModel.Sort>,
            /**
             * Indicates if the comments, related operations and on-screen widgets should be enabled/displayed
             */
            val enableComments: Boolean,
            /**
             * Indicates if the ratings, related operations and on-screen widgets should be enabled/displayed
             */
            val enableRatings: Boolean,
            /**
             * Indicates if the content type icons on the home screen widget should be shown
             */
            val showContentTypeIcons: Boolean,
            /**
             * Indicates if the content reports should be enabled
             */
            val enableReports: Boolean,
            /**
             * Indicates if the season button (aka the parent button of a content) should be shown to
             * link to the parent season of a content
             */
            val showSeasonButton: Boolean,
            /**
             * Indicates if the author of a content should be displayed
             */
            val showAuthor: Boolean,
            /**
             * Indicates if the publish date of a content should be displayed
             */
            val showPublishDate: Boolean,
            /**
             * Indicates if the splash video should be displayed
             */
            val showSplashVideo: Boolean
        )

        /**
         *
         */
        data class SupportConfig(
            /**
             * Indicates the support email for troubleshooting contact if any
             */
            val supportEmail: String?,
            /**
             * Indicates the FAQ url if any
             */
            val faqUrl: String?,
            /**
             * Indicates the tutorial url if any
             */
            val tutorialUrl: String?,
            /**
             * Indicates if the app related links, buttons, modals, etc should be displayed to the user
             * on the web platform
             */
            val showAppsUrls: Boolean
        )

        data class LegalConfig(
            /**
             * Indicates if all legal related operations or texts should be enabled
             */
            val enabled: Boolean
        )

        data class GamificationConfig(
            /**
             * Indicates if all gamification related operations and screen should be enabled
             */
            val enabled: Boolean
        )

        data class UserConfig(
            /**
             * Indicates if the user avatars should be displayed around the different screens of the app
             */
            val showAvatars: Boolean,
            /**
             * Indicates the profile button position (only applies to apps)
             */
            val profileButtonPosition: ProfileButtonPosition
        ) {
            sealed class ProfileButtonPosition {
                /**
                 * Profile button should be positioned on the bottom bar with the other sections
                 */
                object BottomBar: ProfileButtonPosition()

                /**
                 * Profile button should be positioned on the top navigation bar
                 */
                object TopBar: ProfileButtonPosition()
            }
        }

        /**
         * Environment configuration values
         */
        data class EnvConfig(
            /**
             * Tenant identifier of the current build
             */
            val tenant: String,
            /**
             * Env identifier of the current build
             */
            val env: String,
            /**
             * Administration url if any is established (Studio url)
             */
            val adminUrl: String?,
            /**
             * Indicates if app logs are enabled
             */
            val loggingEnabled: Boolean,
            /**
             * Firebase dynamic link url that may be used for dynamic url link creation/handling
             */
            val firebaseDynamicLinkUrl: String,
            /**
             * Firebase dynamic link host that may be used for dynamic url link creation/handling
             */
            val firebaseDynamicLinkHost: String,
            /**
             * The keycloak configuration uri for authentication related operations
             */
            val keycloakConfUri: String,
            /**
             * The keycloak user info uri for authentication related operations
             */
            val keycloakUserInfoUri: String,
            /**
             * The keycloak base uri for authentication related operations
             */
            val keycloakBaseUri: String,
            /**
             * The keycloak realm for authentication related operations
             */
            val keycloakRealm: String,
            /**
             * The keycloak clientId for authentication related operations
             */
            val keycloakClientId: String,
        )
    }
    internal sealed class Model {
        data class EnvConfig(
            val tenant: String,
            val env: String,
            val name: String,
            val realm: String,
            val apiName: String,
            val backendUrl: String,
            val frontendUrl: String,
            val adminUrl: String?,
            val secret: String,
            val loggingEnabled: Boolean,
            val firebaseDynamicLinkUrl: String,
            val firebaseDynamicLinkHost: String,
            val firebaseApiKey: String,
            val androidAppId: String,
            val androidAppUrl: String?,
            val iosAppId: String,
            val iosBundleId: String,
            val iosAppUrl: String?,
            val keycloakConfUri: String,
            val keycloakTokenUri: String,
            val keycloakUserInfoUri: String,
            val keycloakLogoutUri: String,
            val keycloakBaseUri: String,
            val keycloakClientId: String) {
            fun toViewModel(): ViewModel.EnvConfig {
                return ViewModel.EnvConfig(
                    tenant = tenant,
                    env = env,
                    adminUrl = adminUrl,
                    loggingEnabled = loggingEnabled,
                    firebaseDynamicLinkUrl = firebaseDynamicLinkUrl,
                    firebaseDynamicLinkHost = firebaseDynamicLinkHost,
                    keycloakBaseUri = keycloakBaseUri,
                    keycloakConfUri = keycloakConfUri,
                    keycloakUserInfoUri = keycloakUserInfoUri,
                    keycloakClientId = keycloakClientId,
                    keycloakRealm = realm
                )
            }
        }
        data class CoreConfig(
            val signup: SignupConfig,
            val content: ContentConfig,
            val support: SupportConfig,
            val legal: LegalConfig,
            val gamification: GamificationConfig,
            val user: UserConfig
        ) {
            fun toViewModel(): ViewModel.CoreConfig {
                return ViewModel.CoreConfig(
                    signup = signup.toViewModel(),
                    content = content.toViewModel(),
                    support = support.toViewModel(),
                    legal = legal.toViewModel(),
                    gamification = gamification.toViewModel(),
                    user = user.toViewModel()
                )
            }
        }

        data class SignupConfig(
            val mode: Mode,
            val availableProfiles: List<ProfileModel.Model.Profile>,
            val defaultProfile: ProfileModel.Model.Profile,
            val defaultLanguage: String,
            val availableLanguages: List<String>,
            val enableOnBoarding: Boolean,
            val startAsGuest: Boolean
        ) {
            sealed class Mode {
                data class Simple(val defaultBirthday: String): Mode()
                object ByAge: Mode()

                companion object {
                    fun parse(value: Json.SignupConfig.Mode): Mode {
                        return when (value.id) {
                            "simple" -> {
                                val dateRegex = "^\\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])\$".toRegex()
                                val validDate: Boolean = if (value.defaultBirthday != null) {
                                    dateRegex.matches(value.defaultBirthday)
                                } else {
                                    false
                                }
                                if (!validDate) {
                                    throw SerializationException("Default birthday date for simple signup mode missing or unrecognized: ${value.defaultBirthday} (Format should be yyyy-mm-dd)")
                                } else {
                                    Simple(value.defaultBirthday!!)
                                }
                            }
                            "byAge" -> ByAge
                            else ->
                                throw SerializationException("Unrecognized signup mode: ${value.id} (recognized modes: [simple, byAge])")
                        }
                    }
                }

                fun toViewModel(): ViewModel.SignupConfig.Mode {
                    return when (this) {
                        ByAge -> ViewModel.SignupConfig.Mode.ByAge
                        is Simple -> ViewModel.SignupConfig.Mode.Simple(defaultBirthday)
                    }
                }

            }

            fun toViewModel(): ViewModel.SignupConfig {
                return ViewModel.SignupConfig(
                    mode = mode.toViewModel(),
                    availableProfiles = availableProfiles.map { it.toViewModel() },
                    defaultProfile = defaultProfile.toViewModel(),
                    defaultLanguage = defaultLanguage,
                    availableLanguages = availableLanguages,
                    enableOnBoarding = enableOnBoarding,
                    startAsGuest = startAsGuest
                )
            }
        }

        data class ContentConfig(
            val interestFilterEnabled: Boolean,
            val containerFilterEnabled: Boolean,
            val productBuyoutEnabled: Boolean,
            val maxGuestAgeRating: Int?,
            val orders: List<SortModel.Model.Sort>,
            val detailImageType: es.cinfo.tiivii.core.image.Model.Image.Type,
            val enableComments: Boolean,
            val enableRatings: Boolean,
            val showContentTypeIcons: Boolean,
            val defaultImageQuality: Int,
            val enableReports: Boolean,
            val refreshUserValuesOnContentLoad: RefreshUserValuesType,
            val shuffleRelatedContent: Boolean,
            val showSeasonButton: Boolean,
            val showAuthor: Boolean,
            val showPublishDate: Boolean,
            val showSplashVideo: Boolean) {
            /**
             * Indicates when the user values should be refreshed
             * [ALWAYS] user content related values will always be updated when a content is loaded
             * [NEVER] user content related values will never be updated once loaded
             * [ON_CONTENT_FIRST_LOAD] user content related values will be updated on first content load from backend
             * [ON_CONTENT_REFRESHED] user content related values will be updated only when the content has expired on cache and needs update
             */
            enum class RefreshUserValuesType {
                ALWAYS,
                NEVER,
                ON_CONTENT_FIRST_LOAD,
                ON_CONTENT_REFRESHED;

                companion object {
                    fun parse(value: String): RefreshUserValuesType {
                        return when (value) {
                            "always" -> ALWAYS
                            "never" -> NEVER
                            "on-content-first-load" -> ON_CONTENT_FIRST_LOAD
                            "on-content-refreshed" -> ON_CONTENT_REFRESHED
                            else -> throw SerializationException("Unrecognized refresh user content related values $value")
                        }
                    }
                }
            }

            fun toViewModel(): ViewModel.ContentConfig {
                return ViewModel.ContentConfig(
                    productBuyoutEnabled = productBuyoutEnabled,
                    orders = orders.map { it.toViewModel() },
                    enableComments = enableComments,
                    enableRatings = enableRatings,
                    showContentTypeIcons = showContentTypeIcons,
                    enableReports = enableReports,
                    showSeasonButton = showSeasonButton,
                    showAuthor = showAuthor,
                    showPublishDate = showPublishDate,
                    showSplashVideo = showSplashVideo
                )
            }

        }

        data class SupportConfig(
            val supportEmail: String?,
            val faqUrl: String?,
            val tutorialUrl: String?,
            val showAppsUrls: Boolean
        ) {
            fun toViewModel(): ViewModel.SupportConfig {
                return ViewModel.SupportConfig(
                    supportEmail = supportEmail,
                    tutorialUrl = tutorialUrl,
                    faqUrl = faqUrl,
                    showAppsUrls = showAppsUrls
                )
            }
        }

        data class LegalConfig(
            val enabled: Boolean
        ) {
            fun toViewModel(): ViewModel.LegalConfig {
                return ViewModel.LegalConfig(
                    enabled = enabled
                )
            }
        }

        data class GamificationConfig(
            val enabled: Boolean
        ) {
            fun toViewModel(): ViewModel.GamificationConfig {
                return ViewModel.GamificationConfig(
                    enabled = enabled
                )
            }
        }

        data class UserConfig(
            val showAvatars: PlatformEnableMap,
            val profileButtonPosition: String
        ) {
            fun toViewModel(): ViewModel.UserConfig {
                val showAvatarsEnabled = when (PLATFORM_ID) {
                    PlatformModel.Model.Platform.ANDROID -> showAvatars.android
                    PlatformModel.Model.Platform.ANDROID_TV -> showAvatars.androidTv
                    PlatformModel.Model.Platform.WEB -> showAvatars.web
                    PlatformModel.Model.Platform.IOS -> showAvatars.ios
                }
                val profileButtonPosition = when (profileButtonPosition) {
                    "bottom-bar" -> ViewModel.UserConfig.ProfileButtonPosition.BottomBar
                    "top-bar" -> ViewModel.UserConfig.ProfileButtonPosition.TopBar
                    else -> throw SerializationException("Unrecognized profileButtonPosition $profileButtonPosition")
                }
                return ViewModel.UserConfig(
                    showAvatars = showAvatarsEnabled,
                    profileButtonPosition = profileButtonPosition
                )
            }
        }

        data class PlatformEnableMap(
            val web: Boolean,
            val android: Boolean,
            val androidTv: Boolean,
            val ios: Boolean
        )
    }

    internal sealed class Json {
        @Serializable
        data class Build(
            @SerialName(TENANT_PARAM)
            val tenant: String,
            @SerialName(CORE_PARAM)
            val core: CoreBuild,
            @SerialName(ANDROID_PARAM)
            val android: EnvConfig.AndroidBuild,
            @SerialName(IOS_PARAM)
            val ios: EnvConfig.IosBuild
        ) {
            companion object {
                const val TENANT_PARAM = "tenant"
                const val CORE_PARAM = "core"
                const val ANDROID_PARAM = "android"
                const val IOS_PARAM = "ios"
            }

            fun toModel(env: String): Model.EnvConfig {
                val envConf = core.envs.firstOrNull { it.name.lowercase() == env.lowercase() }
                    ?: throw RuntimeException("EnvConfig not found for ${env}, available tenant envs: ${core.envs.map { it.name }}")
                return Model.EnvConfig(
                    tenant = tenant,
                    env = env.lowercase(),
                    name = envConf.name,
                    realm = envConf.realm,
                    apiName = envConf.apiName,
                    backendUrl = envConf.backendUrl,
                    frontendUrl = envConf.frontendUrl,
                    adminUrl = envConf.adminUrl,
                    secret = envConf.secret,
                    loggingEnabled = envConf.loggingEnabled,
                    firebaseDynamicLinkUrl = envConf.firebaseDynamicLinkUrl,
                    firebaseDynamicLinkHost = envConf.firebaseDynamicLinkHost,
                    firebaseApiKey = envConf.firebaseApiKey,
                    androidAppId = android.mobile.appId,
                    androidAppUrl = android.mobile.appUrl,
                    iosAppId = ios.appId,
                    iosBundleId = ios.bundleId,
                    iosAppUrl = ios.appUrl,
                    keycloakUserInfoUri = envConf.keycloakUserInfoUri,
                    keycloakTokenUri = envConf.keycloakTokenUri,
                    keycloakLogoutUri = envConf.keycloakLogoutUri,
                    keycloakConfUri = envConf.keycloakConfUri,
                    keycloakBaseUri = envConf.keycloakBaseUri,
                    keycloakClientId = envConf.keycloakClientId
                )
            }
        }
        @Serializable
        data class CoreBuild(
            @SerialName(ENVS_PARAM)
            val envs: List<EnvConfig>
        ) {
            companion object {
                const val ENVS_PARAM = "envs"
            }
        }
        @Serializable
        data class EnvConfig(
            @SerialName(NAME_PARAM)
            val name: String,
            @SerialName(REALM_PARAM)
            val realm: String,
            @SerialName(API_NAME_PARAM)
            val apiName: String,
            @SerialName(BACKEND_URL_PARAM)
            val backendUrl: String,
            @SerialName(FRONTEND_URL_PARAM)
            val frontendUrl: String,
            @SerialName(ADMIN_URL_PARAM)
            val adminUrl: String? = null,
            @SerialName(SECRET_PARAM)
            val secret: String,
            @SerialName(LOGGING_ENABLED_PARAM)
            val loggingEnabled: Boolean,
            @SerialName(FIREBASE_DYNAMIC_LINK_URL_PARAM)
            val firebaseDynamicLinkUrl: String,
            @SerialName(FIREBASE_DYNAMIC_LINK_HOST_PARAM)
            val firebaseDynamicLinkHost: String,
            @SerialName(FIREBASE_API_KEY_PARAM)
            val firebaseApiKey: String,
            @SerialName(KEYCLOAK_CONF_URI_PARAM)
            val keycloakConfUri: String,
            @SerialName(KEYCLOAK_TOKEN_URI_PARAM)
            val keycloakTokenUri: String,
            @SerialName(KEYCLOAK_USER_URI_PARAM)
            val keycloakUserInfoUri: String,
            @SerialName(KEYCLOAK_LOGOUT_URI_PARAM)
            val keycloakLogoutUri: String,
            @SerialName(KEYCLOAK_BASE_URI_PARAM)
            val keycloakBaseUri: String,
            @SerialName(KEYCLOAK_CLIENT_ID_PARAM)
            val keycloakClientId: String,
        ) {
            companion object {
                const val NAME_PARAM = "name"
                const val REALM_PARAM = "realm"
                const val API_NAME_PARAM = "apiName"
                const val BACKEND_URL_PARAM = "backendUrl"
                const val FRONTEND_URL_PARAM = "frontendUrl"
                const val ADMIN_URL_PARAM = "adminUrl"
                const val SECRET_PARAM = "secret"
                const val LOGGING_ENABLED_PARAM = "loggingEnabled"
                const val FIREBASE_DYNAMIC_LINK_URL_PARAM = "firebaseDynamicLinkUrl"
                const val FIREBASE_DYNAMIC_LINK_HOST_PARAM = "firebaseDynamicLinkHost"
                const val FIREBASE_API_KEY_PARAM = "firebaseApiKey"
                const val KEYCLOAK_CONF_URI_PARAM = "confKeycloakUri"
                const val KEYCLOAK_TOKEN_URI_PARAM = "tokenKeycloakUri"
                const val KEYCLOAK_USER_URI_PARAM = "userInfoKeycloakUri"
                const val KEYCLOAK_LOGOUT_URI_PARAM = "logoutKeycloakUri"
                const val KEYCLOAK_BASE_URI_PARAM = "baseKeycloakUri"
                const val KEYCLOAK_CLIENT_ID_PARAM = "webClientId"
            }

            @Serializable
            data class AndroidBuild(
                @SerialName(MOBILE_PARAM)
                val mobile: AndroidFlavor,
                @SerialName(TV_PARAM)
                val tv: AndroidFlavor
            ) {
                companion object {
                    const val MOBILE_PARAM = "mobile"
                    const val TV_PARAM = "tv"
                }

                @Serializable
                data class AndroidFlavor(
                    @SerialName(APP_NAME_PARAM)
                    val appName: String,
                    @SerialName(APP_ID_PARAM)
                    val appId: String,
                    @SerialName(FLAVOR_NAME_PARAM)
                    val flavorName: String,
                    @SerialName(APP_URL_PARAM)
                    val appUrl: String?
                ) {
                    companion object {
                        const val APP_NAME_PARAM = "appName"
                        const val APP_ID_PARAM = "appId"
                        const val FLAVOR_NAME_PARAM = "flavorName"
                        const val APP_URL_PARAM = "appUrl"
                    }
                }
            }

            @Serializable
            data class IosBuild(
                @SerialName(APP_NAME_PARAM)
                val appName: String,
                @SerialName(APP_ID_PARAM)
                val appId: String,
                @SerialName(BUNDLE_ID_PARAM)
                val bundleId: String,
                @SerialName(APP_URL_PARAM)
                val appUrl: String?
            ) {
                companion object {
                    const val APP_NAME_PARAM = "appName"
                    const val APP_ID_PARAM = "appId"
                    const val BUNDLE_ID_PARAM = "bundleId"
                    const val APP_URL_PARAM = "appUrl"
                }
            }
        }
        @Serializable
        data class CoreConfig(
            @SerialName(SIGNUP_PARAM)
            val signup: SignupConfig,
            @SerialName(CONTENT_PARAM)
            val content: ContentConfig,
            @SerialName(SUPPORT_PARAM)
            val support: SupportConfig,
            @SerialName(LEGAL_PARAM)
            val legal: LegalConfig,
            @SerialName(GAMIFICATION_PARAM)
            val gamification: GamificationConfig,
            @SerialName(USER_PARAM)
            val user: UserConfig
        ) {
            companion object {
                const val SIGNUP_PARAM = "signup"
                const val CONTENT_PARAM = "content"
                const val SUPPORT_PARAM = "support"
                const val LEGAL_PARAM = "legal"
                const val GAMIFICATION_PARAM = "gamification"
                const val USER_PARAM = "user"
            }

            fun toModel(): Model.CoreConfig {
                return Model.CoreConfig(
                    signup = signup.toModel(),
                    content = content.toModel(),
                    support = support.toModel(),
                    legal = legal.toModel(),
                    gamification = gamification.toModel(),
                    user = user.toModel()
                )
            }
        }

        @Serializable
        data class SignupConfig(
            @SerialName(MODE_PARAM)
            val mode: Mode,
            @SerialName(PROFILES_PARAM)
            val availableProfiles: List<String>,
            @SerialName(DEFAULT_PROFILE_PARAM)
            val defaultProfile: String,
            @SerialName(DEFAULT_LANGUAGE_PARAM)
            val defaultLanguage: String,
            @SerialName(AVAILABLE_LANGUAGES_PARAM)
            val availableLanguages: List<String>,
            @SerialName(ENABLE_ON_BOARDING_PARAM)
            val enableOnBoarding: Boolean,
            @SerialName(START_AS_GUEST_PARAM)
            val startAsGuest: Boolean
        ) {
            companion object {
                const val MODE_PARAM = "mode"
                const val PROFILES_PARAM = "availableProfiles"
                const val DEFAULT_PROFILE_PARAM = "defaultProfile"
                const val DEFAULT_LANGUAGE_PARAM = "defaultLanguage"
                const val AVAILABLE_LANGUAGES_PARAM = "availableLanguages"
                const val ENABLE_ON_BOARDING_PARAM = "enableOnBoarding"
                const val START_AS_GUEST_PARAM = "startAsGuest"
            }

            @Serializable
            data class Mode(
                @SerialName(ID_PARAM)
                val id: String,
                @SerialName(DEFAULT_BIRTHDAY_PARAM)
                val defaultBirthday: String? = null
            ) {
                companion object {
                    const val ID_PARAM = "id"
                    const val DEFAULT_BIRTHDAY_PARAM = "defaultBirthday"
                }
            }

            fun toModel(): Model.SignupConfig {
                return Model.SignupConfig(
                    mode = Model.SignupConfig.Mode.parse(mode),
                    availableProfiles = availableProfiles.map { ProfileModel.Model.Profile.parse(it) },
                    defaultProfile = ProfileModel.Model.Profile.parse(defaultProfile),
                    defaultLanguage = defaultLanguage,
                    availableLanguages = availableLanguages,
                    enableOnBoarding = enableOnBoarding,
                    startAsGuest = startAsGuest
                )
            }
        }
        @Serializable
        data class ContentConfig(
            @SerialName(INTEREST_FILTER_PARAM)
            val interestFilterEnabled: Boolean,
            @SerialName(CONTAINER_FILTER_PARAM)
            val containerFilterEnabled: Boolean,
            @SerialName(PRODUCT_BUYOUT_PARAM)
            val productBuyoutEnabled: Boolean,
            @SerialName(MAX_GUEST_AGE_RATING_PARAM)
            val maxGuestAgeRating: Int? = null,
            @SerialName(ORDERS_PARAM)
            val orders: List<String>? = null,
            @SerialName(DETAIL_IMAGE_TYPE)
            val detailImageType: DetailImageType,
            @SerialName(ENABLE_COMMENTS_PARAM)
            val enableComments: Boolean,
            @SerialName(ENABLE_RATINGS_PARAM)
            val enableRatings: Boolean,
            @SerialName(SHOW_CONTENT_TYPES_PARAM)
            val showContentTypeIcons: Boolean,
            @SerialName(DEFAULT_IMAGE_QUALITY_PARAM)
            val defaultImageQuality: Int,
            @SerialName(ENABLE_REPORTS_PARAM)
            val enableReports: Boolean,
            @SerialName(REFRESH_USER_VALUES_PARAM)
            val refreshUserValuesOnContentLoad: String,
            @SerialName(SHOW_AUTHOR_PARAM)
            val showAuthor: Boolean,
            @SerialName(SHOW_PUBLISH_DATE_PARAM)
            val showPublishDate: Boolean,
            @SerialName(SHOW_SPLASH_VIDEO_PARAM)
            val showSplashVideo: Boolean,
            @SerialName(SHOW_SEASON_BUTTON_PARAM)
            val showSeasonButton: Boolean,
            @SerialName(SHUFFLE_RELATED_CONTENT_PARAM)
            val shuffleRelatedContent: Boolean
        ) {
            companion object {
                const val INTEREST_FILTER_PARAM = "interestFilterEnabled"
                const val CONTAINER_FILTER_PARAM = "containerFilterEnabled"
                const val PRODUCT_BUYOUT_PARAM = "productBuyoutEnabled"
                const val MAX_GUEST_AGE_RATING_PARAM = "maxGuestAgeRating"
                const val ORDERS_PARAM = "orders"
                const val ENABLE_COMMENTS_PARAM = "enableComments"
                const val ENABLE_RATINGS_PARAM = "enableRatings"
                const val SHOW_CONTENT_TYPES_PARAM = "showContentTypeIcons"
                const val DETAIL_IMAGE_TYPE = "detailImageType"
                const val DEFAULT_IMAGE_QUALITY_PARAM = "defaultImageQuality"
                const val ENABLE_REPORTS_PARAM = "enableReports"
                const val REFRESH_USER_VALUES_PARAM = "refreshUserValuesOnContentLoad"
                const val SHOW_AUTHOR_PARAM = "showAuthor"
                const val SHOW_PUBLISH_DATE_PARAM = "showPublishDate"
                const val SHOW_SPLASH_VIDEO_PARAM = "showSplashVideo"
                const val SHOW_SEASON_BUTTON_PARAM = "showSeasonButton"
                const val SHUFFLE_RELATED_CONTENT_PARAM = "shuffleRelatedContent"
            }

            @Serializable
            data class DetailImageType(
                @SerialName(WEB_PARAM)
                val web: String,
                @SerialName(IOS_PARAM)
                val ios: String,
                @SerialName(ANDROID_PARAM)
                val android: String,
                @SerialName(ANDROID_TV_PARAM)
                val androidTv: String,
            ) {
                companion object {
                    const val ANDROID_PARAM = "android"
                    const val ANDROID_TV_PARAM = "androidTv"
                    const val IOS_PARAM = "ios"
                    const val WEB_PARAM = "web"
                }
            }

            fun toModel(): Model.ContentConfig {
                val parsedSortings = orders?.map {
                    fromId(it)
                } ?: emptyList()
                val imageType = es.cinfo.tiivii.core.image.Model.Image.Type.parse(
                    when (PLATFORM_ID) {
                        PlatformModel.Model.Platform.ANDROID -> detailImageType.android
                        PlatformModel.Model.Platform.ANDROID_TV -> detailImageType.androidTv
                        PlatformModel.Model.Platform.WEB -> detailImageType.web
                        PlatformModel.Model.Platform.IOS -> detailImageType.ios
                    }
                )
                var imageQuality = max(10, defaultImageQuality)
                imageQuality = min(imageQuality, 100)
                return Model.ContentConfig(
                    interestFilterEnabled = interestFilterEnabled,
                    containerFilterEnabled = containerFilterEnabled,
                    productBuyoutEnabled = productBuyoutEnabled,
                    maxGuestAgeRating = maxGuestAgeRating,
                    orders = parsedSortings,
                    enableComments = enableComments,
                    enableRatings = enableRatings,
                    showContentTypeIcons = showContentTypeIcons,
                    detailImageType = imageType,
                    defaultImageQuality = imageQuality,
                    enableReports = enableReports,
                    refreshUserValuesOnContentLoad = Model.ContentConfig.RefreshUserValuesType.parse(
                        refreshUserValuesOnContentLoad
                    ),
                    showPublishDate = showPublishDate,
                    showSplashVideo = showSplashVideo,
                    showSeasonButton = showSeasonButton,
                    showAuthor = showAuthor,
                    shuffleRelatedContent = shuffleRelatedContent
                )
            }
        }
        @Serializable
        data class SupportConfig(
            @SerialName(SUPPORT_EMAIL_PARAM)
            val supportEmail: String? = null,
            @SerialName(FAQ_URL_PARAM)
            val faqUrl: String? = null,
            @SerialName(TUTORIAL_URL_PARAM)
            val tutorialUrl: String? = null,
            @SerialName(SHOW_APP_URLS_PARAM)
            val showAppUrls: Boolean
        ) {
            companion object {
                const val SUPPORT_EMAIL_PARAM = "supportEmail"
                const val FAQ_URL_PARAM = "faqUrl"
                const val TUTORIAL_URL_PARAM = "tutorialUrl"
                const val SHOW_APP_URLS_PARAM = "showAppUrls"
            }

            fun toModel(): Model.SupportConfig {
                return Model.SupportConfig(
                    supportEmail = supportEmail,
                    faqUrl = faqUrl,
                    tutorialUrl = tutorialUrl,
                    showAppsUrls = showAppUrls
                )
            }
        }
        @Serializable
        data class LegalConfig(
            @SerialName(ENABLED_PARAM)
            val enabled: Boolean,
        ) {
            companion object {
                const val ENABLED_PARAM = "enabled"
            }

            fun toModel(): Model.LegalConfig {
                return Model.LegalConfig(
                    enabled = enabled,
                )
            }
        }
        @Serializable
        data class GamificationConfig(
            @SerialName(ENABLED_PARAM)
            val enabled: Boolean,
        ) {
            companion object {
                const val ENABLED_PARAM = "enabled"
            }

            fun toModel(): Model.GamificationConfig {
                return Model.GamificationConfig(
                    enabled = enabled,
                )
            }
        }
        @Serializable
        data class PlatformEnableMap(
            @SerialName(WEB_PARAM)
            val web: Boolean,
            @SerialName(ANDROID_PARAM)
            val android: Boolean,
            @SerialName(ANDROID_TV_PARAM)
            val androidTv: Boolean,
            @SerialName(IOS_PARAM)
            val ios: Boolean
        ) {
            companion object {
                const val WEB_PARAM = "web"
                const val ANDROID_PARAM = "android"
                const val ANDROID_TV_PARAM = "androidTv"
                const val IOS_PARAM = "ios"
            }

            fun toModel(): Model.PlatformEnableMap {
                return Model.PlatformEnableMap(
                    web = web,
                    android = android,
                    androidTv = androidTv,
                    ios = ios
                )
            }
        }
        @Serializable
        data class UserConfig(
            @SerialName(SHOW_AVATARS_PARAM)
            val showAvatars: PlatformEnableMap,
            @SerialName(PROFILE_BUTTON_POSITION_PARAM)
            val profileButtonPosition: String,
        ) {
            companion object {
                const val SHOW_AVATARS_PARAM = "showAvatars"
                const val PROFILE_BUTTON_POSITION_PARAM = "profileButtonPosition"
            }

            fun toModel(): Model.UserConfig {
                return Model.UserConfig(
                    showAvatars = showAvatars.toModel(),
                    profileButtonPosition = profileButtonPosition
                )
            }
        }
    }

}
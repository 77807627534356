package es.cinfo.tiivii.core.modules.platform

internal sealed class PlatformModel {

    sealed class Model {
        enum class Platform {
            ANDROID, ANDROID_TV, WEB, IOS;

            companion object {
                fun parse(value: String): List<Platform> {
                    return when (value) {
                        "android" -> listOf(ANDROID)
                        "android-tv" -> listOf(ANDROID_TV)
                        "web" -> listOf(WEB)
                        "ios" -> listOf(IOS)
                        "apps" -> listOf(ANDROID, ANDROID_TV, IOS)
                        "all" -> listOf(ANDROID, ANDROID_TV, WEB, IOS)
                        else -> listOf(ANDROID, ANDROID_TV, WEB, IOS)
                    }
                }
            }
        }
    }

}

internal expect val PLATFORM_ID: PlatformModel.Model.Platform
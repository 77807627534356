package es.cinfo.tiivii.core.modules.avatar.model

import es.cinfo.tiivii.core.image.ImageModule
import es.cinfo.tiivii.di.diContainer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.kodein.di.instance

sealed class AvatarModel {

    class ViewModel {

        /**
         * Represents an avatar for a user
         * @param id of the avatar
         * @param url where related image is stored
         * @param imageId id of the image file
         */
        data class Avatar(val id: Int, val url: String, val imageId: Int) {
            internal fun toModel(): Model.Avatar =
                Model.Avatar(id, url, imageId)
        }

    }

    internal sealed class Model {

        data class Avatar(val id: Int, val url: String, val imageId: Int) {
            fun toViewModel(): ViewModel.Avatar =
                ViewModel.Avatar(id, url, imageId)
        }

    }

    internal sealed class ApiResponse {

        @Serializable
        internal class Avatar(
            @SerialName(ID_PARAM)
            private val id: Int,
            @SerialName(IMAGE_PARAM)
            private val image: es.cinfo.tiivii.core.image.ApiResponse.Image,
        ) {
            companion object {
                const val ID_PARAM = "id"
                const val IMAGE_PARAM = "image"
            }

            fun toModel(): Model.Avatar {
                val imageModule: ImageModule by diContainer.instance()
                return Model.Avatar(
                    id,
                    "${imageModule.baseEndpoint}${image.data.assetUrl}",
                    image.id
                )
            }

        }

    }

}
package es.cinfo.tiivii.core.modules.auth

import com.russhwolf.settings.Settings
import com.russhwolf.settings.get
import es.cinfo.tiivii.core.modules.auth.model.AuthModel.Model.Auth
import es.cinfo.tiivii.di.diContainer
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.kodein.di.instance

/**
 * Local storage datasource for authentication data and operations
 */
internal interface AuthStorage {
    companion object {
        /**
         * Storage key for auth data
         */
        const val AUTH_DATA_KEY = "auth"
    }

    /**
     * Obtains the stored login data available on disk and caches it on memory (if available)
     */
    suspend fun getAuth(): Auth?

    /**
     * Obtains the stored login data available on disk and caches it on memory (if available)
     */
    fun getAuthSync(): Auth?

    /**
     * Stores the new login data on disk and updates the cache
     */
    suspend fun storeAuth(auth: Auth)

    /**
     * Clears the stored auth data on disk
     */
    suspend fun clearAuth()
}

/**
 * Default implementation of [AuthStorage] using [Settings]
 */
internal class DefaultAuthStorage() : AuthStorage {
    private val settings: Settings by diContainer.instance()
    private val json: Json by diContainer.instance()

    override suspend fun getAuth(): Auth? {
        val loginData: String? = settings[AuthStorage.AUTH_DATA_KEY]
        return if (loginData != null) {
            json.decodeFromString(loginData)
        } else {
            null
        }
    }

    override fun getAuthSync(): Auth? {
        val loginData: String? = settings[AuthStorage.AUTH_DATA_KEY]
        return if (loginData != null) {
            json.decodeFromString(loginData)
        } else {
            null
        }
    }

    override suspend fun storeAuth(auth: Auth) {
        settings.putString(AuthStorage.AUTH_DATA_KEY, json.encodeToString(auth))
    }

    override suspend fun clearAuth() {
        settings.remove(AuthStorage.AUTH_DATA_KEY)
    }
}
package es.cinfo.tiivii.core.features.playback.model

import es.cinfo.tiivii.core.content.model.ContentTypeModel

internal sealed class Model {
    sealed class ContentLoad {
        data class Video(
            val url: String,
            val type: ContentTypeModel.Model.ContentType
        ): ContentLoad()
        data class Ar(
            val shareUrl: String
        ): ContentLoad()
    }
}
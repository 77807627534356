package es.cinfo.tiivii.core.util

import es.cinfo.tiivii.core.ComponentId
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow

/**
 * Default interface for implementing the business logic of a use case that returns a single value
 */
internal interface UseCase<T> {
    suspend fun invoke(): T
}

/**
 * Abstract interface for implementing business logic of a use case that returns an [Outcome]
 */
internal abstract class OutcomeUseCase<R, E> {
    /**
     * Parameter used to identify the component that executed this use case
     */
    lateinit var component: ComponentId
    /**
     * Defines the business logic of the use case. These are the operations that will be executed when the [invoke]
     * function is called. Must return an [Outcome] instance representing a [success] or a [failure]
     * ```
     *  class GetUserUseCase : OutcomeUseCase<User, RetrievalError>() {
     *      ...
     *      override val work: suspend TryOutcomeContext<Error>.() -> Outcome<String, Error>
     *      get() = {
     *          val user = retrieveUser().mapError { RetrievalError }.getOrAbort()
     *          success(user)
     *       }
     *  }
     * ```
     */
    abstract val work: suspend TryOutcomeContext<E>.() -> Outcome<R, E>

    /**
     * Executes the business logic defined in [work]
     * @return An [Outcome] with a [success] result of type [R] or a [failure] with type [E] if something unexpected
     * happened
     */
    suspend fun invoke(): Outcome<R, E> {
        return tryOutcome {
            work()
        }
    }

    /**
     * Same as [invoke] but also sets the internal [component] to identify which component invoked the use case
     * @param component id of the component that invoked this use case
     */
    suspend fun invokeWith(component: ComponentId): Outcome<R, E> {
        this.component = component
        return invoke()
    }

}

/**
 * Abstract interface for implementing business logic use cases
 */
internal abstract class FlowUseCase<R, E> {
    /**
     * Defines the business logic of the use case. These are the operations that will be executed when the [invoke]
     * function is called. Must return an [Outcome] instance representing a [success] or a [failure]
     * ```
     *  class GetUserUseCase : FlowUseCase<User, RetrievalError>() {
     *      ...
     *      override val work: suspend TryOutcomeContext<Error>.() -> Outcome<String, Error>
     *      get() = {
     *          val user = retrieveUser().mapError { RetrievalError }.getOrAbort()
     *          success(user)
     *       }
     *  }
     * ```
     */
    abstract val work: suspend () -> Flow<Outcome<R, E>>

    /**
     * Executes the business logic defined in [work]
     * @return An [Outcome] with a [success] result of type [R] or a [failure] with type [E] if something unexpected
     * happened
     */
    suspend fun invoke(): Flow<Outcome<R, E>> {
        return work()
    }
}

internal abstract class FlowOutcomeUseCase<R, E> {
    /**
     * Defines the business logic of the use case. These are the operations that will be executed when the [invoke]
     * function is called. Must return an [Outcome] instance representing a [success] or a [failure]
     * ```
     *  class GetUserUseCase : FlowUseCase<User, RetrievalError>() {
     *      ...
     *      override val work: suspend TryOutcomeContext<Error>.() -> Outcome<String, Error>
     *      get() = {
     *          val user = retrieveUser().mapError { RetrievalError }.getOrAbort()
     *          success(user)
     *       }
     *  }
     * ```
     */
    abstract val work: suspend TryOutcomeFlowContext<E>.() -> Flow<Outcome<R, E>>

    /**
     * Executes the business logic defined in [work]
     * @return An [Outcome] with a [success] result of type [R] or a [failure] with type [E] if something unexpected
     * happened
     */
    suspend fun invoke(): Flow<Outcome<R, E>> {
        return flow {
            tryOutcomeFlow<R,E> {
                work()
            }
        }
    }
}
package es.cinfo.tiivii.footer.store

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.SuspendExecutor
import es.cinfo.tiivii.core.error.ErrorService
import es.cinfo.tiivii.core.util.Failure
import es.cinfo.tiivii.core.util.LoadingModel
import es.cinfo.tiivii.core.util.Success
import es.cinfo.tiivii.di.diContainer
import es.cinfo.tiivii.footer.model.Footer
import es.cinfo.tiivii.footer.store.FooterStore.*
import es.cinfo.tiivii.footer.usecase.GetSponsors
import org.kodein.di.instance

internal class FooterStoreFactory(
    private val storeFactory: StoreFactory,
) {

    private val errorService: ErrorService by diContainer.instance()

    private sealed class Result {
        data class SponsorsLoaded(val sponsors: List<Footer.Model.Sponsor>) : Result()
        object ErrorLoadingSponsors : Result()
        object LoadingSponsors : Result()
    }

    fun create(): FooterStore =
        object :
            FooterStore,
            Store<Intent, State, Label> by
            storeFactory.create(
                name = "FooterStore",
                initialState = State(
                    sponsorsLogos = emptyList(),
                    sponsorsLoading = LoadingModel.Model.LoadState.RESET
                ),
                reducer = DefaultReducer,
                executorFactory = ::DefaultExecutor
            ) {

        }

    private object DefaultReducer : Reducer<State, Result> {
        override fun State.reduce(result: Result): State =
            when (result) {
                is Result.SponsorsLoaded -> copy(
                    sponsorsLogos = result.sponsors,
                    sponsorsLoading = LoadingModel.Model.LoadState.LOADED
                )
                Result.ErrorLoadingSponsors -> copy(
                    sponsorsLoading = LoadingModel.Model.LoadState.RESET
                )
                Result.LoadingSponsors -> copy(
                    sponsorsLoading = LoadingModel.Model.LoadState.LOADING
                )
            }
    }

    private inner class DefaultExecutor : SuspendExecutor<Intent, Nothing, State, Result, Label>() {
        override suspend fun executeIntent(intent: Intent, getState: () -> State) {
            when (intent) {
                Intent.FetchSponsors -> getSponsorsLogos()
            }
        }

        private suspend fun getSponsorsLogos() {
            dispatch(Result.LoadingSponsors)
            when (val outcome = GetSponsors().invoke()) {
                is Success -> {
                    val sponsors = outcome.value
                    dispatch(Result.SponsorsLoaded(sponsors))
                }
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = {
                            dispatch(Result.ErrorLoadingSponsors)
                            publish(Label.UserSessionExpired)
                        },
                        requestTimeoutAction = {
                            dispatch(Result.ErrorLoadingSponsors)
                            publish(Label.RequestTimedOut)
                        },
                        handler = {
                            dispatch(Result.ErrorLoadingSponsors)
                            when (outcome.error) {
                                is GetSponsors.Error.SponsorsUnavailable ->
                                    publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }
    }
}
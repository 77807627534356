package es.cinfo.tiivii.core.layout

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.layout.model.LayoutModel.ApiResponse.Screen
import es.cinfo.tiivii.core.layout.model.layoutconfig.ApiResponse.LayoutConfig
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.network.HttpModule
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

/**
 * Network datasource for layout configuration data and operations
 */
internal interface LayoutApi {

    /**
     * Obtains the list of available [LayoutConfig] for the app
     */
    suspend fun getLayoutConfigs(): Outcome<List<LayoutConfig>, NetworkError>

    /**
     * Obtains the layout for the [Screen] with the given id
     */
    suspend fun getScreen(id: Int): Outcome<Screen, NetworkError>

    suspend fun getLegals(version: Int): Outcome<LayoutConfig.Legals, NetworkError>

}

/**
 * Default implementation of [LayoutApi] using Ktor
 */
internal class DefaultLayoutApi : LayoutApi {
    private val http: HttpModule by diContainer.instance()
    private val configModule: ConfigModule by diContainer.instance()

    override suspend fun getLayoutConfigs(): Outcome<List<LayoutConfig>, NetworkError> {
        with(configModule.getEnvConfig()) {
            val endpoint = "$backendUrl/sergio/$apiName/interfaces"
            return http.getAsOutcome(endpoint = endpoint, responseField = "data")
        }
    }

    override suspend fun getScreen(id: Int): Outcome<Screen, NetworkError> {
        val layoutEndpoint = "${configModule.getEnvConfig().backendUrl}/sergio/${configModule.getEnvConfig().apiName}"
        val endpoint = "$layoutEndpoint/screens/$id?fields=*.*"
        return http.getAsOutcome(endpoint = endpoint)
    }

    override suspend fun getLegals(version: Int): Outcome<LayoutConfig.Legals, NetworkError> {
        val layoutEndpoint = "${configModule.getEnvConfig().backendUrl}/sergio/${configModule.getEnvConfig().apiName}"
        val endpoint = "$layoutEndpoint/legal?filter[version]=$version"
        return http.getAsOutcome(endpoint = endpoint)
    }
}
package es.cinfo.tiivii.core.layout.model.loginclient

sealed class Model {
    /**
     * Model defining an entry log in point for the app
     */
    data class LoginClient(
        val confUri: String,
        val tokenUri: String,
        val userInfoUri: String,
        val logoutUri: String,
        val baseUri: String,
        val realm: String,
        val clientId: String
    )
}




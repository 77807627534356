package es.cinfo.tiivii.user.splash.view

import com.arkivanov.mvikotlin.core.view.MviView
import es.cinfo.tiivii.core.layout.model.layoutconfig.ViewModel
import es.cinfo.tiivii.core.modules.game.model.GameModel
import es.cinfo.tiivii.core.util.LoadingModel
import es.cinfo.tiivii.user.screen.ScreenModel
import es.cinfo.tiivii.user.splash.view.SplashView.Event
import es.cinfo.tiivii.user.splash.view.SplashView.Event.*

/**
 * View implementation for the splash screen
 */
interface SplashView : MviView<Nothing, Event> {

    /**
     * Operations available to be requested to the splash store
     * @see StoreAuth
     * @see LogLoginView
     */
    sealed class Event {

        object GetFirstScreen: Event()

        /**
         * Save the authentication data retrieved on login
         * @param accessToken token to authorize requests with
         * @param refreshToken token to be use to refresh the authorization
         */
        data class StoreAuth(val accessToken: String, val refreshToken: String) : Event()

        /**
         * Request the core to log a login screen view to the analytics backend. It should be send
         * whenever the login screen is viewed. This event is exposed for more grained control
         * for the UI
         */
        object LogLoginView : Event()
    }

    data class Model(
        val loadState: LoadingModel.ViewModel.LoadState,
        val nextScreen: ScreenModel.ViewModel.Screen?,
        val iosAppUrl: String?,
        val androidAppUrl: String?,
        val accessToken: String?,
        val refreshToken: String?,
        val styles: ViewModel.StyleConfig?,
        val keycloakParams: ScreenModel.ViewModel.KeycloakParams?
    )

    /**
     * One-time notifications received from the store
     */
    sealed class Output {
        /**
         * Unexpected error happened
         * @param code string representation of a internal error code to be shown with the generic error to the user
         */
        data class UnexpectedError(val code: String) : Output()

        /**
         * A request has timed out. This may indicate a problem with the internet connection
         */
        object RequestTimedOut : Output()

        /**
         * User session has expired, user needs to log in again
         */
        object UserSessionExpired : Output()

        /**
         * A game action has been sent. The user points, ranking or achievements may have changed
         * @param actionResult indicates if the game action has been successful or has failed with an
         * [GameModel.ViewModel.ActionResult]
         */
        data class GameActionSent(
            val actionResult: GameModel.ViewModel.ActionResult
        ): Output()
    }

}
package es.cinfo.tiivii.core.content.model

import es.cinfo.tiivii.core.translation.TranslationModel
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

internal sealed class CategoryModel {

    sealed class Model {
        data class Category(
            val id: Int,
            val code: String,
        )
    }

    sealed class ApiResponse {
        @Serializable
        data class Category(
            @SerialName(ID_PARAM)
            val id: Int,
            @SerialName(CODE_PARAM)
            val code: String,
            @SerialName(TRANSLATIONS_PARAM)
            @Serializable(with = TranslationModel.ApiResponse.Translations.TranslationsDeserializer::class)
            val translations: TranslationModel.ApiResponse.Translations?
        ) {
            companion object {
                const val ID_PARAM = "id"
                const val CODE_PARAM = "code"
                const val TRANSLATIONS_PARAM = "translations"
            }

            fun toModel(language: String): Model.Category {
                val code = translations?.find(CODE_PARAM, language) ?: code
                return Model.Category(
                    id, code
                )
            }
        }
    }

}


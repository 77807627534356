package es.cinfo.tiivii.footer.view

import com.arkivanov.mvikotlin.core.view.MviView
import es.cinfo.tiivii.core.util.LoadingModel
import es.cinfo.tiivii.footer.model.Footer.ViewModel.Sponsor
import es.cinfo.tiivii.footer.view.FooterView.Event.GetSponsors
import es.cinfo.tiivii.footer.view.FooterView.Model
import es.cinfo.tiivii.footer.view.FooterView.Output
import es.cinfo.tiivii.footer.view.FooterView.Output.*
import es.cinfo.tiivii.home.view.HomeView

/**
 * View implementation for the footer screen
 */
interface FooterView : MviView<Model, Output> {

    /**
     * Operations available to be requested for the footer screen
     * @see GetSponsors
     */
    sealed class Event {
        /**
         * Request the available logos from the sponsors
         */
        object GetSponsors : Event()
    }

    /**
     * The model of the footer screen exposed to the UI
     * @param sponsors The list of [Sponsor] of the app
     */
    data class Model(
        val sponsors: List<Sponsor>,
        val sponsorsLoading: LoadingModel.ViewModel.LoadState
    )

    /**
     * One-time notifications that can be received on the footer screen
     * @see UnexpectedError
     * @see UserSessionExpired
     */
    sealed class Output {
        /**
         * Unexpected error happened
         */
        data class UnexpectedError(val code: String) : Output()

        /**
         * User session has expired. Re-log is necessary
         */
        object UserSessionExpired : Output()

        /**
         * A request has timed out. This may indicate a problem with the internet connection
         */
        object RequestTimedOut : Output()
    }
}
package es.cinfo.tiivii.user.role

internal sealed class Role {
    data class Kid(val maxAge: Int) : Role()
    data class Teen(val minAge: Int, val maxAge: Int) : Role()
    data class Adult(val minAge: Int) : Role()

    companion object {
        fun fromAge(age: Int): Role {
            if (age < 0) {
                throw IllegalArgumentException("age cannot be a negative number")
            }
            return if (age <= 9) {
                Kid(9)
            } else if (age in 10..13) {
                Teen(10, 13)
            } else {
                Adult(14)
            }
        }
    }

    fun toViewModel(): RoleViewModel {
        return when (this) {
            is Kid -> RoleViewModel.Kid(maxAge)
            is Teen -> RoleViewModel.Teen(minAge, maxAge)
            is Adult -> RoleViewModel.Adult(minAge)
        }
    }
}

/**
 * Represents the role of a user and categorizes the users by its age
 */
sealed class RoleViewModel {
    data class Kid(val maxAge: Int) : RoleViewModel()
    data class Teen(val minAge: Int, val maxAge: Int) : RoleViewModel()
    data class Adult(val minAge: Int) : RoleViewModel()

    internal fun toModel(): Role {
        return when (this) {
            is Kid -> Role.Kid(maxAge)
            is Teen -> Role.Teen(minAge, maxAge)
            is Adult -> Role.Adult(minAge)
        }
    }
}
package es.cinfo.tiivii.core.modules.product.model

import es.cinfo.tiivii.core.image.ViewModel.Image as ImageViewModel
import es.cinfo.tiivii.core.image.Model.Image as ImageModel
import es.cinfo.tiivii.core.image.ApiResponse.Image as ImageApiResponse
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.SerializationException
import es.cinfo.tiivii.core.sorting.SortModel.Model.Sort

sealed class ProductModel {
    /**
     * Content related representations for the UI's
     */
    sealed class ViewModel {

        /**
         * Representation of a product that may be purchased to watch some content
         * @param id Product identifier
         * @param name Product name for display
         * @param description Small product description
         * @param type Product type
         * @param currency ISO code identifier of the currency associated to the product
         * @param price Product price
         * @param recurringType Product payment recurring type
         * @param recurringPeriod Product payment recurring period
         * @param recurringInterval Product payment recurring interval
         * @param image Related product image
         */
        data class Product(
            val id: Int,
            val name: String,
            val description: String,
            val type: Type,
            val currency: String,
            val price: Float,
            val recurringType: RecurringType,
            val recurringPeriod: RecurringPeriod,
            val recurringInterval: Int,
            val image: ImageViewModel?,
        ) {
            /**
             * Represents the product type
             */
            enum class Type {
                /**
                 * The associated product is free and does not require a payment
                 */
                FREE,
                /**
                 * The associated product is subscription based and will require a recurring payment
                 */
                SUBSCRIPTION,
                /**
                 * The associated product is pay-per-view with a single payment needed
                 */
                PAY_PER_VIEW
            }
            /**
             * Represents the recurring type of payment
             */
            enum class RecurringType {
                /**
                 * Single payment
                 */
                ONE_TIME,

                /**
                 * Recurring payment
                 */
                RECURRING
            }
            /**
             * Represents the recurring period of a payment
             */
            enum class RecurringPeriod {
                /**
                 * Daily payment
                 */
                DAILY,
                /**
                 * Weekly payment
                 */
                WEEKLY,
                /**
                 * Monthly payment
                 */
                MONTHLY,
                /**
                 * Yearly payment
                 */
                YEARLY
            }
        }

        /**
         * Represents a currency for product payments
         */
        enum class Currency {
            /**
             * Euro
             */
            EUR,
            /**
             * United States Dollar
             */
            USD,
            /**
             * Great Britain Pound
             */
            GBP,
            /**
             * Japan Yen
             */
            JPY,
            /**
             * Australian Dollar
             */
            AUD
        }

        /**
         * Represents a product checkout to be made if the user wants to purchase the product
         * @param total Total amount of the purchase
         * @param currency ISO code identifier of the currency associated to the product checkout
         * @param successUrl Url to redirect after the successful purchase
         * @param cancelUrl Url to redirect after the failed/canceled purchased
         * @param checkoutUrl Url to redirect to purchase the content
         */
        data class ProductCheckout(
            val total: Float,
            val currency: String,
            val successUrl: String,
            val cancelUrl: String,
            val checkoutUrl: String
        )

        /**
         * Represents the user products dashboard
         * @param url to access the user products dashboard
         * @param returnUrl to access when coming back from the products dashboard
         */
        data class ProductsDashboard(
            val url: String,
            val returnUrl: String
        )

    }

    internal sealed class Model {

        data class Product(
            val id: Int,
            val name: String,
            val description: String,
            val type: Type,
            val currency: String,
            val price: Float,
            val recurringType: RecurringType,
            val recurringPeriod: RecurringPeriod,
            val recurringInterval: Int,
            val stripeId: String,
            val image: ImageModel?
        ) {
            enum class Type {
                FREE, SUBSCRIPTION, PAY_PER_VIEW;

                companion object {
                    fun parse(value: String): Type =
                        when (value) {
                            "free" -> FREE
                            "svod" -> SUBSCRIPTION
                            "tvod" -> PAY_PER_VIEW
                            else -> throw SerializationException(
                                "Unrecognized product type: $value"
                            )
                        }
                }

                fun toViewModel(): ViewModel.Product.Type =
                    when (this) {
                        FREE -> ViewModel.Product.Type.FREE
                        SUBSCRIPTION -> ViewModel.Product.Type.SUBSCRIPTION
                        PAY_PER_VIEW -> ViewModel.Product.Type.PAY_PER_VIEW
                    }
            }

            enum class RecurringType {
                ONE_TIME, RECURRING;

                companion object {
                    fun parse(value: String): RecurringType =
                        when (value) {
                            "one_time" -> ONE_TIME
                            "recurring" -> RECURRING
                            else -> throw SerializationException(
                                "Unrecognized product recurring type: $value"
                            )
                        }
                }

                fun toViewModel(): ViewModel.Product.RecurringType =
                    when (this) {
                        ONE_TIME -> ViewModel.Product.RecurringType.ONE_TIME
                        RECURRING -> ViewModel.Product.RecurringType.RECURRING
                    }
            }

            enum class RecurringPeriod {
                DAILY, WEEKLY, MONTHLY, YEARLY;

                companion object {
                    fun parse(value: String): RecurringPeriod =
                        when (value) {
                            "day" -> DAILY
                            "week" -> WEEKLY
                            "month" -> MONTHLY
                            "year" -> YEARLY
                            else -> throw SerializationException(
                                "Unrecognized product recurring period: $value"
                            )
                        }
                }

                fun toViewModel(): ViewModel.Product.RecurringPeriod =
                    when (this) {
                        DAILY -> ViewModel.Product.RecurringPeriod.DAILY
                        WEEKLY -> ViewModel.Product.RecurringPeriod.WEEKLY
                        MONTHLY -> ViewModel.Product.RecurringPeriod.MONTHLY
                        YEARLY -> ViewModel.Product.RecurringPeriod.YEARLY
                    }
            }

            fun toViewModel(): ViewModel.Product =
                ViewModel.Product(
                    id = id,
                    name = name,
                    description = description,
                    type = type.toViewModel(),
                    currency = currency,
                    price = price,
                    recurringType = recurringType.toViewModel(),
                    recurringPeriod = recurringPeriod.toViewModel(),
                    recurringInterval = recurringInterval,
                    image = image?.toViewModel()
                )
        }

        data class ProductCheckout(
            val total: Float,
            val currency: String,
            val successUrl: String,
            val cancelUrl: String,
            val checkoutUrl: String
        ) {
            fun toViewModel(): ViewModel.ProductCheckout =
                ViewModel.ProductCheckout(
                    total = total,
                    currency = currency,
                    successUrl = successUrl,
                    cancelUrl = cancelUrl,
                    checkoutUrl = checkoutUrl
                )
        }

        data class ProductsLoad(
            val products: List<Product>,
            val count: Int,
            val page: Int,
            val limit: Int,
            val sort: Sort,
        ) {
            fun hasMoreContent(): Boolean {
                return (page * limit) + limit < count
            }
        }

        data class ProductsDashboard(
            val url: String,
            val returnUrl: String
        ) {
            fun toViewModel(): ViewModel.ProductsDashboard = ViewModel.ProductsDashboard(url, returnUrl)
        }

    }

    internal sealed class ApiResponse {

        @Serializable
        data class ProductsLoad(
            @SerialName(PRODUCTS_PARAM)
            val products: List<Product>,
            @SerialName(PAGE_PARAM)
            val page: Int,
            @SerialName(LIMIT_PARAM)
            val limit: Int,
            @SerialName(SORT_PARAM)
            val sort: String,
        ) {
            companion object {
                const val PRODUCTS_PARAM = "products"
                const val PAGE_PARAM = "page"
                const val LIMIT_PARAM = "limit"
                const val SORT_PARAM = "sort"
            }

            fun toModel(): Model.ProductsLoad {
                return Model.ProductsLoad(
                    products = products.map { it.toModel() },
                    // TODO: Add real count size when available
                    count = products.size,
                    page = page,
                    limit = limit,
                    sort = Sort.fromParam(sort)
                )
            }
        }

        @Serializable
        data class Products(
            @SerialName(DATA_PARAM)
            val data: List<Product>
        ) {
            companion object {
                const val DATA_PARAM = "data"
            }

            fun toModel(): List<Model.Product> {
                return data.map { it.toModel() }
            }
        }

        @Serializable
        data class ProductWrapper(
            @SerialName(PRODUCT_PARAM)
            val product: Product
        ) {
            companion object {
                const val PRODUCT_PARAM = "products_id"
            }
        }

        @Serializable
        data class Product(
            @SerialName(ID_PARAM)
            val id: Int,
            @SerialName(NAME_PARAM)
            val name: String,
            @SerialName(DESCRIPTION_PARAM)
            val description: String,
            @SerialName(TYPE_PARAM)
            val type: String,
            @SerialName(PRICE_PARAM)
            val price: Float,
            @SerialName(CURRENCY_PARAM)
            val currency: String,
            @SerialName(RECURRING_TYPE_PARAM)
            val recurringType: String,
            @SerialName(RECURRING_PERIOD_PARAM)
            val recurringPeriod: String,
            @SerialName(RECURRING_INTERVAL_PARAM)
            val recurringInterval: Int,
            @SerialName(STRIPE_ID_PARAM)
            val stripeId: String,
            @SerialName(IMAGE_PARAM)
            val image: ImageApiResponse? = null
        ) {
            companion object {
                const val ID_PARAM = "id"
                const val NAME_PARAM = "name"
                const val DESCRIPTION_PARAM = "description"
                const val TYPE_PARAM = "type"
                const val PRICE_PARAM = "price"
                const val CURRENCY_PARAM = "currency"
                const val RECURRING_TYPE_PARAM = "recurring_type"
                const val RECURRING_PERIOD_PARAM = "recurring_period"
                const val RECURRING_INTERVAL_PARAM = "recurring_interval"
                const val STRIPE_ID_PARAM = "stripe_product_id"
                const val IMAGE_PARAM = "image"
            }

            fun toModel(): Model.Product {
                val parsedType = Model.Product.Type.parse(type)
                val parsedRecurringType = Model.Product.RecurringType.parse(recurringType)
                val parsedCurrency = currency
                val parsedRecurringPeriod = Model.Product.RecurringPeriod.parse(recurringPeriod)
                return Model.Product(
                    id = id,
                    name = name,
                    description = description,
                    type = parsedType,
                    currency = parsedCurrency,
                    price = price,
                    recurringType = parsedRecurringType,
                    recurringPeriod = parsedRecurringPeriod,
                    recurringInterval = recurringInterval,
                    stripeId = stripeId,
                    image = image?.toModel(es.cinfo.tiivii.core.image.Model.Image.Type.OTHER)
                )
            }
        }

        @Serializable
        data class ProductCheckout(
            @SerialName(TOTAL_PARAM)
            val total: Float,
            @SerialName(CURRENCY_PARAM)
            val currency: String,
            @SerialName(SUCCESS_URL_PARAM)
            val successUrl: String,
            @SerialName(CANCEL_URL_PARAM)
            val cancelUrl: String,
            @SerialName(CHECKOUT_URL_PARAM)
            val checkoutUrl: String
        ) {
            companion object {
                const val TOTAL_PARAM = "amount_total"
                const val CURRENCY_PARAM = "currency"
                const val SUCCESS_URL_PARAM = "success_url"
                const val CANCEL_URL_PARAM = "cancel_url"
                const val CHECKOUT_URL_PARAM = "url"
            }

            fun toModel(): Model.ProductCheckout {
                return Model.ProductCheckout(
                    total = total,
                    currency = currency,
                    successUrl = successUrl,
                    cancelUrl = cancelUrl,
                    checkoutUrl = checkoutUrl
                )
            }
        }

        @Serializable
        data class ProductsDashboard(
            @SerialName(URL_PARAM)
            val url: String,
            @SerialName(RETURN_URL_PARAM)
            val returnUrl: String,
        ) {
            companion object {
                const val URL_PARAM = "url"
                const val RETURN_URL_PARAM = "return_url"
            }

            fun toModel(): Model.ProductsDashboard {
                return Model.ProductsDashboard(
                    url, returnUrl
                )
            }
        }
    }

}

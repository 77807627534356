package es.cinfo.tiivii.core.features.catalogue.store

import com.arkivanov.mvikotlin.core.store.Store
import es.cinfo.tiivii.core.features.catalogue.model.Model.Content
import es.cinfo.tiivii.core.features.catalogue.model.Model.ContentNode
import es.cinfo.tiivii.core.features.catalogue.model.ViewModel
import es.cinfo.tiivii.core.features.catalogue.store.CatalogueStore.*
import es.cinfo.tiivii.core.modules.game.model.GameModel
import es.cinfo.tiivii.core.sorting.SortModel
import es.cinfo.tiivii.core.util.LoadingModel.Model.LoadState
import es.cinfo.tiivii.core.util.Model.Node
import es.cinfo.tiivii.core.util.Model.Tree

internal interface CatalogueStore :
    Store<Intent, State, Label> {

    sealed class Action {
        object LoadRootNodes : Action()
    }

    sealed class Intent {
        object Reload : Intent()
        data class LoadNode(val coordinates: List<Int>) : Intent()
        data class SelectContent(val coordinates: List<Int>) : Intent()
        object DeselectContent : Intent()
        object LoadMoreContent : Intent()
        data class Search(val query: String) : Intent()
        object ClearSearch : Intent()
        data class Sort(val sortId: String) : Intent()
        object LogCatalogueView: Intent()
        data class AddToFavorites(
            val coordinates: List<Int>
        ): Intent()
        data class RemoveFromFavorites(
            val coordinates: List<Int>
        ): Intent()
    }

    data class State(
        val nodeTree: Tree<ViewModel.ContentNode, ContentNode>?,
        val selectedNode: Node<ViewModel.ContentNode, ContentNode>?,
        val loadingNodes: LoadState,

        val nodeContents: Tree<ViewModel.Content, Content>?,
        val selectedContent: Node<ViewModel.Content, Content>?,
        val hasMoreContents: Boolean,
        val queryFilter: String?,
        val selectedSort: SortModel.Model.Sort?,
        val availableSorts: List<SortModel.Model.Sort>?,
        val currentPage: Int,
        val loadingContents: LoadState,
    )

    sealed class Label {
        data class GameActionSent(
            val actionResult: GameModel.Model.ActionResult
        ) : Label()
        object UserSessionExpired : Label()
        object RequestTimedOut : Label()
        data class UnexpectedError(val code: String) : Label()
        data class IllegalOperationError(val reason: String) : Label()
    }

}
package es.cinfo.tiivii.user.signup.view

import com.arkivanov.mvikotlin.core.view.MviView
import es.cinfo.tiivii.core.features.catalogue.view.CatalogueView
import es.cinfo.tiivii.core.interest.model.ViewModel.Interest
import es.cinfo.tiivii.core.modules.avatar.model.AvatarModel.ViewModel.Avatar
import es.cinfo.tiivii.home.view.HomeView
import es.cinfo.tiivii.user.role.RoleViewModel
import es.cinfo.tiivii.user.signup.view.SignupView.Event
import es.cinfo.tiivii.user.signup.view.SignupView.Model

/**
 * View implementation for the sign up screen
 */
interface SignupView : MviView<Model, Event> {

    sealed class Event {
        /**
         * Requests the default available avatars
         */
        object GetAvatars : Event()

        /**
         * Requests the list of available interests
         */
        object GetInterests : Event()

        /**
         * Requests the available languages for the app
         */
        object GetLanguages : Event()

        /**
         * Requests the latest available legal conditions
         */
        object GetLegalConditions : Event()

        /**
         * Changes the random username generated
         */
        object RollUsername : Event()

        /**
         * Sets the user preferred language
         */
        data class SetPreferredLanguage(val language: String) : Event()

        /**
         * Sets the username
         *
         * This will trigger an existing username check
         */
        data class SetUsername(val username: String) : Event()

        /**
         * Sets the avatar from the list of available avatars
         */
        data class SetAvatar(val avatar: Avatar) : Event()

        /**
         * Sets the user email
         *
         * This will trigger an existing email check
         */
        data class SetEmail(val email: String) : Event()

        /**
         * Sets the user credentials
         *
         * A credential check is triggered for security reasons
         * @param credentials Must be at least 8 character long with at least 1 character, 1 number and 1
         * special symbol
         */
        data class SetCredentials(val credentials: String) : Event()

        /**
         * Sets the birthday of the user
         *
         * A birthday check is triggered for the given birthday and previously established role
         * @param birthday with the format: yyyy-mm-dd
         */
        data class SetBirthday(val birthday: String) : Event()

        /**
         * Sets the first name of the user
         */
        data class SetFirstName(val firstName: String) : Event()

        /**
         * Sets the last name of the user
         */
        data class SetLastName(val lastName: String) : Event()

        /**
         * Adds the given interest to the list of selected user interests
         */
        data class AddInterest(val interest: Interest) : Event()

        /**
         * Removes the given interest to the list of selected user interests
         */
        data class RemoveInterest(val interest: Interest) : Event()

        /**
         * Accept the legal conditions
         * @param isDataCollectionAllowed indicates if the user allows for data collection
         */
        data class AcceptLegalConditions(val isDataCollectionAllowed: Boolean) : Event()

        /**
         * Requests signup of the user with the established information
         */
        object Signup : Event()

        /**
         * Request the core to log a signup screen view to the analytics backend. It should be send
         * whenever the signup screen is viewed. This event is exposed for more grained control
         * for the UI
         */
        object LogSignupView : Event()
    }

    data class Model(
        val availableRoles: Set<RoleViewModel> = emptySet(),
        val availableLanguages: Set<String> = emptySet(),
        val availableAvatars: Set<Avatar> = emptySet(),
        val availableInterests: Set<Interest> = emptySet(),
        val randomUsername: String?,
        val role: RoleViewModel?,
        val username: String?,
        val avatar: Avatar?,
        val language: String?,
        val credentials: String?,
        val firstName: String?,
        val lastName: String?,
        val email: String?,
        val birthday: String?,
        val interests: Set<Interest> = emptySet(),
        val legalConditions: String?,
        val isDataCollectionAllowed: Boolean = false,
        val errors: Set<Output>? = null
    )

    sealed class Output {
        /**
         * Unexpected error happened
         */
        data class UnexpectedError(val code: String) : Output()

        /**
         * An operation has been request through an [Event] but the operation cannot be done due to some requirements
         * not met. This may happen if an [Event] is requested when is not due. This usually means that the usage of the
         * internal of the component is wrong and should be checked
         * @param reason explains why the operation cannot be executed
         */
        data class IllegalOperationError(val reason: String) : Output()

        /**
         * A request has timed out. This may indicate a problem with the internet connection
         */
        object RequestTimedOut : Output()


        /**
         * User session has expired, user needs to log in again
         */
        object UserSessionExpired : Output()

        /**
         * Email is not valid
         */
        object InvalidEmail : Output()

        /**
         * Email is valid and can be used for signup
         */
        object ValidEmail: Output()

        /**
         * Credentials do not met the security requirements
         */
        object InvalidCredentials : Output()

        /**
         * Birthday is not valid or does not match the role established ages
         */
        object InvalidBirthday : Output()

        /**
         * Username is already in use
         */
        object InvalidUsername : Output()

        /**
         * Username is available and can be used for signup
         */
        object ValidUsername: Output()

        /**
         * First name is blank
         */
        object InvalidFirstName : Output()

        /**
         * Last name is blank
         */
        object InvalidLastName : Output()

        /**
         * User has been registered
         */
        object UserCreated : Output()
    }

}

package es.cinfo.tiivii.core.features.detail.view

import com.arkivanov.mvikotlin.core.view.MviView
import es.cinfo.tiivii.core.content.model.CommentModel.ViewModel.Comments
import es.cinfo.tiivii.core.content.model.NextContentModel
import es.cinfo.tiivii.core.content.model.RelatedContentModel.ViewModel.RelatedContent
import es.cinfo.tiivii.core.content.model.SerialContentModel.ViewModel.SerialContent
import es.cinfo.tiivii.core.content.model.ViewModel.PlaybackSupport
import es.cinfo.tiivii.core.content.model.ViewModel.PlayableChild
import es.cinfo.tiivii.core.content.model.ViewModel.Content
import es.cinfo.tiivii.core.content.model.ViewModel.Playable
import es.cinfo.tiivii.core.content.model.report.ContentReportModel.ViewModel.ContentReport
import es.cinfo.tiivii.core.features.detail.model.DetailModel
import es.cinfo.tiivii.core.modules.avatar.model.AvatarModel.ViewModel.Avatar
import es.cinfo.tiivii.core.features.detail.view.DetailView.Event
import es.cinfo.tiivii.core.features.detail.view.DetailView.Event.*
import es.cinfo.tiivii.core.features.detail.view.DetailView.Model
import es.cinfo.tiivii.core.features.detail.view.DetailView.Output.ContentReported
import es.cinfo.tiivii.core.modules.game.model.GameModel
import es.cinfo.tiivii.core.translation.TranslationModel
import es.cinfo.tiivii.core.util.LoadingModel.ViewModel.LoadState

/**
 * View implementation for the content detail screen
 */
interface DetailView : MviView<Model, Event> {

    /**
     * Operations available to be requested for the content detail screen
     * @see LoadContent
     * @see RefreshComments
     * @see LoadMoreComments
     * @see AddToFavorites
     * @see RemoveFromFavorites
     * @see Rate
     * @see DeleteRating
     * @see AddComment
     * @see RemoveComment
     * @see UpdateComment
     * @see ReportContent
     * @see LogDetailView
     * @see LogSelectItem
     */
    sealed class Event {
        /**
         * Requests the data load of the content associated with the given id
         * @param id of the content to load
         * @param payload optional payload param to access the data of a content in draft state
         */
        data class LoadContent(
            val id: Int,
            val payload: String? = null
        ) : Event()

        /**
         * Requests the list of comments to be refreshed
         */
        object RefreshComments : Event()

        /**
         * Requests the next batch of comments to be loaded
         */
        object LoadMoreComments : Event()

        /**
         * Requests the content loaded to be added as a favorite
         */
        object AddToFavorites : Event()

        /**
         * Requests the content loaded to be removed from favorites
         */
        object RemoveFromFavorites : Event()

        /**
         * Requests the loaded content to be scored with the given value
         * @param rating float value between 0 and 5 to evaluate the content with. The following restrictions apply:
         * If the value is below 0 is received, a rating of 0 will be sent
         * If the value is above 5 is received, a rating of 5 will be sent
         * If decimal values are received, the value will be rounded to the nearest whole integer
         */
        data class Rate(
            val rating: Float,
        ) : Event()

        /**
         * Requests the rating of the user on the current content to be deleted
         */
        object DeleteRating : Event()

        /**
         * Requests the comment to be registered for the loaded content
         * @param comment to be registered
         */
        data class AddComment(
            val comment: String,
        ) : Event()

        /**
         * Requests the comment to be removed for the loaded content
         * @param id of the comment to be removed
         */
        data class RemoveComment(
            val id: Int,
        ) : Event()

        /**
         * Requests the comment to be updated for the loaded content
         * @param id of the comment to be updated
         * @param text new comment text
         */
        data class UpdateComment(
            val id: Int,
            val text: String,
        ) : Event()

        /**
         * Requests a content report to be made with the given reason id
         * @param reason report id (id of one of the [Model.availableReportReasons])
         * @param userMessage An optional message from the user
         */
        data class ReportContent(val reason: Int, val userMessage: String? = null): Event()

        /**
         * Request a content detail view log to be made. It should be sent
         * whenever the content detail screen is viewed. This event is exposed for more grained control
         * for the UI
         */
        object LogDetailView: Event()

        /**
         * Request a item select event to be made. It should be sent
         * whenever the user selects a content in the content detail screen.
         * This event is exposed for more grained control for the UI
         */
        data class LogSelectItem(val contentId: Int) : Event()
    }

    /**
     * The model of the content detail screen exposed to the UI
     * @param content identifies the content main data loaded
     * @param purchaseInfo identifies the content purchase information identifying if the content has a free version
     * and the associated paid products
     * @param nextContent identifies the content to played after the current one if any
     * @param canReport indicates if the content report option should be enabled
     * @param playable indicates if the content playable status [Playable]
     * @param playbackSupport indicates the reason why the content is not playable or [PlaybackSupport.Ready] if the content is playable
     * @param playableChild indicates the child content that can be played if the playable status is [Playable.CHILDREN], null otherwise
     * @param canFav indicates if the user can add to favorites this content
     * @param isFavorite indicates if the content is a user's favorite
     * @param canRate indicates if the user can rate the content
     * @param userRating indicates the user's content rating if any
     * @param availableReportReasons represents all the available [ContentReport]s
     * @param editUrl represents the url to access the edit page of the content if available
     * @param shareUrl represents the url that can be used to share this content
     * @param shareQrBase64 represents the base64 representation of the [shareUrl] (not available in all targets)
     * @param isContentLoading indicates the content loading state
     *
     * @param serialContent represents other serial contents related to this (children/siblings)
     * @param isSerialContentLoading indicates if the serial contents are being loaded
     *
     * @param relatedContent represents other related contents related to this (category/tags)
     * @param isRelatedContentLoading indicates the related contents loading state
     *
     * @param userAvatar represents the current logged user's avatar
     * @param comments represents the list of comments made over the loaded content
     * @param areCommentsLoading indicates the comments loading state
     * @param productsUrl indicates the web url that can be used to purchase any related product
     * @param serialContentsTitle indicates the title string to show for serial content widget
     */
    data class Model(
        // Core content
        var content: Content?,
        val purchaseInfo: DetailModel.ViewModel.ContentPurchaseInfo?,
        var nextContent: NextContentModel.ViewModel.NextContent?,
        val canReport: Boolean?,
        val playable: Playable?,
        val playbackSupport: PlaybackSupport?,
        val playableChild: PlayableChild?,
        val canFav: Boolean?,
        var isFavorite: Boolean?,
        val canRate: Boolean?,
        val userRating: Int?,
        val availableReportReasons: List<ContentReport>,
        val editUrl: String?,
        val shareUrl: String?,
        val shareQrBase64: String?,
        val isContentLoading: LoadState,
        val productsUrl: String?,
        val serialContentsTitle: TranslationModel.ViewModel.LocalizedString?,

        // Serial content (children/siblings)
        val serialContent: List<SerialContent>,
        val isSerialContentLoading: LoadState,

        // Related content (same category, tags)
        var relatedContent: List<RelatedContent>,
        val isRelatedContentLoading: LoadState,

        // Content comments
        var comments: Comments?,
        val areMoreCommentsAvailable: Boolean,
        val areCommentsLoading: LoadState
    )

    /**
     * One-time notifications that can be received on the content detail screen
     * @see UnexpectedError
     * @see ContentReported
     */
    sealed class Output {
        /**
         * Unexpected error happened
         */
        data class UnexpectedError(val code: String) : Output()

        /**
         * An operation has been request through an [Event] but the operation cannot be done due to some requirements
         * not met. This may happen if an [Event] is requested when is not due. This usually means that the usage of the
         * internal of the component is wrong and should be checked
         * @param reason explains why the operation cannot be executed
         */
        data class IllegalOperationError(val reason: String) : Output()

        /**
         * User session has expired. Re-log is necessary
         */
        object UserSessionExpired : Output()

        /**
         * A request has timed out. This may indicate a problem with the internet connection
         */
        object RequestTimedOut : Output()

        /**
         * A game action has been sent. The user points, ranking or achievements may have changed
         */
        data class GameActionSent(
            val actionResult: GameModel.ViewModel.ActionResult
        ): Output()

        /**
         * Notifies confirmation that the requested [Event.ReportContent]
         * has been made
         */
        object ContentReported : Output()
    }

}

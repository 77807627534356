package es.cinfo.tiivii.core.modules.game.model

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.di.diContainer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.kodein.di.instance

sealed class GameModel {
    sealed class ViewModel {
        /**
         * Identifies the result of a gamification action
         * @param hasFailed true if the gamification action has failed
         * @param achievements list of [Achievement] that the action has generated if any
         * @param rewards list of [Reward] that the action has generated if any
         */
        data class ActionResult(
            val hasFailed: Boolean,
            val achievements: List<Achievement> = emptyList(),
            val rewards: List<Reward> = emptyList(),
        )

        /**
         * Identifies an achievement given by the gamification service
         * @param name name of the achievement
         * @param description extra text describing the achievement
         * @param priority indicates the priority value in reference to other achievements. A higher value indicates
         * a more important achievement if available
         * @param pointsNeeded achievement points needed to receive this achievement
         * @param accomplishmentTimeMs time of accomplishment of the achievement in ms since epoch or null if not accomplished
         */
        data class Achievement(
            val name: String,
            val description: String,
            val priority: Int?,
            val pointsNeeded: Int,
            val accomplishmentTimeMs: Long?
        )

        /**
         * Identifies a reward given by the gamification service for completing an achievement
         * @param name name of the achievement
         * @param type string representation of the reward type
         * * ***`cert`*** if the reward is a certificate given to the user
         * * ***`hook`*** if the reward is a link to another site
         * @param imageRef associated image ref for the given reward type
         * @param value associated value related to the type of the reward (maybe null)
         * * for ***`cert`***s, a link to download the certificate
         * * for ***`hook`***s, a link to another site
         * @param achievement associated [Achievement] to this reward (maybe null if the associated
         * achievement has been removed)
         */
        data class Reward(
            val name: String,
            val type: String,
            val imageRef: String?,
            val value: String?,
            val achievement: Achievement?
        )
    }

    internal sealed class Model {
        sealed class ActionResult {
            data class Success(
                val achievements: List<Achievement>,
                val rewards: List<Reward>
            ): ActionResult()
            sealed class Failure: ActionResult() {
                object NoUserSession: Failure()
                object ContentActionsUnavailable: Failure()
                data class GamificationServiceUnavailable(
                    val defaultActionError: NetworkError?,
                    val inGameActionError: NetworkError?,
                    val gameBunchActionError: NetworkError?,
                ): Failure()
                object GamificationDisabled: Failure()
            }
            fun toViewModel(): ViewModel.ActionResult {
                return when (this) {
                    Failure.ContentActionsUnavailable,
                    is Failure.GamificationServiceUnavailable,
                    Failure.GamificationDisabled,
                    Failure.NoUserSession -> {
                        ViewModel.ActionResult(hasFailed = true)
                    }
                    is Success -> {
                        ViewModel.ActionResult(
                            hasFailed = false,
                            achievements = this.achievements.map { it.toViewModel() },
                            rewards = this.rewards.map { it.toViewModel() }
                        )
                    }
                }
            }
        }

        data class Action(
            val achievements: List<Achievement> = emptyList(),
            val rewards: List<Reward> = emptyList(),
        ) {
            fun hasAchievementsOrRewards(): Boolean {
                return achievements.isNotEmpty() || rewards.isNotEmpty()
            }
        }

        data class Achievement(
            val id: Int,
            val name: String,
            val description: String,
            val priority: Int?,
            val pointsNeeded: Int,
            val accomplishmentTimeMs: Long?
        ) {
            fun toViewModel(): ViewModel.Achievement {
                return ViewModel.Achievement(
                    name, description, priority, pointsNeeded, accomplishmentTimeMs
                )
            }
        }

        data class Reward(
            val id: Int,
            val name: String,
            val type: String,
            val imageRef: String?,
            val value: String?,
            val achievement: Achievement?
        ) {
            fun toViewModel(): ViewModel.Reward {
                return ViewModel.Reward(
                    name, type, imageRef, value, achievement?.toViewModel()
                )
            }
        }
    }

    internal sealed class ApiResponse {
        @Serializable
        data class Action(
            @SerialName(ACHIEVEMENTS_PARAM)
            val achievements: List<Achievement>? = null,
            @SerialName(REWARDS_PARAM)
            val rewards: List<Reward>? = null,
        ) {
            companion object {
                const val ACHIEVEMENTS_PARAM = "achievements"
                const val REWARDS_PARAM = "rewards"
            }
            fun toModel(username: String): Model.Action {
                return Model.Action(
                    achievements?.map { it.toModel() } ?: emptyList(),
                    rewards?.map { it.toModel(username) } ?: emptyList()
                )
            }
        }

        @Serializable
        data class Achievement(
            @SerialName(ID_PARAM)
            val id: Int,
            @SerialName(NAME_PARAM)
            val name: String,
            @SerialName(DESCRIPTION_PARAM)
            val description: String,
            @SerialName(PRIORITY_PARAM)
            val priority: Int? = null,
            @SerialName(POINTS_PARAM)
            val value: String,
        ) {
            companion object {
                const val ID_PARAM = "id"
                const val NAME_PARAM = "name"
                const val DESCRIPTION_PARAM = "description"
                const val PRIORITY_PARAM = "priority"
                const val POINTS_PARAM = "value"
            }

            fun toModel(accomplishmentTimeSec: Long? = null): Model.Achievement {
                return Model.Achievement(
                    id, name, description, priority, value.toInt(), accomplishmentTimeSec?.times(1000)
                )
            }
        }

        @Serializable
        data class Reward(
            @SerialName(ID_PARAM)
            val id: Int,
            @SerialName(NAME_PARAM)
            val name: String,
            @SerialName(TYPE_PARAM)
            val type: String,
            @SerialName(VALUE_PARAM)
            val value: String?,
            @SerialName(ACHIEVEMENT_PARAM)
            val achievement: Achievement? = null
        ) {
            companion object {
                const val ID_PARAM = "id"
                const val NAME_PARAM = "name"
                const val TYPE_PARAM = "type"
                const val VALUE_PARAM = "value"
                const val ACHIEVEMENT_PARAM = "achievement"
            }

            fun toModel(username: String): Model.Reward {
                val configModule: ConfigModule by diContainer.instance()
                val image = when (type) {
                    "cert" -> "document-text-outline"
                    "hook" -> "link-outline"
                    else -> value
                }
                val finalValue = when (type) {
                    "cert" -> value?.let {
                        "${configModule.getEnvConfig().backendUrl}/cert/${configModule.getEnvConfig().apiName}/${username}/${it.replace(" ", "")}.pdf"
                    }
                    else -> value
                }
                return Model.Reward(
                    id = id,
                    name = name,
                    type = type,
                    imageRef = image,
                    value = finalValue,
                    achievement = achievement?.toModel()
                )
            }
        }
    }
}
package es.cinfo.tiivii.core.userstats

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.network.HttpModule
import es.cinfo.tiivii.core.userstats.UserStatsModel.ApiResponse.Rankings
import es.cinfo.tiivii.core.userstats.UserStatsModel.ApiResponse.UserStats
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

internal interface UserStatsApi {

    suspend fun getBoardRankings(id: Int): Outcome<Rankings, NetworkError>

    suspend fun getUserStats(username: String): Outcome<UserStats, NetworkError>

}

internal class DefaultUserStatsApi : UserStatsApi {
    private val http: HttpModule by diContainer.instance()
    private val configModule: ConfigModule by diContainer.instance()

    override suspend fun getBoardRankings(id: Int): Outcome<Rankings, NetworkError> {
        val endpoint = "${configModule.getEnvConfig().backendUrl}/game-ranking/${configModule.getEnvConfig().apiName}/$id"
        return http.getAsOutcome(endpoint = endpoint)
    }

    override suspend fun getUserStats(username: String): Outcome<UserStats, NetworkError> {
        val endpoint = "${configModule.getEnvConfig().backendUrl}/orch/${configModule.getEnvConfig().apiName}/consumer-stats/$username"
        return http.getAsOutcome(endpoint = endpoint)
    }

}
package es.cinfo.tiivii.core.util

sealed class LoadingModel {
    sealed class ViewModel {
        /**
         * Loading indicator for any loading operation
         */
        enum class LoadState {
            /**
             * A load has not been requested
             */
            RESET,

            /**
             * A loading is being made
             */
            LOADING,

            /**
             * A load has been made
             */
            LOADED
        }
    }

    internal sealed class Model {
        enum class LoadState {
            RESET, LOADING, LOADED;

            fun toViewModel(): ViewModel.LoadState {
                return when (this) {
                    RESET -> ViewModel.LoadState.RESET
                    LOADING -> ViewModel.LoadState.LOADING
                    LOADED -> ViewModel.LoadState.LOADED
                }
            }
        }
    }
}
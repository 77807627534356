package es.cinfo.tiivii.core.features.ranking.binder

import es.cinfo.tiivii.core.features.ranking.store.RankingStore.*
import es.cinfo.tiivii.core.features.ranking.view.RankingView.*

internal val STATE_TO_MODEL: State.() -> Model = {
    Model(
        userAvatar = userAvatar?.toViewModel(),
        userRanking = userRanking?.toViewModel(),
        rankings = rankings.map { it.toViewModel() },
        achievements = achievements.map { it.toViewModel() },
        closestAchievement = closestAchievement?.toViewModel(),
        rewards = rewards.map { it.toViewModel() },
        loadingStats = loadingStats.toViewModel()
    )
}

internal val EVENT_TO_INTENT: Event.() -> Intent = {
    when (this) {
        Event.ReloadUserStats -> Intent.ReloadUserStats
    }
}

internal val LABEL_TO_OUTPUT: Label.() -> Output = {
    when (this) {
        Label.UserSessionExpired -> Output.UserSessionExpired
        is Label.UnexpectedError -> Output.UnexpectedError(code)
        Label.RequestTimedOut -> Output.RequestTimedOut
    }
}

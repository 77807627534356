package es.cinfo.tiivii.footer.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

sealed class Footer {

    sealed class ViewModel(val sponsors: List<Sponsor>) {
        /**
         * Represents a sponsor of the application
         * @param logo url to the logo of the sponsor
         * @param companyUrl url to the sponsor's site
         * @param companyName public name of the sponsor to be displayed if needed
         */
        data class Sponsor(
            val logo: String,
            val companyUrl: String?,
            val companyName: String
        ) {
            /**
             * Retrieves the url for loading the image
             *
             * @param pixelWidth width of the image in pixels
             * @param pixelHeight height of the image in pixels
             * @return The url to retrieve the image from
             */
            fun urlForSize(pixelWidth: Int, pixelHeight: Int): String {
                return "$logo?w=$pixelWidth&h=$pixelHeight&q=100&f=contain"
            }
        }
    }

    internal sealed class Model(val sponsors: List<Sponsor>) {
        data class Sponsor(
            val image: String,
            val companyUrl: String?,
            val companyName: String
        ) {
            fun toViewModel(): ViewModel.Sponsor {
                return ViewModel.Sponsor(
                    this.image,
                    this.companyUrl,
                    this.companyName
                )
            }
        }
    }

    /**
     * Not used at the moment, but the model is created now to be available in the future
     */
    internal sealed class ApiResponse {
        @Serializable
        data class Footer(
            @SerialName(ID_PARAM)
            val id: String,
            @SerialName(OPTIONS_PARAM)
            val options: List<Options>
        ) {
            companion object {
                /**
                 * Param key in the backend response for the id of the footer associated with the interface in the app
                 */
                const val ID_PARAM = "id"

                /**
                 * Param key in the backend response for the options of the footer associated with the interface in the app
                 */
                const val OPTIONS_PARAM = "options"
            }

            @Serializable
            data class Options(
                @SerialName(ICON_URI_PARAM)
                val iconUri: String,
                @SerialName(LINK_PARAM)
                val link: String,
                @SerialName(LOCATION_PARAM)
                val location: String,
                @SerialName(OPTION_NAME_PARAM)
                val optionName: String,
            ) {
                companion object {
                    /**
                     * Param key in the backend response for the icon url associated with the footer in the app
                     */
                    const val ICON_URI_PARAM = "iconURI"

                    /**
                     * Param key in the backend response for the icon url associated with the footer in the app
                     */
                    const val LINK_PARAM = "link"

                    /**
                     * Param key in the backend response for the link associated with the footer in the app
                     */
                    const val LOCATION_PARAM = "location"

                    /**
                     * Param key in the backend response for the option name associated with the footer in the app
                     */
                    const val OPTION_NAME_PARAM = "optionName"
                }
            }
        }
    }
}


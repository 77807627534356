package es.cinfo.tiivii.search.usecase

import es.cinfo.tiivii.core.ComponentId
import es.cinfo.tiivii.core.ErrorId
import es.cinfo.tiivii.core.UseCaseId
import es.cinfo.tiivii.core.error.CodedError
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.error.asErrorId
import es.cinfo.tiivii.core.modules.analytics.LogEvent
import es.cinfo.tiivii.core.modules.analytics.model.AnalyticsModel.Action
import es.cinfo.tiivii.core.modules.analytics.model.AnalyticsModel.Action.Search.QUERY_KEY
import es.cinfo.tiivii.core.modules.rating.RatingModel.Model.Companion.RATING_FILTER
import es.cinfo.tiivii.core.sorting.SortModel.Model
import es.cinfo.tiivii.core.usecase.GetRatingFilter
import es.cinfo.tiivii.core.util.*
import es.cinfo.tiivii.di.diContainer
import es.cinfo.tiivii.search.data.SearchService
import es.cinfo.tiivii.search.model.Search
import org.kodein.di.instance

internal class PerformSearch(private val searchQuery: String) :
    OutcomeUseCase<Search.Model.SearchResult, PerformSearch.Error>() {

    //TODO: Add to a configuration service
    private val searchMinChars = 3

    private val searchService: SearchService by diContainer.instance()

    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.SEARCH, UseCaseId.PERFORM_SEARCH, errorId, networkError) {
        data class SearchUnavailable(val error: NetworkError) : Error(
            asErrorId<SearchUnavailable>(1),
            error
        )
        object MissingSearchChars: Error(
            asErrorId<MissingSearchChars>(2)
        )
    }

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Search.Model.SearchResult, Error>
        get() = {
            if (searchQuery.length >= searchMinChars) {
                val ratingFilter = GetRatingFilter().invokeWith(ComponentId.SEARCH).mapError {
                    when (it) {
                        is GetRatingFilter.Error.UnavailableRatings -> Error.SearchUnavailable(it.error)
                    }
                }.getOrAbort()
                val searchFilters = if (ratingFilter != null) {
                    mutableMapOf(RATING_FILTER to ratingFilter)
                } else {
                    null
                }
                searchService.search(
                    filters = searchFilters,
                    searchQuery = searchQuery,
                    page = 1)
                    .log {
                        // Log search
                        LogEvent(
                            action = Action.Search,
                            keyValue = QUERY_KEY to searchQuery)
                    }
                    .mapError {
                        Error.SearchUnavailable(it)
                    }
            } else {
                failure(Error.MissingSearchChars)
            }
        }
}

internal class GetSuggestions(private val searchQuery: String) :
    OutcomeUseCase<Search.Model.SearchResult, GetSuggestions.Error>() {

    private val searchService: SearchService by diContainer.instance()

    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.SEARCH, UseCaseId.GET_SUGGESTIONS, errorId, networkError) {
        data class SuggestionsUnavailable(val error: NetworkError) : Error(
            asErrorId<SuggestionsUnavailable>(1),
            error
        )
    }

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Search.Model.SearchResult, Error>
        get() = {
//            //TODO: Replace suggestions mock
//            val suggestions = searchService.getSuggestions(searchQuery)
//                .mapError {
//                    Error.SuggestionsUnavailable(it)
//                }.getOrAbort()
//            val filteredSuggestions = suggestions.filter { it.title.startsWith(searchQuery, true) }
//            val result = Search.Model.SearchResult(
//                searchResultEntries = filteredSuggestions,
//                1000,
//                1,
//                Model.Sort.titleAlphabetical,
//                filteredSuggestions.size
//            )
            val result = Search.Model.SearchResult(
                searchResultEntries = emptyList(),
                limit = 100,
                page = 1,
                sort = Model.Sort.titleAlphabetical,
                count = 0
            )
            success(result)
        }
}
package es.cinfo.tiivii.core.content.model

import es.cinfo.tiivii.core.content.model.ApiResponse.Content.Data.Companion.MAX_SCORE_VALUE
import es.cinfo.tiivii.core.content.model.ApiResponse.Content.Data.Companion.MIN_SCORE_VALUE
import es.cinfo.tiivii.core.content.model.ViewModel.Playable.*
import es.cinfo.tiivii.core.content.model.report.ContentReportModel.Model.ContentReport
import es.cinfo.tiivii.core.date.DateService
import es.cinfo.tiivii.core.features.detail.model.DetailModel
import es.cinfo.tiivii.core.image.ImageModule
import es.cinfo.tiivii.core.layout.LayoutApi
import es.cinfo.tiivii.core.modules.game.model.GameModel
import es.cinfo.tiivii.core.modules.product.model.ProductModel
import es.cinfo.tiivii.core.modules.video.model.VideoModel
import es.cinfo.tiivii.core.translation.TranslationModel
import es.cinfo.tiivii.core.util.IntListSerializer
import es.cinfo.tiivii.di.diContainer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.kodein.di.instance
import kotlin.js.JsName
import kotlin.math.max
import kotlin.math.min
import es.cinfo.tiivii.core.content.model.CategoryModel.ApiResponse.Category as CategoryApiResponse
import es.cinfo.tiivii.core.content.model.CategoryModel.Model.Category as CategoryModel
import es.cinfo.tiivii.core.content.model.CommentModel.Model.Comments as CommentsModel
import es.cinfo.tiivii.core.content.model.ContentTypeModel.Model.ContentType as ContentTypeModel
import es.cinfo.tiivii.core.content.model.ContentTypeModel.ViewModel.ContentType as ContentTypeViewModel
import es.cinfo.tiivii.core.image.ApiResponse.Image as ImageApiResponse
import es.cinfo.tiivii.core.image.Model.Image as ImageModel
import es.cinfo.tiivii.core.image.Model.Image.Type as ImageType
import es.cinfo.tiivii.core.image.ViewModel.Image as ImageViewModel
import es.cinfo.tiivii.core.modules.rating.RatingModel.ApiResponse.AgeRating as AgeRatingApiResponse
import es.cinfo.tiivii.core.modules.rating.RatingModel.Model.AgeRating as AgeRatingModel

/**
 * Content related representations for the UI's
 */
sealed class ViewModel {

    /**
     * Indicates the playback support of a content. JS clients may use the [name] function to obtain a string code
     * for each possible value
     */
    sealed class PlaybackSupport {
        data class Ready(
            val isFree: Boolean,
            val products: List<ProductModel.ViewModel.Product>
            ): PlaybackSupport() {
                override fun name(): String = "Ready"
            }
        sealed class Locked: PlaybackSupport() {
            object UserSessionRequired: Locked() {
                override fun name(): String = "Locked.UserSessionRequired"
            }
            data class MissingAchievements(val achievements: List<GameModel.ViewModel.Achievement>): Locked() {
                override fun name(): String = "Locked.MissingAchievements"
            }
            data class MissingProduct(val products: List<ProductModel.ViewModel.Product>): Locked() {
                override fun name(): String = "Locked.MissingProduct"
            }
            object Rating: Locked() {
                override fun name(): String = "Locked.Rating"
            }
            object Other: Locked() {
                override fun name(): String = "Locked.Other"
            }
        }
        sealed class Unavailable: PlaybackSupport() {
            object MissingVideoUrl: Unavailable() {
                override fun name(): String = "Unavailable.MissingVideoUrl"
            }
            object MissingIframe: Unavailable() {
                override fun name(): String = "Unavailable.MissingIframe"
            }
            object ServiceUnavailable: Unavailable() {
                override fun name(): String = "Unavailable.ServiceUnavailable"
            }
            object ProductBuyoutDisabled: Unavailable() {
                override fun name(): String = "Unavailable.ProductBuyoutDisabled"
            }
        }
        sealed class Unsupported: PlaybackSupport() {
            object NoContentTypeSupport: Unsupported() {
                override fun name(): String = "Unsupported.NoContentTypeSupport"
            }
            object NoPlatformSupport: Unsupported() {
                override fun name(): String = "Unsupported.NoPlatformSupport"
            }
        }
        @JsName("name")
        abstract fun name(): String
    }

    /**
     * Representation of the content playable status
     * @see DIRECT
     * @see CHILDREN
     * @see DISABLED
     */
    enum class Playable {
        /**
         * Content is playable
         */
        DIRECT,

        /**
         * Content has a playable children
         */
        CHILDREN,

        /**
         * Content cannot be played
         */
        DISABLED
    }

    /**
     * Representation of a content to be used by the UI's to present information in a detailed way
     */
    data class Content(
        val id: Int,
        val background: ImageViewModel?,
        val banner: ImageViewModel?,
        val poster: ImageViewModel?,
        val title: String,
        val subtitle: String?,
        val description: String,
        val additionalInfo: String?,
        val category: String,
        val subcategory: String?,
        val tags: List<String>,
        val rating: String,
        val score: Float,
        val votes: Int,
        val publishDate: String?,
        val participants: List<Participant>?,
        val availableAudios: List<Language>?,
        val availableSubtitles: List<Language>?,
        val isSerial: Boolean,
        val type: ContentTypeViewModel,
        val seasons: Int?,
        val extra: Map<String, String>?,
        val backgroundColor: String,
        val backgroundImage: ImageViewModel?,
        val author: String,
        val parentId: Int?,
        val startTimeMs: Long?
    ) {

        @JsName("secondsSinceStart")
        fun secondsSinceStart(): Long? {
            return if (startTimeMs != null) {
                val dateService: DateService by diContainer.instance()
                val timeNowMs = dateService.currentEpochMillis()
                val timeSinceMs = timeNowMs - startTimeMs
                timeSinceMs / 1000
            } else {
                null
            }
        }

        /**
         * Representation of one member of the cast for any content
         */
        data class Participant(
            val name: String,
            val surname: String,
            val type: String,
            val image: ImageViewModel?,
        )

        /**
         * Representation of available languages for the content
         */
        data class Language(
            val code: String,
            val label: String,
        )
    }

    /**
     * Representation of content's playable child
     */
    data class PlayableChild(
        val id: Int,
        val title: String,
        val subtitle: String?,
        val episode: Int?,
    )

}

/**
 * Content related representations for the core (use cases & services)
 */
internal sealed class Model {

    sealed class PlaybackSupport {
        data class Ready(
            val isFree: Boolean = false,
            val products: List<ProductModel.Model.Product> = emptyList()): PlaybackSupport()
        sealed class Locked: PlaybackSupport() {
            data class MissingAchievements(val achievements: List<GameModel.Model.Achievement>): Locked()
            data class MissingProduct(val products: List<ProductModel.Model.Product>): Locked()
            object Rating: Locked()
            object UserSessionRequired: Locked()
            object Other: Locked()
        }
        sealed class Unavailable: PlaybackSupport() {
            object ProductBuyoutDisabled: Unavailable()
            object MissingVideoUrl: Unavailable()
            object MissingIframe: Unavailable()
            object ServiceUnavailable: Unavailable()
        }
        sealed class Unsupported: PlaybackSupport() {
            object NoContentTypeSupport: Unsupported()
            object NoPlatformSupport: Unsupported()
        }

        fun toViewModel(): ViewModel.PlaybackSupport {
            return when (this) {
                is Locked.MissingAchievements -> ViewModel.PlaybackSupport.Locked.MissingAchievements(achievements.map { it.toViewModel() })
                is Locked.MissingProduct -> ViewModel.PlaybackSupport.Locked.MissingProduct(products.map { it.toViewModel() })
                Locked.Rating -> ViewModel.PlaybackSupport.Locked.Rating
                Locked.Other -> ViewModel.PlaybackSupport.Locked.Other
                is Ready -> ViewModel.PlaybackSupport.Ready(isFree, products.map { it.toViewModel() })
                Unavailable.MissingIframe -> ViewModel.PlaybackSupport.Unavailable.MissingIframe
                Unavailable.MissingVideoUrl -> ViewModel.PlaybackSupport.Unavailable.MissingVideoUrl
                Unavailable.ServiceUnavailable -> ViewModel.PlaybackSupport.Unavailable.ServiceUnavailable
                Unavailable.ProductBuyoutDisabled -> ViewModel.PlaybackSupport.Unavailable.ProductBuyoutDisabled
                Unsupported.NoContentTypeSupport -> ViewModel.PlaybackSupport.Unsupported.NoContentTypeSupport
                Unsupported.NoPlatformSupport -> ViewModel.PlaybackSupport.Unsupported.NoPlatformSupport
                Locked.UserSessionRequired -> ViewModel.PlaybackSupport.Locked.UserSessionRequired
            }
        }
    }

    enum class Playable {
        DIRECT, CHILDREN, DISABLED;

        fun toViewModel(): ViewModel.Playable {
            return when (this) {
                DIRECT -> ViewModel.Playable.DIRECT
                CHILDREN -> ViewModel.Playable.CHILDREN
                DISABLED -> ViewModel.Playable.DISABLED
            }
        }
    }

    data class PlayableChild(
        val id: Int,
        val title: String,
        val subtitle: String?,
        val episode: Int?,
    ) {
        fun toViewModel(): ViewModel.PlayableChild {
            return ViewModel.PlayableChild(
                id = id,
                title = title,
                subtitle = subtitle,
                episode = episode
            )
        }
    }

    data class ContentLoad(
        val content: Content?,
        val contentPurchaseInfo: DetailModel.Model.ContentPurchaseInfo?,
        val nextContent: NextContentModel.Model.NextContent?,
        val canReport: Boolean?,
        val playable: Playable?,
        val playbackSupport: PlaybackSupport?,
        val playableChild: PlayableChild?,
        val canFav: Boolean?,
        val isFavorite: Boolean,
        val canRate: Boolean?,
        val contentRating: Int?,
        val editUrl: String?,
        val shareUrl: String?,
        val shareQrBase64: String?,
        val reportReasons: List<ContentReport>,
        val isContentLoading: Boolean,
        val productsUrl: String?,
        val serialContentsTitle: TranslationModel.Model.LocalizedString?,

        val serialContent: List<SerialContentModel.Model.SerialContent>,
        val isSerialContentLoading: Boolean,
        val relatedContent: List<RelatedContentModel.Model.RelatedContent>,
        val isRelatedContentLoading: Boolean,

        val comments: CommentsModel?,
        val areCommentsLoading: Boolean
    )

    data class RatedContent(
        val content: Content,
        val rating: Int
    )

    data class Content(
        val id: Int,
        val background: ImageModel?,
        val banner: ImageModel?,
        val poster: ImageModel?,
        val title: String,
        val subtitle: String?,
        val description: String,
        val additionalInfo: String?,
        val category: CategoryModel,
        val subcategory: CategoryModel?,
        val tags: List<String>,
        val rating: AgeRatingModel,
        val score: Float,
        val votes: Int,
        val publishDate: String?,
        val participants: List<Participant>?,
        val availableAudios: List<Language>?,
        val availableSubtitles: List<Language>?,
        val isSerial: Boolean,
        val type: ContentTypeModel,
        val backgroundColor: String,
        val backgroundImage: ImageModel?,
        val parentId: Int?,
        val geolocationActive: Boolean,
        val status: Status,
        val author: String,
        val smil: String?,
        val adminTiiviiUrl: String?,
        val streaming: List<VideoModel.Model.Video>,
        val nimbleUrls: List<VideoModel.Model.Video>,
        val lockingAchievements: List<Int>,
        val inGameActions: List<String>,
        val gameBunch: GameBunch?,
        val language: String?,
        val iframeUrl: String?,
        val startTimeMs: Long?
    ) {

        companion object {
            object ContentFilter {
                const val key = "[type][neq]"
                const val value = "container"
            }
        }

        enum class Status {
            DRAFT, PROCESSING, DELETED, PUBLISHED, UNKNOWN;

            companion object {
                fun parse(value: String): Status {
                    return when (value) {
                        "published" -> PUBLISHED
                        "draft" -> DRAFT
                        "processing" -> PROCESSING
                        "deleted" -> DELETED
                        else -> UNKNOWN
                    }
                }
            }
        }

        data class Participant(
            val name: String,
            val surname: String,
            val type: String,
            val image: ImageModel?,
        ) {
            fun toViewModel(): ViewModel.Content.Participant =
                ViewModel.Content.Participant(
                    name, surname, type, image?.toViewModel()
                )
        }

        data class Language(
            val code: String,
            val label: String,
        ) {

            fun toViewModel(): ViewModel.Content.Language =
                ViewModel.Content.Language(
                    code, label
                )
        }

        data class GameBunch(
            val name: String,
            val actions: List<String>
        )

        fun toViewModel(): ViewModel.Content {
            return ViewModel.Content(
                id = id,
                background = background?.toViewModel(),
                banner = banner?.toViewModel(),
                poster = poster?.toViewModel(),
                title = title,
                subtitle = subtitle,
                description = description,
                additionalInfo = additionalInfo,
                category = category.code,
                subcategory = subcategory?.code,
                tags = tags,
                rating = rating.code,
                score = score,
                votes = votes,
                publishDate = publishDate,
                participants = participants?.map { it.toViewModel() },
                availableAudios = availableAudios?.map { it.toViewModel() },
                availableSubtitles = availableSubtitles?.map { it.toViewModel() },
                isSerial = isSerial,
                type = type.toViewModel(),
                // TODO: To be added with the seasons endpoint
                seasons = null,
                // TODO: To be defined a field/s from backend
                extra = null,
                backgroundColor = backgroundColor,
                backgroundImage = backgroundImage?.toViewModel(),
                author = author,
                parentId = parentId,
                startTimeMs = startTimeMs,
            )
        }
    }

    /**
     * Indicates the status of the content in the content cache
     * [MISSING] indicates that the content does not exist on the cache
     * [EXPIRED] indicates that the content exists but its validity has expires
     * [CACHED] indicates that the content is currently cached
     */
    enum class CachedContentStatus {
        MISSING,
        EXPIRED,
        CACHED
    }

}

/**
 * Content related representations for the API's
 */
internal sealed class ApiResponse {

    @Serializable
    data class Content(
        @SerialName(DATA_PARAM)
        val data: Data,
    ) {
        companion object {
            const val DATA_PARAM = "data"
        }

        @Serializable
        data class Data(
            @SerialName(ID_PARAM)
            val id: Int,
            @SerialName(TITLE_PARAM)
            val title: String,
            @SerialName(SUBTITLE_PARAM)
            val subtitle: String?,
            @SerialName(DESCRIPTION_PARAM)
            val description: String,
            @SerialName(ADDITIONAL_INFO_PARAM)
            val additionalInfo: String?,
            @SerialName(TYPE_PARAM)
            val type: String,
            @SerialName(CATEGORY_PARAM)
            val category: CategoryApiResponse,
            @SerialName(SUBCATEGORY_PARAM)
            val subcategory: CategoryApiResponse? = null,
            @SerialName(TAGS_PARAM)
            val tags: List<String>,
            @SerialName(SERIAL_PARAM)
            val serial: Boolean,
            @SerialName(PARENT_PARAM)
            val parent: Parent?,
            @SerialName(EPISODE_PARAM)
            val episode: Int?,
            @SerialName(RATING_PARAM)
            val rating: AgeRatingApiResponse,
            @SerialName(SCORE_PARAM)
            val score: Float,
            @SerialName(VOTES_PARAM)
            val votes: Int,
            @SerialName(PUBLISH_DATE_PARAM)
            val publishDate: String?,
            @SerialName(CONFIGURATION_PARAM)
            val configuration: Configuration? = null,
            @SerialName(GEOLOCATION_PARAM)
            val geolocation: Geolocation? = null,
            @SerialName(POSTER_PARAM)
            val poster: ImageApiResponse?,
            @SerialName(BANNER_PARAM)
            val banner: ImageApiResponse?,
            @SerialName(BACKGROUND_PARAM)
            val background: ImageApiResponse?,
            @SerialName(PARTICIPANTS_PARAM)
            val participants: List<Participant>?,
            @SerialName(STATUS_PARAM)
            val status: String,
            @SerialName(AUTHOR_PARAM)
            val author: String,
            @SerialName(RESOURCES_PARAM)
            val resources: List<ResourceWrapper>? = null,
            @SerialName(START_TIME_EPOCH_MS_PARAM)
            val startTimeEpochSec: Long? = null,
            @SerialName(TRANSLATIONS_PARAM)
            @Serializable(with = TranslationModel.ApiResponse.Translations.TranslationsDeserializer::class)
            val translations: TranslationModel.ApiResponse.Translations?
        ) {
            companion object {
                const val ID_PARAM = "id"
                const val TITLE_PARAM = "title"
                const val SUBTITLE_PARAM = "subtitle"
                const val DESCRIPTION_PARAM = "description"
                const val ADDITIONAL_INFO_PARAM = "additional_info"
                const val TYPE_PARAM = "type"
                const val CATEGORY_PARAM = "category"
                const val SUBCATEGORY_PARAM = "subcategory"
                const val TAGS_PARAM = "tags"
                const val SERIAL_PARAM = "serial"
                const val PARENT_PARAM = "parent"
                const val EPISODE_PARAM = "episode"
                const val RATING_PARAM = "rating"
                const val SCORE_PARAM = "valoration"
                const val VOTES_PARAM = "votes"
                const val PUBLISH_DATE_PARAM = "publish_date"
                const val CONFIGURATION_PARAM = "configuration"
                const val GEOLOCATION_PARAM = "geolocation"
                const val POSTER_PARAM = "poster"
                const val BANNER_PARAM = "banner"
                const val BACKGROUND_PARAM = "background"
                const val PARTICIPANTS_PARAM = "participants"
                const val STATUS_PARAM = "status"
                const val AUTHOR_PARAM = "author"
                const val RESOURCES_PARAM = "resources"
                const val START_TIME_EPOCH_MS_PARAM = "start"
                const val TRANSLATIONS_PARAM = "translations"

                // TODO: Maybe configuration value?
                const val MAX_SCORE_VALUE = 5F
                const val MIN_SCORE_VALUE = 0F
            }

            @Serializable
            data class ResourceWrapper(
                @SerialName(DIRECTUS_ID_PARAM)
                val file: Resource,
            ) {
                companion object {
                    const val DIRECTUS_ID_PARAM = "directus_files_id"
                }

                @Serializable
                data class Resource(
                    @SerialName(ID_PARAM)
                    val id: Int,
                    @SerialName(TYPE_PARAM)
                    val type: String,
                    @SerialName(METADATA_PARAM)
                    val metadata: Metadata? = null,
                ) {
                    companion object {
                        const val ID_PARAM = "id"
                        const val TYPE_PARAM = "type"
                        const val METADATA_PARAM = "metadata"
                    }

                    @Serializable
                    data class Metadata(
                        @SerialName(SMIL_PARAM)
                        val smil: String? = null,
                    ) {
                        companion object {
                            const val SMIL_PARAM = "smil"
                        }
                    }

                }
            }

            @Serializable
            data class Parent(
                @SerialName(ID_PARAM)
                val id: Int,
            ) {
                companion object {
                    const val ID_PARAM = "id"
                }
            }

            @Serializable
            data class Participant(
                @SerialName(PARTICIPANT_ID_PARAM)
                val participantId: ParticipantId,
            ) {
                companion object {
                    const val PARTICIPANT_ID_PARAM = "participants_id"
                }

                fun toModel(): Model.Content.Participant {
                    val imageModule: ImageModule by diContainer.instance()
                    val firstImage = participantId.images.firstOrNull()
                    return Model.Content.Participant(
                        participantId.name,
                        participantId.surname,
                        participantId.type,
                        firstImage?.file?.let {
                            ImageModel(
                                firstImage.file.id,
                                firstImage.file.width,
                                firstImage.file.height,
                                firstImage.let {
                                    "${{imageModule.baseEndpoint}}${firstImage.file.data.assetUrl}"
                                },
                                ImageType.OTHER
                            )
                        }
                    )
                }
            }

            @Serializable
            data class ParticipantId(
                @SerialName(ID_PARAM)
                val id: Int,
                @SerialName(NAME_PARAM)
                val name: String,
                @SerialName(SURNAME_PARAM)
                val surname: String,
                @SerialName(TYPE_PARAM)
                val type: String,
                @SerialName(IMAGES_PARAM)
                val images: List<Image>,
            ) {
                companion object {
                    const val ID_PARAM = "id"
                    const val NAME_PARAM = "name"
                    const val SURNAME_PARAM = "surname"
                    const val TYPE_PARAM = "type"
                    const val IMAGES_PARAM = "images"
                }

                @Serializable
                data class Image(
                    @SerialName(FILES_PARAM)
                    val file: File?,
                ) {
                    companion object {
                        const val FILES_PARAM = "directus_files_id"
                    }
                }

                @Serializable
                data class File(
                    @SerialName(ID_PARAM)
                    val id: Int,
                    @SerialName(STORAGE_PARAM)
                    val storage: String,
                    @SerialName(PRIVATE_HASH_PARAM)
                    val privateHash: String,
                    @SerialName(FILENAME_DISK_PARAM)
                    val filenameDisk: String,
                    @SerialName(FILENAME_DOWNLOAD_PARAM)
                    val filenameDownload: String,
                    @SerialName(TITLE_PARAM)
                    val title: String,
                    @SerialName(TYPE_PARAM)
                    val type: String,
                    @SerialName(UPLOADED_BY_PARAM)
                    val uploadedBy: Int,
                    @SerialName(UPLOADED_ON_PARAM)
                    val uploadedOn: String,
                    @SerialName(CHARSET_PARAM)
                    val charset: String,
                    @SerialName(FILE_SIZE_PARAM)
                    val fileSize: Int,
                    @SerialName(WIDTH_PARAM)
                    val width: Int,
                    @SerialName(HEIGHT_PARAM)
                    val height: Int,
                    @SerialName(DURATION_PARAM)
                    val duration: Int,
                    @SerialName(DESCRIPTION_PARAM)
                    val description: String,
                    @SerialName(LOCATION_PARAM)
                    val location: String,
                    @SerialName(CHECKSUM_PARAM)
                    val checksum: String,
                    @SerialName(DATA_PARAM)
                    val data: Data,
                ) {
                    companion object {
                        const val ID_PARAM = "id"
                        const val STORAGE_PARAM = "storage"
                        const val PRIVATE_HASH_PARAM = "private_hash"
                        const val FILENAME_DISK_PARAM = "filename_disk"
                        const val FILENAME_DOWNLOAD_PARAM = "filename_download"
                        const val TITLE_PARAM = "title"
                        const val TYPE_PARAM = "type"
                        const val UPLOADED_BY_PARAM = "uploaded_by"
                        const val UPLOADED_ON_PARAM = "uploaded_on"
                        const val CHARSET_PARAM = "charset"
                        const val FILE_SIZE_PARAM = "filesize"
                        const val WIDTH_PARAM = "width"
                        const val HEIGHT_PARAM = "height"
                        const val DURATION_PARAM = "duration"
                        const val DESCRIPTION_PARAM = "description"
                        const val LOCATION_PARAM = "location"
                        const val CHECKSUM_PARAM = "checksum"
                        const val DATA_PARAM = "data"
                    }

                    @Serializable
                    data class Data(
                        @SerialName(FULL_URL_PARAM)
                        val fullUrl: String,
                        @SerialName(URL_PARAM)
                        val url: String,
                        @SerialName(ASSET_URL_PARAM)
                        val assetUrl: String,
                        @SerialName(THUMBNAILS_PARAM)
                        val thumbnails: List<Thumbnail>,
                    ) {
                        companion object {
                            const val FULL_URL_PARAM = "full_url"
                            const val URL_PARAM = "url"
                            const val ASSET_URL_PARAM = "asset_url"
                            const val THUMBNAILS_PARAM = "thumbnails"
                        }

                        @Serializable
                        data class Thumbnail(
                            @SerialName(KEY_PARAM)
                            val key: String,
                            @SerialName(URL_PARAM)
                            val url: String,
                            @SerialName(RELATIVE_URL_PARAM)
                            val relativeUrl: String,
                            @SerialName(DIMENSION_PARAM)
                            val dimension: String,
                            @SerialName(WIDTH_PARAM)
                            val width: Int,
                            @SerialName(HEIGHT_PARAM)
                            val height: Int,
                        ) {
                            companion object {
                                const val KEY_PARAM = "key"
                                const val URL_PARAM = "url"
                                const val RELATIVE_URL_PARAM = "relative_url"
                                const val DIMENSION_PARAM = "dimension"
                                const val WIDTH_PARAM = "width"
                                const val HEIGHT_PARAM = "height"
                            }
                        }
                    }
                }
            }

            @Serializable
            data class Configuration(
                @SerialName(STREAMING_PARAM)
                val streaming: VideoModel.ApiResponse.VideoUrls? = null,
                @SerialName(VIDEO_RESOURCES_PARAM)
                val videoResources: VideoResources? = null,
                @SerialName(GAMIFICATION_PARAM)
                val gamification: Gamification? = null,
                @SerialName(IFRAME_PARAM)
                val iframeUrl: String? = null,
            ) {
                companion object {
                    const val STREAMING_PARAM = "streaming"
                    const val VIDEO_RESOURCES_PARAM = "videoresources"
                    const val GAMIFICATION_PARAM = "gamification"
                    const val IFRAME_PARAM = "iframeUrl"
                }

                @Serializable
                data class VideoResources(
                    @SerialName(URL_PARAM)
                    val url: String,
                    @SerialName(NIMBLE_URLS_PARAM)
                    val nimbleUrls: VideoModel.ApiResponse.VideoUrls? = null
                ) {
                    companion object {
                        const val URL_PARAM = "url"
                        const val NIMBLE_URLS_PARAM = "nimble_urls"
                    }
                }

                @Serializable
                data class Gamification(
                    @Serializable(with = IntListSerializer::class)
                    @SerialName(REQUIREMENTS_PARAM)
                    val requirements: List<Int>? = emptyList(),
                    @SerialName(IN_GAME_PARAM)
                    val inGame: InGame? = null,
                    @SerialName(GAME_BUNCH_PARAM)
                    val gameBunch: GameBunch? = null
                ) {
                    companion object {
                        const val REQUIREMENTS_PARAM = "blockingAchievement"
                        const val IN_GAME_PARAM = "ingame"
                        const val GAME_BUNCH_PARAM = "gamebunch"
                    }
                    @Serializable
                    data class InGame(
                        @SerialName(ACTIONS_PARAM)
                        val actions: List<String>? = emptyList()
                    ) {
                        companion object {
                            const val ACTIONS_PARAM = "actions"
                        }
                    }
                    @Serializable
                    data class GameBunch(
                        @SerialName(NAME_PARAM)
                        val name: String,
                        @SerialName(ACTIONS_PARAM)
                        val actions: List<String>? = emptyList()
                    ) {
                        companion object {
                            const val NAME_PARAM = "bunchname"
                            const val ACTIONS_PARAM = "actions"
                        }

                        fun toModel(): Model.Content.GameBunch? {
                            return if (actions != null) {
                                Model.Content.GameBunch(name, actions)
                            } else {
                                null
                            }
                        }
                    }
                }
            }

            @Serializable
            data class Geolocation(
                @SerialName(LATITUDE_PARAM)
                val latitude: Float,
                @SerialName(LONGITUDE_PARAM)
                val longitude: Float,
            ) {
                companion object {
                    const val LATITUDE_PARAM = "lat"
                    const val LONGITUDE_PARAM = "lng"
                }
            }

        }

        fun toModel(language: String): Model.Content {
            val smil = data.resources?.find { it.file.type == "video/mp4" }?.file?.metadata?.smil
            val gameBunch: Model.Content.GameBunch? = data.configuration?.gamification?.gameBunch?.toModel()
            val startTimeMs = if (data.startTimeEpochSec == null || data.startTimeEpochSec == 0L) {
                null
            } else {
                data.startTimeEpochSec.times(1000)
            }
            val title = data.translations?.find(WidgetModel.ApiResponse.Widget.TITLE_PARAM, language) ?: data.title
            val description = data.translations?.find(WidgetModel.ApiResponse.Widget.DESCRIPTION_PARAM, language) ?: data.description
            return Model.Content(
                id = data.id,
                background = data.background?.toModel(ImageType.BACKGROUND),
                banner = data.banner?.toModel(ImageType.BANNER),
                poster = data.poster?.toModel(ImageType.POSTER),
                title = title,
                subtitle = data.subtitle,
                description = description,
                additionalInfo = data.additionalInfo,
                category = data.category.toModel(language),
                subcategory = data.subcategory?.toModel(language),
                tags = data.tags,
                rating = data.rating.toModel(),
                score = max(min(data.score, MAX_SCORE_VALUE), MIN_SCORE_VALUE),
                votes = data.votes,
                publishDate = data.publishDate,
                participants = data.participants?.map { it.toModel() },
                availableAudios = emptyList(),
                availableSubtitles = emptyList(),
                isSerial = data.serial,
                type = ContentTypeModel.parse(data.type),
                // TODO: To be replaced by backend values
                backgroundColor = "#607d8Bff",
                backgroundImage = null,
                parentId = data.parent?.id,
                geolocationActive = data.geolocation != null,
                status = Model.Content.Status.parse(data.status),
                author = data.author,
                smil = smil,
                adminTiiviiUrl = data.configuration?.videoResources?.url,
                streaming = data.configuration?.streaming?.toModel(type = VideoModel.Model.Type.HLS) ?: emptyList(),
                nimbleUrls = data.configuration?.videoResources?.nimbleUrls?.toModel(type = VideoModel.Model.Type.HLS) ?: emptyList(),
                lockingAchievements = data.configuration?.gamification?.requirements ?: emptyList(),
                inGameActions = data.configuration?.gamification?.inGame?.actions ?: emptyList(),
                gameBunch = gameBunch,
                language = language,
                iframeUrl = data.configuration?.iframeUrl,
                startTimeMs = startTimeMs
            )
        }
    }

    @Serializable
    data class ContentPublish(
        @SerialName(PUBLISHED_PARAM)
        val published: Boolean
    ) {
        companion object {
            const val PUBLISHED_PARAM = "confirmed"
        }
    }
}

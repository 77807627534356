package es.cinfo.tiivii.core.features.catalogue.store

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.SuspendExecutor
import es.cinfo.tiivii.core.ComponentId
import es.cinfo.tiivii.core.error.ErrorService
import es.cinfo.tiivii.core.features.catalogue.model.Model.*
import es.cinfo.tiivii.core.features.catalogue.model.ViewModel
import es.cinfo.tiivii.core.features.catalogue.store.CatalogueStore.*
import es.cinfo.tiivii.core.features.catalogue.usecase.*
import es.cinfo.tiivii.core.modules.analytics.LogEvent
import es.cinfo.tiivii.core.modules.analytics.model.AnalyticsModel
import es.cinfo.tiivii.core.modules.game.model.GameActions
import es.cinfo.tiivii.core.sorting.SortModel
import es.cinfo.tiivii.core.usecase.AddContentToFavorites
import es.cinfo.tiivii.core.usecase.LoadDefaultOrders
import es.cinfo.tiivii.core.usecase.RemoveContentFromFavorites
import es.cinfo.tiivii.core.usecase.SendGameAction
import es.cinfo.tiivii.core.util.Failure
import es.cinfo.tiivii.core.util.LoadingModel.Model.LoadState
import es.cinfo.tiivii.core.util.Model.Node
import es.cinfo.tiivii.core.util.Model.Tree
import es.cinfo.tiivii.core.util.Success
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

internal class CatalogueStoreFactory(
    private val storeFactory: StoreFactory,
) {

    private val errorService: ErrorService by diContainer.instance()

    private sealed class Result {
        data class NodesUpdate(
            val nodeTree: Tree<ViewModel.ContentNode, ContentNode>,
            val selectedNode: Node<ViewModel.ContentNode, ContentNode>,
            val nodeContents: ContentTreeLoad
        ) : Result()
        object LoadingNodes : Result()
        data class Reloading(val sort: SortModel.Model.Sort) : Result()
        object ErrorLoadingNodes : Result()
        object LoadingContents : Result()
        data class ContentsUpdate(
            val contentTree: ContentTreeLoad
        ) : Result()
        data class SelectedContent(
            val contentTree: ContentTreeLoad
        ): Result()
        object ErrorLoadingContent : Result()
        data class ContentFiltered(val contentsUpdate: ContentTreeLoad, val query: String) : Result()
        data class ContentReloaded(val contentsUpdate: ContentTreeLoad) : Result()
        object SearchFilterCleared : Result()
        object DeselectedContent : Result()
        data class UpdatedSearchFilter(val query: String) : Result()
        data class UpdatedSort(val sort: SortModel.Model.Sort) : Result()
        data class FavoriteStatusChanged(val coordinates: List<Int>, val isFav: Boolean) : Result()
        data class DefaultOrders(val defaultOrders: List<SortModel.Model.Sort>) : Result()
    }

    fun create(): CatalogueStore =
        object :
            CatalogueStore,
            Store<Intent, State, Label> by
            storeFactory.create(
                name = "CatalogueStore",
                initialState = State(
                    nodeTree = null,
                    selectedNode = null,
                    loadingNodes = LoadState.RESET,
                    nodeContents = null,
                    selectedContent = null,
                    hasMoreContents = false,
                    queryFilter = null,
                    selectedSort = null,
                    availableSorts = null,
                    currentPage = 0,
                    loadingContents = LoadState.RESET,
                ),
                bootstrapper = SimpleBootstrapper(Action.LoadRootNodes),
                reducer = DefaultReducer,
                executorFactory = ::DefaultExecutor
            ) {
        }

    private object DefaultReducer : Reducer<State, Result> {
        override fun State.reduce(result: Result): State =
            when (result) {
                is Result.NodesUpdate -> copy(
                    nodeTree = result.nodeTree,
                    selectedNode = result.selectedNode,
                    loadingNodes = LoadState.LOADED,
                    nodeContents = result.nodeContents.contents,
                    hasMoreContents = result.nodeContents.hasMoreContent(),
                    selectedSort = result.nodeContents.sort,
                    currentPage = result.nodeContents.page,
                    queryFilter = null,
                    loadingContents = LoadState.RESET,
                )
                is Result.ErrorLoadingNodes -> copy(
                    loadingNodes = LoadState.RESET
                )
                Result.LoadingNodes -> copy(
                    selectedNode = null,
                    loadingNodes = LoadState.LOADING,
                    nodeContents = null,
                    hasMoreContents = false,
                    queryFilter = null,
                    currentPage = 0,
                    loadingContents = LoadState.LOADING
                )
                is Result.Reloading -> copy(
                    nodeTree = null,
                    selectedNode = null,
                    loadingNodes = LoadState.LOADING,
                    nodeContents = null,
                    selectedContent = null,
                    hasMoreContents = false,
                    queryFilter = null,
                    selectedSort = result.sort,
                    currentPage = 0,
                    loadingContents = LoadState.LOADING
                )
                Result.LoadingContents -> copy(
                    loadingContents = LoadState.LOADING
                )
                is Result.ContentsUpdate -> copy(
                    loadingContents = LoadState.LOADED,
                    nodeContents = result.contentTree.contents,
                    hasMoreContents = result.contentTree.hasMoreContent(),
                    selectedSort = result.contentTree.sort,
                    currentPage = result.contentTree.page
                )
                is Result.ErrorLoadingContent -> copy(
                    loadingContents = LoadState.RESET
                )
                is Result.ContentFiltered -> copy(
                    nodeContents = result.contentsUpdate.contents,
                    hasMoreContents = result.contentsUpdate.hasMoreContent(),
                    queryFilter = result.query,
                    selectedSort = result.contentsUpdate.sort,
                    selectedContent = result.contentsUpdate.selectedNode,
                    currentPage = result.contentsUpdate.page,
                    loadingContents = LoadState.LOADED
                )
                is Result.ContentReloaded -> copy(
                    nodeContents = result.contentsUpdate.contents,
                    hasMoreContents = result.contentsUpdate.hasMoreContent(),
                    selectedSort = result.contentsUpdate.sort,
                    currentPage = result.contentsUpdate.page,
                    selectedContent = result.contentsUpdate.selectedNode,
                    loadingContents = LoadState.LOADED
                )
                Result.SearchFilterCleared -> copy(queryFilter = null)
                is Result.UpdatedSort -> copy(selectedSort = result.sort)
                is Result.UpdatedSearchFilter -> copy(queryFilter = result.query)
                is Result.SelectedContent -> copy(
                    queryFilter = null,
                    loadingContents = LoadState.LOADED,
                    nodeContents = result.contentTree.contents,
                    selectedContent = result.contentTree.selectedNode!!
                )
                Result.DeselectedContent -> copy(
                    selectedContent = null,
                    queryFilter = null
                )
                is Result.FavoriteStatusChanged -> {
                    nodeContents?.findNode(result.coordinates)?.value?.isFav = result.isFav
                    // Last coordinate indicates the position of the element in the current level
                    val lastCoordinate = result.coordinates.last()
                    selectedContent?.children?.get(lastCoordinate)?.value?.isFav = result.isFav
                    copy(
                        nodeContents = nodeContents,
                        selectedContent = selectedContent
                    )
                }
                is Result.DefaultOrders -> copy(
                    selectedSort = result.defaultOrders.firstOrNull(),
                    availableSorts = result.defaultOrders
                )
            }
    }

    private inner class DefaultExecutor :
        SuspendExecutor<Intent, Action,
                State,
                Result, Label>() {
        override suspend fun executeIntent(intent: Intent, getState: () -> State) {
            when (intent) {
                is Intent.LoadNode -> loadNode(intent.coordinates, getState().nodeTree, getState().selectedSort!!)
                is Intent.Search -> filterContents(
                    getState().selectedNode,
                    getState().nodeContents,
                    intent.query,
                    getState().selectedSort!!,
                    getState().selectedContent
                )
                Intent.ClearSearch -> clearSearch(
                    getState().nodeContents,
                    getState().selectedNode,
                    getState().selectedContent,
                    getState().queryFilter,
                    getState().selectedSort!!)
                is Intent.Sort -> sortContents(
                    getState().nodeContents,
                    getState().selectedNode,
                    getState().selectedContent,
                    getState().queryFilter,
                    intent.sortId)
                Intent.Reload -> loadRootNodes()
                Intent.LoadMoreContent -> loadMoreContent(
                    getState().selectedNode,
                    getState().nodeContents,
                    getState().currentPage,
                    getState().selectedSort!!,
                    getState().queryFilter
                )
                is Intent.SelectContent -> selectContent(
                    intent.coordinates,
                    getState().nodeContents,
                    getState().selectedSort!!
                )
                Intent.DeselectContent -> deselectNode()
                Intent.LogCatalogueView -> logCatalogueView()
                is Intent.AddToFavorites -> addContentToFavorites(intent.coordinates, getState().nodeContents)
                is Intent.RemoveFromFavorites -> removeContentFromFavorites(intent.coordinates, getState().nodeContents)
            }
        }

        override suspend fun executeAction(action: Action, getState: () -> State) {
            when (action) {
                Action.LoadRootNodes -> {
                    loadRootNodes()
                }
            }
        }

        private suspend fun loadSorts(): List<SortModel.Model.Sort>? {
            return when (val outcome = LoadDefaultOrders().invokeWith(ComponentId.CATALOGUE)) {
                is Success ->
                    outcome.value
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = {
                            dispatch(Result.ErrorLoadingNodes)
                            publish(Label.UserSessionExpired)
                        },
                        requestTimeoutAction = {
                            dispatch(Result.ErrorLoadingNodes)
                            publish(Label.RequestTimedOut)
                        },
                        handler = {
                            when (outcome.error) {
                                is LoadDefaultOrders.Error.EmptySortList ->
                                    publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                    null
                }
            }
        }

        private suspend fun loadRootNodes() {
            val sorts = loadSorts()
            if (sorts != null && sorts.isNotEmpty()) {
                dispatch(Result.DefaultOrders(sorts))
                val sort = sorts.first()
                dispatch(Result.Reloading(sort))
                when (val outcome = LoadRootNodes(sorts.first()).invoke()) {
                    is Success -> dispatch(
                        Result.NodesUpdate(
                            outcome.value.nodeTree,
                            outcome.value.selectedNode,
                            outcome.value.nodeContents
                        )
                    )
                    is Failure -> {
                        errorService.handleError(
                            error = outcome.error,
                            sessionExpiredAction = {
                                dispatch(Result.ErrorLoadingNodes)
                                publish(Label.UserSessionExpired)
                            },
                            requestTimeoutAction = {
                                dispatch(Result.ErrorLoadingNodes)
                                publish(Label.RequestTimedOut)
                            },
                            handler = {
                                when (outcome.error) {
                                    is LoadRootNodes.Error.UnavailableContents,
                                    is LoadRootNodes.Error.UnavailableNodes,
                                    LoadRootNodes.Error.UnexpectedEmptyNode -> {
                                        dispatch(Result.ErrorLoadingNodes)
                                        publish(Label.UnexpectedError(outcome.error.getCode()))
                                    }
                                }
                            }
                        )
                    }
                }
            }
        }

        private suspend fun loadNode(coordinates: List<Int>, nodeTree: Tree<ViewModel.ContentNode, ContentNode>?, sort: SortModel.Model.Sort) {
            if (nodeTree == null) {
                val error = "Catalogue nodes have not been loaded"
                errorService.handleStoreStateError(error) {
                    dispatch(Result.ErrorLoadingNodes)
                    publish(Label.IllegalOperationError(error))
                }
            } else {
                dispatch(Result.LoadingNodes)
                when (val outcome = LoadNode(coordinates, nodeTree, sort).invoke()) {
                    is Success -> dispatch(
                        Result.NodesUpdate(
                            outcome.value.nodeTree,
                            outcome.value.selectedNode,
                            outcome.value.nodeContents
                        )
                    )
                    is Failure -> {
                        errorService.handleError(
                            error = outcome.error,
                            sessionExpiredAction = {
                                dispatch(Result.ErrorLoadingNodes)
                                publish(Label.UserSessionExpired)
                            },
                            requestTimeoutAction = {
                                dispatch(Result.ErrorLoadingNodes)
                                publish(Label.RequestTimedOut)
                            },
                            handler = {
                                when (outcome.error) {
                                    LoadNode.Error.InvalidCoordinates,
                                    is LoadNode.Error.UnavailableContents,
                                    is LoadNode.Error.UnavailableNodes,
                                    LoadNode.Error.UnexpectedEmptyNode -> {
                                        dispatch(Result.ErrorLoadingNodes)
                                        publish(Label.UnexpectedError(outcome.error.getCode()))
                                    }
                                }
                            }
                        )
                    }
                }
            }
        }

        private suspend fun selectContent(
            coordinates: List<Int>,
            contentTree: Tree<ViewModel.Content, Content>?,
            sort: SortModel.Model.Sort
        ) {
            if (contentTree == null) {
                val error = "Catalogue nodes have not been loaded"
                errorService.handleStoreStateError(error) {
                    publish(Label.IllegalOperationError(error))
                }
            } else {
                dispatch(Result.LoadingContents)
                LogContentSelection(coordinates, contentTree).invoke()
                when (val outcome = LoadNestedContent(coordinates, contentTree, sort).invoke()) {
                    is Success -> dispatch(Result.SelectedContent(outcome.value))
                    is Failure -> {
                        errorService.handleError(
                            error = outcome.error,
                            sessionExpiredAction = {
                                dispatch(Result.ErrorLoadingContent)
                                publish(Label.UserSessionExpired)
                            },
                            requestTimeoutAction = {
                                dispatch(Result.ErrorLoadingContent)
                                publish(Label.RequestTimedOut)
                            },
                            handler = {
                                when (outcome.error) {
                                    LoadNestedContent.Error.ContentNotSerialized,
                                    LoadNestedContent.Error.InvalidCoordinates,
                                    is LoadNestedContent.Error.UnavailableContents -> {
                                        dispatch(Result.ErrorLoadingContent)
                                        publish(Label.UnexpectedError(outcome.error.getCode()))
                                    }
                                }
                            }
                        )
                    }
                }
            }
        }


        private suspend fun loadMoreContent(node: Node<ViewModel.ContentNode, ContentNode>?,
                                            contentTree: Tree<ViewModel.Content, Content>?,
                                            currentPage: Int, sort: SortModel.Model.Sort, queryFilter: String?) {
            if (node == null) {
                val error = "Node has not been loaded"
                errorService.handleStoreStateError(error) {
                    publish(Label.IllegalOperationError(error))
                }
            } else if (contentTree == null) {
                val error = "Catalogue nodes have not been loaded"
                errorService.handleStoreStateError(error) {
                    publish(Label.IllegalOperationError(error))
                }
            } else {
                dispatch(Result.LoadingContents)
                when (val outcome = LoadContentPage(
                    node = node, contentTree = contentTree, queryFilter = queryFilter,
                    page = currentPage + 1, sort = sort
                ).invoke()) {
                    is Success -> dispatch(Result.ContentsUpdate(outcome.value))
                    is Failure -> {
                        errorService.handleError(
                            error = outcome.error,
                            sessionExpiredAction = {
                                dispatch(Result.ErrorLoadingContent)
                                publish(Label.UserSessionExpired)
                            },
                            requestTimeoutAction = {
                                dispatch(Result.ErrorLoadingContent)
                                publish(Label.RequestTimedOut)
                            },
                            handler = {
                                when (outcome.error) {
                                    is LoadContentPage.Error.UnavailableContents -> {
                                        dispatch(Result.ErrorLoadingContent)
                                        publish(Label.UnexpectedError(outcome.error.getCode()))
                                    }
                                }
                            }
                        )
                    }
                }
            }
        }

        private suspend fun filterContents(node: Node<ViewModel.ContentNode, ContentNode>?,
                                           contentTree: Tree<ViewModel.Content, Content>?,
                                           query: String, sort: SortModel.Model.Sort,
                                           selectedContent: Node<ViewModel.Content, Content>?
        ) {
            if (node == null) {
                val error = "Node has not been loaded"
                errorService.handleStoreStateError(error) {
                    publish(Label.IllegalOperationError(error))
                }
            } else if (contentTree == null) {
                val error = "Catalogue nodes have not been loaded"
                errorService.handleStoreStateError(error) {
                    publish(Label.IllegalOperationError(error))
                }
            } else {
                dispatch(Result.UpdatedSearchFilter(query))
                dispatch(Result.LoadingContents)
                when (val outcome = FilterContents(
                    node = node, query = query,
                    sort = sort, selectedContent = selectedContent
                ).invoke()) {
                    is Success -> dispatch(Result.ContentFiltered(outcome.value, query))
                    is Failure -> {
                        errorService.handleError(
                            error = outcome.error,
                            sessionExpiredAction = {
                                dispatch(Result.ErrorLoadingContent)
                                publish(Label.UserSessionExpired)
                            },
                            requestTimeoutAction = {
                                dispatch(Result.ErrorLoadingContent)
                                publish(Label.RequestTimedOut)
                            },
                            handler = {
                                when (outcome.error) {
                                    is FilterContents.Error.SearchUnavailable -> {
                                        dispatch(Result.ErrorLoadingContent)
                                        publish(Label.UnexpectedError(outcome.error.getCode()))
                                    }
                                }
                            }
                        )

                    }
                }
            }
        }

        private suspend fun clearSearch(contentTree: Tree<ViewModel.Content, Content>?,
                                        node: Node<ViewModel.ContentNode, ContentNode>?,
                                        selectedContent: Node<ViewModel.Content, Content>?,
                                        queryFilter: String?,
                                        sort: SortModel.Model.Sort
        ) {
            dispatch(Result.SearchFilterCleared)
            reloadNode(contentTree, node, selectedContent, queryFilter, sort.id)
        }

        private suspend fun sortContents(contentTree: Tree<ViewModel.Content, Content>?,
                                         node: Node<ViewModel.ContentNode, ContentNode>?,
                                         selectedContent: Node<ViewModel.Content, Content>?,
                                         queryFilter: String?,
                                         sortId: String) {
            dispatch(Result.UpdatedSort(SortModel.Model.Sort.fromId(sortId)))
            reloadNode(contentTree, node, selectedContent, queryFilter, sortId)
        }

        private suspend fun reloadNode(contentTree: Tree<ViewModel.Content, Content>?,
                                       node: Node<ViewModel.ContentNode, ContentNode>?,
                                       selectedContent: Node<ViewModel.Content, Content>?,
                                       queryFilter: String?,
                                       sortId: String) {
            if (node == null) {
                val error = "Node has not been loaded"
                errorService.handleStoreStateError(error) {
                    dispatch(Result.ErrorLoadingNodes)
                    publish(Label.IllegalOperationError(error))
                }
            } else if (contentTree == null) {
                val error = "Catalogue nodes have not been loaded"
                errorService.handleStoreStateError(error) {
                    dispatch(Result.ErrorLoadingNodes)
                    publish(Label.IllegalOperationError(error))
                }
            } else {
                dispatch(Result.LoadingContents)
                when (val outcome = ReloadContents(contentTree, node, selectedContent, queryFilter, SortModel.Model.Sort.fromId(sortId)).invoke()) {
                    is Success -> dispatch(Result.ContentReloaded(outcome.value))
                    is Failure -> {
                        errorService.handleError(
                            error = outcome.error,
                            sessionExpiredAction = { publish(Label.UserSessionExpired) },
                            requestTimeoutAction = { publish(Label.RequestTimedOut) },
                            handler = {
                                when (outcome.error) {
                                    is ReloadContents.Error.UnavailableContents -> {
                                        dispatch(Result.ErrorLoadingContent)
                                        publish(Label.UnexpectedError(outcome.error.getCode()))
                                    }
                                }
                            }
                        )
                    }
                }
            }
        }

        private fun deselectNode() {
            dispatch(Result.DeselectedContent)
        }

        private suspend fun logCatalogueView() {
            LogEvent(
                action = AnalyticsModel.Action.PageView,
                keyValue = AnalyticsModel.Action.PageView.PAGE_ID_KEY to AnalyticsModel.Action.PageView.CATALOGUE_VALUE
            ).invoke()
        }

        private suspend fun addContentToFavorites(coordinates: List<Int>, nodeContents: Tree<ViewModel.Content, Content>?) {
            val contentId = nodeContents?.findNodeOrNull(coordinates)?.value?.id
            if (contentId == null) {
                val error = "Content not found in node for coordinates: $coordinates. Ensure that the content coordinates are correct"
                errorService.handleStoreStateError(error) {
                    publish(Label.IllegalOperationError(error))
                }
            } else {
                when (val outcome = AddContentToFavorites(contentId).invokeWith(ComponentId.CATALOGUE)) {
                    is Success -> {
                        dispatch(Result.FavoriteStatusChanged(coordinates, true))
                        val gameActionResult = SendGameAction(GameActions.FAV, contentId).invoke()
                        publish(Label.GameActionSent(gameActionResult))
                    }
                    is Failure -> {
                        errorService.handleError(
                            error = outcome.error,
                            sessionExpiredAction = { publish(Label.UserSessionExpired) },
                            requestTimeoutAction = { publish(Label.RequestTimedOut) },
                            handler = {
                                when (outcome.error) {
                                    is AddContentToFavorites.Error.UnavailableFavorites ->
                                        publish(Label.UnexpectedError(outcome.error.getCode()))
                                    is AddContentToFavorites.Error.ContentAlreadyFavorited -> { }
                                    is AddContentToFavorites.Error.UserSessionUnavailable ->
                                        publish(Label.UnexpectedError(outcome.error.getCode()))
                                }
                            }
                        )
                    }
                }
            }
        }

        private suspend fun removeContentFromFavorites(coordinates: List<Int>, nodeContents: Tree<ViewModel.Content, Content>?) {
            val contentId = nodeContents?.findNodeOrNull(coordinates)?.value?.id
            if (contentId == null) {
                val error = "Content not found in node for coordinates: $coordinates. Ensure that the content coordinates are correct"
                errorService.handleStoreStateError(error) {
                    publish(Label.IllegalOperationError(error))
                }
            } else {
                when (val outcome = RemoveContentFromFavorites(contentId).invokeWith(ComponentId.CATALOGUE)) {
                    is Success -> dispatch(Result.FavoriteStatusChanged(coordinates, false))
                    is Failure -> {
                        errorService.handleError(
                            error = outcome.error,
                            sessionExpiredAction = { publish(Label.UserSessionExpired) },
                            requestTimeoutAction = { publish(Label.RequestTimedOut) },
                            handler = {
                                when (outcome.error) {
                                    is RemoveContentFromFavorites.Error.UnavailableFavorites ->
                                        publish(Label.UnexpectedError(outcome.error.getCode()))
                                    is RemoveContentFromFavorites.Error.UserSessionUnavailable ->
                                        publish(Label.UnexpectedError(outcome.error.getCode()))
                                }
                            }
                        )
                    }
                }
            }
        }

    }
}

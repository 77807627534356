package es.cinfo.tiivii.user.signup.store

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.SuspendExecutor
import es.cinfo.tiivii.core.ComponentId
import es.cinfo.tiivii.core.error.ErrorService
import es.cinfo.tiivii.core.modules.analytics.LogEvent
import es.cinfo.tiivii.core.modules.analytics.model.AnalyticsModel
import es.cinfo.tiivii.core.modules.avatar.model.AvatarModel.Model.Avatar
import es.cinfo.tiivii.core.interest.model.Model.Interest
import es.cinfo.tiivii.core.modules.config.ConfigModel
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.util.Failure
import es.cinfo.tiivii.core.util.Success
import es.cinfo.tiivii.user.legal.usecase.GetLatestLegalConditions
import es.cinfo.tiivii.user.role.Role
import es.cinfo.tiivii.user.signup.store.SignupStore.*
import es.cinfo.tiivii.user.signup.usecase.*
import es.cinfo.tiivii.core.usecase.GetAvailableAvatars
import es.cinfo.tiivii.core.usecase.GetAvailableInterests
import es.cinfo.tiivii.core.usecase.GetAvailableRoles
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

internal class SignupStoreFactory(
    private val storeFactory: StoreFactory,
) {

    private val errorService: ErrorService by diContainer.instance()
    private val configService: ConfigModule by diContainer.instance()

    private sealed class Result {
        class AvailableRoles(val roles: Set<Role>) : Result()
        class AvailableAvatars(val avatars: Set<Avatar>) : Result()
        class AvailableInterests(val interests: Set<Interest>) : Result()
        class AvailableLanguages(val languages: Set<String>, val defaultLanguage: String) : Result()
        class LegalConditions(val legalConditions: es.cinfo.tiivii.user.legal.usecase.LegalConditions) : Result()
        class AddInterest(val interest: Interest) : Result()
        class RemoveInterest(val interest: Interest) : Result()
        class SetRole(val role: Role?) : Result()
        class SetUsername(val username: String) : Result()
        class SetPreferredLanguage(val language: String) : Result()
        class SetEmail(val email: String) : Result()
        class SetCredentials(val credentials: String) : Result()
        class SetBirthday(val birthday: String) : Result()
        class RandomUsername(val randomUsername: String) : Result()
        class SetAvatar(val avatar: Avatar) : Result()
        class AcceptLegalConditions(val isDataCollectionAllowed: Boolean) : Result()
        class FirstName(val name: String) : Result()
        class LastName(val name: String) : Result()
        class AddError(val error: Label): Result()
        class RemoveError(val error: Label): Result()
    }

    fun create(): SignupStore =
        object :
            SignupStore,
            Store<Intent, State, Label> by
            storeFactory.create(
                name = "SignupStore",
                initialState = State(
                    availableRoles = emptySet(),
                    availableLanguages = emptySet(),
                    availableAvatars = emptySet(),
                    availableInterests = emptySet(),
                    randomUsername = null,
                    role = null,
                    username = null,
                    avatar = null,
                    language = null,
                    credentials = null,
                    firstName = null,
                    lastName = null,
                    email = null,
                    birthday = null,
                    interests = emptySet(),
                    legalConditions = null,
                    isDataCollectionAllowed = false,
                    errors = emptySet()
                ),
                bootstrapper = SimpleBootstrapper(Action.GetRoles, Action.GetLanguages, Action.GetAvatars),
                reducer = DefaultReducer,
                executorFactory = ::DefaultExecutor
            ) {
            override fun onDestroy() { }
        }

    private object DefaultReducer : Reducer<State, Result> {
        override fun State.reduce(result: Result): State =
            when (result) {
                is Result.AvailableRoles -> copy(availableRoles = result.roles)
                is Result.AvailableAvatars -> copy(availableAvatars = result.avatars)
                is Result.AvailableInterests -> copy(availableInterests = result.interests)
                is Result.AvailableLanguages -> copy(
                    availableLanguages = result.languages,
                    language = result.defaultLanguage
                )
                is Result.LegalConditions -> copy(
                    legalConditions = result.legalConditions.text
                )
                is Result.AddInterest -> copy(interests = interests + result.interest)
                is Result.RemoveInterest -> copy(interests = interests - result.interest)
                is Result.SetRole -> copy(role = result.role)
                is Result.SetUsername -> copy(username = result.username)
                is Result.SetEmail -> copy(email = result.email)
                is Result.SetCredentials -> copy(credentials = result.credentials)
                is Result.SetBirthday -> copy(birthday = result.birthday)
                is Result.RandomUsername -> copy(randomUsername = result.randomUsername)
                is Result.SetAvatar -> copy(avatar = result.avatar)
                is Result.AcceptLegalConditions -> copy(
                    isDataCollectionAllowed = result.isDataCollectionAllowed
                )
                is Result.FirstName -> copy(firstName = result.name)
                is Result.LastName -> copy(lastName = result.name)
                is Result.SetPreferredLanguage -> copy(language = result.language)
                is Result.AddError -> copy(errors = errors.orEmpty() + result.error)
                is Result.RemoveError -> copy(errors = errors?.let { it - result.error})
            }
    }

    private inner class DefaultExecutor :
        SuspendExecutor<Intent, Action,
                State,
                Result, Label>() {
        override suspend fun executeIntent(intent: Intent, getState: () -> State) {
            when (intent) {
                Intent.GetAvatars -> getAvailableAvatars()
                Intent.GetInterests -> getAvailableInterests()
                Intent.GetLanguages -> getAvailableLanguages()
                Intent.GetLegalConditions -> getLegalConditions(getState().language)
                is Intent.AddInterest -> addInterest(intent.interest)
                is Intent.RemoveInterest -> removeInterest(intent.interest)
                is Intent.SetUsername -> setUsername(intent.username)
                Intent.RollUsername -> rollUsername()
                is Intent.SetAvatar -> setAvatar(intent.avatar)
                is Intent.AcceptLegalConditions -> acceptLegalConditions(intent.isDataCollectionAllowed)
                is Intent.SetFirstName -> setFirstName(intent.firstName)
                is Intent.SetLastName -> setLastName(intent.lastName)
                is Intent.SetPreferredLanguage -> setPreferredLanguage(intent.language)
                is Intent.SetEmail -> setEmail(intent.email, getState().birthday)
                is Intent.SetCredentials -> setCredentials(intent.credentials)
                is Intent.SetBirthday -> {
                    setBirthday(intent.birthday)
                }
                Intent.SignUp -> {
                    signup(
                        getState().username,
                        getState().avatar,
                        getState().language,
                        getState().credentials,
                        getState().firstName,
                        getState().lastName,
                        getState().email,
                        getState().birthday,
                        getState().interests,
                    )
                }
                Intent.LogSignupView -> logSignupView()
            }
        }

        private suspend fun logSignupView() {
            LogEvent(
                action = AnalyticsModel.Action.PageView,
                keyValue = AnalyticsModel.Action.PageView.PAGE_ID_KEY to AnalyticsModel.Action.PageView.SIGN_UP_VALUE).invoke()
        }

        override suspend fun executeAction(action: Action, getState: () -> State) {
            when (action) {
                Action.GetRoles -> getAvailableRoles()
                Action.GetInterests -> getAvailableInterests()
                Action.GetAvatars -> getAvailableAvatars()
                Action.GetLanguages -> getAvailableLanguages()
            }
        }

        private suspend fun getAvailableRoles() {
            val availableRoles = GetAvailableRoles().invoke()
            dispatch(Result.AvailableRoles(availableRoles))
        }

        private suspend fun getAvailableAvatars() {
            when (val outcome = GetAvailableAvatars().invokeWith(ComponentId.SIGNUP)) {
                is Success -> dispatch(Result.AvailableAvatars(outcome.value))
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                is GetAvailableAvatars.Error.UnavailableAvatars ->
                                    publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }

        private suspend fun getAvailableInterests() {
            when (val outcome = GetAvailableInterests().invokeWith(ComponentId.SIGNUP)) {
                is Success -> dispatch(Result.AvailableInterests(outcome.value))
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                is GetAvailableInterests.Error.UnavailableInterests ->
                                    publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }

        private suspend fun getAvailableLanguages() {
            val languages = GetAvailableLanguages().invoke()
            val defaultLanguage = GetDefaultLanguage().invoke()
            dispatch(Result.AvailableLanguages(languages, defaultLanguage))
        }

        private suspend fun getLegalConditions(selectedLanguage: String?) {
            when (val outcome = GetLatestLegalConditions(selectedLanguage).invoke()) {
                is Success -> dispatch(Result.LegalConditions(outcome.value))
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                is GetLatestLegalConditions.Error.UnavailableLegalConditions,
                                GetLatestLegalConditions.Error.LegalModuleDisabled -> publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }

        private fun addInterest(interest: Interest) {
            dispatch(Result.AddInterest(interest))
        }

        private fun removeInterest(interest: Interest) {
            dispatch(Result.RemoveInterest(interest))
        }

        private suspend fun setUsername(username: String) {
            when (val outcome = IsUsernameValid(username).invoke()) {
                is Success -> {
                    dispatch(Result.SetUsername(username))
                    dispatch(Result.RemoveError(Label.InvalidUsername))
                    publish(Label.ValidUsername)
                }
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                is IsUsernameValid.Error.UsernameCheckUnavailable ->
                                    publish(Label.UnexpectedError(outcome.error.getCode()))
                                IsUsernameValid.Error.BlankUsername,
                                IsUsernameValid.Error.UsernameInUse -> {
                                    publish(Label.InvalidUsername)
                                    dispatch(Result.AddError(Label.InvalidUsername))
                                }
                            }
                        }
                    )
                }
            }
        }

        private suspend fun setPreferredLanguage(language: String) {
            getLegalConditions(language)
            dispatch(Result.SetPreferredLanguage(language))
        }

        private suspend fun setEmail(email: String, birthday: String?) {
            when (val outcome = IsEmailValid(email, birthday).invoke()) {
                is Success -> {
                    dispatch(Result.SetEmail(email))
                    dispatch(Result.RemoveError(Label.InvalidEmail))
                    publish(Label.ValidEmail)
                }
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                is IsEmailValid.Error.EmailCheckUnavailable ->
                                    publish(Label.UnexpectedError(outcome.error.getCode()))
                                IsEmailValid.Error.BlankEmail,
                                IsEmailValid.Error.EmailInUse -> {
                                    publish(Label.InvalidEmail)
                                    dispatch(Result.AddError(Label.InvalidEmail))
                                }
                                IsEmailValid.Error.MissingRequiredBirthday -> {
                                    val error = "No user birthday has been set. Email can't be checked without birthday"
                                    errorService.handleStoreStateError(error) {
                                        publish(Label.IllegalOperationError(error))
                                    }
                                }
                            }
                        }
                    )
                }
            }
        }

        private suspend fun setCredentials(credentials: String) {
            val areCredentialsValid = AreCredentialsValid(credentials).invoke()
            if (areCredentialsValid) {
                dispatch(Result.SetCredentials(credentials))
                dispatch(Result.RemoveError(Label.InvalidCredentials))
            } else {
                publish(Label.InvalidCredentials)
                dispatch(Result.AddError(Label.InvalidCredentials))
            }
        }

        private suspend fun setBirthday(birthday: String) {
            val mode = configService.getCoreConfig().signup.mode
            if (mode is ConfigModel.Model.SignupConfig.Mode.Simple){
                val error = "Signup mode is $mode. On this mode SetBirthday operation is not available"
                errorService.handleStoreStateError(error) {
                    publish(Label.IllegalOperationError(error))
                }
            } else {
                val isBirthdayValid = IsBirthdayValid(birthday).invoke()
                if (isBirthdayValid) {
                    when (val outcome = GetRole(birthday).invoke()) {
                        is Failure -> {
                            errorService.handleError(
                                error = outcome.error,
                                sessionExpiredAction = { publish(Label.UserSessionExpired) },
                                requestTimeoutAction = { publish(Label.RequestTimedOut) },
                                handler = {
                                    when (outcome.error) {
                                        GetRole.Error.InvalidAge -> {
                                            publish(Label.InvalidBirthday)
                                            dispatch(Result.AddError(Label.InvalidBirthday))
                                        }
                                    }
                                }
                            )
                        }
                        is Success -> {
                            dispatch(Result.RemoveError(Label.InvalidBirthday))
                            dispatch(Result.SetRole(outcome.value))
                            dispatch(Result.SetBirthday(birthday))
                        }
                    }
                } else {
                    publish(Label.InvalidBirthday)
                    dispatch(Result.AddError(Label.InvalidBirthday))
                }
            }
        }

        private suspend fun rollUsername() {
            when (val outcome = GetRandomUsername().invoke()) {
                is Success -> dispatch(Result.RandomUsername(outcome.value))
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                is GetRandomUsername.Error.UnavailableRandomizer ->
                                    publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }

        private fun setAvatar(avatar: Avatar) {
            dispatch(Result.SetAvatar(avatar))
        }

        private fun acceptLegalConditions(dataCollectionAllowed: Boolean) {
            dispatch(Result.AcceptLegalConditions(dataCollectionAllowed))
        }

        private suspend fun setFirstName(firstName: String) {
            val isFirstNameValid = IsNameValid(firstName).invoke()
            if (isFirstNameValid) {
                dispatch(Result.FirstName(firstName))
                dispatch(Result.RemoveError(Label.InvalidFirstName))
            } else {
                publish(Label.InvalidFirstName)
                dispatch(Result.AddError(Label.InvalidFirstName))
            }
        }

        private suspend fun setLastName(lastName: String) {
            val isLastNameValid = IsNameValid(lastName).invoke()
            if (isLastNameValid) {
                dispatch(Result.LastName(lastName))
                dispatch(Result.RemoveError(Label.InvalidLastName))
            } else {
                publish(Label.InvalidLastName)
                dispatch(Result.AddError(Label.InvalidFirstName))
            }
        }

        private suspend fun signup(
            username: String?,
            avatar: Avatar?,
            language: String?,
            credentials: String?,
            firstName: String?,
            lastName: String?,
            email: String?,
            birthday: String?,
            interests: Set<Interest>,
        ) {
            val outcome = CreateUser(
                username,
                avatar,
                language,
                credentials,
                firstName,
                lastName,
                email,
                birthday,
                interests
            ).invoke()
            when (outcome) {
                is Success -> publish(Label.UserCreated)
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                is CreateUser.Error.InvalidProperty,
                                is CreateUser.Error.UnavailableLegalConditions,
                                is CreateUser.Error.UserCreationUnavailable,
                                CreateUser.Error.InvalidHash ->
                                    publish(Label.UnexpectedError(outcome.error.getCode()))

                            }
                        }
                    )
                }
            }
        }
    }

}

package es.cinfo.tiivii.core.features.playback.binder

import es.cinfo.tiivii.core.features.playback.store.PlaybackStore.*
import es.cinfo.tiivii.core.features.playback.view.PlaybackView.*

internal val STATE_TO_MODEL: State.() -> Model = {
    Model(
        videoUrl = videoUrl,
        shareUrl = shareUrl,
        contentType = contentType?.toViewModel(),
        loading = loading.toViewModel()
    )
}

internal val EVENT_TO_INTENT: Event.() -> Intent = {
    when (this) {
        is Event.LoadVideoUrl -> Intent.LoadVideoUrl(contentId, ignoreList)
        Event.LogPlaybackView -> Intent.LogPlaybackView
    }
}

internal val LABEL_TO_OUTPUT: Label.() -> Output = {
    when (this) {
        Label.UserSessionExpired -> Output.UserSessionExpired
        is Label.PlaybackLocked -> Output.PlaybackLocked(reason.toViewModel())
        is Label.UnexpectedError -> Output.UnexpectedError(code)
        Label.RequestTimedOut -> Output.RequestTimedOut
    }
}

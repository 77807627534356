package es.cinfo.tiivii.nav.menu.model

internal sealed class MenuModel {
    sealed class Model {
        data class Config(
            val faqUrl: String?,
            val supportEmail: String?,
            val tutorialUrl: String?
        )
        data class Declarations(
            val privacyStatementUrl: String?,
            val termsAndConditionsUrl: String?,
            val legalText: String?
        )
    }
}
package es.cinfo.tiivii.core.modules.config

import es.cinfo.tiivii.core.AppPackage
import es.cinfo.tiivii.core.modules.config.ConfigModel.Model.CoreConfig
import es.cinfo.tiivii.core.modules.config.ConfigModel.Model.EnvConfig
import es.cinfo.tiivii.core.util.buildJson
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json

internal interface ConfigModule {
    fun getCoreConfig(): CoreConfig
    fun getEnvConfig(): EnvConfig
}

internal class DefaultConfigModule: ConfigModule {

    private lateinit var coreConfig: CoreConfig
    private lateinit var envConfig: EnvConfig

    private val json: Json = buildJson()

    override fun getCoreConfig(): CoreConfig {
        if (!::coreConfig.isInitialized) {
            coreConfig = decodeCoreConfig()
        }
        return coreConfig
    }

    override fun getEnvConfig(): EnvConfig {
        if (!::envConfig.isInitialized) {
            envConfig = decodeEnvConfig()
        }
        return envConfig
    }

    private fun decodeCoreConfig(): CoreConfig {
        return json.decodeFromString<ConfigModel.Json.CoreConfig>(AppPackage.CORE_CONFIG).toModel()
    }

    private fun decodeEnvConfig(): EnvConfig {
        val build = json.decodeFromString<ConfigModel.Json.Build>(AppPackage.ENV_CONFIG)
        return build.toModel(AppPackage.ENV.lowercase())
    }
}